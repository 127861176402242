<template>
<div>

    <div class="slice slice-sm bg-section-secondary">
        <div v-if="$store.getters.isEnroller || $store.getters.isAdmin" class="container">
            <div v-if="!loading" class="row justify-content-center">
                <div class="col-lg-12">

                    
                    <div class="row mt-2">
                        <div class="col-lg-12">
                            <div class="mb-10">
                                <div class="col-10 form-group mb-5" style="margin: 0 auto;">
                                    
                                    
                                    <div class="btn-group-toggle d-flex w-100 mt-2 overflow-this" data-toggle="buttons">

                                        <label class="btn btn-lg btn-neutral flex-fill ml-sm-4" :class="[ $store.state.messageList == 'agents' ? 'active' : '']">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user mx-3 mr-sm-3"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle>
                                            </svg> 
                                        <input @click="agentInquiry2()" type="radio" name="billing_entity" id="person" >Agent Inquiry</label> 
                                        
                                        <label class="btn btn-lg btn-neutral flex-fill ml-sm-4 " :class="[ $store.state.messageList == 'questionnaire' ? 'active' : '']">
                                            <i class="uil uil-envelope " style="font-size:1rem;"></i> 
                                        <input @click="showQuestionnaire()" type="radio" name="billing_entity" id="company">Questionnaire</label>
                                        
                                        <label class="btn btn-lg btn-neutral flex-fill ml-sm-4 " :class="[ $store.state.messageList == 'general' ? 'active' : '']">
                                            <i class="uil uil-envelope " style="font-size:1rem;"></i> 
                                        <input @click="allMessages2()" type="radio" name="billing_entity" id="company">Messages</label>
                                        
                                        <label @click="allProspects()" class="btn btn-lg btn-neutral flex-fill ml-sm-4" :class="[ $store.state.messageList == 'prospects' ? 'active' : '']">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users mx-3 mr-sm-3"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                            </svg> 
                                        <input type="radio" name="billing_entity" id="company" checked="checked">Prospects</label>
                                    </div>
                                </div>
                               
                                
                                <message-agent v-if="$store.state.messageList == 'agents'"></message-agent>
                                <message-questionnaire v-if="$store.state.messageList == 'questionnaire'"></message-questionnaire>
                                <message-general v-if="$store.state.messageList == 'general'"></message-general>
                                <message-prospect v-if="$store.state.messageList == 'prospects'"></message-prospect>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div v-else style="height:145px;width:100%;display:flex;align-items:center;justify-content:center;">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <div v-if="$store.getters.isAgent && $store.getters.isAdmin == false && $store.getters.isEnroller == false" class="container">
            <div v-if="!loading" class="row justify-content-center">
                <div class="col-lg-12">

                    
                    <div class="row mt-2">
                        <div class="col-lg-12">
                            <div class="mb-10">
                                <div class="col-3 form-group" style="">
                                    
                                    
                                   
                                </div>
                               
                                
                                
                                <message-questionnaire></message-questionnaire>
                              
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div v-else style="height:145px;width:100%;display:flex;align-items:center;justify-content:center;">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        </div>
        <inquiry-view
            ref="iView"
        />
        <message-viewed
            ref="mViewed"
        />
        <message-remove
            ref="mRemove"
        />
</div>
</template>
<script>
import axios from 'axios';
import InfiniteLoading from 'vue-infinite-loading';
import Avatar from 'vue-avatar'

import messageAgent from '../comps/messages/MessageAgent.vue'
import messageQuestionnaire from '../comps/messages/MessageQuestionnaire.vue'
import messageGeneral from '../comps/messages/MessageGeneral.vue'
import messageProspect from '../comps/messages/MessageProspect.vue'

import enrollerAccept from '../comps/EnrollerAccept.vue';
import inquiryView from '../comps/InquiryView.vue';
import messageViewed from '../comps/MessageViewed.vue';
import messageRemove from '../comps/MessageRemove.vue';
export default {
    components: {
        InfiniteLoading,
        messageAgent,
        messageQuestionnaire,
        messageGeneral,
        messageProspect,
        enrollerAccept,
        Avatar,
        inquiryView,
        messageViewed,
        messageRemove,
    },
    data() {
        return {
            role : JSON.parse(localStorage.getItem('access_token2')).role,
            loading: false,
            loadingS: false,
            showContent: true,
            showAgentsMessages: true,
            showQuestionnaireMessages: true,
            showGeneralMessages: false,
            showProspectMessages: false,
            messagesLoaded: false,
            ticketList: [],
            messagesList: [],
            sInfo: {},
            sHour: '',
            sIndex: '',
            page: 0,
            pagem: 1,
            last_page: 1,
            last_pagem: 1,
            routeInitial: false,
            contactInfo: '',
            mViewed: '',
            mIndex: '',
            iviewConfirmPre: false,
            iviewConfirmPreDel: false,
            iviewConfirmSend: false,
            selectedRecruiter: '',
            recruitersList: [],
        }
    },
    created() {
        //this.fetchTickets();
        window.scrollTo(0,0);
        if(this.$store.state.messageList == '') {
            this.$store.state.messageList = 'general';
        }
    },
    computed: {
        
    },
    methods: {
        agentInquiry2() {
            this.showAgentsMessages = true;
            this.$store.state.messageList = 'agents';
            this.showQuestionnaireMessages = false;
            this.showGeneralMessages = false;
            this.showProspectMessages = false;
        },
        showQuestionnaire() {
            this.showAgentsMessages = false;
            this.showQuestionnaireMessages = true;
            this.$store.state.messageList = 'questionnaire';
            this.showGeneralMessages = false;
            this.showProspectMessages = false;
        },
        allMessages2() {
            this.showAgentsMessages = false;
            this.showQuestionnaireMessages = false;
            this.showGeneralMessages = true;
            this.$store.state.messageList = 'general';
            this.showProspectMessages = false;
        },
        allProspects(){
            this.showProspectMessages = true;
            this.$store.state.messageList = 'prospects';
            this.showQuestionnaireMessages = false;
            this.showAgentsMessages = false;
            this.showGeneralMessages = false;
        },
        fetchApplicants() {
            if(this.routeInitial == false) {
                    this.loading = true;
                    this.routeInitial = true;
                    this.loadingS = true;
                }
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.get(`api/fetchinquiry?page=${this.page}`).then(resp => {
                console.log(resp);
                this.last_page = resp.data.t.last_page;
                this.ticketList.push(...resp.data.t.data);
            }).then(resp2 => {
                this.loading = false;
            }).catch(error => {
                if(err.message == "Request failed with status code 401") {
                      
                        this.$router.push({name: 'Login'});
                        this.$store.dispatch('destroyToken').then(resp => {
                            console.log(resp.data);
                            
                            this.$router.push({name: 'Login'});
                        }).then(resp => {  }).catch(err => { this.outLoading = false });
                    }
                this.loading= false;
            });
        },
        fetchTickets() {
            let role = JSON.parse(localStorage.getItem('access_token2')).role;
            console.log(role);
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            if (role.toLowerCase().includes('enroller') || role.toLowerCase().includes('recruitor') || role.toLowerCase().includes('admin')) {
                if(this.routeInitial == false) {
                    this.loading = true;
                    this.routeInitial = true;
                }
                axios.get(`/api/fetchticketsall?page=${this.page}`).then(resp => {
                    console.log(resp);
                    this.last_page = resp.data.t.last_page;
                    this.ticketList.push(...resp.data.t.data);
                    
                }).then(resp2 => {
                    this.loading = false;
                
                }).catch(err => { 
                    if(err.message == "Request failed with status code 401") {
                      
                        this.$router.push({name: 'Login'});
                        this.$store.dispatch('destroyToken').then(resp => {
                            console.log(resp.data);
                            
                            this.$router.push({name: 'Login'});
                        }).then(resp => {  }).catch(err => { this.outLoading = false });
                    }
                    this.loading = false });

            } else if(role.toLowerCase().includes('agent')) {
                console.log('fetching from agent');
                if(this.routeInitial == false) {
                    this.loading = true;
                    this.routeInitial = true;
                }
                axios.get(`/api/fetchticketsagent?page=${this.page}`).then(resp => {
                    console.log(resp.data);
                    this.last_page = resp.data.t.last_page;
                    this.ticketList.push(...resp.data.t.data);
                    this.loading = false;
                }).catch(err => { 
                    if(err.message == "Request failed with status code 401") {
                      
                        this.$router.push({name: 'Login'});
                        this.$store.dispatch('destroyToken').then(resp => {
                            console.log(resp.data);
                            
                            this.$router.push({name: 'Login'});
                        }).then(resp => {  }).catch(err => { this.outLoading = false });
                    }
                    this.loading = false });
            }
            
            
        },
        fetchMessages() {
                axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
                axios.get(`/api/fetchmessages?page=${this.page}`).then(resp => {
                    console.log(resp);
                    this.last_pagem = resp.data.t.last_page;
                    this.messagesList.push(...resp.data.t.data)
                    this.loadingS = false;
                }).catch(err => {
                    if(err.message == "Request failed with status code 401") {
                      
                        this.$router.push({name: 'Login'});
                        this.$store.dispatch('destroyToken').then(resp => {
                            console.log(resp.data);
                            
                            this.$router.push({name: 'Login'});
                        }).then(resp => {  }).catch(err => { this.outLoading = false });
                    }
                });
        
            
        },
        scrollBottom(isVisible) {
            if(!isVisible) { return }
            if(this.page >= this.last_page) { return }
            console.log('Bottom');
            this.page++;
            this.fetchApplicants();
        },
        scrollBottom2(isVisible) {
            if(!isVisible) { return }
            if(this.pagem >= this.last_pagem) { return }
            console.log('Bottom');
            this.pagem++;
            this.fetchMessages();
        },
        returnName(a, b) {
            return a + ' ' + b;
        },
        agentInquiry() {
            this.showContent = true;
            console.log('agent selected');
        },
        allMessages() {
            this.showContent = false;
            if(this.messagesLoaded == false) {
                this.fetchMessages();
                this.messagesLoaded = true;
            }
            console.log('reading messages');
        },
        styleBorder(i) {
            if(i == false) {
                return {borderLeft: '3px solid #5cc9a7'}
            } else {
                return {}
            } 
            
        },
        styleBorder1(i) {
            if(i == 0) {
                return {borderLeft: '4px solid rgba(0,100,0,0.4)', borderTop: '0px solid #5cc9a7', borderBottom: '0px solid #5cc9a7'}
            }  
            
        },
        styleBorder2(i) {
            if(i == 0) {
                return { borderTop: '1px solid #5cc9a7', borderBottom: '1px solid #5cc9a7'}
            } 
         },
        styleBorder3(i) {
            if(i == 0) {
                return {borderRight: '2px solid #5cc9a7', borderTop: '1px solid #5cc9a7', borderBottom: '1px solid #5cc9a7'}
            } 
        },
        styleSuccessRight(date) {
            return date == null ? {borderRight: '1px solid #5cc9a7;'} : {};
        },
        styleSuccessBottom(date) {
            return date == null ? {borderBottom: '1px solid #5cc9a7;'} : {};
        },
        showEnrAccept(i, index) {
            this.sInfo = i;
            this.sHour = i.hour;
            this.sIndex = index;
            if(i.hour) {
                $(this.$refs.enrAccept.$el).modal('show');
            } else {
                this.sInfo = '';
                this.sHour = '';
            }
            
        },
        showIView(i, index) {
            this.contactInfo = i;
            this.iIndex = index;
            this.iviewConfirmPre = false;
            this.iviewConfirmPreDel = false;
            this.iviewConfirmSend = false;
            this.selectedRecruiter = '';
            $(this.$refs.iView.$el).modal('show');
        },
        showMViewed(i, index) {
            this.mViewed = i;
            this.mIndex = index,
            $(this.$refs.mViewed.$el).modal('show');
        },
        showMRemove(i, index) {
            this.mViewed = i;
            this.mIndex = index;
            $(this.$refs.mRemove.$el).modal('show');
        }
    }
}
</script>
<style>
.overflow-this {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
}
.overflow-this::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}
.tc2 tbody tr th {
    border-radius: .75rem 0 0 .75rem;
}

.tc2 tbody th {
    padding: 0 1rem;
}
.tc2 tbody th {
    position: relative;
}
.tc2 {
    border-spacing: 0 1rem;
}
.outlineTicketSuccess {
    border-color: #5cc9a7;
    border-left: 10px solid;
}
.outlineTicketDanger {
    border-color: #ee283c;;
}
</style>