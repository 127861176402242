<template>
    <div>
        <form-header></form-header>
        
        <section>
            <div class="container d-flex flex-column">
                <div class="row align-items-center justify-content-center min-vh-100">
                    <div class="col-md-7 col-lg-7 col-xl-6">
                        <div>
                            <div class="mb-5">
                                <div class="row" style="">
                                    <div class="col-auto align-items-center" style="display:flex;align-items:center;">
                                        <h3 class="h5 mb-0">What is your current weight?</h3>
                                    </div>
                                    
                                </div>
                                
                            
                            </div>
                            <span class="clearfix"></span>
                            <form>
                                <div class="form-group mb-5">
                                    <div class="row justify-content-center" style="">
                                    
                                    <!-- <div class="col text-center">
                                        <button type="button" :class="[ buttonOne ? 'btn-dark' : 'btn-outline-info']" @click="toggleFormButton" value="0 - 4" class="btn btn-sm mx-2" style="width:8rem;">
                                           0 - 4
                                        </button>
                                        <button type="button" :class="[ buttonTwo ? 'btn-dark' : 'btn-outline-info']" @click="toggleFormButton" value="4 - 6" class="btn btn-sm mx-4" style="width:8rem;">
                                           4 - 6
                                        </button>
                                        <button type="button" :class="[ buttonPre ? 'btn-dark' : 'btn-outline-info']" @click="toggleFormButton" value="6 - 12+" class="btn btn-sm mx-2" style="width:8rem;">
                                            6 - 12+
                                        </button>
                                    </div> -->
                                    
                                    <!-- <div class="col text-center">
                                        <button type="button" :class="[ zerosix ? 'btn-dark' : 'btn-outline-info']" @click="toggleFormButton" value="0 - 6" class="btn btn-sm mx-1" style="">
                                           0 - 6
                                        </button>
                                        <button type="button" :class="[ sevennine ? 'btn-dark' : 'btn-outline-info']" @click="toggleFormButton" value="7 - 9" class="btn btn-sm mx-1" style=";">
                                           7 - 9
                                        </button>
                                        <button type="button" :class="[ tentwelve ? 'btn-dark' : 'btn-outline-info']" @click="toggleFormButton" value="10 - 12" class="btn btn-sm mx-1" style="">
                                            10 - 12
                                        </button>
                                        <button type="button" :class="[ thirteenplus ? 'btn-dark' : 'btn-outline-info']" @click="toggleFormButton" value="13+" class="btn btn-sm mx-2" style="">
                                            13 +
                                        </button>
                                        
                                    </div> -->
                                    <div class="h4 text-center mb-0 mt-2 mb-4">
                                        <span v-if="!changed" class="price font-weight-bolder">Select Value</span>
                                        <span v-else class="price font-weight-bolder">{{  value }}</span>
                                    </div>
                                    
                                    <div class="col-12">
                                        <input class="form-range" type="range" @input="showValue" :min="min" :max="max" v-model="value" style="width:100%;" />
                                    </div>

                                </div>
                                    
                                </div>
                                <div class="mt-4">
                                    <button type="button" class="btn btn-block btn-primary hover-continue" @click="submit()" >Continue</button>
                                </div>
                            </form>
                            <div class="mt-4 text-center"><small>Feel free to call our toll free number: </small> <a href="tel:+8002565307" class="small font-weight-bold">(800) 256-5307</a></div></div></div></div></div>
        </section>
    </div>
</template>
<script>
import formHeader from "./Header.vue";


export default {
    components: {
        formHeader
    },
    data() {
        return {
            form: '',
            changed: false,
            value: 150,
            min: 80,
            max: 360,
            zerosix: false,
            sevennine: false,
            tentwelve: false,
            thirteenplus: false,
        }
    },
    created(){
       
       if(this.$store.state.directForm.weight != '') {
            this.value = parseInt(this.$store.state.directForm.weight.split(' ')[0]);
            this.changed = true;
            console.log(this.value);
        }
        
    },
    methods: {
        showValue(e) {
            this.changed = true;
            this.$store.state.directForm.weight = this.value;
            console.log(this.value)
        },
        submit() {
            console.log(this.$store.state.directForm)
            if(this.changed) {
                console.log("yupp")
                this.$router.push({name: 'Questions'})
            } else {
                this.$toasted.error('Select value');
            }
            
        },
        toggleFormButton(e) {
            console.log(e.target.value);
            if(e.target.value == "0 - 6") {
                this.zerosix = true;
                this.sevennine = false;
                this.tentwelve = false;
                this.thirteenplus = false;
            }
            if(e.target.value == "7 - 9") {
                this.zerosix = false;
                this.sevennine = true;
                this.tentwelve = false;
                this.thirteenplus = false;
            }
            if(e.target.value == "10 - 12") {
                this.zerosix = false;
                this.sevennine = false;
                this.tentwelve = true;
                this.thirteenplus = false;
            }
            if(e.target.value == "13+") {
                this.zerosix = false;
                this.sevennine = false;
                this.tentwelve = false;
                this.thirteenplus = true;
            }
            this.$store.state.directForm.a1c = e.target.value;
            
        }
    }
}
</script>
<style scoped>
 .hover-continue:hover {
     background-color: #171347;
 }
</style>