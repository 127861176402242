<template>
<div class="row">
<div v-for="(i, index) in data" :key="index" class="col-md-6 col-sm-12">
<div class="card ">
    <div class="card-header">
                <h3 class="h3 mb-0">Turbo Term - {{i.productName}}<br></h3>
                <p class="text-sm mb-1">Average to Below Average Health, No Life-Threatening Illnesses, Full Coverage Immediately</p>
                <p class=" mb-4">
                    <button class="btn btn-xs btn-outline-info" @click="ShowTurboTermUnderwritting()">Underwritting Guide</button>
                </p>
                <div class="h1 text-center mb-0">
                    $<span class="price font-weight-bolder">{{i.quote}}

                    </span>
                
                    
                </div> 
                <div>
                    <span class="h6 text-center" style="margin: 0 auto;">Monthly Price</span>
                </div>
                
            </div>
            <div class="card-body">
                <ul class="list-unstyled text-sm mt-2 mb-2">
                
                    <div class="form-group">
                        <div class="row justify-content-between">
                            <div class="col-auto"><span class="h6 text-center">Coverage Amount</span></div>
                            
                        </div>
                        
                        <div class="h4 text-center mb-0 mt-3">
                            $<span class="price font-weight-bolder">{{i.coverageValue}},000</span>
                        </div>
                        
                    </div>
                   
                    <input type="range" class="form-range" :min="i.minCoverageRange" :max="i.maxCoverageRange" v-model="i.coverageValue" @input="ChangeTerm($event, index, i)" step="5" id="customRange3" style="width:100%;">
                     <!-- <input type="range" class="form-range" :min="$parent.fidelitytermForm.minCoverageRange" :max="$parent.fidelitytermForm.maxCoverageRange" step="5" :value="$parent.fidelitytermForm.coverageValue" @input="showValue" @change="showValueChanges" id="customRange3" style="width:100%;"> -->
                    
                    <div class="form-group mt-2">
                        <div class="row justify-content-between">
                            <div class="col-auto"><span class="h6 text-center">Term Length</span></div>
                            <div class="col-auto" style="display:flex;justify-content:end;">
                                
                            </div>
                        </div>
                        
                        <div class="h4 text-center mb-0 mt-3">
                            <span class="price font-weight-bolder">{{i.coverageTerm}} </span> Years
                        </div>
                        
                        
                    </div>
                    <input type="range" class="form-range" :min="i.minTermRange" :max="i.maxTermRange" v-model="i.coverageTerm" @input="ChangeTerm($event, index, i)" step="5" id="customRange3" style="width:100%;">
                    
                </ul>
                <div id="form-contact">
            
                    <div class="text-center">
                        
                        <button type="submit" class="btn btn-block btn-lg btn-primary mt-4 mb-2">
                            <span>Add</span>
                            
                        </button>

                        
                    </div>
                </div>
            </div>
</div>
</div>
    <turbo-term
        ref="turboTermUnderwritting"
    />
</div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import turboTerm from '../UModals/TurboTerm.vue';
export default {
  components: { turboTerm },
    name: 'turboterm-component',
    data() {
        return {
            age: moment(this.$store.state.policy.dob, "MM/DD/YYYY").fromNow().split(" ")[0],
            policy: [],
            rate: [],
            data: [],

        }
    },
    created() {
        this.FetchQuote();
    },
    methods: {
        FetchQuote() {
            let age = moment(this.$store.state.policy.dob, "MM/DD/YYYY").fromNow().split(" ")[0];
            this.policy = this.$store.state.policy;
            axios.post('/api/fetchturbotermquote', {age: age}).then(resp => {
                console.log('turbo term ----');
                console.log(resp.data);
                this.rates = resp.data.rates;
                this.CompileRates(resp.data.rates);

            });

        },
        CompileRates(i) {
            console.log('within turbo compiled rates');

             /* Female Male ns ss */

             /* ns: preferred, standard, standard express
             ss: preferred smoker, standard smoker */
            this.data = [];

            let term = 20;

            if(this.age > 60) {
                term = 10;
            }

            let refTerms = [];
            let pullTerms = this.rates.filter(item => item.age == this.age && item.gender == this.policy.gender.charAt(0));
            let filtered = this.rates.filter(item => item.age == this.age && item.term == term && item.gender == this.policy.gender.charAt(0));

            console.log('all terms');;

            pullTerms.forEach((item, index) => {
                refTerms.push(item.term)
            });
            console.log(refTerms)

             if(this.policy.ss == 'ns') {
                for(let i = 0; i<3; i++) {
                    console.log(i);
                    console.log(filtered);
                    let amount = this.policy.amount/1000;
                    let rate = '';
                    if(amount < 250 ) {
                        if(i == 0) {
                            rate = filtered[0]['preferred_100k-250k'];
                        }
                        if(i == 1) {
                            rate = filtered[0]['standard_100k-250k'];
                        }
                        if(i == 2) {
                            
                            rate = filtered[0]['standardexpress_100k-250k'];
                        }

                    }
                    if(amount >= 250 && amount < 500) {
                        if(i == 0) {
                            rate = filtered[0]['preferred_250k-500k'];
                        }
                        if(i == 1) {
                            rate = filtered[0]['standard_250k-500k'];
                        }
                        if(i == 2) {
                            
                            rate = filtered[0]['standardexpress_250k-500k'];
                        }
                    }
                    if(amount >= 500 && amount < 1000) {
                        if(i == 0) {
                            rate = filtered[0]['preferred_500k-1000k'];
                        }
                        if(i == 1) {
                            rate = filtered[0]['standard_500k-1000k'];
                        }
                        if(i == 2) {
                            
                            rate = filtered[0]['standardexpress_500k-1000k'];
                        }
                    }
                    if(amount >= 1000) {
                        if(i == 0) {
                            rate = filtered[0]['preferred_1000+'];
                        }
                        if(i == 1) {
                            rate = filtered[0]['standard_1000+'];
                        }
                        if(i == 2) {
                            
                            rate = filtered[0]['standardexpress_1000+'];
                        }
                    }

                    let productName = "";
                    if(i == 0) {
                        productName = 'Preferred'
                    }
                    if(i == 1) {
                        productName = 'Standard';
                    }
                    if(i == 2) {
                        
                        productName = 'Standard Express';
                    }
                    
                    console.log('rate: ' + rate);
                    
                    let quote = quote = (((rate * (Number(amount))) + Number(36)) * Number(filtered[0].modal_monthly)).toFixed(2);
                    let minTerm = refTerms[0];

                    let maxTerm = refTerms.length >= 1 ? refTerms[refTerms.length-1] : '';

                    console.log('minterm: ----- ' + minTerm);
                    console.log('maxterm: --------- ' + maxTerm)
                   this.data.push({
                        amount: '',
                        term: term,
                        quote: quote,
                        rate: rate,
                        dob: '',
                        minCoverageRange: 100,
                        maxCoverageRange: 1000,
                        coverageValue: amount,
                        minTermRange: minTerm,
                        maxTermRange: maxTerm,
                        coverageTerm: term,
                        gender: this.policy.gender,
                        ss: this.policy.ss, 
                        productName: productName,
                   })
                }
             }
             if(this.policy.ss == 'ss') {
                for(let i = 0; i<2; i++) {
                    console.log(i);
                    console.log(filtered);
                    let amount = this.policy.amount/1000;
                    let rate = '';
                    if(amount < 250 ) {
                        if(i == 0) {
                            rate = filtered[0]['preferredsmoker_100k-250k'];
                        }
                        if(i == 1) {
                            rate = filtered[0]['standardsmoker_100k-250k'];
                        }

                    }
                    if(amount >= 250 && amount < 500) {
                        if(i == 0) {
                            rate = filtered[0]['preferredsmoker_250k-500k'];
                        }
                        if(i == 1) {
                            rate = filtered[0]['standardsmoker_250k-500k'];
                        }
                    }
                    if(amount >= 500 && amount < 1000) {
                        if(i == 0) {
                            rate = filtered[0]['preferredsmoker_500k-1000k'];
                        }
                        if(i == 1) {
                            rate = filtered[0]['standardsmoker_500k-1000k'];
                        }
                    }
                    if(amount >= 1000) {
                        if(i == 0) {
                            rate = filtered[0]['preferredsmoker_1000k'];
                        }
                        if(i == 1) {
                            rate = filtered[0]['standardsmoker_1000k'];
                        }
                    }

                    let productName = "";
                    if(i == 0) {
                        productName = 'Prefered Smoker'
                    }
                    if(i == 1) {
                        productName = 'Standard Smoker';
                    }
                    
                    console.log('rate: ' + rate);
                    
                    let quote = (((rate * (Number(amount))) + Number(36)) * Number(filtered[0].modal_monthly)).toFixed(2);

                    let minTerm = refTerms[0];

                    let maxTerm = refTerms.length >= 1 ? refTerms[refTerms.length-1] : '';

                    console.log('minterm: ----- ' + minTerm);
                    console.log('maxterm: --------- ' + maxTerm)
                   this.data.push({
                        amount: '',
                        term: term,
                        quote: quote,
                        rate: rate,
                        dob: '',
                        minCoverageRange: 100,
                        maxCoverageRange: 1000,
                        coverageValue: amount,
                        minTermRange: minTerm,
                        maxTermRange: maxTerm,
                        coverageTerm: term,
                        gender: this.policy.gender,
                        ss: this.policy.ss, 
                        productName: productName,
                   })
                }
             }


        },
        ChangeTerm(e, i, data) {
            console.log(e.target.value);
            console.log('index: ' + i);
            console.log('data');
            console.log(data);

           

            let filtered = this.rates.filter(item => item.age == this.age && item.term == this.data[i].coverageTerm && item.gender == this.policy.gender.charAt(0));
            let amount = data.coverageValue;
            let rate = '';

            

            if(this.policy.ss == 'ns') {
                if(data.productName == 'Preferred') {
                    if(amount < 250) {
                        rate = filtered[0]['preferred_100k-250k'];
                    }
                    if(amount >= 250 && amount < 500) {
                        rate = filtered[0]['preferred_250k-500k'];
                    }
                    if(amount >= 500 && amount < 1000) {
                        rate = filtered[0]['preferred_500k-1000k'];
                    }
                    if(amount >= 1000) {
                        rate = filtered[0]['preferred_1000+'];
                    }
                }
                if(data.productName == 'Standard') {
                    if(amount < 250) {
                        rate = filtered[0]['standard_100k-250k'];
                    }
                    if(amount >= 250 && amount < 500) {
                        rate = filtered[0]['standard_250k-500k'];
                    }
                    if(amount >= 500 && amount < 1000) {
                        rate = filtered[0]['standard_500k-1000k'];
                    }
                    if(amount >= 1000) {
                        rate = filtered[0]['standard_1000+'];
                    }
                }
                if(data.productName == 'Standard Express') {
                    if(amount < 250) {
                        rate = filtered[0]['standardexpress_100k-250k'];
                    }
                    if(amount >= 250 && amount < 500) {
                        rate = filtered[0]['standardexpress_250k-500k'];
                    }
                    if(amount >= 500 && amount < 1000) {
                        rate = filtered[0]['standardexpress_500k-1000k'];
                    }
                    if(amount >= 1000) {
                        rate = filtered[0]['standardexpress_1000+'];
                    }
                }

            }
            if(this.policy.ss == 'ss') {
                if(data.productName == 'Preferred Soker') {
                    if(amount < 250) {
                        rate = filtered[0]['preferredsmoker_100k-250k'];
                    }
                    if(amount >= 250 && amount < 500) {
                        rate = filtered[0]['preferredsmoker_250k-500k'];
                    }
                    if(amount >= 500 && amount < 1000) {
                        rate = filtered[0]['preferredsmoker_500k-1000k'];
                    }
                    if(amount >= 1000) {
                        rate = filtered[0]['preferredsmoker_1000+'];
                    }
                }
                if(data.productName == 'Standard Smoker') {
                    if(amount < 250) {
                        rate = filtered[0]['standardsmoker_100k-250k'];
                    }
                    if(amount >= 250 && amount < 500) {
                        rate = filtered[0]['standardsmoker_250k-500k'];
                    }
                    if(amount >= 500 && amount < 1000) {
                        rate = filtered[0]['standardsmoker_500k-1000k'];
                    }
                    if(amount >= 1000) {
                        rate = filtered[0]['standardsmoker_1000+'];
                    }
                }


               

            }

            let quote = quote = (((rate * (Number(amount))) + Number(36)) * Number(filtered[0].modal_monthly)).toFixed(2);
            data.rate = rate;
            data.quote = quote;
        },
        ShowTurboTermUnderwritting() {
            $(this.$refs.turboTermUnderwritting.$el).modal('show');
        }

    }
}
</script>