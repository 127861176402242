<template>
<div>
    <section class="slice pt-9 pb-7 bg-light">
        <img src="https://images.unsplash.com/photo-1562557082-7f2785d741e7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1473&q=80" alt="Image" class="img-as-bg blend-mode--multiply">
        <span class="mask bg-gradient-dark opacity-2"></span>
        <div data-offset-top="#navbar-main">
            <div class="container d-flex align-items-center text-center text-lg-left">
                <div class="col px-0">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 text-center">
                            <h2 class="h1 text-white mt-6 mb-2">Carrier Contracts</h2>
                            <h3 class="font-weight-light text-white opacity-8">Becoming an agent with Visionary</h3>
                            <h3 class="font-weight-light text-white opacity-8">
                                <!-- <button @click="$router.push({name: 'RegisterAll2'})" data-scroll-to="" class="btn btn-primary btn-lg btn-icon">
                                    <span class="btn-inner--icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                                        </svg> 
                                    </span>
                                    <span class="btn-inner--text">Registration Page</span>
                                </button> -->
                            </h3>
                            <div class="my-3">
                                
                            </div>
                            <div class="row mt-5">
                                
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="slice slice-lg">
        <div class="container">
            <div class="row justify-content-center mb-5">
                <div class="col-lg-12 text-center">
                    <h4 class="mb-4">Welcome Agents!</h4>
                </div>
                <div class="col-lg-7">
                    <p>
                        The next step to working with Visionary Inc. insurance carriers is to complete and sign the contracting paperwork. All contracts are completed digitally with an electronic signature. Please follow these instructions to get contracted which takes you one step closer to earning commission!
                    </p>
                    
                </div>
            </div>
            <hr class="pb-6">


            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h4 class="mb-4">Fidelity Life</h4>
                </div>
                <div class="col-lg-8">
                    <p>

                    <a href="https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=b6f9a621-51ad-4962-8df7-b39219e9f867&env=na3&acct=141ce13e-f1f8-4a3a-a859-604b91d0a931&v=2">Fidelity Life Producer Contract</a>
                    </p>
                    <p>
                        <a href="https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=32a162c2-3268-49d6-a3b0-7f625ade427c&env=na3&acct=141ce13e-f1f8-4a3a-a859-604b91d0a931&v=2">Fidelity Life General Agent Contract</a> 
                        * Only to be completed by agents/agencies who have downline agents.
                    </p>
                </div>
            </div>
            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h4 class="mb-4">Federal Life</h4>
                </div>
                <div class="col-lg-8">
                    <p>
                        <a href="https://drive.google.com/file/d/1ttdQjSCa57Kwme5lyiJbT039CTwlBHMe/view">Federal Life Contract</a>
                    </p>
                    <p>
                        This contract needs to be signed with a wet signature or adobe. Email back to clientsupport@visionaryinsurance.life
                    </p>
                </div>
            </div>
            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h4 class="mb-4">Transamerica</h4>
                    <p class="" style="font-size:14px;">
                        *Complete this contract if you need enhanced backroom support or are using our referral program
                    </p>
                </div>
                <div class="col-lg-8">
                    <p>
                        These contracts are with Visionary Inc. directly and come with enhanced case management services. 
                    </p>
                    <p>
                        <a href="https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=a83de14b-8b46-4e0e-933e-24c784593805&env=na3&acct=141ce13e-f1f8-4a3a-a859-604b91d0a931&v=2">Marketing Allowance Agreement GA Level</a>
                        Committing to Production or has downline agents.
                    </p>
                    <p>
                        <a href="https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=a83de14b-8b46-4e0e-933e-24c784593805&env=na3&acct=141ce13e-f1f8-4a3a-a859-604b91d0a931&v=2">Marketing Allowance Agreement Producer Level</a>
                    </p>
                    <p>
                        <a href="https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=9225d505-8d12-487c-b8b0-2e4d1dabc549&env=na3&acct=141ce13e-f1f8-4a3a-a859-604b91d0a931&v=2">Marketing Allowance Agreement Downline Agent Level</a>
                    </p>
                </div>
            </div>
            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h5 class="mb-4">Sons of Norway</h5>
                </div>
                <div class="col-lg-8">
                    <p>
                        <a href="https://powerforms.docusign.net/d67fa942-6c86-4d2b-ba15-d1610755ae3a?env=na3&acct=141ce13e-f1f8-4a3a-a859-604b91d0a931&accountId=141ce13e-f1f8-4a3a-a859-604b91d0a931">Sons of Norway Producer Contract </a>
                    </p>
                    
                </div>
            </div>
            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h4 class="mb-4">Gerber</h4>
                </div>
                <div class="col-lg-8">
                    <p>
                        <a href="https://surelc.surancebay.com/sbweb/login.jsp?branchEditable=off&branchRequired=off&branch=Visionary%2C%20Inc.%20(Jessica%20Lopez)&branchVisible=on&cc=jessica%40visionaryinsurance.life&gaId=138&gaName=The%20Brokerage%20Resource%2C%20Inc">Gerber Contracting Link  </a>
                    </p>
                    
                </div>
            </div>
            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h4 class="mb-4">Royal Arcanum</h4>
                </div>
                <div class="col-lg-8">
                    <p>
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfJmdMx9fmPa2u2PeQkQpn8v0PY275xrVGYDh3Ts25dOUW9Uw/viewform">Royal Arcanum Contracting Link  </a>
                    </p>
                    
                </div>
            </div>
            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h4 class="mb-4">American Amicable </h4>
                </div>
                <div class="col-lg-8">
                    <p>
                        <a href="https://powerforms.docusign.net/9eb10e2e-5fa6-45a4-b36a-33a76213766d?env=na3&acct=141ce13e-f1f8-4a3a-a859-604b91d0a931&accountId=141ce13e-f1f8-4a3a-a859-604b91d0a931">American Amicable Contracting Link  </a>
                    </p>
                    
                </div>
            </div>
            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h4 class="mb-4">Ethos </h4>
                </div>
                <div class="col-lg-8">
                    <p>
                        <a href=" https://agents.ethoslife.com/agency/signup/58462.">Ethos Contracting Link  </a>
                    </p>
                    
                </div>
            </div>
            <div class="row justify-content-between">
                <div class="col-lg-4 text-lg-right">
                    <h5 class="mb-4">American General, Mutual of Omaha, Foresters, SBLI, GTL, AETNA and all other Visionary core carriers.</h5>
                </div>
                <div class="col-lg-8">
                    <p>
                        <a href="https://accounts.surancebay.com/oauth/authorize?redirect_uri=https:%2F%2Fsurelc.surancebay.com%2Fproducer%2Foauth%3FreturnUrl%3D%252Fprofile%253FgaId%253D785%2526gaId%253D785%2526autoAdd%253Dfalse%2526requestMethod%253DGET&gaId=785&client_id=surecrmweb&response_type=code">Visionary Contracting Link</a>
                    </p>
                    <p>
                        <ol>
                            <li>
                                If you have never used the Visionary SureLC contracting portal, the first step is to register. If you have registered, simply log in.*NOTE: If you have used SureLC in the past you will still need to register, you may still use the same username, but your password must be different.
                            </li>
                            <li>
                                Once you have logged in, review your personal and/or business entity information to make sure everything is current.
                            </li>
                            <li>
                                Check your E&O tab as well as your Training tab to make sure the most current information has been entered and/or uploaded. 
                            </li>
                            <li>
                                Once you have updated and completed all sections you will see that a green check mark will appear for each section, meaning you may proceed to requesting an appointment with a carrier.
                            </li>
                            <li>
                                 To request an appointment look at the menu on the left hand side, toward the bottom you'll find request appointment.
                            </li>
                        </ol>
                    </p>
                
                </div>
            </div>
            <div class="row justify-content-between">
                <div class="col-lg-4 text-lg-right">
                    <h5 class="mb-4">USA+ Contracting</h5>
                </div>
                <div class="col-lg-8">
                    <p>
                        <a href="https://powerforms.docusign.net/33f55572-c66f-4633-bc1b-7f281766c55b?env=na3&acct=141ce13e-f1f8-4a3a-a859-604b91d0a931&accountId=141ce13e-f1f8-4a3a-a859-604b91d0a931">Visionary Contracting Link</a>
                    </p>
                    <p>
                        <ol>
                            <li>
                                Before you begin contracting, have your driver's license, E&O and State License information saved on your desktop. It will need to be uploaded with the contract request.
                            </li>
                        </ol>
                    </p>
                
                </div>
            </div>
            <div class="row justify-content-between">
                <div class="col-lg-4 text-lg-right">
                    <h5 class="mb-4">CICA Contracting</h5>
                </div>
                <div class="col-lg-8">
                    <p>
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLScsOFLwUqDT6cVzyK4n4rP4vc3diHyZmqJ1RhlMVjY6nA96mQ/viewform?pli=1">Visionary Contracting Link</a>
                    </p>
                    <p>
                        <ol>
                            <li>
                                Once complete, you'll receive an email from Vertafore Sircon system, the email your invitation for contracting will come from is donotreply@vertafore.com
                            </li>
                        </ol>
                    </p>
                
                </div>
            </div>
        </div>
    
</section>
</div>
</template>
<script>
export default {
    
}
</script>