<template>
<div>
<div v-if="$store.state.policy.age >= 18 && $store.state.policy.age <= 85 && this.$parent.transamericaForm.pShow == true " class=mb-2>
                            
    <div class=card>
        <div class=card-header>
            <h3 class="h3 mb-0">Transamerica - Solutions Immediate Whole Life Preferred, Health Class Excellent</h3>
            <p class="text-sm mb-0">Competitively Priced, No Medical Exam; Full Coverage Immediately</p>
            <p class="mt-1 mb-0">
                <button class="btn btn-xs btn-outline-info" @click="ShowUnderwritting()">Underwritting Guide</button>
            </p>
        </div>
        <ul class="list-group list-group-flush">
            <li class=list-group-item>
                <div class="row align-items-center">
                    <div class=col-md-4>
                        <div class="card hover-translate-y-n4 hover-shadow-lg" style="">
                            <div class="card-body text-center">
                                <div class="">
                                    
                                    <h2> {{ $parent.formatCurrency2($parent.transamericaForm.pQuote) }}</h2>
                                </div>
                                <div class="">
                                    <a href="#" class="h6 stretched-link mb-0">Monthly Price</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=col-md-5>
                        <div class="card hover-translate-y-n4 hover-shadow-lg" style="">
                            <div class="card-body text-center">
                                <!-- <div v-if="editQuote" class="pb-3 pt-2">
                                    <h4>{{formatCurrency2(this.editQuoteValue * 1000)}}</h4>
                                    
                                </div>
                                <div v-else class="pb-3 pt-2">
                                    <h4>{{formatCurrency2(this.$store.state.policy.amount * 1000)}}</h4>
                                </div> -->
                                <div class="">
                                    <h4>$ {{ parseInt($parent.transamericaForm.pAmount * 1000).toLocaleString('en') }}</h4>
                                </div>
                                <div class="">
                                    <span class="h6 stretched-link mb-0">Coverage Amount</span>
                                </div>
                                <!-- <div  style="position:absolute;top:0px;left:10%;z-index:44;">
                                
                                        <i @click="changeQuoteCoverage" class="uil uil-ellipsis-h" style="font-size:1.45rem;"></i>
                                    
                                    
                                </div>
                                <div v-if="editQuote == true" style="position:absolute;z-index:44;top:5px;right:21%;cursor:pointer;">
                                    
                                    <i @click="showTicketQuoter" class="uil uil-pen" style="margin: 0px;padding:0px;font-size:1rem;"></i>
                                    
                                </div>
                                <div v-if="editQuote == true" style="position:absolute;z-index:44;top:6px;right:8%">
                                    
                                    <i @click="changeQuoteCoverageExit" class="uil uil-multiply" style="margin: 0px;padding:0px"></i>
                                </div>
                                <div v-if="editQuote" style="position:absolute;height:100%;top:0px;left:0px;z-index:40;display:flex;align-items:center;">
                                    <i @click="changeQuoteCoverageLeft" class="uil uil-angle-left" style="font-size:4rem;"></i>
                                </div>
                                <div v-if="editQuote" style="position:absolute;height:100%;top:0px;right:0px;z-index:40;display:flex;align-items:center;">
                                    <i @click="changeQuoteCoverageRight" class="uil uil-angle-right" style="font-size:4rem;"></i>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class=col-md-3>
                        <div class="">
                            <div class=" p-5 text-center">
                                <div class="pb-4">
                                    <button @click="addToShelf1()" type="button" class="btn btn-primary arrow-up-right">
                                        Apply
                                    </button>
                                </div>
                                <div class="pt-2">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                    
                </div>
                
            </li>
            
                
        </ul>
    </div>
</div>
<div v-if="$store.state.policy.age >= 18 && $store.state.policy.age <= 85 && this.$parent.transamericaForm.sShow == true" class=mb-2>
                            
    <div class=card>
        <div class=card-header>
            <h3 class="h3 mb-0">Transamerica - Solutions Immediate Standard, Health Class Average </h3>
            <p class="text-sm mb-0">No Medical Exam; Full Coverage Immediately</p>
            <p class="mt-1 mb-0">
                <button class="btn btn-xs btn-outline-info" @click="ShowUnderwritting()">Underwritting Guide</button>
            </p>
        </div>
        <ul class="list-group list-group-flush">
            <li class=list-group-item>
                <div class="row align-items-center">
                    <div class=col-md-4>
                        <div class="card hover-translate-y-n4 hover-shadow-lg" style="">
                            <div class="card-body text-center">
                                <div class="">
                                    
                                    <h2> {{ $parent.formatCurrency2($parent.transamericaForm.sQuote) }}</h2>
                                </div>
                                <div class="">
                                    <a href="#" class="h6 stretched-link mb-0">Monthly Price</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=col-md-5>
                        <div class="card hover-translate-y-n4 hover-shadow-lg" style="">
                            <div class="card-body text-center">
                                <!-- <div v-if="editQuote" class="pb-3 pt-2">
                                    <h4>{{formatCurrency2(this.editQuoteValue * 1000)}}</h4>
                                    
                                </div>
                                <div v-else class="pb-3 pt-2">
                                    <h4>{{formatCurrency2(this.$store.state.policy.amount * 1000)}}</h4>
                                </div> -->
                                <div class="">
                                    <h4>$ {{ parseInt($parent.transamericaForm.sAmount * 1000).toLocaleString('en') }}</h4>
                                </div>
                                <div class="">
                                    <span class="h6 stretched-link mb-0">Coverage Amount</span>
                                </div>
                                <!-- <div  style="position:absolute;top:0px;left:10%;z-index:44;">
                                
                                        <i @click="changeQuoteCoverage" class="uil uil-ellipsis-h" style="font-size:1.45rem;"></i>
                                    
                                    
                                </div>
                                <div v-if="editQuote == true" style="position:absolute;z-index:44;top:5px;right:21%;cursor:pointer;">
                                    
                                    <i @click="showTicketQuoter" class="uil uil-pen" style="margin: 0px;padding:0px;font-size:1rem;"></i>
                                    
                                </div>
                                <div v-if="editQuote == true" style="position:absolute;z-index:44;top:6px;right:8%">
                                    
                                    <i @click="changeQuoteCoverageExit" class="uil uil-multiply" style="margin: 0px;padding:0px"></i>
                                </div>
                                <div v-if="editQuote" style="position:absolute;height:100%;top:0px;left:0px;z-index:40;display:flex;align-items:center;">
                                    <i @click="changeQuoteCoverageLeft" class="uil uil-angle-left" style="font-size:4rem;"></i>
                                </div>
                                <div v-if="editQuote" style="position:absolute;height:100%;top:0px;right:0px;z-index:40;display:flex;align-items:center;">
                                    <i @click="changeQuoteCoverageRight" class="uil uil-angle-right" style="font-size:4rem;"></i>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class=col-md-3>
                        <div class="">
                            <div class=" p-5 text-center">
                                <div class="pb-4">
                                    <button @click="addToShelf2()" type="button" class="btn btn-primary arrow-up-right">
                                        Apply
                                    </button>
                                </div>
                                <div class="pt-2">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                    
                </div>
                
            </li>
            
                
        </ul>
    </div>
</div>
<div v-if="$store.state.policy.age >= 18 && $store.state.policy.age <= 80 && this.$parent.transamericaForm.gShow == true" class=mb-2>
                            
    <div class=card>
        <div class=card-header>
            <h3 class="h3 mb-0">Transamerica - Easy Solutions Graded, Health Class Average</h3>
            <p class="text-sm mb-0">No Medical Exam; Full Coverage After Two Years</p>
            <p class="mt-1 mb-0">
                <button class="btn btn-xs btn-outline-info" @click="ShowUnderwritting()">Underwritting Guide</button>
            </p>
        </div>
        <ul class="list-group list-group-flush">
            <li class=list-group-item>
                <div class="row align-items-center">
                    <div class=col-md-4>
                        <div class="card hover-translate-y-n4 hover-shadow-lg" style="">
                            <div class="card-body text-center">
                                <div class="">
                                    
                                    <h2> {{ $parent.formatCurrency2($parent.transamericaForm.gQuote) }}</h2>
                                </div>
                                <div class="">
                                    <a href="#" class="h6 stretched-link mb-0">Monthly Price</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=col-md-5>
                        <div class="card hover-translate-y-n4 hover-shadow-lg" style="">
                            <div class="card-body text-center">
                                <!-- <div v-if="editQuote" class="pb-3 pt-2">
                                    <h4>{{formatCurrency2(this.editQuoteValue * 1000)}}</h4>
                                    
                                </div>
                                <div v-else class="pb-3 pt-2">
                                    <h4>{{formatCurrency2(this.$store.state.policy.amount * 1000)}}</h4>
                                </div> -->
                                <div class="">
                                    <h4>$ {{ parseInt($parent.transamericaForm.gAmount * 1000).toLocaleString('en') }}</h4>
                                </div>
                                <div class="">
                                    <span class="h6 stretched-link mb-0">Coverage Amount</span>
                                </div>
                                <!-- <div  style="position:absolute;top:0px;left:10%;z-index:44;">
                                
                                        <i @click="changeQuoteCoverage" class="uil uil-ellipsis-h" style="font-size:1.45rem;"></i>
                                    
                                    
                                </div>
                                <div v-if="editQuote == true" style="position:absolute;z-index:44;top:5px;right:21%;cursor:pointer;">
                                    
                                    <i @click="showTicketQuoter" class="uil uil-pen" style="margin: 0px;padding:0px;font-size:1rem;"></i>
                                    
                                </div>
                                <div v-if="editQuote == true" style="position:absolute;z-index:44;top:6px;right:8%">
                                    
                                    <i @click="changeQuoteCoverageExit" class="uil uil-multiply" style="margin: 0px;padding:0px"></i>
                                </div>
                                <div v-if="editQuote" style="position:absolute;height:100%;top:0px;left:0px;z-index:40;display:flex;align-items:center;">
                                    <i @click="changeQuoteCoverageLeft" class="uil uil-angle-left" style="font-size:4rem;"></i>
                                </div>
                                <div v-if="editQuote" style="position:absolute;height:100%;top:0px;right:0px;z-index:40;display:flex;align-items:center;">
                                    <i @click="changeQuoteCoverageRight" class="uil uil-angle-right" style="font-size:4rem;"></i>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class=col-md-3>
                        <div class="">
                            <div class=" p-5 text-center">
                                <div class="pb-4">
                                    <button @click="addToShelf3()" type="button" class="btn btn-primary arrow-up-right">
                                        Apply
                                    </button>
                                </div>
                                <div class="pt-2">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                    
                </div>
                
            </li>
            
                
        </ul>
    </div>
</div>
<trans-standard 
    ref="transStandard"
    
/>  
</div>
</template>
<script>
import axios from 'axios';
import transStandard from '../UModals/TransStandard.vue';
export default {
    name: 'transamerica-component',
    components: {
        transStandard,
    },
    data() {
        return {
            values: [],
            preffered: {
                quote: '',
                amount: '',
            }
        }
    },
    mounted() {

    },
    created() {
        this.fetchQuote();
    },
    methods: {
        ShowUnderwritting() {
            $(this.$refs.transStandard.$el).modal('show');
        },
        addToShelf1() {
            var form = [];
            form.id = 'Transamerica - Solutions Immediate Whole Life Preferred, Health Class Excellent';
            form.amount = this.$parent.transamericaForm.pAmount;
            form.quote = this.$parent.transamericaForm.pQuote;
            form.dob = this.$store.state.policy.dob;

            this.$store.state.shelf = [];
            this.$store.commit('addToShelf', form);
            console.log(this.$store.state.shelf[0]);
            console.log(this.$store.state.shelf.length)
            this.$router.push({name: 'prequal.contactinfo'});
           
        },
        addToShelf2() {
            var form = [];
            form.id = 'Transamerica - Solutions Immediate Standard, Health Class Average';
            form.amount = this.$parent.transamericaForm.sAmount;
            form.quote = this.$parent.transamericaForm.sQuote;
            form.dob = this.$store.state.policy.dob;

            this.$store.state.shelf = [];
            this.$store.commit('addToShelf', form);
            this.$router.push({name: 'prequal.contactinfo'});
           
        },
        addToShelf3() {
            var form = [];
            form.id = 'Transamerica - Easy Solutions Graded, Health Class Average';
            form.amount = this.$parent.transamericaForm.gAmount;
            form.quote = this.$parent.transamericaForm.gQuote;
            form.dob = this.$store.state.policy.dob;

            this.$store.state.shelf = [];
            this.$store.commit('addToShelf', form);
            this.$router.push({name: 'prequal.contactinfo'});
           
        },
        fetchQuote() {
            
            axios.post('/api/fetchtransamericaquotes', this.$store.state.policy).then(resp => {
                console.log('transamerica values');
                console.log(resp.data);
                this.$parent.transamericaForm.values = resp.data.qval;
            }).then(resp2 => {
                this.showPreferred();
                this.showStandard();
                this.showGraded();
            });
        },
        showPreferred() {
            let rate = '';
            let quote = '';
            let values = this.$parent.transamericaForm.values;
            if(this.$store.state.policy.gender == 'Male' && this.$store.state.policy.ss == 'ns') {
                
                rate = values.m_ns_rate_1;
            }
            if(this.$store.state.policy.gender == 'Male' && this.$store.state.policy.ss == 'ss') {
                rate = values.m_ss_rate_1;
            }
            if(this.$store.state.policy.gender == 'Female' && this.$store.state.policy.ss == 'ns') {
                rate = values.f_ns_rate_1;
            }
            if(this.$store.state.policy.gender == 'Female' && this.$store.state.policy.ss == 'ss') {
                rate = values.f_ss_rate_1;
            }

            /* Set logic to set min and max values depending on product  */
            let amount = Number(this.$store.state.policy.amount).toFixed(2);

            if(amount < Number(values.min_coverage)) {
                amount = Number(values.min_coverage).toFixed(2);
            }
            if(amount > Number(values.max_coverage)) {
                amount = Number(values.max_coverage).toFixed(2);
            }
            amount = Number(amount) / 1000;
            

            
            
            console.log('Amount: ' + amount);
            quote = (((rate * amount) + Number(values.premium)) * values.mode_eft);
            this.$parent.transamericaForm.pQuote = quote;
            this.$parent.transamericaForm.pAmount = amount;
            if(Number(amount * 1000) == Number(this.$store.state.policy.amount)) {
                this.$parent.transamericaForm.pShow = true;
            } else {
                this.$parent.transamericaForm.pShow = false;
            }
        },
        showStandard() {
            let rate = '';
            let quote = '';
            let values = this.$parent.transamericaForm.values;
            if(this.$store.state.policy.gender == 'Male' && this.$store.state.policy.ss == 'ns') {
                
                rate = values.m_ns_rate_2;
            }
            if(this.$store.state.policy.gender == 'Male' && this.$store.state.policy.ss == 'ss') {
                rate = values.m_ss_rate_2;
            }
            if(this.$store.state.policy.gender == 'Female' && this.$store.state.policy.ss == 'ns') {
                rate = values.f_ns_rate_2;
            }
            if(this.$store.state.policy.gender == 'Female' && this.$store.state.policy.ss == 'ss') {
                rate = values.f_ss_rate_2;
            }

            /* Set logic to set min and max values depending on product  */
            let amount = Number(this.$store.state.policy.amount).toFixed(2);

            if(amount < Number(values.min_coverage)) {
                amount = Number(values.min_coverage).toFixed(2);
            }
            if(amount > Number(values.max_coverage)) {
                amount = Number(values.max_coverage).toFixed(2);
            }
            amount = Number(amount) / 1000;
            

            
            
            console.log('Amount: ' + amount);
            quote = (((rate * amount) + Number(values.premium)) * values.mode_eft);
            this.$parent.transamericaForm.sQuote = quote;
            this.$parent.transamericaForm.sAmount = amount;
            if(Number(amount * 1000) == Number(this.$store.state.policy.amount)) {
                this.$parent.transamericaForm.sShow = true;
            } else {
                this.$parent.transamericaForm.sShow = false;
            }
        },
        showGraded() {
            let rate = '';
            let quote = '';
            let values = this.$parent.transamericaForm.values;
            if(this.$store.state.policy.gender == 'Male' && this.$store.state.policy.ss == 'ns') {
                
                rate = values.m_ns_rate_3;
            }
            if(this.$store.state.policy.gender == 'Male' && this.$store.state.policy.ss == 'ss') {
                rate = values.m_ss_rate_3;
            }
            if(this.$store.state.policy.gender == 'Female' && this.$store.state.policy.ss == 'ns') {
                rate = values.f_ns_rate_3;
            }
            if(this.$store.state.policy.gender == 'Female' && this.$store.state.policy.ss == 'ss') {
                rate = values.f_ss_rate_3;
            }

            /* Set logic to set min and max values depending on product  */
            let amount = Number(this.$store.state.policy.amount).toFixed(2);

            if(amount < Number(values.min_coverage)) {
                amount = Number(values.min_coverage).toFixed(2);
            }
            if(amount > Number(values.max_coverage)) {
                amount = Number(values.max_coverage).toFixed(2);
            }
            amount = Number(amount) / 1000;
            

            
            
            console.log('Amount: ' + amount);
            quote = (((rate * amount) + Number(values.premium)) * values.mode_eft);
            this.$parent.transamericaForm.gQuote = quote;
            this.$parent.transamericaForm.gAmount = amount;
            if(Number(amount * 1000) == Number(this.$store.state.policy.amount)) {
                this.$parent.transamericaForm.gShow = true;
            } else {
                this.$parent.transamericaForm.gShow = false;
            }
        },
    }
}
</script>