<template>
    <div>
        <form-header></form-header>
        
        <section>
            <div class="container d-flex flex-column">
                <div class="row align-items-center justify-content-center min-vh-100">
                    

                    <div class="col-md-7 col-lg-7 col-xl-6">
                        <div>
                            <div class="mb-5">
                                <div class="row" style="">
                                    <div class="col-auto align-items-center" style="display:flex;align-items:center;">
                                        <h3 class="h5 mb-0">Are you a diabetic?</h3>
                                    </div>
                                    
                                </div>
                                
                            
                            </div>
                            <span class="clearfix"></span>
                            <form>
                                <div class="form-group mb-5">
                                    <div class="row justify-content-end" style="">
                                    
                                    <button type="button" :class="[ buttonYes ? 'btn-dark' : 'btn-outline-info']" @click="toggleFormButton" value="yes" class="btn btn-sm px-2" style="width:95px;">
                                        Yes
                                    </button>
                                    <button type="button" :class="[ buttonNo ? 'btn-dark' : 'btn-outline-info']" @click="toggleFormButton" value="no" class="btn btn-sm px-2" style="width:95px;">
                                        No
                                    </button>
                                </div>
                                    
                                </div>
                                <div class="mt-4">
                                    <button type="button" class="btn btn-block btn-primary hover-continue" @click="submit()" >Continue</button>
                                </div>
                            </form>
                            <div class="mt-4 text-center"><small>Feel free to call our toll free number: </small> <a href="tel:+8002565307" class="small font-weight-bold">(800) 256-5307</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import formHeader from "./Header.vue";

export default {
    components: {
        formHeader,
    },
    data() {
        return {
            form: '',
            buttonYes: false,
            buttonNo: false,
        }
    },
    created() {
        if(this.$store.state.directForm.diabetic != '') {
            this.$store.state.directForm.diabetic == "Yes" ? this.buttonYes = true : this.buttonNo = true;
        }
    },
    methods: {
        submit() {
            if(this.buttonYes || this.buttonNo) {
                console.log("yupp")
                this.buttonYes ? this.$router.push({name: 'B'}) : this.$router.push({name: 'Height'})
            } else {
                this.$toasted.error('Select an option');
            }
            
        },
        toggleFormButton(e) {
            
            if(e.target.value == "yes") {
                this.buttonYes = true;
                this.buttonNo = false;
                this.$store.state.directForm.diabetic = 'Yes';
                
                
            }
            if(e.target.value == "no") {
                this.buttonYes = false;
                this.buttonNo = true;
                this.$store.state.directForm.diabetic = 'No';
                
            }

            
        }
    }
}
</script>
<style scoped>
 .hover-continue:hover {
     background-color: #171347;
 }
</style>