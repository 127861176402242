<template>
<div>

    <div class="slice slice-sm bg-section-secondary">
        <div class="container">
            <div class="row mx-n2 justify-content-between">
                
                <div class="col-lg-3 col-sm-6 px-2">
                    <div class="card" style="cursor:pointer;">
                        <div class="card-body text-center" @click="SelectTab('carrier underwriting')">
                            <div class="mb-3">
                                <div class="icon icon-shape icon-md bg-primary shadow-primary text-white">
                                    <i class="fuil uil-book-open"></i>
                                </div>
                            </div>
                            <span class="d-block text-sm text-muted font-weight-bold">
                                Carrier Underwritting
                                
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 px-2">
                    <div class="card" style="cursor:pointer;">
                        <div class="card-body text-center" @click="SelectTab('agent guides')">
                            <div class="mb-3">
                                <div class="icon icon-shape icon-md bg-warning shadow-warning text-white">
                                    <i class="uil uil-user-exclamation"></i>
                                </div>
                            </div>
                            <span class="d-block text-sm text-muted font-weight-bold">
                                Instant Underwriting Tool
                                
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 px-2">
                    <div class="card" style="cursor:pointer;">
                        <div class="card-body text-center" @click="SelectTab('contracting info')">
                            <div class="mb-3">
                                <div class="icon icon-shape icon-md bg-success shadow-success text-white">
                                    <i class="uil uil-file-question-alt"></i>
                                </div>
                            </div>
                            <span class="d-block text-sm text-muted font-weight-bold">
                                Contracting Information
                                
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 px-2">
                    <div class="card" style="cursor:pointer;" @click="SelectTab('account settings')">
                        <div class="card-body text-center">
                            <div class="mb-3">
                                <div class="icon icon-shape icon-md bg-danger shadow-danger text-white">
                                    <i class="uil uil-setting"></i>
                                </div>
                            </div>
                            <span class="d-block text-sm text-muted font-weight-bold">
                                Account Settings
                                
                            </span>
                        </div>
                    </div>
                </div>
                
                
                
            </div>
            <div class="row mx-n2">
                
                <div class="col-lg-3 col-sm-6 px-2">
                    <div class="card" style="cursor:pointer;">
                        <div class="card-body text-center" @click="showDirectQuote()">
                            
                            <div class="mb-3">
                                <div class="icon icon-shape border-primary quote-client">
                                    <i class="uil uil-plus" style="font-size:1rem;"></i>
                                </div>
                            </div>
                            <span class="d-block text-sm text-muted font-weight-bold">
                                Quote Client
                                
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 px-2">
                    <div class="card" style="cursor:pointer;">
                        <div class="card-body text-center" @click="showQuestionairModal()">
                            <div class="mb-3">
                                <div class="icon icon-shape questionnaire-modal" style="">
                                    <i class="uil uil-plus" style="font-size:1rem;"></i>
                                </div>
                            </div>
                            <span class="d-block text-sm text-muted font-weight-bold">
                                All Questionnaires
                                
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 px-2">
                   
                </div>
                
                
                
                
                
            </div>
            <div class="row" style="display:flex;justify-content:center;">
          <div class="col-md-8" style="">
              
            <div class="row" style="">

            <div class="col-12 mb-4">
                <!-- <h5 class="mb-4 pb-4">Account information</h5> -->
                <div class="mb-4">
                  <!-- <div class="row align-items-center mb-3">
                    <div class="col">
                      <h6 class="mb-0">New connections</h6>
                    </div>
                  </div> -->
                  <!-- <div class="row mx-n2">
                    <div class="col-auto text-center mb-3 px-2">
                      <span class="avatar  rounded-circle" @click="SelectTab('account settings')" :class="[tab == 'account settings' ? 'bg-primary text-primary' : 'bg-secondary text-dark']" style="cursor:pointer;">
                        <i class="uil uil-user" :style="[tab == 'account settings' ? {color: 'white'} :  '']"></i>
                      </span>
                      <span class="d-block text-xs mt-1 stretched-link" @click="SelectTab('account settings')" style="cursor:pointer;" :class="[tab == 'account settings' ? 'text-primary' : 'text-muted']">Account Settings</span>
                    </div>
                    <div class="col-auto text-center mb-3 px-2">
                      <span class="avatar rounded-circle" @click="SelectTab('carrier underwriting')" :class="[tab == 'carrier underwriting' ? 'bg-primary text-primary' : 'bg-secondary text-dark']">
                        <i class="uil uil-book-open" :style="[tab == 'carrier underwriting' ? {color: 'white'} :  '']"></i>
                      </span>
                      <span class="d-block text-xs mt-1 stretched-link" @click="SelectTab('carrier underwriting')" style="cursor:pointer;" :class="[tab == 'carrier underwriting' ? 'text-primary' : 'text-muted']">Carrier Underwritting</span>
                    </div>
                    <div class="col-auto text-center mb-3 px-2">
                      <span class="avatar rounded-circle" @click="SelectTab('contracting info')" :class="[tab == 'contracting info' ? 'bg-primary text-primary' : 'bg-secondary text-dark']">
                        <i class="uil uil-file-question-alt" :style="[tab == 'contracting info' ? {color: 'white'} :  '']"></i>
                      </span>
                      <span class="d-block text-xs mt-1 stretched-link" @click="SelectTab('contracting info')" style="cursor:pointer;" :class="[tab == 'contracting info' ? 'text-primary' : 'text-muted']">Contracting Info</span>
                    </div>
                    <div class="col-auto text-center mb-3 px-2">
                      <span class="avatar rounded-circle" @click="SelectTab('agent guides')" :class="[tab == 'agent guides' ? 'bg-primary text-primary' : 'bg-secondary text-dark']">
                        <i class="uil uil-user-exclamation" :style="[tab == 'agent guides' ? {color: 'white'} :  '']"></i>
                      </span>
                      <span class="d-block text-xs mt-1 stretched-link" @click="SelectTab('agent guides')" style="cursor:pointer;" :class="[tab == 'agent guides' ? 'text-primary' : 'text-muted']">Agent Guides</span>
                    </div>
                    
                  </div> -->
                </div>
            </div>
             
             
                <account-password v-if="tab == 'account settings'">
                </account-password> 
                
                <contracting-info v-if="tab == 'contracting info'">

                </contracting-info>
                <agent-guides v-if="tab == 'agent guides'">

                </agent-guides>
              
                
              </div>
          
            
        </div>
       </div>
       <carrier-underwriting v-if="tab == 'carrier underwriting'">

                </carrier-underwriting>
        </div>
        
        </div>
        <quote-all @exit='closeQuoter'
            ref="directQuoter"
        />
        <questionair-modal 
            ref="questionairModal"
        />
        
</div>
</template>
<script>
import axios from 'axios';
import Avatar from 'vue-avatar'
import accountPassword from '../comps/AccountPassword.vue'
import carrierUnderwriting from '../comps/CarrierUnderwriting.vue'
import contractingInfo from '../comps/ContractingInfo.vue';
import agentGuides from '../comps/AgentGuides.vue';
import quoteAll from '../comps/QuoteAll.vue';
import questionairModal from '../comps/QuestionairModal.vue';
export default {
    components: {
        Avatar, accountPassword, carrierUnderwriting, contractingInfo, agentGuides, quoteAll, questionairModal,
    },
    data() {
        return {
            tab: 'account settings',
            user: {
              password: '',
            },
            showQuoter: false,
            pconfirm: '',
            passc: '',
            loading: false,
        }
    },
    created() {
      this.user.email = JSON.parse(localStorage.getItem('access_token2')).email;
      this.user.name = JSON.parse(localStorage.getItem('access_token2')).name;
      this.checkRecruitorLink();
      if(this.$store.state.userTabSelected != '') {
        this.tab = this.$store.state.userTabSelected;
      }
    },
    methods: {
      SelectTab(i) {
        console.log(i)
        this.$store.state.userTabSelected = i;
        this.tab = i;
      },
      checkRecruitorLink() {
        if(this.$store.getters.isRecruiter) {
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.get('/api/fetchrecruitorlink').then(resp => {
              console.log(resp.data.t.slug);
              this.$parent.recrSlug = resp.data.t.slug;
            });
        }
      },
      confirmPassword(event) {

      },
      confirmPassword(event) {
            
            if(event.target.value.length >= 4) {
                console.log(event.target.value);
                if(this.pconfirm == this.user.password) {
                    this.passc = 1;
                    console.log("equals");
                }  else {
                    this.passc = 2;
                }

            } else {
                this.passc = '';
            }
        },
      showDirectQuote() {
          $(this.$refs.directQuoter.$el).modal('show');
      },
      hideDirectQuote() {
          $(this.$refs.directQuoter.$el).modal('hide');
      },
      showQuestionairModal() {
          $(this.$refs.questionairModal.$el).modal('show');
      },
      hideQuestionairModal() {
          $(this.$refs.questionairModal.$el).modal('hide');
      },
      updateAccount() {
        if(this.user.name != '' && this.user.email != '' && this.user.password != '' && this.user.password == this.pconfirm) {
          this.loading = true;
          axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
          axios.post('/api/updateprofile', this.user).then(resp => {
            console.log(resp.data.t);
            this.user.password = '';
            this.pconfirm = '';
            this.passc = '';
          }).then(resp2 => {
              this.loading = false;
              this.$toasted.success("Updated");
          }).catch(err => {
            if(err.message == "Request failed with status code 401") {
                      
                        this.$router.push({name: 'Login'});
                        this.$store.dispatch('destroyToken').then(resp => {
                            console.log(resp.data);
                            
                            this.$router.push({name: 'Login'});
                        }).then(resp => {  }).catch(err => { this.outLoading = false });
                    }
          });
        } else if(this.user.name == '') {
          this.$toasted.error('Name');
        } else if(this.user.email == '') {
          this.$toasted.error('Email');
        } else if(this.user.password == '' || this.user.password != this.pconfirm) {
          this.$toasted.error('Password');
        }
        
      }
    },
}
</script>
<style scoped>
.outlineTicketSuccess {
    border-color: #5cc9a7;
}
.outlineTicketDanger {
    border-color: #ee283c;;
}
</style>