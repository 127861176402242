<template>
<div>
    
   
    <div class="slice slice-sm bg-section-secondary">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="row">
                        <div class="col-lg-12 mb-4">
                            <div class="card mt-5" style="margin-bottom:4px;">
                               <div class="card-body">
                                    <div class="row row-grid align-items-center">
                                        <div class="col-lg-8">
                                            <div class="media align-items-center">
                                                <span class="avatar bg-success text-white rounded-circle mr-3">
                                                    <i class="uil uil-check" style="font-size: 1.75rem;"></i>
                                                </span>
                                                <div class="media-body">
                                                    <h5 class="mb-0">PreQual Questionnaire</h5>
                                                     <p v-if="ticket.agent" class="text-muted h5 lh-150 text-sm mb-0">Agent:  {{ticket.agent.user.name}}</p>
                                                    <!-- <p class="text-muted h5 lh-150 text-sm mb-0">Age: {{ticket.policy_age}}, <span style="text-transform: uppercase;">{{ticket.policy_ss}}</span> | Term: {{ticket.policy_term + " Years"}} </p>
                                                    <p class="text-muted h5 lh-150 text-sm mb-0">Coverage Amount: {{formatCurrency2(ticket.policy_coverage * 1000)}} </p> -->
                                                   
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-auto flex-fill mt-4 mt-sm-0 text-sm-right d-none d-lg-block">
                                            <p>{{$moment(ticket.created_at).local().format('ll')}}</p>
                                            <!-- <h4 class="h6">{{formatCurrency(ticket.policy_monthly)}}</h4> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                            
                            <div class="mt-5">
                                
                                <h5 class="mb-3">Contact information</h5>
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="form-control-label">First name</label> 
                                                    <p v-html="ticket.first_name" class="form-control" type="text" placeholder="Enter your first name"></p>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="form-control-label">Last name</label> 
                                                    <p v-html="ticket.last_name" class="form-control" type="text" placeholder="Also your last name"></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row align-items-center">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="form-control-label">Date of Birth</label> 
                                                    <input v-model="ticket.dob" type="text" class="form-control flatpickr-input" data-toggle="date" placeholder="Select your birth date">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="form-control-label">Gender</label> 
                                                    <p v-html="ticket.gender" type="text" class="form-control flatpickr-input" data-toggle="date" placeholder="Select your birth date"></p>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="form-control-label">Email</label> 
                                                    <p v-html="ticket.email" class="form-control" type="email" placeholder="name@exmaple.com"></p>
                                                </div>
                                            </div>
                                            
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="form-control-label">Phone</label> 
                                                    <p v-html="ticket.phone" class="form-control"  type="text" placeholder="(777) 245-549"></p>
                                                </div>
                                            </div>
                                            
                                            
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-9">
                                                <div class="form-group">
                                                    <label class="form-control-label">Address</label> 
                                                    <p v-html="ticket.address" class="form-control" type="text" placeholder="Enter your home address"></p>
                                                </div>
                                            </div>
                                            <div class="col-sm-3">
                                                <div class="form-group">
                                                    <label class="form-control-label">Number</label> 
                                                    <p v-html="ticket.address_no" class="form-control" type="tel" placeholder="No."></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">
                                                <div class="form-group">
                                                    <label class="form-control-label">City</label> 
                                                    <p v-html="ticket.city" class="form-control" type="text" placeholder="City"></p>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <div class="form-group">
                                                    <label class="form-control-label">State</label> 
                                                    <p class="form-control" type="text" placeholder="City">{{$store.state.state[ticket.state]}}</p>
                                                
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <div class="form-group">
                                                    <label class="form-control-label">ZIP</label> 
                                                    <p v-html="ticket.zip" class="form-control" type="tel" placeholder="ZIP"></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    


                                    <div class="mt-3">
                                        
                                    </div>
                                
                            </div>
                            <hr>
                            
                            <div>
                                    <h5 class="mt-4 mb-3">U.S. Citizenship</h5>
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">U.S. Citizen?</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q1" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <h5 class="mt-4 mb-3">Foreign Nationals: Green Card Information</h5>
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Does your client have a Green Card or TIN number?</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q2" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">IF YES which one?</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q3" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Country of Citizenship</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q4" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <h5 class="mt-4 mb-3">Product Details</h5>
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Product Name</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q5" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Coverage Amount (This is the amount of life insurance you are applying for) Minimum Issue amount is $1,000      Maximum Issue amount by age:                                                          Ages   0-55 $50,000     Ages 56-65  $40,000                                 Ages 66-75 $30,000     Ages  76-85  $25,000</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q6" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Where does your client fit regarding their Health?  What Rate Class applies?</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q7" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                           
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">What Premium did you quote?  Example: Standard Rate Class; $125 per month.</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q8" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">IF...a policy CANNOT be issued as applied for, would you like to see an alternative plan if available?</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q9" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                           
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <h5 class="mt-4 mb-3">Untitled Section</h5>
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Additional Benefits Optional at Additional Cost (Not available with all products and not available in all states)</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q10" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                           
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Any Comments or Questions at this time?</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q11" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                
                                                
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <h5 class="mt-4 mb-3">Payment Options</h5>
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Payment Option</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q12" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                
                                                
                                                
                                            </div>
                                        </div>
                                    </div>

                                    <h5 class="mt-4 mb-3">Lifestyle</h5>
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Within the LAST 12 MONTHS have you used nicotine replacement, smoking or tobacco products in any form including, but not limited to the following: nicotine gum, patch or pills; cigarettes; cigars; pipe; chew; snuff; e-cigarettes; vape; hookah; or have you used marijuana more that 12 times in the last 12 months?</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q13" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Height and Weight:          Example 5' 10'   220 lbs</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q14" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                
                                                
                                                
                                                
                                            </div>
                                        </div>
                                    </div>

                                    <h5 class="mt-4 mb-3">Mesical History Part 1</h5>
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Only IF client is currently UNDER the AGE of 18:  HAVE YOU BEEN...diagnosed with, treated for, tested positive for or been given medical advise by a member of the medical profession for any of the following:  (Check all that APPLY)</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q15" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">PRIOR to the AGE of 45:                                             HAVE YOU BEEN...diagnosed with, treated for, tested positive for or been given medical advise by a member of the medical profession for any of the following:  (Check all that APPLY)</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q16" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Are you currently:  (Check all that APPLY)</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q17" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">HAVE YOU EVER been: diagnosed by a member of the medical profession or tested positive for Acquired Immune Deficiency Syndrome (AIDS), Human Immunodeficiency Virus (HIV) or tested positive on an AIDS/HIV-related test?</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q18" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                           
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">HAVE YOU EVER been: the recipient or been given medical advice by a member of the medical profession to be a recipient of:  (Check all that APPLY)</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q19" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">HAVE YOU EVER been: diagnosed with, treated for, tested positive for or been given medical advice by a member of the medical profession for any of the following: (Check all that APPLY)</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q20" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">HAVE YOU EVER been: .diagnosed with, treated for, tested positive for or been given medical advice by a member of the medical profession for any of the following:  Diabetic Coma?</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q21" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">HAVE YOU EVER been: diagnosed with, treated for, tested positive for or been given medical advice by a member of the medical profession for any of the following: Amputation other than at the time of an accident or trauma?</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q22" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">10 I. HAVE YOU EVER been: diagnosed with, treated for, tested positive for or been given medical advice by a member of the medical profession for any of the following: (Check all that APPLY)</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q23" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">10 J. DURING THE LAST 2 YEARS have you been diagnosed with, treated for, tested positive for or been given medical advice by a member of the medical profession for any of the following: Cancer (other than basal cell carcinoma)?</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q24" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">10 K. DURING THE LAST 2 YEARS have you:  (Check all that APPLY)</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q25" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">10 L. DURING THE LAST 2 YEARS have you:  (Check all that APPLY)</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q26" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">10 M. Been convicted for or plead no contest to reckless driving or operating while intoxicated (DWI/OWI/DUI)or had 3 or more moving violations?</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q27" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                           
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">IF YES to any question in PART 1 Please Provide details.  Reference the Question Number and Letter.  IF all answers are NO please type NONE...</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairsecond.text1" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                
                                                
                                                
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <h5 class="mt-4 mb-3">Mesical History Part 2</h5>
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">11 A. PRIOR to the age of 20; Have you been diagnosed with, treated for, tested positive for or been given medical advice by a member of the medical profession for any of the following:     Diabetes (other than gestational diabetes)?</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q28" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                           
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">11 B. PRIOR to the age of 26 with Crohn’s Disease?</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q29" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">11 C. PRIOR to the age of 45 with: (Check all that APPLY)</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q30" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">11 D. HAVE YOU EVER been diagnosed with, treated for, tested positive for or been given medical advice by a member of the medical profession for any of the following:  (Check all that APPLY)</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q31" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">11 E. Hepatitis C? (If yes, proceed to E1 & E2.)</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q32" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">E1. IF YES above; Has the Hepatitis C been cured?</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q33" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">E2. If CURED, when was the last blood test (RNA PCR Titer) showing the Hepatitis C was cured?</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q34" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">11 F. DURING the LAST 4 YEARS... have you been diagnosed with, treated for, tested positive for or been given medical advice by a member of the medical profession for cancer (other than basal cell carcinoma)?</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q35" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">11 G. During the LAST 2 YEARS,  or been diagnosed with, treated for, tested positive for or been given medical advice by a member of the medical profession for alcoholism, alcohol use/abuse, drug use/abuse(including prescription drugs), muscular dystrophy, or systemic lupus erythematosus (SLE)? (Check ALL that APPLY)</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q36" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">11 H. During the LAST 2 YEARS have you: Required assistance with:  activities of daily living (ADL’s) such as:  (Check ALL that APPLY)</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q37" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                           
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">11 I. Used a wheelchair, electric scooter or electric cart?</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q38" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">I1. If YES to above, regarding Wheelchair USE, provide details regarding use:</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q39" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">11 J. During the LAST 1 YEAR; have you been diagnosed with, treated for, tested positive for, treated or been given medical advice by a member of the medical profession or advised to receive treatment for any of the following:  (Check ALL that APPLY)  </label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q40" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">11 K. During the LAST 1 YEAR; have you been diagnosed with, treated for, tested positive for, treated or been given medical advice by a member of the medical profession or advised to receive treatment for any of the following:  (Check ALL that APPLY)     </label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q41" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">11 L. During the LAST 1 YEAR; have you been diagnosed with, treated for, tested positive for, treated or been given medical advice by a member of the medical profession or advised to receive treatment for any of the following:  : (Check ALL that APPLY)   or have you been: unemployed or disabled and been diagnosed with, treated for or been given medical advice by a member of the medical profession for chronic pain?    </label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q42" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">11 M. During the LAST 1 YEAR; have you been diagnosed with, treated for, tested positive for, treated or been given medical advice by a member of the medical profession or advised to receive treatment for any of the following:  (Check ALL that APPLY)    </label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q43" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">11 M1. IF checked YES to ANGINA above; when was the angina (chest pain) first diagnosed?   </label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q44" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">IF YES to any question in PART 2 Please Provide details.  Reference the Question Number and Letter.  IF all answers are NO please type NONE...</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairsecond.text_2" class="form-control custom-input-lg" type="text" placeholder=""></p>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                
                                                
                                                
                                                
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <h5 class="mt-4 mb-3">Mesical History Part 3</h5>
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">12 A. PRIOR to the age of 45, have you been diagnosed with, treated for, tested positive for or been given medical advice by a member of the medical profession for CANCER  (other than Basal Cell)?  </label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q45" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                           
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">12 B. HAVE YOU EVER been: diagnosed with, treated for, tested positive for or been given medical advice by a member of the medical profession for any of the following:  (Check ALL that APPLY) </label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q46" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                           
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                       <label class="form-control-label">12 C. HAVE YOU EVER been: diagnosed with, treated for, tested positive for or been given medical advice by a member of the medical profession for any of the following:  (Check ALL that APPLY)  </label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q47" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                           
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                       <label class="form-control-label">12 D. DURING the LAST 4 YEARS have you been diagnosed with, treated for, tested positive for or been given medical advice by a member of the medical profession for any of the following:  (Check ALL that APPLY) </label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q48" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                           
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">12 E. Used illegal drugs; alcoholism, alcohol use/abuse, drug use/abuse, (including prescription drugs)? </label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q49" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                           
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">12 F. DURING the LAST 4 YEARS have you been CONVICTED for or PLEAD no contest to: (Check ALL that APPLY)</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q50" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                           
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">12 G. DURING the LAST 2 YEARS have you been diagnosed with, treated for, tested positive for or been given medical advice by a member of the medical profession for any of the following:  (Check all that APPLY)</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q51" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                           
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">12 H. DURING the LAST 2 YEARS... have you been diagnosed with, treated for, tested positive for or been given medical advice by a member of the medical profession for any of the following:  (Check all that APPLY)</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q52" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                           
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">12 I. DURING the LAST 2 YEARS... have you been diagnosed with, treated for, tested positive for or been given medical advice by a member of the medical profession for any of the following: (Check all that APPLY)</label>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q53" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                           
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">12 I1. IF YES to ANGINA; when was the angina (chest pain) first diagnosed?</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairfirst.q54" class="form-control custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                           
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">If the answer to I1 is 0-12 months, then the best rate class is GRADED.               If the answer is 13-24 months, then the best rate class is STANDARD.               If the answer is greater than 24 months, count I as a “No” when referring to directions below.</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairsecond.text_3" class="form-control custom-input-lg" type="text" placeholder=""></p>
                                                            </div>
                                                           
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">IF YES to any question in PART 3 Please Provide details.  Reference the Question Number and Letter.  IF all answers are NO please type NONE...</label> 
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <p v-html="ticket.questionnairsecond.text_4" class="form-control custom-input-lg" type="text" placeholder=""></p>
                                                            </div>
                                                           
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                
                                                
                                                
                                                
                                                
                                                
                                            </div>
                                        </div>
                                    </div>
                        
                                
                                        
                                        
                                        
                                        
                                       
                                    
                                        <div class="row justify-content-end mt-4 pt-4 mb-4 pb-4">
                                            <div class="col-md-12 mt-6">
                                                <div class="row" >
                                                    <div class="col-md-6 justify-content-start">
                                                        <!-- <ul class="cancelOptions">
                                                            <li>
                                                                <button v-if="$store.getters.isEnroller && !ticket.complete" @click="showScheduler()" type="button" class="btn btn-danger">New Schedule</button><br>
                                                            </li>
                                                            <li>
                                                                <button v-if="$store.getters.isEnroller && !ticket.complete && ticket.enroller_id" @click="showEnrCancel()" type="button" class="btn btn-danger">Enroller Cancel</button>
                                                            </li>
                                                            <li>
                                                                <button v-if="$store.getters.isEnroller && !ticket.complete" @click="showEnrCancel()" type="button" class="btn btn-danger">Terminate Ticket</button>
                                                            </li>
                                                        </ul> -->
                                                        <div class="row">
                                                            <div class="col-6 mb-4">
                                                                <button type="button" class="btn btn-danger px-6">
                                                                    Delete
                                                                </button>
                                                            <br>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                        
                                                        
                                                        
                                                    </div>
                                                    <div class="col-6 justify-content-end" style="display:flex;justify-content:end">
                                                        
                                                        
                                                        
                                                        
                                                        <div class="row justify-content-end">
                                                            <div v-if="ticket.viewed == false" class="col-12 mb-4" style="display:flex;justify-content:end;height: fit-content;">
                                                                <span v-if="!loading2" @click="QuestionnaireViewed()" type="button" class="btn btn-success px-6">
                                                                    Viewed
                                                                </span>
                                                                <span v-else type="button" class="btn btn-success px-6">
                                                                    <div class="spinner-border" role="status">
                                                                    <span class="sr-only">Loading...</span>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                            
                                                            
                                                        </div>

                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                        </div>
                                    <div class="mt-3">
                                        
                                    </div>
                                    
                                    
                            </div><hr>
                            <div>
                                <div class="page-inner-header mb-4">
                                    <h5 class="mb-1"></h5>
                                    <p class="text-muted mb-0"></p>
                                </div>
                                
                                <div class="modal fade" id="modal_account_deactivate" tabindex="-1" role="dialog" aria-labelledby="modal_account_deactivate" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                        <div class="modal-content">
                                            <div class="modal-body">
                                                <div class="pt-5 text-center">
                                                    <div class="icon text-danger">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-x"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><line x1="18" y1="8" x2="23" y2="13"></line><line x1="23" y1="8" x2="18" y2="13"></line>
                                                        </svg>
                                                    </div>
                                                    <h4 class="h5 mt-5 mb-3">Extremely important</h4>
                                                    <p>We will immediately delete all of your personal data from our database. This action can not be undone. Are you sure you want to do this?</p>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-sm btn-link text-danger btn-zoom--hover font-weight-600">Delete</button> 
                                                <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="position:absolute;top:0px;margin-top:-14px;left:10px;">
                        <div class="col-auto">
                            <button @click="$router.go(-1)" type="button" class="btn btn-xs btn-primary btn-icon rounded-pill">
                                <span class="btn-inner--icon">
                                    <i class="uil uil-arrow-left"></i>
                                </span> 
                                <span class="btn-inner--text">Back</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <llp 
            ref="llp"
            
        />
    <scheduler 
        ref="schedulerOpen"
    />
    <enroller-cancel 
            ref="enrCancel"
            
        />
    <ticket-cancel 
            ref="ticketCancel"
            
        />
    <slot-assign 
        ref="slotAssign"
        
    />
    <enroller-accept2 
            ref="enrAccept"
            
        />
    <ticket-signature 
        ref="tSignature"
    />
    <ticket-complete 
            ref="tComplete"
            
        />
</div>  
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import flatpickr from "flatpickr";
import llp from '../../../comps/LLP.vue';
import VuePhoneNumberInput from 'vue-phone-number-input';
import _ from 'lodash';
import scheduler from '../../../comps/Scheduler.vue';
import enrollerCancel from '../../../comps/EnrollerCancel.vue';
import ticketCancel from '../../../comps/TicketCancel.vue';
import slotAssign from '../../../comps/SlotAssign.vue';
import enrollerAccept2 from '../../../comps/EnrollerAccept2.vue';
import ticketSignature from '../../../comps/TicketSignature.vue';
import ticketComplete from '../../../comps/TicketComplete.vue';
export default {
    
    components: {
        flatpickr, llp, VuePhoneNumberInput, scheduler, enrollerCancel,
        ticketCancel, slotAssign, enrollerAccept2, ticketSignature, ticketComplete
    },
    data() {
        return {
            loading2: false,
            sSlots: '',
            sHours: '',
            emailc: '',
            phonec: '',
            timeZone: '',
            submitLoading: false,
            noteLoading: false,
            showNoteUpdate: false,
            ticket: '',
            q: {
                policy_age: '',
                policy_amount: '',
                policy_dob: '',
                policy_gender: '',
                policy_quote: '',
                policy_ss: '',
                policy_startdate: '',
                policy_state: '',
                policy_term: '',

                first_name: '',
                last_name: '',
                dob: '',
                gender: '',
                email: '',
                phone: '',
                phone_confirm: '',
                address: '',
                address_no: '',
                city: '',
                state: '',
                zip: '',

                license_number: '',
                license_state: '',
                height_ft: '',
                height_in: '',
                weight: '',
                weight_10: '',
                weight_loss: '',
                weight_reason: '',
                tobacco: '',
                med_one: '',
                med_two: '',
                med_three: '',
                med_four: '',
                med_five: '',
                med_six: '',
                med_seven: '',
                med_eight: '',
                med_nine: '',
                med_ten: '',
                med_eleven: '',
                med_twelve: '',
                med_thirteen: '',
                med_fourteen: '',
                med_fifteen: '',
                med_sixteen: '',
                med_seventeen: '',
                med_eighteen: '',
                additional_one: '',
                additional_two: '',
                additional_three: '',
                owner_one: '',
                owner_two: '',
                owner_three: '',

                owner_address: '',
                owner_addressno: '',
                owner_city: '',
                owner_state: '',
                owner_zip: '',
                owner_phone: '',

                secondaddres_one: '',
                secondaddress_two: '',

                secondaddress_address: '',
                secondaddress_addressno: '',
                secondaddress_city: '',
                secondaddress_state: '',
                secondaddress_zip: '',
                secondaddress_phone: '',

                ben_one: '',
                ben_two: '',
                ben_three: '',
                ben_four: '',
                ben_five: '',
                ben_six: '',

                addben_one: '',
                addben_two: '',
                addben_three: '',
                addben_four: '',
                addben_five: '',
                addben_six: '',

                contben_one: '',
                contben_two: '',
                contben_three: '',
                contben_four: '',
                contben_five: '',
                contben_six: '',

                exist_one: '',
                exist_two: '',
                exist_three: '',
                exist_four: '',
                exist_five: '',

                exist_six: '',
                thirdparty: '',

                payment_one: '',
                payment_two: '',

                payment_three: '',
                payment_four: '',
                payment_five: '',
                payment_six: '',
                payment_seven: '',
                payment_eight: '',

                payment_nine: '',
                payment_ten: '',
                payment_eleven: '',
                paymet_exp: '',
                payment_twelve: '',
                comments: '',

                timezone: '',
                schedule_hourid: '',
            },
            form: {
                timezone: '',
                policy_coverage: '',
                policy_start: '',
                policy_age: '',
                policy_monthly: '',

                first_name: '',
                last_name: '',
                dob: '',
                gender: '',
                email: '',
                phone: '',
                address: '',
                address_no: '',
                city: '',
                state: '',
                zip: '',

                tobacco: '',
                hours: '',
                medical: '',
                employeer_name: '',
                dateofhire: '',
                occupation: '',
                income: '',
                agentexplainedllp: '',
                paymentalternative: '',

                schedule_hourid: '',
            },
            email: "",
            emailValid: '',
            isSS: false,
            isSS2: false,
            
        }
    },
    created() {
        window.scrollTo(0,0);
        console.log('displaying query')
        console.log(this.$route.query.ticket);
        this.ticket = this.$route.query.ticket;

        
    },
    mounted() {
       

        
        

        var dateToday = new Date(); 
       // console.log('01: ' + dateToday);
       // console.log('02: ' + this.$moment(dateToday).utc().format());
       // console.log('1: ' + new Date('7-28-2021 14:00:00'))
       // console.log('2: ' +new Date('7-28-2021 14:00:00').toUTCString());
       // console.log(new Date().toLocaleDateString(undefined, {day:'2-digit',timeZoneName: 'short' }).substring(4));
        
        flatpickr('#datepicker', {
            
            dateFormat: "m-d-Y",
            minDate: dateToday,
            "disable": [
                function(date) {
                return (date.getDay() === 0 || date.getDay() === 6);  // disable weekends
                }
            ],
            "locale": {
                "firstDayOfWeek": 1 // set start day of week to Monday
            }
        });
        flatpickr('#datepicker2', {
            
            dateFormat: "m-d-Y",
           
        }); 
        
    },
    methods: {
        fetchScheduler() {
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.get('/api/fetchscheduler').then(resp => {
               console.log(resp.data);
                this.sSlots = resp.data.t;
            });
            
        },
        QuestionnaireViewed() {
            this.loading2 = true;
             axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
             axios.post('/api/questionnaireviewd', this.ticket).then(resp => {
                console.log(resp.data);
                
                this.loading2 = false;
                this.$toasted.success('Status Viewed');
             }).catch(err => {
                this.loading2 = false;
             });
        },
        
        formatCurrency(amount) {
            amount = (amount / 100);
            return amount.toLocaleString('en', { style: 'currency', currency: 'usd', minimumFractionDigits: 2 });
        },
        formatCurrency2(amount) {
            amount = (amount);
            return amount.toLocaleString('en', { style: 'currency', currency: 'usd', minimumFractionDigits: 0, maximumFractionDigits: 2 });
        },
        printDate(i) {
            return moment.utc(i).local().format('dddd MMM D,   h:mm a');
        },
        printDate2(i) {
            return moment.utc(i).local().format('dddd MMMM DD');
        },
        printDateTimezone(i, t) {
            return moment.utc(i).tz(t).format('dddd MMM D,   h:mm a');
        },
        showNoteButton() {
            this.showNoteUpdate = true;
        },
        updateNotes() {
            this.noteLoading = true;
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.post('/api/updatenotes', this.ticket).then(resp=> {
                console.log(resp);
                this.noteLoading = false;
                this.showNoteUpdate = false;
            }).then(resp2 => {
                this.$toasted.success('Updated');
            });
        },
        cartTotal() {
                let amount = this.$store.state.shelf.reduce((acc, item) => acc + (item.quote), 0);
                if(this.$store.state.rider1.cost) {
                    amount += this.$store.state.rider1.cost;
                }
                return amount;
            },
        emailConfirm(event) {
            
            if(event.target.value.length >= 4) {
                console.log(event.target.value);
                if(event.target.value == this.form.email) {
                    this.emailc = 1;
                }  else {
                    this.emailc = 2;
                }

            } else {
                this.emailc = '';
            }
        },
        validateEmail() {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(this.form.email)) {
                console.log('email looks alright');  
                this.emailValid = true;
            } else {
                console.log('Please enter a valid email address');
                this.emailValid = false;
            }
        },
        phoneConfirm(event) {
            
            if(event.target.value.length >= 4) {
                console.log(event.target.value);
                if(event.target.value == this.q.phone) {
                    this.phonec = 1;
                } else {
                    this.phonec = 2;
                    console.log('phone not equal ' + this.phonec);
                }

            } else {
                this.phonec = '';
            }
        },
        acceptNumber() {
            var x = this.q.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.form.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        acceptNumber2() {
            var x = this.q.phoneConfirm.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.q.phoneConfirm = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        tState(event) {
          if(event.target.value !== '') {
              this.form.tobacco = event.target.value;

              if(event.target.value == 'No' && this.$store.state.policy.ss == 1) {
                  this.isSS = true;
                  
              } else if(event.target.value == 'Yes' && this.$store.state.policy.ss == 2) {
                  this.isSS = true;
                  
              } else {
                  this.isSS = false;
                  
              }
              
          }
        },
        tState2(event) {
          if(event.target.value !== '') {
              this.form.spouse_tobacco = event.target.value;
              if(event.target.value == 'No' && this.$store.state.policySpouse.ss == 1) {
                  this.isSS2 = true;
              } else if(event.target.value == 'Yes' && this.$store.state.policySpouse.ss == 2) {
                  this.isSS2 = true;
              }
              
          }
        },
        hState(event) {
          if(event.target.value !== '') {
              this.form.hours = event.target.value;
              
          }
        },
        mState(event) {
          if(event.target.value !== '') {
              this.form.medical = event.target.value;
              
          }
        },
        llpState(event) {
          if(event.target.value !== '') {
              this.form.agentexplainedllp = event.target.value;
              
          }
        },
        selectTimezone(event) {
            this.timeZone = event.target.value;
            this.q.timezone = event.target.value;
        },
        selectDateChange(event) {
            console.log(event.target.value);
            this.sHours = this.sSlots[parseInt(event.target.value)].hours;
        },
        shouldDisableSlot(i) {
            if(i.ticket_id) {
                console.log(i);
                return true;
            } 
        },
        selectedHour(event) {
            if(event.target.value != 'Select time' || event.target.value == '') {
                this.q.schedule_hourid = event.target.value;
            }
            
        },
        /* printDate(i) {
            return moment.utc(i).local().format('hh:mm a');
        },
        
        printDate2(i) {
            return moment.utc(i).local().format('dddd MMMM DD');
        }, */
        submitTicket() {
            if(this.form.first_name != '' && this.form.last_name != '' && this.form.dob != '' && this.form.gender != '' && this.form.email != '' && this.emailValid == true && this.emailc == 1 && this.form.phone != '' && this.phonec == 1 && this.form.address != '' && this.form.city != '' && this.form.state != '' && this.form.zip != '' && this.isSS == true && this.form.hours == 'Yes' && this.form.medical == "No" && this.employeer_name != '' && this.form.occupation != '' && this.form.income != '' && this.form.agentexplainedllp == 'Yes' && this.form.schedule_hourid != '') {
                this.submitLoading = true;
                let role = JSON.parse(localStorage.getItem('access_token2')).role;
                axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
                axios.post('/api/createticket', this.form).then(resp => {
                    console.log(resp);
                    
                }).then(resp2 => {
                    this.submitLoading = false;
                    this.$toasted.success('Ticket Created');
                    this.$router.push({name: 'admin.tickets'});
                });
            } else if(this.form.first_name == '') {
                this.$toasted.error('First Name');
            } else if(this.form.last_name == '') {
                this.$toasted.error('Last Name');
            } else if(this.form.dob == '') {
                this.$toasted.error('Dob');
            } else if(this.form.gender == '') {
                this.$toasted.error('Gender');
            } else if(this.form.email == '') {
                this.$toasted.error('Email');
            } else if(this.emailValid != true) {
                this.$toasted.error('Invalid Email');
            } else if(this.emailc != 1) {
                this.$toasted.error('Confirm Email');
            } else if(this.form.phone == '') {
                this.$toasted.error('Phone');
            } else if(this.phonec != 1) {
                this.$toasted.error('Confirm Phone');
            } else if(this.form.address == '') {
                this.$toasted.error('Address');
            } else if(this.form.city == '') {
                this.$toasted.error('City');
            } else if(this.form.state == '') {
                this.$toasted.error('State');
            } else if(this.form.zip == '') {
                this.$toasted.error('Zip Code');
            } else if(this.form.gender == '') {
                this.$toasted.error('Gender');
            } else if(this.isSS == false) {
                this.$toasted.error('Tobacco Usage Not matching Quoted');
            } else if(this.form.tobacco == '') {
                this.$toasted.error('Tobacco Usage');
            } else if(this.form.hours != 'Yes') {
                this.$toasted.error('Must be working');
            } else if(this.form.medical != 'No') {
                this.$toasted.error('Medical Status');
            } else if(this.form.employeer_name == '') {
                this.$toasted.error('Employeer Name');
            } else if(this.form.occupation == '') {
                this.$toasted.error("Client's Occupation");
            } else if(this.form.income == '') {
                this.$toasted.error("Client's Income");
            } else if(this.form.agentexplainedllp != 'Yes') {
                this.$toasted.error('Explained LP');
            } else if(this.form.schedule_hourid == '') {
                this.$toasted.error('Schedule Enrollment Hour');
            }
            
        },
        showLLP() {
            $(this.$refs.llp.$el).modal('show');
        },
        closellp() {
            $(this.$refs.llp.$el).modal('hide');
            this.focusForm();
        },
        focusForm:_.debounce(function() {
            const el = this.$el.getElementsByClassName('llpshow')[0];
            console.log(el);
            if (el) {
                // Use el.scrollIntoView() to instantly scroll to the element
                el.scrollIntoView({behavior: 'smooth'});
            }
        }, 500),

        // Starting new ticket question dynamics 
        selectLicenseState(e) {
            if(e.target.value != '') {
                this.q.license_state = e.target.value;
                console.log(e.target.value)
            }
        },
        selectHeightFt(e) {
            if(e.target.value != '') {
                this.q.height_ft = e.target.value;
            }
        },
        selectHeightIn(e) {
            if(e.target.value != '') {
                this.q.height_in = e.target.value;
            }
        },
        selectWeight(e) {
            if(e.target.value != '') {
                this.q.weight = e.target.value;
            }
        },
        SelectWeightTen(e) {
            if(e.target.value != '') {
                this.q.weight_ten = e.target.value;
                console.log('weight 10 ' + e.target.value)
            }
        },
        SelectTobacco(e) {
            if(e.target.value != '') {
                this.q.tobacco = e.target.value;
            }
        },
        SelectMedOne(e) {
            if(e.target.value != '') {
                this.q.med_one = e.target.value;
            }
        },
        SelectMedTwo(e) {
            if(e.target.value != '') {
                this.q.med_two = e.target.value;
            }
        },
        SelectMedThree(e) {
            if(e.target.value != '') {
                this.q.med_three = e.target.value;
            }
        },
        SelectMedFour(e) {
            if(e.target.value != '') {
                this.q.med_four = e.target.value;
            }
        },
        SelectMedFive(e) {
            if(e.target.value != '') {
                this.q.med_five = e.target.value;
            }
        },
        SelectMedSix(e) {
            if(e.target.value != '') {
                this.q.med_six = e.target.value;
            }
        },
        SelectMedSeven(e) {
            if(e.target.value != '') {
                this.q.med_seven = e.target.value;
            }
        },
        SelectMedEight(e) {
            if(e.target.value != '') {
                this.q.med_eight = e.target.value;
            }
        },
        SelectMedNine(e) {
            if(e.target.value != '') {
                this.q.med_nine = e.target.value;
            }
        },
        SelectMedTen(e) {
            if(e.target.value != '') {
                this.q.med_ten = e.target.value;
            }
        },
        SelectMedEleven(e) {
            if(e.target.value != '') {
                this.q.med_eleven = e.target.value;
            }
        },
        SelectMedTwelve(e) {
            if(e.target.value != '') {
                this.q.med_twelve = e.target.value;
            }
        },
        SelectMedThirteen(e) {
            if(e.target.va,lue != '') {
                this.q.med_thirteen = e.target.value;
            }
        },
        SelectMedFourteen(e) {
            if(e.target.value != '') {
                this.q.med_fourteen = e.target.value;
            }
        },
        SelectMedFifteen(e) {
            if(e.target.value != '') {
                this.q.med_fifteen = e.target.value;
            }
        },
        SelectMedSixteen(e) {
            if(e.target.value != '') {
                this.q.med_sixteen = e.target.value;
            }
        },
        SelectMedSeventeen(e) {
            if(e.target.value != '') {
                this.q.med_seventeen = e.target.value;
            }
        },
        SelectMedEighteen(e) {
            if(e.target.value != '') {
                this.q.med_eighteen = e.target.value;
            }
        },
        SelectAdditionalOne(e) {
            if(e.target.value != '') {
                this.q.additional_one = e.target.value;
            }
        },
        SelectAdditionalTwo(e) {
            if(e.target.value != '') {
                this.q.additional_two = e.target.value;
            }
        },
        SelectAdditionalThree(e) {
            if(e.target.value != '') {
                this.q.additional_three = e.target.value;
            }
        },
        SelectSecondAddressOne(e) {
            if(e.target.value != '') {
                this.q.secondaddress_one = e.target.value;
            }
        },
        SelectBenSix(e) {
            if(e.target.value != '') {
                this.q.ben_six = e.target.value;
            }
        },
        SelectExistOne(e) {
            if(e.target.value != '') {
                this.q.exist_one = e.target.value;
            }
        },
        SelectExistSix(e) {
            if(e.target.value != '') {
                this.q.exist_six = e.target.value;
            }
        },
        SelectPaymentOne(e) {
            if(e.target.value != '') {
                this.q.payment_one = e.target.value;
            }
        },
        SelectPaymentTwo(e) {
            if(e.target.value != '') {
                this.q.payment_two = e.target.value;
            }
        },
        SelectPaymentFour(e) {
            if(e.target.value != '') {
                this.q.payment_four = e.target.value;
            }
        },
        SelectPaymentTen(e) {
            if(e.target.value != '') {
                this.q.payment_ten = e.target.value;
            }
        },
        SelectOwnerState(e) {
            if(e.target.value != '') {
                this.q.owner_state = e.target.value;
            }
        },
        SelectSecondState(e) {
            if(e.target.value != '') {
                this.q.secondaddress_state = e.target.value;
            }
        },
        acceptOwnerNumber() {
            var x = this.q.owner_phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.q.owner_phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        submitQuestions() {
            this.submitLoading = true;

            
            

           
            console.log(this.q);
            this.submitLoading = false;
        },
        showScheduler() {
            $(this.$refs.schedulerOpen.$el).modal('show');
        },
        showEnrCancel() {          
                $(this.$refs.enrCancel.$el).modal('show');
        },
        showTicketCancel() {          
                $(this.$refs.ticketCancel.$el).modal('show');
        },
        showSlotAssign() {          
                $(this.$refs.slotAssign.$el).modal('show');
        },
        showEnrAccept() {          
                $(this.$refs.enrAccept.$el).modal('show');
        },
        showTSignature() {
            $(this.$refs.tSignature.$el).modal('show');
        },
        showTComplete() { 
                if(this.ticket.note != '' && this.ticket.note != null) {
                    $(this.$refs.tComplete.$el).modal('show');
                } else {
                    this.$toasted.error('Notes are Missing');
                }      
                
        },
        pulltestquestions() {
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.get('pulltestquestions').then(resp => {
                console.log(resp.data);
            });
        }
        

    }

}
</script>
<style scoped>
.form-control-label {
    color: #525458;
    font-size: 0.975rem;
}
#datepicker {
    background-color: white;
    
}
.outlineTicketSuccess {
    border-color: #5c5e5d;
}
.outlineTicketDanger {
    border-color: #ee283c;;
}
.form-control option:disabled {
    display: none;
}
</style>