<template>
    <div class="modal fade docs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

        <div class="modal-header">
            <h5 class="modal-title h6" id="myLargeModalLabel">Manager User</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            
            <div class="row mt-6 pb-4" style="display: flex; justify-content: center; margin-top: -24px;">
                <div class="col-md-10" style="display: flex; justify-content: center;">
                    <div class="row" style="justify-content: center;">
                        
                        <div class="col-md-12">
                            <div class="card mt-5">
                                <div class="card-body">
                                    <div class="row row-grid align-items-center justify-content-center">
                                        <div class="col-md-7 mb-1" style="display: flex;align-items:center">
                            
                                            <div class="col-12 text-start">
                                            
                                                <div class="ml-3">
                                                <span class="h6"><i class="uil uil-user"></i> {{$parent.userModValues.name}}</span><br>
                                                <span class="h6 mb-0"><i class="uil uil-envelope"></i> {{$parent.userModValues.email}}</span>
                                                <p v-if="$parent.userModValues" class="text mb-0" style="text-transform: capitalize">{{$parent.userModValues.role}}</p>
                                                </div>
                                            </div>
                                        </div>
                                       
                                        <div class="col-md-4 mb-1" style="">
                                            <ul class="list-group">
                                                <!-- <li class="p-2">
                                                    <button v-if="!$parent.userModValueRoles.admin && !$parent.confirm.admin" class="btn btn-xs btn-primary d-block">Admin</button>
                                                    <button v-if="!$parent.userModValueRoles.admin && $parent.confirm.admin" class="btn btn-xs btn-success d-block">Confirm</button>
                                                    <button v-if="$parent.userModValueRoles.admin && !$parent.confirm.admin"  class="btn btn-xs btn-danger d-block">Remove Admin</button>
                                                    <button v-if="$parent.userModValueRoles.admin && $parent.confirm.admin" class="btn btn-xs btn-warning d-block">Confirm</button>
                                                </li> -->
                                                <li class="p-2">
                                                    <button v-if="!$parent.userModValueRoles.agent && !$parent.confirm.agent" @click="confirmAgent2()" class="btn btn-xs btn-primary d-block">Add Agent</button>
                                                    <button v-if="!$parent.userModValueRoles.agent && $parent.confirm.agent" class="btn btn-xs btn-danger d-block" @click="submitAddAgentCancel()">Cancel</button>
                                                    <button v-if="$parent.userModValueRoles.agent && !$parent.confirm.agent" @click="confirmAgent()" class="btn btn-xs btn-danger d-block">Remove Agent</button>
                                                    <button v-if="$parent.userModValueRoles.agent && $parent.confirm.agent" class="btn btn-xs btn-warning d-block" @click="submitRemoveAgent()">
                                                    
                                                        <span v-if="!loading">Confirm</span>
                                                        <div v-else class="spinner-border" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                        </div>
                                                    </button>
                                                </li>
                                                <li class="p-2">
                                                    <button v-if="!$parent.userModValueRoles.recruiter && !$parent.confirm.recruiter" @click="confirmRecruiter()" class="btn btn-xs btn-primary d-block">Add Recruiter</button>
                                                    <button v-if="!$parent.userModValueRoles.recruiter && $parent.confirm.recruiter"  class="btn btn-xs btn-success d-block" @click="submitAddRecruiter()">
                                                        
                                                        <span v-if="!loading">Confirm</span>
                                                        <div v-else class="spinner-border" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                        </div>
                                                    </button>
                                                    <button v-if="$parent.userModValueRoles.recruiter && !$parent.confirm.recruiter" @click="confirmRecruiter()" class="btn btn-xs btn-danger d-block">Remove Recruiter</button>
                                                    <button v-if="$parent.userModValueRoles.recruiter && $parent.confirm.recruiter" class="btn btn-xs btn-warning d-block" @click="submitRemoveRecruiter()">
                                                
                                                        <span v-if="!loading">Confirm</span>
                                                        <div v-else class="spinner-border" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                        </div>
                                                    </button>
                                                </li>
                                                <li class="p-2">
                                                    <button v-if="!$parent.userModValueRoles.enroller && !$parent.confirm.enroller" @click="confirmEnroller()" class="btn btn-xs btn-primary d-block">Add Enroller</button>
                                                    <button v-if="!$parent.userModValueRoles.enroller && $parent.confirm.enroller"  class="btn btn-xs btn-success d-block" @click="submitAddEnroller()">
                                                        
                                                        <span v-if="!loading">Confirm</span>
                                                        <div v-else class="spinner-border" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                        </div>
                                                    </button>
                                                    <button v-if="$parent.userModValueRoles.enroller && !$parent.confirm.enroller" @click="confirmEnroller()" class="btn btn-xs btn-danger d-block">Remove Enroller</button>
                                                    <button v-if="$parent.userModValueRoles.enroller && $parent.confirm.enroller" class="btn btn-xs btn-warning d-block" @click="submitRemoveEnroller()">
                                                    
                                                        <span v-if="!loading">Confirm</span>
                                                        <div v-else class="spinner-border" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                        </div>
                                                    </button>
                                                </li>
                                            </ul>
                                            
                                            
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="$parent.pLoad && $parent.userModValueRoles.changePassword" class="col-md-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label style="color:rgba(0,0,0,0.6)">Name *</label>
                                        <input v-model="$parent.userModValues.name" name="contact-name" type="text" placeholder="Name Here" class="form-control" required="">
                                        <div class="invalid-feedback">
                                        Please type your name.
                                        </div>
                                    </div>
                                    </div>
                                    <div class="col-md-6">
                                    <div class="form-group">
                                        <label style="color: rgba(0,0,0,0.6)">Email Address *</label>
                                        <input v-model="$parent.userModValues.email" name="contact-email" type="email" placeholder="Email@email.com" class="form-control" required="">
                                    </div>
                                    </div>
                                    <div class="col-md-6">
                                    <div class="form-group">
                                        <label style="color: rgba(0,0,0,0.6)">Password *</label>
                                        <input name="password" v-model="$parent.confirm.pass" type="password" placeholder="Password" class="form-control" required="" :class="{'outlineTicketSuccess' : $parent.confirm.pc == 1, 'outlineTicketDanger' : $parent.confirm.pc == 2}">
                                    </div>
                                    </div>
                                    <div class="col-md-6">
                                    <div class="form-group">
                                        <label style="color: rgba(0,0,0,0.6)">Confirm Password *</label>
                                        <input  name="password" @keyup="confirmPassword" v-model="$parent.confirm.passC" type="password" placeholder="Confirm Password" class="form-control" required="" :class="{'outlineTicketSuccess' : $parent.confirm.pc == 1, 'outlineTicketDanger' : $parent.confirm.pc == 2}">
                                    </div>
                                    </div>
                            </div>
                        </div>
                      
                        <div v-if="!$parent.pLoad" class="col-md-12 pb-2" style="display: flex; justify-content: center; align-items: center; margin-top: 40px;">
                            
                            <button @click="showPassChange()" type="submit" class="btn btn-primary btn-loading">
                                
                                <span>Change Password</span>
                                
                            </button>
                        </div>
                        <div v-if="$parent.pLoad && $parent.userModValueRoles.changePassword" class="col-md-12 pb-2" style="display: flex; justify-content: center; align-items: center; margin-top: 40px;">
                            
                            <button @click="submitModifyCredentials()" type="submit" class="btn btn-primary btn-loading">
                                
                                <span v-if="!submitCredentials">Update Password</span>
                                <div v-else class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                                </div>
                            </button>
                        </div>

                        <div v-if="this.$parent.confirm.agentAdd && !$parent.userModValueRoles.agent" class="col-md-12">
                            <div class="form-group mt-4">
                                <select @change="selectEnroller" class="custom-select custom-select-lg mb-3">
                                    <option selected>Open this select menu</option>
                                    <option v-for="i in enrollersList" :key="i.id" :value="i.id">{{i.user.name}}</option>
                                    
                                </select>
                            </div>
                        </div>
                        <div v-if="this.$parent.confirm.agentAdd && !$parent.userModValueRoles.agent" class="col-md-12 pb-2" style="display: flex; justify-content: center; align-items: center; margin-top: 40px;">
                            
                            <button type="submit" @click="submitAddAgent()" class="btn btn-primary btn-loading">
                                
                                <span v-if="!submitAgentLoading">Add Agent</span>
                                <div v-else class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                                </div>
                            </button>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    </div>
</template>
<script>
import Avatar from 'vue-avatar'
import axios from 'axios';
export default {
    name: "user-modify",
   
    components: {
        Avatar,
    },
    
    data() {
        return {
            slot: '',
            loading: false,
            pLoad: false,
            submitCredentials: false,
            submitAgentLoading: false,
            enrollersList: [],
            form: {
                name: '',
            },
            confirm: {
                agent: false,
                recruiter: false,
                enroller: false,
            }
        }
    },
   computed: {
       
       
   },
   created() {
       this.fetchEnrollers();
   },
    methods: {
       showPassChange() {
           this.$parent.pLoad = true;
           this.$parent.userModValueRoles.changePassword = true;
       },
       confirmAgent() {
           this.$parent.confirm.agent = true;
       },
       confirmAgent2() {
           this.$parent.confirm.agent = true;
           this.$parent.confirm.agentAdd = true;
       },
       confirmRecruiter() {
           this.$parent.confirm.recruiter = true;
       },
       confirmEnroller() {
           this.$parent.confirm.enroller = true;
       },
        fetchEnrollers() {
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.get('/api/fetchrecruitors2').then(resp => {
                console.log(resp);
                this.enrollersList = resp.data.t;
            });
        },
        selectEnroller(event) {
          
            console.log(event.target.value);
            if(event.target.value !== '') {
            
                this.$parent.userModValues.recruiter_id = event.target.value;
            }
        },
        confirmPassword(event) {
            
            if(event.target.value.length >= 4) {
                console.log(event.target.value);
                if(this.$parent.confirm.pass == this.$parent.confirm.passC) {
                    this.$parent.confirm.pc = 1;
                    console.log("equals");
                }  else {
                    this.$parent.confirm.pc = 2;
                }

            } else {
                this.passc = '';
            }
        },
        submitAddAgentCancel() {
            this.$parent.confirm.agent = false;
            this.$parent.confirm.agentAdd = false;
        },
       submitRemoveAgent() {
           this.loading = true;
           axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
           axios.post('/api/modifyremoveagent', this.$parent.userModValues).then(resp => {

               console.log(resp);
               this.$parent.userModValueRoles.agent = false;
               this.$parent.userModValues = resp.data.u;
               this.$parent.userList[this.$parent.modIndex] = resp.data.u;
               this.$parent.confirm.agent = false;

               this.$parent.page = 1;
               this.$parent.userList = [];
               this.loading = false;
           }).then(resp2 => {
               this.$toasted.success('Updated');
               this.$parent.fetchUsers();
           }).catch(err => { this.$parent.userModValueRoles.agent = false;this.$parent.confirm.agent = false;this.loading=false; });
       },
       submitAddAgent() {
           if(this.$parent.userModValues.recruiter_id != '') {
               this.submitAgentLoading = true;
                this.loading = true;
                axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
                axios.post('/api/modifyaddagent', this.$parent.userModValues).then(resp => {
                    console.log(resp);
                    this.$parent.userModValueRoles.agent = true;
                    this.$parent.userModValues = resp.data.u;
                    this.$parent.userList[this.$parent.modIndex] = resp.data.u;
                    this.$parent.confirm.agent = false;
                    this.$parent.confirm.agentAdd = false;
                    console.log(this.$parent.userList);
                    this.submitAgentLoading = false;
                    this.$parent.page = 1;
                    this.$parent.userList = [];
                    this.loading = false;
                }).then(resp2 => {
                    this.$toasted.success('Updated');
                    this.$parent.fetchUsers();
                }).catch(err => { this.submitAgentLoading = false;this.$parent.confirm.agentAdd = false;this.loading=false; });

           } else if(this.$parent.userModValues.recruiter_id == '') {
               this.$toasted.error('Select Recruiter');
           }
           
       },
       submitRemoveRecruiter() {
           this.loading = true;
           axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
           axios.post('/api/modifyremoverecruiter ', this.$parent.userModValues).then(resp => {

               console.log(resp);
               this.$parent.userModValueRoles.recruiter = false;
               this.$parent.userModValues = resp.data.u;
               this.$parent.userList[this.$parent.modIndex] = resp.data.u;
               this.$parent.confirm.recruiter = false;
               this.$parent.page = 1;
               this.$parent.userList = [];
               this.loading = false;

           }).then(resp2 => {
               this.$toasted.success('Updated');
               this.$parent.fetchUsers();
           }).catch(err => { this.$parent.userModValueRoles.recruiter = false;this.$parent.confirm.recruiter = false;this.loading=false; });
       },
       submitAddRecruiter() {
           this.loading = true;
           axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
           axios.post('/api/modifyaddrecruiter', this.$parent.userModValues).then(resp => {
               console.log(resp);
               this.$parent.userModValueRoles.recruiter = true;
               this.$parent.userModValues = resp.data.u;
               this.$parent.userList[this.$parent.modIndex] = resp.data.u;
               this.$parent.confirm.recruiter = false;
               this.$parent.page = 1;
               this.$parent.userList = [];
               this.loading = false;
               
           }).then(resp2 => {
               this.$toasted.success('Updated');
               this.$parent.fetchUsers();
           }).catch(err => { this.$parent.userModValueRoles.recruiter = true;this.$parent.confirm.recruiter = false;this.loading = false;});
       },
       submitRemoveEnroller() {
           this.loading = true;
           axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
           axios.post('/api/modifyremoveenroller', this.$parent.userModValues).then(resp => {

               console.log(resp);
               this.$parent.userModValueRoles.enroller = false;
               this.$parent.userModValues = resp.data.u;
               this.$parent.userList[this.$parent.modIndex] = resp.data.u;
               this.$parent.confirm.enroller = false;
               this.$parent.page = 1;
               this.$parent.userList = [];
               this.loading = false;

           }).then(resp2 => {
               this.$toasted.success('Updated');
               this.$parent.fetchUsers();
           }).catch(err => { this.$parent.userModValueRoles.enroller = false;this.$parent.confirm.enroller = false;this.loading=false; });
       },
       submitAddEnroller() {
           this.loading = true;
           axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
           axios.post('/api/modifyaddenroller', this.$parent.userModValues).then(resp => {
               console.log(resp);
               this.$parent.userModValueRoles.enroller = true;
               this.$parent.userModValues = resp.data.u;
               this.$parent.userList[this.$parent.modIndex] = resp.data.u;
               this.$parent.confirm.enroller = false;
               this.$parent.page = 1;
               this.$parent.userList = [];
               this.loading = false;
               
           }).then(resp2 => {
               this.$toasted.success('Updated');
               this.$parent.fetchUsers();
           }).catch(err => { this.$parent.userModValueRoles.enroller = true;this.$parent.confirm.enroller = false;this.loading=false;  });
       },
       submitModifyCredentials() {
           let pLength = this.$parent.confirm.pass.length; 
           console.log(this.$parent.confirm.pass.length);
           if(pLength >= 8 && this.$parent.confirm.pc == 1) {
               this.submitCredentials = true;
               this.$parent.userModValues.password = this.$parent.confirm.pass;
               axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
               axios.post('/api/modifyupdatecredentials', this.$parent.userModValues).then(resp => {
                   this.$parent.userModValues = resp.data.u;
                   //this.$parent.userList[this.$parent.modIndex] = resp.data.u;
                   this.$parent.userModValueRoles.changePassword = false;
                   this.$parent.pLoad = false;

                    this.submitCredentials = false;
                    this.$parent.page = 1;
                    this.$parent.userList = [];

               }).then(resp2 => {
                   this.$toasted.success('Updated');
                   this.$parent.fetchUsers();
               }).catch(err => { this.submitCredentials = false;this.$parent.userModValueRoles.changePassword = false;this.pLoad = false; });
           } else if(pLength < 8) {
               this.$toasted.error('Password Should be Atleast 8 Characters');
           } else if(this.$parent.confirm.pc != 1) {
               this.$toasted.error("Passwords Don't Match")
           } else {
               this.$toasted.error('Another error');
           }
       }
    }
    
}
</script>
<style scoped>
.outlineTicketSuccess {
    border-color: #5cc9a7;
}
.outlineTicketDanger {
    border-color: #ee283c;;
}
</style>