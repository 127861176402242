<template>
<div>
    
   
    <div class="slice slice-sm bg-section-secondary">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="row">
                        <div class="col-lg-12 mb-4">
                            <div class="card mt-5" style="margin-bottom:4px;">
                                <div v-if="ticket.policy_monthly !== null && ticket.policy_monthly != ''" class="card-body">
                                    <div class="row row-grid align-items-center">
                                        <div class="col-lg-8">
                                            ello: {{ticket.policy_monthly}}
                                            <div class="media align-items-center">
                                                <span class="avatar bg-success text-white rounded-circle mr-3">
                                                    <i class="uil uil-check" style="font-size: 1.75rem;"></i>
                                                </span>
                                                <div class="media-body">
                                                    <h5 class="mb-0">{{ ticket.product_name }}</h5>
                                                    <p class="text-muted lh-150 text-sm mb-0">Age {{ticket.policy_age}} <span v-if="ticket.policy_startdate">| Date of policy start: {{ticket.policy_startdate}}</span> </p>
                                                    <p class="text-muted lh-150 text-sm mb-0"><span v-if="ticket.policy_term">Term {{ticket.policy_term}}  |</span> Coverage Amount: {{ formatCurrency2(ticket.policy_coverage)}} </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-auto flex-fill mt-4 mt-sm-0 text-sm-right d-none d-lg-block">
                                            <h4 class="h6">${{formatCurrency2(Number(ticket.policy_monthly).toFixed(2))}}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                            
                            <div class="mt-5">
                                
                                <h5 class="mb-3">Contact information</h5>
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="form-control-label">First name</label> 
                                                    <p v-html="ticket.first_name" class="form-control" type="text" placeholder="Enter your first name"></p>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="form-control-label">Last name</label> 
                                                    <p v-html="ticket.last_name" class="form-control" type="text" placeholder="Also your last name"></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row align-items-center">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="form-control-label">Date of Birth</label> 
                                                    <input v-model="ticket.dob" type="text" class="form-control flatpickr-input" data-toggle="date" placeholder="Select your birth date">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="form-control-label">Gender</label> 
                                                    <p v-html="ticket.gender" type="text" class="form-control flatpickr-input" data-toggle="date" placeholder="Select your birth date"></p>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="form-control-label">Email</label> 
                                                    <p v-html="ticket.email" class="form-control" type="email" placeholder="name@exmaple.com"></p>
                                                </div>
                                            </div>
                                            
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="form-control-label">Phone</label> 
                                                    <p v-html="ticket.phone" class="form-control"  type="text" placeholder="(777) 245-549"></p>
                                                </div>
                                            </div>
                                            
                                            
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-9">
                                                <div class="form-group">
                                                    <label class="form-control-label">Address</label> 
                                                    <p v-html="ticket.address" class="form-control" type="text" placeholder="Enter your home address"></p>
                                                </div>
                                            </div>
                                            <div class="col-sm-3">
                                                <div class="form-group">
                                                    <label class="form-control-label">Number</label> 
                                                    <p v-html="ticket.address_no" class="form-control" type="tel" placeholder="No."></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">
                                                <div class="form-group">
                                                    <label class="form-control-label">City</label> 
                                                    <p v-html="ticket.city" class="form-control" type="text" placeholder="City"></p>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <div class="form-group">
                                                    <label class="form-control-label">State</label> 
                                                    <p class="form-control" type="text" placeholder="City">{{$store.state.state[ticket.state]}}</p>
                                                
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <div class="form-group">
                                                    <label class="form-control-label">ZIP</label> 
                                                    <p v-html="ticket.zip" class="form-control" type="tel" placeholder="ZIP"></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    


                                    <div class="mt-3">
                                        
                                    </div>
                                
                            </div>
                            <hr>
                            
                            <div>
                                <h5 class="mt-4 mb-3">General information</h5>
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label class="form-control-label">Driver's License Number including state</label> 
                                                    <div class="row">
                                                        <div class="col-5">
                                                            <p v-html="ticket.pquestions.q1" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                        </div>
                                                        <div class="col-1">

                                                        </div>
                                                        <div class="col-4">
                                                            <p class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number">{{$store.state.state[ticket.pquestions.q2]}}</p>
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label class="form-control-label">What is the proposed insured's current height</label> 
                                                    <div class="row">
                                                        <div class="col-5">
                                                            <p v-html="ticket.pquestions.q3" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                        </div>
                                                        <div class="col-1">

                                                        </div>
                                                        <div class="col-4">
                                                            <p v-html="ticket.pquestions.q4" class="form-control-new" type="text" placeholder="Enter License Number"></p>
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label class="form-control-label">What is the proposed insured's current weight</label> 
                                                    <div class="row">
                                                        <div class="col-5">
                                                            <p v-html="ticket.pquestions.q5" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <h5 class="mt-4 mb-3">Weight Loss</h5>
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label class="form-control-label">Has the proposed insured lost more than 10 lbs. in the past 6 months? </label> 
                                                    <div class="row">
                                                        <div class="col-5">
                                                            <p v-html="ticket.pquestions.q6" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                        </div>
                                                        <div class="col-1">

                                                        </div>
                                                        <div class="col-4">
                                                            
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label class="form-control-label">Amount of weight loss</label> 
                                                    <div class="row">
                                                        <div class="col-5">
                                                            <p v-html="ticket.pquestions.q7" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                        </div>
                                                        <div class="col-1">

                                                        </div>
                                                        <div class="col-4">
                                                            
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label class="form-control-label">Reson for weight loss</label> 
                                                    <p v-html="ticket.pquestions.q8" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                    
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <h5 class="mt-4 mb-3">Tobacco </h5>
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label class="form-control-label">In the past 12 months have you, the proposed insured used any form of tobacco or nicotine products?</label> 
                                                    <div class="row">
                                                        <div class="col-5">
                                                            <p v-html="ticket.pquestions.q9" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                        </div>
                                                        <div class="col-1">

                                                        </div>
                                                        <div class="col-4">
                                                            
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <h5 class="mt-4 mb-3">Medical Questions</h5>
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Are you currently hospitalized, confined to a nursing home, hospice, bed, assisted living, rehabilitation or psychiatric facility, receiving home health care; or have you been hospitalized two or more times in the past 12 months?</label> 
                                                <div class="row">
                                                    <div class="col-5">
                                                        <p v-html="ticket.pquestions.q10" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                    </div>
                                                    <div class="col-1">

                                                    </div>
                                                    <div class="col-4">
                                                        
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Do you need ongoing personal assistance performing regular activities of daily living: bathing, dressing, eating, taking medication, toleting, or moving around the house: or are you onfined to a wheelchair?</label> 
                                                <div class="row">
                                                    <div class="col-5">
                                                        <p v-html="ticket.pquestions.q11" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                    </div>
                                                    <div class="col-1">

                                                    </div>
                                                    <div class="col-4">
                                                        
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Have you ever been diagnoes with or received tratment or medication for:
                                                    Acquired Immune Deficiency SYndrome (AIDS) HIV, or ARC (Aids related complex)</label> 
                                                <div class="row">
                                                    <div class="col-5">
                                                       <p v-html="ticket.pquestions.q12" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                    </div>
                                                    <div class="col-1">

                                                    </div>
                                                    <div class="col-4">
                                                        
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Heart Failure (HF), Congestive Heart Failure (CHF), cardiomyopathy or pulminary hypertension?</label> 
                                                <div class="row">
                                                    <div class="col-5">
                                                        <p v-html="ticket.pquestions.q13" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                    </div>
                                                    <div class="col-1">

                                                    </div>
                                                    <div class="col-4">
                                                        
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Amyotrophic Lateral Sclerosis (ALS), Alzheimer's diseas, dementia, cognitive impairment, memroy loss, schizophrenia, or any neurologic or neuromuscular disease, or a terminal illness?</label> 
                                                <div class="row">
                                                    <div class="col-5">
                                                        <p v-html="ticket.pquestions.q14" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                    </div>
                                                    <div class="col-1">

                                                    </div>
                                                    <div class="col-4">
                                                        
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Chronic Kidney (renal) disease, cirrhosis of the liver, or had a bone marrow or organ transplant?</label> 
                                                <div class="row">
                                                    <div class="col-5">
                                                        <p v-html="ticket.pquestions.q15" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                    </div>
                                                    <div class="col-1">

                                                    </div>
                                                    <div class="col-4">
                                                        
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Complications of diabetes: diabetic coma, insulin shock, retinopathy (eye) nephropathy (kidney), neuropathy (nerve or numbness), or peripheral vascular disease (PVD/PAD)?</label> 
                                                <div class="row">
                                                    <div class="col-5">
                                                        <p v-html="ticket.pquestions.q16" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                    </div>
                                                    <div class="col-1">

                                                    </div>
                                                    <div class="col-4">
                                                        
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                        </div>
                                        
                                        </div>
                                    </div>
                                </div>
                                    
                                <h5 class="mt-4 mb-3">Within the past 12 months have you</h5>
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label class="form-control-label">Used or been advised to use Oxygen to assist with breathing (excluding treament for Sleep apnea), or had been advised to have kisney dialysis? </label> 
                                                    <div class="row">
                                                        <div class="col-5">
                                                            <p v-html="ticket.pquestions.q17" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                        </div>
                                                        <div class="col-1">

                                                        </div>
                                                        <div class="col-4">
                                                            
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label class="form-control-label">Been advised to have surgery, hospitalization, treatment, or a diagnostic test, (except for HIV) which has not been done or for which the results are not known? </label> 
                                                    <div class="row">
                                                        <div class="col-5">
                                                            <p v-html="ticket.pquestions.q18" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                        </div>
                                                        <div class="col-1">

                                                        </div>
                                                        <div class="col-4">
                                                            
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label class="form-control-label">Had or been advised to have a pacemaker or defibrillator implant, cardioversion treatment, or any type of hear or circulatory surgery or procedure?  </label> 
                                                    <div class="row">
                                                        <div class="col-5">
                                                            <p v-html="ticket.pquestions.q19" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                        </div>
                                                        <div class="col-1">

                                                        </div>
                                                        <div class="col-4">
                                                            
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label class="form-control-label">Within the past 24 months have you been diagnosed with having a heart attack, stroke, Transient Ischemic Attack (TIA), angina, or are you taking medication for angina?   </label> 
                                                    <div class="row">
                                                        <div class="col-5">
                                                            <p v-html="ticket.pquestions.q20" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                        </div>
                                                        <div class="col-1">

                                                        </div>
                                                        <div class="col-4">
                                                            
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    
                                <h5 class="mt-4 mb-3">Within the past 36 months have you been</h5>
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label class="form-control-label">Diagnosed with or received or been advised to receive treatment for a brain tumor, aneurysm, alcohol or drug abuse; or have you used illegal drugs, or controlled drugs except as prescribed for you? </label> 
                                                    <div class="row">
                                                        <div class="col-5">
                                                            <p v-html="ticket.pquestions.q21" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                        </div>
                                                        <div class="col-1">

                                                        </div>
                                                        <div class="col-4">
                                                            
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label class="form-control-label">
                                                        Diagnosed with cancer, or received or been advised to receive any type of treatment ot medication for cancer; or ever been diagnosed or treatment for more than one occurance of the same or different type of canace (except basal cell skin cancer)
                                                    </label> 
                                                    <div class="row">
                                                        <div class="col-5">
                                                            <p v-html="ticket.pquestions.q22" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                        </div>
                                                        <div class="col-1">

                                                        </div>
                                                        <div class="col-4">
                                                            
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                    
                                    <h5 class="mt-4 mb-3">Covid 19</h5>
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">
                                                            Within the past 12 months have you been advised by a licensed medical professional to self-quarantine for any period of time?
                                                        </label> 
                                                        <div class="row">
                                                            <div class="col-5">
                                                                <p v-html="ticket.pquestions.q23" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            <div class="col-1">

                                                            </div>
                                                            <div class="col-4">
                                                                
                                                            </div>
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">
                                                            Within the past 12 months have you been diagnosed, treated, tested positive for, or been given medical advise by a licensed medical professional for COVID 19?
                                                        </label> 
                                                        <div class="row">
                                                            <div class="col-5">
                                                                <p v-html="ticket.pquestions.q24" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            <div class="col-1">

                                                            </div>
                                                            <div class="col-4">
                                                                
                                                            </div>
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">
                                                        Within the past 30 days, has licensed medical professional administered a test on you for COVID 19, for which the results have not been received, or recommended that you be tested for COVID 19?
                                                        </label> 
                                                        <div class="row">
                                                            <div class="col-5">
                                                                <p v-html="ticket.pquestions.q25" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            <div class="col-1">

                                                            </div>
                                                            <div class="col-4">
                                                                
                                                            </div>
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">
                                                            Within the past 30 days, have you been treated, examined or advised by a licensed medical professional, whether in person, by phone or by other electronic means, for fever cough, shortness of breath or fatigue?
                                                        </label> 
                                                        <div class="row">
                                                            <div class="col-5">
                                                                <p v-html="ticket.pquestions.q26" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            <div class="col-1">

                                                            </div>
                                                            <div class="col-4">
                                                                
                                                            </div>
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">
                                                            Have you ever been diagnosed, treated, given medical advise or had medication prescribed by a licensed medical professional for any chronic lung or respiratory system disease, paralysis, or paraplegia?
                                                        </label> 
                                                        <div class="row">
                                                            <div class="col-5">
                                                                <p v-html="ticket.pquestions.q27" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            <div class="col-1">

                                                            </div>
                                                            <div class="col-4">
                                                                
                                                            </div>
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        
                                    <h5 class="mt-4 mb-3">Additional Information</h5>
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">
                                                            Automatic Premium Loan request? In the event of a missed payment, the company will access your cash value in the form of a load to pay the premium and keep your policy in force
                                                        </label> 
                                                        <div class="row">
                                                            <div class="col-5">
                                                                <p v-html="ticket.pquestions.q28" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            <div class="col-1">

                                                            </div>
                                                            <div class="col-4">
                                                                
                                                            </div>
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">
                                                            If we need to contact you for any clarifications, what is the best time for us to call you between the hours of 7am and 4pm (CST) Central Standard Time?
                                                        </label> 
                                                        <div class="row">
                                                            <div class="col-5">
                                                                <p v-html="ticket.pquestions.q29" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            <div class="col-1">

                                                            </div>
                                                            <div class="col-4">
                                                                
                                                            </div>
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">
                                                            Is someone other than you going to be the owner of this policy?
                                                        </label> 
                                                        <div class="row">
                                                            <div class="col-5">
                                                                <p v-html="ticket.pquestions.q30" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                            </div>
                                                            <div class="col-1">

                                                            </div>
                                                            <div class="col-4">
                                                                
                                                            </div>
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        
                                        <h5 class="mt-4 mb-3">Owner Information (Complete only if the owner is other than you, the proposed insured)
                                            Provide the full legal name of individual (first, middle, last)
                                        </h5>
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="form-group">
                                                                <label class="form-control-label">
                                                                Provide the full legal name of individual (first, middle, last)
                                                                </label> 
                                                                <div class="row">
                                                                    <div class="col-5">
                                                                        <p v-html="ticket.pquestions.q31" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                    
                                                                    </div>
                                                                    <div class="col-1">

                                                                    </div>
                                                                    <div class="col-4">
                                                                        
                                                                    </div>
                                                                </div>
                                                                
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="form-group">
                                                                <label class="form-control-label">
                                                                    Social Security Number
                                                                </label> 
                                                                <div class="row">
                                                                    <div class="col-5">
                                                                        <p v-html="ticket.pquestions.q32" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                    
                                                                    </div>
                                                                    <div class="col-1">

                                                                    </div>
                                                                    <div class="col-4">
                                                                        
                                                                    </div>
                                                                </div>
                                                                
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="form-group">
                                                                <label class="form-control-label">
                                                                    Relationship to Insured
                                                                </label> 
                                                                <div class="row">
                                                                    <div class="col-5">
                                                                        <p v-html="ticket.pquestions.q33" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                    
                                                                    </div>
                                                                    <div class="col-1">

                                                                    </div>
                                                                    <div class="col-4">
                                                                        
                                                                    </div>
                                                                </div>
                                                                
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="form-group">
                                                                
                                                                <div class="row">
                                                                    <div class="col-sm-9">
                                                                        <div class="form-group">
                                                                            <label class="form-control-label">Address</label> 
                                                                            <p v-html="ticket.pquestions.q34" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-sm-3">
                                                                        <div class="form-group">
                                                                            <label class="form-control-label">Number</label> 
                                                                            <p v-html="ticket.pquestions.q35" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-sm-4">
                                                                        <div class="form-group">
                                                                            <label class="form-control-label">City</label> 
                                                                            <p v-html="ticket.pquestions.q36" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-sm-4">
                                                                        <div class="form-group">
                                                                            <label class="form-control-label">State</label> 
                                                                            <p class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number">{{$store.state.state[ticket.pquestions.q37]}}</p>
                                                                        
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-sm-4">
                                                                        <div class="form-group">
                                                                            <label class="form-control-label">ZIP</label> 
                                                                            <p v-html="ticket.pquestions.q38" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="form-group">
                                                                <label class="form-control-label">
                                                                    Phone Number
                                                                </label> 
                                                                <div class="row">
                                                                    <div class="col-5">
                                                                        <p v-html="ticket.pquestions.q39" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                    
                                                                    </div>
                                                                    <div class="col-1">

                                                                    </div>
                                                                    <div class="col-4">
                                                                        
                                                                    </div>
                                                                </div>
                                                                
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                        <h5 class="mt-4 mb-3">Beneficiary(s)</h5>
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                                Primary beneficiary full name 
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-5">
                                                                    <p v-html="ticket.pquestions.q48" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                
                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                                <div class="col-4">
                                                                    
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                                Social Security Number 
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-5">
                                                                    <p v-html="ticket.pquestions.q49" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                                <div class="col-4">
                                                                    
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                                Date of Birth
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-5">
                                                                    <p v-html="ticket.pquestions.q50" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                
                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                                <div class="col-4">
                                                                    
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                                Relationship to insured 
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-5">
                                                                   <p v-html="ticket.pquestions.q51" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                
                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                                <div class="col-4">
                                                                    
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                                
                                                                Percentage (if less than 100% please add additional Beneficiaries)
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-5">
                                                                    <p v-html="ticket.pquestions.q52" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                
                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                                <div class="col-4">
                                                                    
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                                Do you want to add another Primary Beneficiary?
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-5">
                                                                    <p v-html="ticket.pquestions.q53" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                                <div class="col-4">
                                                                    
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <h5 class="mt-4 mb-3">Additional Beneficiary</h5>
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                                Additional beneficiary full name 
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-5">
                                                                    <p v-html="ticket.pquestions.q54" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                
                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                                <div class="col-4">
                                                                    
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                                Social Security Number 
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-5">
                                                                    <p v-html="ticket.pquestions.q55" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                
                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                                <div class="col-4">
                                                                    
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                                Date of Birth
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-5">
                                                                    <p v-html="ticket.pquestions.q56" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                
                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                                <div class="col-4">
                                                                    
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                                Relationship to insured 
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-5">
                                                                    <p v-html="ticket.pquestions.q57" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                
                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                                <div class="col-4">
                                                                    
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                                
                                                                Percentage (if less than 100% please add additional Beneficiaries)
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-5">
                                                                    <p v-html="ticket.pquestions.q58" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                
                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                                <div class="col-4">
                                                                    
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                                If more than two primary beneficiaries, provide the same details as the first primary beneficiary
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <p v-html="ticket.pquestions.q59" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                </div>
                                                                
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                               
                                            </div>
                                        </div>
                                        
                                        <h5 class="mt-4 mb-3">Contingent Beneficiary</h5>
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                                Contingent beneficiary full name 
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-5">
                                                                    <p v-html="ticket.qquestions.q60" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                
                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                                <div class="col-4">
                                                                    
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                                Social Security Number 
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-5">
                                                                    <p v-html="ticket.qquestions.q61" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                
                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                                <div class="col-4">
                                                                    
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                                Date of Birth
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-5">
                                                                    <p v-html="ticket.qquestions.q62" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                
                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                                <div class="col-4">
                                                                    
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                                Relationship to insured 
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-5">
                                                                    <p v-html="ticket.qquestions.q63" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                
                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                                <div class="col-4">
                                                                    
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                                
                                                                Percentage (if less than 100% please add additional Beneficiaries)
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-5">
                                                                    <p v-html="ticket.qquestions.q64" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                
                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                                <div class="col-4">
                                                                    
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                                If more than one contingent beneficiary, provide the same details as the first contingent beneficiary
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <p v-html="ticket.qquestions.q65" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                </div>
                                                            
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <h5 class="mt-4 mb-3">Existing Life Insurance Coverage</h5>
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                                Do you have any existing life insurance or annuity contracts in force or pending with this or any other company?
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-5">
                                                                    <p v-html="ticket.qquestions.q66" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                                <div class="col-4">
                                                                    
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <h5 class="mt-4 mb-3">Existing Life Insurance Details</h5>
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                                Insurance Company Name
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-5">
                                                                    <p v-html="ticket.qquestions.q67" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                                <div class="col-4">
                                                                    
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                                Policy Number
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-5">
                                                                    <p v-html="ticket.qquestions.q67" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                                <div class="col-4">
                                                                    
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                                How much insurance do you have with this company?
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-5">
                                                                    <p v-html="ticket.qquestions.q68" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                                <div class="col-4">
                                                                    
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                                Additional details for any other existing policies that would be replaced
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-12">
                                                                <p v-html="ticket.qquestions.q70" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                </div>
                                                                
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <h5 class="mt-4 mb-3">Existing Life Insurance Replacement</h5>
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                            Will this policy, if issued, result in the replacements, termination or change in value of any existing life insurance or annuity contracts in this or any other company?
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-5">
                                                                    <p v-html="ticket.qquestions.q71" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                                <div class="col-4">
                                                                    
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <h5 class="mt-4 mb-3">Third Party</h5>
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                                Additional details for any other existing policies that would be replaced
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-12">
                                                                <p v-html="ticket.qquestions.q72" class="form-control-new custom-input-lg" type="text" placeholder="Enter License Number"></p>
                                                                </div>
                                                                
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <!-- <h5 class="mt-4 mb-3">Payment Information</h5>
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                            Premium payment frequency: 
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-5">
                                                                    <select @change="SelectPaymentOne" class="form-control custom-select">
                                                                        <option selected>Select Option</option>
                                                                        
                                                                        <option value="Monthly">Monthly - 12 payments</option>
                                                                        <option value="Quarterly">Quarterly - 4 payments</option>
                                                                        <option value="Semi-Annual">Semi-Annual - 2 payments</option>
                                                                        <option value="Annual">Annual - 1 payment</option>

                                                                    </select>
                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                                <div class="col-4">
                                                                    
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                            Payment Method 
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-5">
                                                                    <select @change="SelectPaymentTwo" class="form-control custom-select">
                                                                        <option selected>Select Option</option>
                                                                        
                                                                        <option value="Electronic Funds Transfer">Electronic Funds Transfer (Checking or Savings)</option>
                                                                        <option value="Credit Card">Credit Card</option>
                                                                        <option value="Direct Bill">Direct Bill</option>
                                                                        <option value="I will provide this information later">I will provide this information later</option>

                                                                    </select>
                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                                <div class="col-4">
                                                                    
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <h5 class="mt-4 mb-3">Electronic Funds Transfer</h5>
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                                Name of your Bank
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-5">
                                                                    <input v-model="q.payment_three" class="form-control" type="text" placeholder="Enter your client's employer">
                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                                <div class="col-4">
                                                                    
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                            Do you want this to come from your Checking or Savings Account?
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-5">
                                                                    <select @change="SelectPaymentFour" class="form-control custom-select">
                                                                        <option selected>Select Option</option>
                                                                        
                                                                        <option value="Checking">Checking</option>
                                                                        <option value="Savings Account">Savings Account</option>

                                                                    </select>
                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                                <div class="col-4">
                                                                    
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                                Bank Account Holder's Name exactly as printed on the Checking or Savings Account
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-5">
                                                                    <input v-model="q.payment_five" class="form-control" type="text" placeholder="Enter your client's employer">
                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                                <div class="col-4">
                                                                    
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                            Routing Number (9 digits found on the bottom LEFT of your check or saings) NOTE: If your routing number starts with the number '5', please contact your bank and request the proper Routing Number for Electtric Funds Transfer (EFT)
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-5">
                                                                    <input v-model="q.payment_six" class="form-control" type="text" placeholder="Enter your client's employer">
                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                                <div class="col-4">
                                                                    
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                            Bank Account
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-5">
                                                                    <input v-model="q.payment_seven" class="form-control" type="text" placeholder="Enter your client's employer">
                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                                <div class="col-4">
                                                                    
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                            What day of the month would you like funds to be withdrawn to pay the policy? Any day between the 1st day and the 28th day of the Month. NOTE: (Payments will be deducted one to three business days after the EFT Process Date)
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-5">
                                                                    <input v-model="q.payment_eight" class="form-control" type="text" placeholder="Enter your client's employer">
                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                                <div class="col-4">
                                                                    
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <h5 class="mt-4 mb-3">Credit Card Authorization</h5>
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                                Name as it is listed on the Credit Card 
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <input v-model="q.payment_nine" class="form-control" type="text" placeholder="Enter your client's employer">
                                                                </div>
                                                                
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                            Credit Card Type?
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <select @change="SelectPaymentTen" class="form-control custom-select">
                                                                        <option selected>Select Option</option>
                                                                        
                                                                        <option value="Visa">Visa</option>
                                                                        <option value="Mastercard">Mastercard</option>
                                                                        <option value="Discover">Discover</option>
                                                                        <option value="American Express">American Express</option>

                                                                    </select>
                                                                </div>
                                                                
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-md-7">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                                Credit Card Number
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <input v-model="q.payment_eleven" class="form-control" type="text" placeholder="Enter your client's employer">
                                                                </div>
                                                                
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-1"></div>
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                                Expiration Date
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-5">
                                                                    <input v-model="q.payment_exp" class="form-control" type="text" placeholder="Enter your client's employer">
                                                                </div>
                                                                
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                                Address of the Credit Card Holder 
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-7">
                                                                    <input v-model="q.payment_twelve" class="form-control" type="text" placeholder="Enter your client's employer">
                                                                </div>
                                                            
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">
                                                                Comments
                                                            </label> 
                                                            <div class="row">
                                                                <div class="col-7">
                                                                    <input v-model="q.comments" class="form-control" type="text" placeholder="Enter your client's employer">
                                                                </div>
                                                            
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <h5 class="mt-4 mb-3">Scheduled Appointment</h5>
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">Scheduled Day </label> 
                                                            <p v-if="ticket.hour"  class="form-control text-muted">{{printDateTimezone(ticket.hour.hour, ticket.timezone)}} {{ticket.timezone}}</p>
                                                        
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">Enroller: 
                                                            </label> 
                                                            <p class="form-control text-muted"><span v-if="ticket.enroller">
                                                                                                                {{ticket.enroller.user.name}}
                                                                                                        </span> 
                                                                                                        <span v-else>Not Assigned</span></p>
                                                            
                                                        </div>
                                                    </div>
                                                    
                                                    <div v-if="ticket.enrollment_id" class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">Enrollment ID: 
                                                                                                        
                                                            </label> 
                                                            <p class="form-control text-muted"><span>{{ticket.enrollment_id}}</span></p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">Ticket Created: 
                                                            </label> 
                                                            <p class="form-control text-muted">
                                                               {{printDateTimezone(ticket.created_at, ticket.timezone)}} {{ticket.timezone}}
                                                            </p>
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="form-control-label">Ticket Notes:</span>
                                                            </label> 
                                                            <textarea v-model="ticket.note" @keyup="showNoteButton()" class="form-control" placeholder="Complete Ticket Notes" rows="12"></textarea>
                                                        </div>
                                                        
                                                    </div>
                                                    <div v-if="$store.getters.isEnroller && !ticket.complete && showNoteUpdate" class="col-md-12" style="display:flex;justify-content:end;">
                                                        <div class="form-group">
                                                            <button @click="updateNotes" type="button" class="btn btn-primary btn-xs">
                                                                <span v-if="!noteLoading">Update</span>
                                                                <div v-else class="spinner-border" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                                </div>    
                                                            </button>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div class="row justify-content-end pt-4 mb-4 pb-4">
                                            <div class="col-md-12">
                                                <div class="row" v-if="ticket.status != 'canceled' || ticket.status == 'completed'">
                                                    <div class="col-md-6 justify-content-start">
                                                        <!-- <ul class="cancelOptions">
                                                            <li>
                                                                <button v-if="$store.getters.isEnroller && !ticket.complete" @click="showScheduler()" type="button" class="btn btn-danger">New Schedule</button><br>
                                                            </li>
                                                            <li>
                                                                <button v-if="$store.getters.isEnroller && !ticket.complete && ticket.enroller_id" @click="showEnrCancel()" type="button" class="btn btn-danger">Enroller Cancel</button>
                                                            </li>
                                                            <li>
                                                                <button v-if="$store.getters.isEnroller && !ticket.complete" @click="showEnrCancel()" type="button" class="btn btn-danger">Terminate Ticket</button>
                                                            </li>
                                                        </ul> -->
                                                        <div class="row">
                                                            <div class="col-6 mb-4">
                                                                <button v-if="$store.getters.isEnroller && !ticket.complete" @click="showScheduler()" type="button" class="btn btn-primary">New Schedule</button><br>
                                                            </div>
                                                            <div class="col-6 mb-4">
                                                                <button v-if="$store.getters.isEnroller && !ticket.complete && ticket.enroller_id" @click="showEnrCancel()" type="button" class="btn btn-warning">Enroller Cancel</button>
                                                            </div>
                                                            <div class="col-6 mb-4">
                                                                <button v-if="$store.getters.isEnroller && !ticket.complete" @click="showTicketCancel()" type="button" class="btn btn-danger">Terminate Ticket</button>
                                                            </div>
                                                        </div>
                                                        
                                                        
                                                        
                                                        
                                                    </div>
                                                    <div class="col-6 justify-content-end" style="display:flex;justify-content:end">
                                                        
                                                        
                                                        
                                                        
                                                        <div class="row justify-content-end">
                                                            <div class="col-12 mb-4" v-if="$store.getters.isAdmin && $store.getters.isEnroller && !ticket.enroller_id" style="display:flex;justify-content:end;height: fit-content;">
                                                                <button  @click="showSlotAssign()" type="button" class="btn btn-success px-6 py-3">Assign Schedule</button>
                                                            </div>
                                                            <div class="col-12 mb-4"  v-if="$store.getters.isEnroller && !ticket.enroller_id" @click="showEnrAccept()" style="display:flex;justify-content:end;height: fit-content;">
                                                                <button type="button" class="btn btn-success px-6 py-3">Accept Schedule</button>
                                                            </div>
                                                            <div class="col-12 mb-4" v-if="$store.getters.isEnroller && !ticket.complete && ticket.enroller_id && ticket.enrolled == false" style="display:flex;justify-content:end;height: fit-content;">
                                                                <button  @click="showTSignature()" type="button" class="btn btn-success px-6 py-3">Pend Signature</button>
                                                            </div>
                                                            <div class="col-12 mb-4" v-if="$store.getters.isEnroller && !ticket.complete && ticket.enroller_id && ticket.enrolled" style="display:flex;justify-content:end;height: fit-content;">
                                                                <button  @click="showTComplete()" type="button" class="btn btn-success px-6 py-3">Complete</button>
                                                            </div>
                                                            
                                                        </div>

                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                        </div>
                                    <div class="mt-3">
                                        
                                    </div>
                                    
                                    
                            </div><hr>
                            <div>
                                <div class="page-inner-header mb-4">
                                    <h5 class="mb-1"></h5>
                                    <p class="text-muted mb-0"></p>
                                </div>
                                
                                <div class="modal fade" id="modal_account_deactivate" tabindex="-1" role="dialog" aria-labelledby="modal_account_deactivate" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                        <div class="modal-content">
                                            <div class="modal-body">
                                                <div class="pt-5 text-center">
                                                    <div class="icon text-danger">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-x"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><line x1="18" y1="8" x2="23" y2="13"></line><line x1="23" y1="8" x2="18" y2="13"></line>
                                                        </svg>
                                                    </div>
                                                    <h4 class="h5 mt-5 mb-3">Extremely important</h4>
                                                    <p>We will immediately delete all of your personal data from our database. This action can not be undone. Are you sure you want to do this?</p>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-sm btn-link text-danger btn-zoom--hover font-weight-600">Delete</button> 
                                                <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="position:absolute;top:0px;margin-top:-14px;left:10px;">
                        <div class="col-auto">
                            <button @click="$router.go(-1)" type="button" class="btn btn-xs btn-primary btn-icon rounded-pill">
                                <span class="btn-inner--icon">
                                    <i class="uil uil-arrow-left"></i>
                                </span> 
                                <span class="btn-inner--text">Back</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <llp 
            ref="llp"
            
        />
    <scheduler 
        ref="schedulerOpen"
    />
    <enroller-cancel 
            ref="enrCancel"
            
        />
    <ticket-cancel 
            ref="ticketCancel"
            
        />
    <slot-assign 
        ref="slotAssign"
        
    />
    <enroller-accept2 
            ref="enrAccept"
            
        />
    <ticket-signature 
        ref="tSignature"
    />
    <ticket-complete 
            ref="tComplete"
            
        />
</div>  
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import flatpickr from "flatpickr";
import llp from '../comps/LLP.vue';
import VuePhoneNumberInput from 'vue-phone-number-input';
import _ from 'lodash';
import scheduler from '../comps/Scheduler.vue';
import enrollerCancel from '../comps/EnrollerCancel.vue';
import ticketCancel from '../comps/TicketCancel.vue';
import slotAssign from '../comps/SlotAssign.vue';
import enrollerAccept2 from '../comps/EnrollerAccept2.vue';
import ticketSignature from '../comps/TicketSignature.vue';
import ticketComplete from '../comps/TicketComplete.vue';
export default {
    
    components: {
        flatpickr, llp, VuePhoneNumberInput, scheduler, enrollerCancel,
        ticketCancel, slotAssign, enrollerAccept2, ticketSignature, ticketComplete
    },
    data() {
        return {
            sSlots: '',
            sHours: '',
            emailc: '',
            phonec: '',
            timeZone: '',
            submitLoading: false,
            noteLoading: false,
            showNoteUpdate: false,
            ticket: '',
            q: {
                policy_age: '',
                policy_amount: '',
                policy_dob: '',
                policy_gender: '',
                policy_quote: '',
                policy_ss: '',
                policy_startdate: '',
                policy_state: '',
                policy_term: '',

                first_name: '',
                last_name: '',
                dob: '',
                gender: '',
                email: '',
                phone: '',
                phone_confirm: '',
                address: '',
                address_no: '',
                city: '',
                state: '',
                zip: '',

                license_number: '',
                license_state: '',
                height_ft: '',
                height_in: '',
                weight: '',
                weight_10: '',
                weight_loss: '',
                weight_reason: '',
                tobacco: '',
                med_one: '',
                med_two: '',
                med_three: '',
                med_four: '',
                med_five: '',
                med_six: '',
                med_seven: '',
                med_eight: '',
                med_nine: '',
                med_ten: '',
                med_eleven: '',
                med_twelve: '',
                med_thirteen: '',
                med_fourteen: '',
                med_fifteen: '',
                med_sixteen: '',
                med_seventeen: '',
                med_eighteen: '',
                additional_one: '',
                additional_two: '',
                additional_three: '',
                owner_one: '',
                owner_two: '',
                owner_three: '',

                owner_address: '',
                owner_addressno: '',
                owner_city: '',
                owner_state: '',
                owner_zip: '',
                owner_phone: '',

                secondaddres_one: '',
                secondaddress_two: '',

                secondaddress_address: '',
                secondaddress_addressno: '',
                secondaddress_city: '',
                secondaddress_state: '',
                secondaddress_zip: '',
                secondaddress_phone: '',

                ben_one: '',
                ben_two: '',
                ben_three: '',
                ben_four: '',
                ben_five: '',
                ben_six: '',

                addben_one: '',
                addben_two: '',
                addben_three: '',
                addben_four: '',
                addben_five: '',
                addben_six: '',

                contben_one: '',
                contben_two: '',
                contben_three: '',
                contben_four: '',
                contben_five: '',
                contben_six: '',

                exist_one: '',
                exist_two: '',
                exist_three: '',
                exist_four: '',
                exist_five: '',

                exist_six: '',
                thirdparty: '',

                payment_one: '',
                payment_two: '',

                payment_three: '',
                payment_four: '',
                payment_five: '',
                payment_six: '',
                payment_seven: '',
                payment_eight: '',

                payment_nine: '',
                payment_ten: '',
                payment_eleven: '',
                paymet_exp: '',
                payment_twelve: '',
                comments: '',

                timezone: '',
                schedule_hourid: '',
            },
            form: {
                timezone: '',
                policy_coverage: '',
                policy_start: '',
                policy_age: '',
                policy_monthly: '',

                first_name: '',
                last_name: '',
                dob: '',
                gender: '',
                email: '',
                phone: '',
                address: '',
                address_no: '',
                city: '',
                state: '',
                zip: '',

                tobacco: '',
                hours: '',
                medical: '',
                employeer_name: '',
                dateofhire: '',
                occupation: '',
                income: '',
                agentexplainedllp: '',
                paymentalternative: '',

                schedule_hourid: '',
            },
            email: "",
            emailValid: '',
            isSS: false,
            isSS2: false,
            
        }
    },
    created() {
        window.scrollTo(0,0);
        console.log('displaying query')
        console.log(this.$route.query.ticket);
        this.ticket = this.$route.query.ticket;

        
    },
    mounted() {
        if(this.$store.state.policy.dob) {
            this.form.dob = this.$store.state.policy.dob;
            this.form.gender = this.$store.state.policy.gender;
            this.form.state = this.$store.state.policy.state;

            this.q.policy_age = this.$store.state.policy.age;
            this.q.policy_amount = this.$store.state.policy.amount;
            this.q.policy_dob = this.$store.state.policy.dob;
            this.q.policy_gender = this.$store.state.policy.gender;
            this.q.policy_quote = this.$store.state.policy.quote;
            this.q.policy_ss = this.$store.state.policy.ss;
            this.q.policy_startdate = this.$store.state.policy.startdate;
            this.q.policy_state = this.$store.state.policy.state;
            this.q.policy_term = this.$store.state.policy.term;
        }
        

        if(this.$store.state.shelf.findIndex(item => item.id === 2) != -1) {
            this.form.spouse_dob = this.$store.state.policySpouse.dob;
            this.form.spouse_gender = this.$store.state.policySpouse.gender;

            this.form.policyspouse_age = this.$store.state.policySpouse.age;
            this.form.policyspouse_coverage = this.$store.state.policySpouse.amount;
            this.form.policyspouse_monthly = this.$store.state.policySpouse.quote
            this.form.policyspouse_start = this.$store.state.policySpouse.startdate;
        }
        if(this.$store.state.rider1.amount) {
            this.form.policychild_coverage = this.$store.state.rider1.amount;
            this.form.policychild_monthly = this.$store.state.rider1.cost;
        }

        
        

        var dateToday = new Date(); 
       // console.log('01: ' + dateToday);
       // console.log('02: ' + this.$moment(dateToday).utc().format());
       // console.log('1: ' + new Date('7-28-2021 14:00:00'))
       // console.log('2: ' +new Date('7-28-2021 14:00:00').toUTCString());
       // console.log(new Date().toLocaleDateString(undefined, {day:'2-digit',timeZoneName: 'short' }).substring(4));
        
        flatpickr('#datepicker', {
            
            dateFormat: "m-d-Y",
            minDate: dateToday,
            "disable": [
                function(date) {
                return (date.getDay() === 0 || date.getDay() === 6);  // disable weekends
                }
            ],
            "locale": {
                "firstDayOfWeek": 1 // set start day of week to Monday
            }
        });
        flatpickr('#datepicker2', {
            
            dateFormat: "m-d-Y",
           
        }); 
        
    },
    methods: {
        fetchScheduler() {
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.get('/api/fetchscheduler').then(resp => {
               console.log(resp.data);
                this.sSlots = resp.data.t;
            });
            
        },
        
        formatCurrency(amount) {
            amount = (amount / 100);
            return amount != null ? amount.toLocaleString('en', { style: 'currency', currency: 'usd', minimumFractionDigits: 2 }) : '';
        },
        formatCurrency2(amount) {
            amount = (amount);
            return amount !== null ? amount.toLocaleString('en', { style: 'currency', currency: 'usd', minimumFractionDigits: 0, maximumFractionDigits: 2 }) : '';
        },
        printDate(i) {
            return moment.utc(i).local().format('dddd MMM D,   h:mm a');
        },
        printDate2(i) {
            return moment.utc(i).local().format('dddd MMMM DD');
        },
        printDateTimezone(i, t) {
            return moment.utc(i).tz(t).format('dddd MMM D,   h:mm a');
        },
        showNoteButton() {
            this.showNoteUpdate = true;
        },
        updateNotes() {
            this.noteLoading = true;
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.post('/api/updatenotes', this.ticket).then(resp=> {
                console.log(resp);
                this.noteLoading = false;
                this.showNoteUpdate = false;
            }).then(resp2 => {
                this.$toasted.success('Updated');
            });
        },
        cartTotal() {
                let amount = this.$store.state.shelf.reduce((acc, item) => acc + (item.quote), 0);
                if(this.$store.state.rider1.cost) {
                    amount += this.$store.state.rider1.cost;
                }
                return amount;
            },
        emailConfirm(event) {
            
            if(event.target.value.length >= 4) {
                console.log(event.target.value);
                if(event.target.value == this.form.email) {
                    this.emailc = 1;
                }  else {
                    this.emailc = 2;
                }

            } else {
                this.emailc = '';
            }
        },
        validateEmail() {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(this.form.email)) {
                console.log('email looks alright');  
                this.emailValid = true;
            } else {
                console.log('Please enter a valid email address');
                this.emailValid = false;
            }
        },
        phoneConfirm(event) {
            
            if(event.target.value.length >= 4) {
                console.log(event.target.value);
                if(event.target.value == this.q.phone) {
                    this.phonec = 1;
                } else {
                    this.phonec = 2;
                    console.log('phone not equal ' + this.phonec);
                }

            } else {
                this.phonec = '';
            }
        },
        acceptNumber() {
            var x = this.q.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.form.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        acceptNumber2() {
            var x = this.q.phoneConfirm.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.q.phoneConfirm = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        tState(event) {
          if(event.target.value !== '') {
              this.form.tobacco = event.target.value;

              if(event.target.value == 'No' && this.$store.state.policy.ss == 1) {
                  this.isSS = true;
                  
              } else if(event.target.value == 'Yes' && this.$store.state.policy.ss == 2) {
                  this.isSS = true;
                  
              } else {
                  this.isSS = false;
                  
              }
              
          }
        },
        tState2(event) {
          if(event.target.value !== '') {
              this.form.spouse_tobacco = event.target.value;
              if(event.target.value == 'No' && this.$store.state.policySpouse.ss == 1) {
                  this.isSS2 = true;
              } else if(event.target.value == 'Yes' && this.$store.state.policySpouse.ss == 2) {
                  this.isSS2 = true;
              }
              
          }
        },
        hState(event) {
          if(event.target.value !== '') {
              this.form.hours = event.target.value;
              
          }
        },
        mState(event) {
          if(event.target.value !== '') {
              this.form.medical = event.target.value;
              
          }
        },
        llpState(event) {
          if(event.target.value !== '') {
              this.form.agentexplainedllp = event.target.value;
              
          }
        },
        selectTimezone(event) {
            this.timeZone = event.target.value;
            this.q.timezone = event.target.value;
        },
        selectDateChange(event) {
            console.log(event.target.value);
            this.sHours = this.sSlots[parseInt(event.target.value)].hours;
        },
        shouldDisableSlot(i) {
            if(i.ticket_id) {
                console.log(i);
                return true;
            } 
        },
        selectedHour(event) {
            if(event.target.value != 'Select time' || event.target.value == '') {
                this.q.schedule_hourid = event.target.value;
            }
            
        },
        printDate(i) {
            return moment.utc(i).local().format('hh:mm a');
        },
        
        printDate2(i) {
            return moment.utc(i).local().format('dddd MMMM DD');
        },
        submitTicket() {
            if(this.form.first_name != '' && this.form.last_name != '' && this.form.dob != '' && this.form.gender != '' && this.form.email != '' && this.emailValid == true && this.emailc == 1 && this.form.phone != '' && this.phonec == 1 && this.form.address != '' && this.form.city != '' && this.form.state != '' && this.form.zip != '' && this.isSS == true && this.form.hours == 'Yes' && this.form.medical == "No" && this.employeer_name != '' && this.form.occupation != '' && this.form.income != '' && this.form.agentexplainedllp == 'Yes' && this.form.schedule_hourid != '') {
                this.submitLoading = true;
                let role = JSON.parse(localStorage.getItem('access_token2')).role;
                axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
                axios.post('/api/createticket', this.form).then(resp => {
                    console.log(resp);
                    
                }).then(resp2 => {
                    this.submitLoading = false;
                    this.$toasted.success('Ticket Created');
                    this.$router.push({name: 'admin.tickets'});
                });
            } else if(this.form.first_name == '') {
                this.$toasted.error('First Name');
            } else if(this.form.last_name == '') {
                this.$toasted.error('Last Name');
            } else if(this.form.dob == '') {
                this.$toasted.error('Dob');
            } else if(this.form.gender == '') {
                this.$toasted.error('Gender');
            } else if(this.form.email == '') {
                this.$toasted.error('Email');
            } else if(this.emailValid != true) {
                this.$toasted.error('Invalid Email');
            } else if(this.emailc != 1) {
                this.$toasted.error('Confirm Email');
            } else if(this.form.phone == '') {
                this.$toasted.error('Phone');
            } else if(this.phonec != 1) {
                this.$toasted.error('Confirm Phone');
            } else if(this.form.address == '') {
                this.$toasted.error('Address');
            } else if(this.form.city == '') {
                this.$toasted.error('City');
            } else if(this.form.state == '') {
                this.$toasted.error('State');
            } else if(this.form.zip == '') {
                this.$toasted.error('Zip Code');
            } else if(this.form.gender == '') {
                this.$toasted.error('Gender');
            } else if(this.isSS == false) {
                this.$toasted.error('Tobacco Usage Not matching Quoted');
            } else if(this.form.tobacco == '') {
                this.$toasted.error('Tobacco Usage');
            } else if(this.form.hours != 'Yes') {
                this.$toasted.error('Must be working');
            } else if(this.form.medical != 'No') {
                this.$toasted.error('Medical Status');
            } else if(this.form.employeer_name == '') {
                this.$toasted.error('Employeer Name');
            } else if(this.form.occupation == '') {
                this.$toasted.error("Client's Occupation");
            } else if(this.form.income == '') {
                this.$toasted.error("Client's Income");
            } else if(this.form.agentexplainedllp != 'Yes') {
                this.$toasted.error('Explained LP');
            } else if(this.form.schedule_hourid == '') {
                this.$toasted.error('Schedule Enrollment Hour');
            }
            
        },
        showLLP() {
            $(this.$refs.llp.$el).modal('show');
        },
        closellp() {
            $(this.$refs.llp.$el).modal('hide');
            this.focusForm();
        },
        focusForm:_.debounce(function() {
            const el = this.$el.getElementsByClassName('llpshow')[0];
            console.log(el);
            if (el) {
                // Use el.scrollIntoView() to instantly scroll to the element
                el.scrollIntoView({behavior: 'smooth'});
            }
        }, 500),

        // Starting new ticket question dynamics 
        selectLicenseState(e) {
            if(e.target.value != '') {
                this.q.license_state = e.target.value;
                console.log(e.target.value)
            }
        },
        selectHeightFt(e) {
            if(e.target.value != '') {
                this.q.height_ft = e.target.value;
            }
        },
        selectHeightIn(e) {
            if(e.target.value != '') {
                this.q.height_in = e.target.value;
            }
        },
        selectWeight(e) {
            if(e.target.value != '') {
                this.q.weight = e.target.value;
            }
        },
        SelectWeightTen(e) {
            if(e.target.value != '') {
                this.q.weight_ten = e.target.value;
                console.log('weight 10 ' + e.target.value)
            }
        },
        SelectTobacco(e) {
            if(e.target.value != '') {
                this.q.tobacco = e.target.value;
            }
        },
        SelectMedOne(e) {
            if(e.target.value != '') {
                this.q.med_one = e.target.value;
            }
        },
        SelectMedTwo(e) {
            if(e.target.value != '') {
                this.q.med_two = e.target.value;
            }
        },
        SelectMedThree(e) {
            if(e.target.value != '') {
                this.q.med_three = e.target.value;
            }
        },
        SelectMedFour(e) {
            if(e.target.value != '') {
                this.q.med_four = e.target.value;
            }
        },
        SelectMedFive(e) {
            if(e.target.value != '') {
                this.q.med_five = e.target.value;
            }
        },
        SelectMedSix(e) {
            if(e.target.value != '') {
                this.q.med_six = e.target.value;
            }
        },
        SelectMedSeven(e) {
            if(e.target.value != '') {
                this.q.med_seven = e.target.value;
            }
        },
        SelectMedEight(e) {
            if(e.target.value != '') {
                this.q.med_eight = e.target.value;
            }
        },
        SelectMedNine(e) {
            if(e.target.value != '') {
                this.q.med_nine = e.target.value;
            }
        },
        SelectMedTen(e) {
            if(e.target.value != '') {
                this.q.med_ten = e.target.value;
            }
        },
        SelectMedEleven(e) {
            if(e.target.value != '') {
                this.q.med_eleven = e.target.value;
            }
        },
        SelectMedTwelve(e) {
            if(e.target.value != '') {
                this.q.med_twelve = e.target.value;
            }
        },
        SelectMedThirteen(e) {
            if(e.target.va,lue != '') {
                this.q.med_thirteen = e.target.value;
            }
        },
        SelectMedFourteen(e) {
            if(e.target.value != '') {
                this.q.med_fourteen = e.target.value;
            }
        },
        SelectMedFifteen(e) {
            if(e.target.value != '') {
                this.q.med_fifteen = e.target.value;
            }
        },
        SelectMedSixteen(e) {
            if(e.target.value != '') {
                this.q.med_sixteen = e.target.value;
            }
        },
        SelectMedSeventeen(e) {
            if(e.target.value != '') {
                this.q.med_seventeen = e.target.value;
            }
        },
        SelectMedEighteen(e) {
            if(e.target.value != '') {
                this.q.med_eighteen = e.target.value;
            }
        },
        SelectAdditionalOne(e) {
            if(e.target.value != '') {
                this.q.additional_one = e.target.value;
            }
        },
        SelectAdditionalTwo(e) {
            if(e.target.value != '') {
                this.q.additional_two = e.target.value;
            }
        },
        SelectAdditionalThree(e) {
            if(e.target.value != '') {
                this.q.additional_three = e.target.value;
            }
        },
        SelectSecondAddressOne(e) {
            if(e.target.value != '') {
                this.q.secondaddress_one = e.target.value;
            }
        },
        SelectBenSix(e) {
            if(e.target.value != '') {
                this.q.ben_six = e.target.value;
            }
        },
        SelectExistOne(e) {
            if(e.target.value != '') {
                this.q.exist_one = e.target.value;
            }
        },
        SelectExistSix(e) {
            if(e.target.value != '') {
                this.q.exist_six = e.target.value;
            }
        },
        SelectPaymentOne(e) {
            if(e.target.value != '') {
                this.q.payment_one = e.target.value;
            }
        },
        SelectPaymentTwo(e) {
            if(e.target.value != '') {
                this.q.payment_two = e.target.value;
            }
        },
        SelectPaymentFour(e) {
            if(e.target.value != '') {
                this.q.payment_four = e.target.value;
            }
        },
        SelectPaymentTen(e) {
            if(e.target.value != '') {
                this.q.payment_ten = e.target.value;
            }
        },
        SelectOwnerState(e) {
            if(e.target.value != '') {
                this.q.owner_state = e.target.value;
            }
        },
        SelectSecondState(e) {
            if(e.target.value != '') {
                this.q.secondaddress_state = e.target.value;
            }
        },
        acceptOwnerNumber() {
            var x = this.q.owner_phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.q.owner_phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        submitQuestions() {
            this.submitLoading = true;

            
            

           
            console.log(this.q);
            this.submitLoading = false;
        },
        showScheduler() {
            $(this.$refs.schedulerOpen.$el).modal('show');
        },
        showEnrCancel() {          
                $(this.$refs.enrCancel.$el).modal('show');
        },
        showTicketCancel() {          
                $(this.$refs.ticketCancel.$el).modal('show');
        },
        showSlotAssign() {          
                $(this.$refs.slotAssign.$el).modal('show');
        },
        showEnrAccept() {          
                $(this.$refs.enrAccept.$el).modal('show');
        },
        showTSignature() {
            $(this.$refs.tSignature.$el).modal('show');
        },
        showTComplete() { 
                if(this.ticket.note != '' && this.ticket.note != null) {
                    $(this.$refs.tComplete.$el).modal('show');
                } else {
                    this.$toasted.error('Notes are Missing');
                }      
                
        },
        pulltestquestions() {
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.get('pulltestquestions').then(resp => {
                console.log(resp.data);
            });
        }
        

    }

}
</script>
<style scoped>
.form-control-label {
    color: #525458;
    height: auto;
    font-size: 0.975rem;
}
.form-control-new {
    display: block;
    width: 100%;
    height: auto;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #4a5568;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
    box-shadow: inset 0 1px 1px rgb(31 45 61 / 8%);
    transition: all .2s ease;
}
#datepicker {
    background-color: white;
    
}
.outlineTicketSuccess {
    border-color: #5c5e5d;
}
.outlineTicketDanger {
    border-color: #ee283c;;
}
.form-control option:disabled {
    display: none;
}
</style>