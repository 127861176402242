<template>
    <div>
        <form-header></form-header>
        
        <section>
            <div class="container d-flex flex-column">
                <div class="row align-items-center justify-content-center min-vh-100">
                    <div class="col-md-7 col-lg-7 col-xl-6">
                        <div>
                            <div class="mb-5">
                                <div class="row" style="">
                                    <div class="col-auto align-items-center" style="display:flex;align-items:center;">
                                        <h3 class="h5 mb-0">What is your current height?</h3>
                                    </div>
                                    
                                </div>
                                
                            
                            </div>
                            <span class="clearfix"></span>
                            <form>
                                
                                <div class="form-group mb-5">
                                    <div class="row justify-content-center">
                                        <div class="col-4">
                                            <select class="custom-select text-center" @change="selectFt" v-model="ftVal">
                                                
                                                <!-- <option v-for="i in 4" :key="i" :value="i + 3 + ' Ft'">{{ i + 3 + " Ft" }}</option> -->
                                                <option value="">Select Ft</option>
                                                <option value="4">4 Ft</option>
                                                <option value="5">5 Ft</option>
                                                <option value="6">6 Ft</option>
                                                <option value="7">7 Ft</option>
                                               
                                            </select>
                                        </div>
                                        <div class="col-4">
                                            <select v-model="inchVal" class="custom-select text-center" @change="selectIn" >
                                               
                                               
                                                <option value="">Select In</option>
                                                <option value="0">0 In</option>
                                                <option value="1">1 In</option>
                                                <option value="2">2 In</option>
                                                <option value="3">3 In</option>
                                                <option value="4">4 In</option>
                                                <option value="5">5 In</option>
                                                <option value="6">6 In</option>
                                                <option value="7">7 In</option>
                                                <option value="8">8 In</option>
                                                <option value="9">9 In</option>
                                                <option value="10">10 In</option>
                                                <option value="11">11 In</option>

                                            </select>
                                        </div>
                                    </div>
                                    
                                    
                                </div>
                                <div class="mt-4">
                                    <button type="button" class="btn btn-block btn-primary hover-continue" @click="submit()" >Continue</button>
                                </div>
                            </form>
                            <div class="mt-4 text-center"><small>Feel free to call our toll free number: </small> 
                                <a href="tel:+8002565307" class="small font-weight-bold">(800) 256-5307</a>}
                            
                            </div></div></div></div></div>
        </section>
    </div>
</template>
<script>
import formHeader from "./Header.vue";

export default {
    components: {
        formHeader,
    },
    data() {
        return {
            ftVal: '',
            inchVal: '',
            form: '',
            fourFt: false,
            fiveFt: false,
            sixFt: false,
            sevenFt: false,
            
        }
    },
    created() {
        if(this.$store.state.directForm.height != '') {
            this.ftVal = parseInt(this.$store.state.directForm.height.split(',')[0].split('Ft')[0]);
            this.inchVal = parseInt(this.$store.state.directForm.height.split(',')[1].split('In')[0]);
            console.log(this.ftVal + ' ' + this.inchVal);
        }
    },
    methods: {
        submit() {
            if(this.ftVal != '' && this.inchVal != '') {
                console.log("yupp")
                this.$store.state.directForm.height = this.ftVal + 'Ft, ' + this.inchVal + 'In';
                this.$router.push({name: 'Weight'})
            } else {
                this.$toasted.error('Select an option');
            }
            
        },
        selectFt(e) {
            if(e.target.value) {
                this.ftVal = e.target.value;
                this.$store.state.directForm.heightFt = e.target.value;
            }
            

        },
        selectIn(e) {
            if(e.target.value) {
                this.inchVal = e.target.value;
                this.$store.state.directForm.heightIn = e.target.value;
            }
            
            
        },
        toggleFormButton(e) {
            console.log(e.target.value);
            if(e.target.value == "4") {
                this.fourFt = true;
                this.fiveFt = false;
                this.sixFt = false;
                this.sevenFt = false;
            }
            if(e.target.value == "5") {
                this.fourFt = false;
                this.fiveFt = true;
                this.sixFt = false;
                this.sevenFt = false;
            }
            if(e.target.value == "6") {
                this.fourFt = false;
                this.fiveFt = false;
                this.sixFt = true;
                this.sevenFt = false;
            }
            if(e.target.value == "7") {
                this.fourFt = false;
                this.fiveFt = false;
                this.sixFt = false;
                this.sevenFt = true;
            }
            this.ftVal = e.target.value + " ft";
            this.$store.state.directForm.heightFt = e.target.value + " ft";
        },
      
    }
}
</script>
<style scoped>
 .hover-continue:hover {
     background-color: #171347;
 }
</style>