<template>
<div>
    
    <div class="slice slice-sm bg-section-secondary">
        <div class="container">
            <div v-if="!loading" class="row justify-content-center">
                
                <div class="col-lg-9">
                    <div class="card mt-5" style="margin-bottom:4px;">
                                <div class="card-body">
                                    <div class="row row-grid align-items-center">
                                        <div class="col-lg-8">
                                            <div class="media align-items-center">
                                                <span class="avatar bg-success text-white rounded-circle mr-3">
                                                    <i class="uil uil-check" style="font-size: 1.75rem;"></i>
                                                </span>
                                                <div class="media-body">
                                                    <h5 class="mb-0">Diabetic Questionnaire</h5>
                                                     <p v-if="ticket.agent" class="text-muted h5 lh-150 text-sm mb-0">Agent:  {{ticket.agent.user.name}}</p>
                                                    <!-- <p class="text-muted h5 lh-150 text-sm mb-0">Age: {{ticket.policy_age}}, <span style="text-transform: uppercase;">{{ticket.policy_ss}}</span> | Term: {{ticket.policy_term + " Years"}} </p>
                                                    <p class="text-muted h5 lh-150 text-sm mb-0">Coverage Amount: {{formatCurrency2(ticket.policy_coverage * 1000)}} </p> -->
                                                   
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-auto flex-fill mt-4 mt-sm-0 text-sm-right d-none d-lg-block">
                                            <p>{{$moment(ticket.created_at).local().format('ll')}}</p>
                                            <!-- <h4 class="h6">{{formatCurrency(ticket.policy_monthly)}}</h4> -->
                                        </div>
                                    </div>
                                </div>
                    </div>
                    
                   
                    <h5 class="mt-4 mb-3">Contact information</h5>
                    <div class="card">
                        <div class="list-group list-group-flush">
                            <div class="list-group-item d-flex w-100 justify-content-between" style="border: 0px;">
                                <div class="row w-100">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">First name</label> 
                                                <p class="form-control text-muted">{{ticket.first_name}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Last name</label> 
                                                <p class="form-control text-muted">{{ticket.last_name}}</p>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <!-- <div class="list-group-item d-flex w-100 justify-content-between" style="border: 0px;">
                                <div class="row w-100 align-items-center">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Date of Birth</label> 
                                                <p class="form-control text-muted">{{ticket.dob}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Gender</label> 
                                                <p class="form-control text-muted">{{ticket.gender}}</p>
                                            </div>
                                        </div>
                                    </div>
                            </div> -->
                            <div class="list-group-item d-flex w-100 justify-content-between" style="border: 0px;">
                                <div class="row w-100">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Email</label> 
                                                <p class="form-control text-muted">{{ticket.email}}</p> 
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Phone</label> 
                                                <p class="form-control text-muted">{{ticket.phone}}</p>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            
                            <!-- <div class="list-group-item d-flex w-100 justify-content-center" style="border: 0px;">
                                <div class="row w-100">
                                        
                                        <div class="col-md-6" style="">
                                            <div class="form-group">
                                                <label class="form-control-label">State</label> 
                                                <p class="form-control text-muted">{{state[ticket.state]}}</p>
                                               
                                            </div>
                                        </div>
                                        
                                    </div>
                            </div> -->
                            
                            
                            <div class="list-group-item d-flex w-100 justify-content-between" style="border: 0px;">
                            </div>
                        </div>
                    </div>
                    <div class="mt-5">
                        <h5 class="mb-3">General information</h5>
                        <div class="card">
                        <div class="list-group list-group-flush">
                            <div class="list-group-item d-flex w-100 justify-content-between" style="border: 0px;">
                                
                                <div class="row w-100">
                                    <div>
                                        <div class="col-12 my-4">
                                            <div class="row justify-content-center py-2" style="">
                                                <div class="col-auto align-items-center" style="display:flex;align-items:center;">
                                                    <h3 class="h5 mb-0 ml-2">Are you a diabetic?</h3>
                                                </div>
                                                <div v-if="ticket.questionnairfirst.q1 == 'Yes'" class="col text-right text-right">
                                                    <button type="button" value="yes" class="btn btn-sm btn-dark" style="width:95px;">
                                                        Yes
                                                    </button>
                                                    <button type="button" value="no" class="btn btn-sm btn-outline-info" style="width:95px;">
                                                        No
                                                    </button>
                                                </div>
                                                <div v-else class="col text-right text-right">
                                                    <button type="button" value="yes" class="btn btn-sm btn-outline-info" style="width:95px;">
                                                        Yes
                                                    </button>
                                                    <button type="button" value="no" class="btn btn-sm btn-dark" style="width:95px;">
                                                        No
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    <div>
                                        <!-- type of diabetic  -->
                                        <div class="col-12 my-4">
                                            <div class="row py-2" style="">
                                                <div class="col-12 align-items-start" style="display:flex;align-items:center;">
                                                    <h3 class="h5 mb-0 ml-2">Which describes you best?</h3>
                                                </div>
                                               
                                                <div class="col-12 align-items-end text-right py-2">
                                                    <button type="button" :class="[ ticket.questionnairfirst.q2 == 'I am Type I' ? 'btn-dark' : 'btn-outline-info']" value="I am Type I" class="btn btn-sm mx-2" style=";">
                                                    I am Type I
                                                    </button>
                                                    <button type="button" :class="[ ticket.questionnairfirst.q2 == 'I am Type II' ? 'btn-dark' : 'btn-outline-info']" value="I am Type II" class="btn btn-sm mx-4" style="">
                                                    I am Type II
                                                    </button>
                                                    <button type="button" :class="[ ticket.questionnairfirst.q2 == 'I am Pre-Diabetic' ? 'btn-dark' : 'btn-outline-info']" value="I am Pre-Diabetic" class="btn btn-sm mx-2" style="">
                                                        I am Pre-Diabetic
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- // do you take insulin -->
                                        <div class="col-12 my-4">
                                            <div class="row justify-content-center py-2" style="">
                                                <div class="col-auto align-items-center" style="display:flex;align-items:center;">
                                                    <h3 class="h5 mb-0 ml-2">Do you take insulin? </h3>
                                                </div>
                                                <div class="col text-right text-right">

                                                    <button type="button" value="yes" :class="[ ticket.questionnairfirst.q11 == 'Yes' ? 'btn-dark' : 'btn-outline-info']" class="btn btn-sm" style="width:95px;">
                                                        Yes
                                                    </button>
                                                    <button type="button" value="no" :class="[ ticket.questionnairfirst.q11 == 'No' ? 'btn-dark' : 'btn-outline-info']" class="btn btn-sm" style="width:95px;">
                                                        No
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- // revent a1c value -->
                                        <div class="col-12 my-4">
                                            <div class="row justify-content-between py-2" style="">
                                                <div class="col-auto align-items-center" style="display:flex;align-items:center;">
                                                    <h3 class="h5 mb-0 ml-2">What is your most revent A1C test value?</h3>
                                                </div>
                                                <div class="col text-right">
                                                    <div class="h4 text-right mb-0 mt-2 mb-4">
                                                        <span class="price font-weight-bolder mr-4">{{  ticket.questionnairfirst.q3 == 13 ? '13+' : ticket.questionnairfirst.q3 }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- // year have you had diabetes -->
                                        <div class="col-12 my-4">
                                            <div class="row justify-content-between py-2" style="">
                                                <div class="col-auto align-items-center" style="display:flex;align-items:center;">
                                                    <h3 class="h5 mb-0 ml-2">How many years have you had Diabetes?</h3>
                                                </div>
                                                <div class="col text-right text-right">
                                                    <div class="h4 text-right mb-0 mt-2 mb-4">
                                                        <span class="price font-weight-bolder mr-4">{{  ticket.questionnairfirst.q4 }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        <!-- // current height --> 
                                        <div class="col-12 my-4">
                                            <div class="row justify-content-between py-2" style="">
                                                <div class="col-auto align-items-center" style="display:flex;align-items:center;">
                                                    <h3 class="h5 mb-0 ml-2">What is your current height?</h3>
                                                </div>
                                                <div class="col text-right">
                                                    <div class="h4 text-right mb-0 mt-2 mb-4">
                                                        <span class="price font-weight-bolder mr-4">{{ ticket.questionnairfirst.q10 }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- // current weight -->
                                        <div class="col-12 my-4">
                                            <div class="row justify-content-between py-2" style="">
                                                <div class="col-auto align-items-center" style="display:flex;align-items:center;">
                                                    <h3 class="h5 mb-0 ml-2">What is your current weight?</h3>
                                                </div>
                                                <div class="col text-right text-right">
                                                    <div class="h4 text-right mb-0 mt-2 mb-4">
                                                        <span class="price font-weight-bolder mr-4">{{ ticket.questionnairfirst.q5 }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        


                                        <div class="col-12 mt-4">
                                            <div class="row justify-content-center my-4" style="">
                                                <div class="col-auto align-items-center" style="display:flex;align-items:center;">
                                                    <h3 class="h5 mb-0 ml-2">Eye problems?</h3>
                                                </div>
                                                <div class="col text-right text-right">
                                                    <button type="button" value="yes" :class="[ ticket.questionnairfirst.q7 == 'Yes' ? 'btn-dark' : 'btn-outline-info']" class="btn btn-sm" style="width:95px;">
                                                        Yes
                                                    </button>
                                                    <button type="button" value="no" :class="[ ticket.questionnairfirst.q7 == 'No' ? 'btn-dark' : 'btn-outline-info']" class="btn btn-sm" style="width:95px;">
                                                        No
                                                    </button>
                                                </div>
                                                <div class="col-12">
                                                    <p v-if="ticket.questionnairsecond.text1" class="form-control text-muted mt-3 mx-4">
                                                        {{ ticket.questionnairsecond.text1 }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="row justify-content-center my-4" style="">
                                                <div class="col-auto align-items-center" style="display:flex;align-items:center;">
                                                    <h3 class="h5 mb-0 ml-2">Heart problems?</h3>
                                                </div>
                                                <div class="col text-right text-right">
                                                    <button type="button" value="yes" :class="[ ticket.questionnairfirst.q8 == 'Yes' ? 'btn-dark' : 'btn-outline-info']" class="btn btn-sm" style="width:95px;">
                                                        Yes
                                                    </button>
                                                    <button type="button" value="no" :class="[ ticket.questionnairfirst.q8 == 'No' ? 'btn-dark' : 'btn-outline-info']" class="btn btn-sm" style="width:95px;">
                                                        No
                                                    </button>
                                                </div>
                                                <div class="col-12">
                                                    <p v-if="ticket.questionnairsecond.text3" class="form-control text-muted mt-3 mx-4">
                                                        {{ ticket.questionnairsecond.text3 }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="row justify-content-center my-4" style="">
                                                <div class="col-auto align-items-center" style="display:flex;align-items:center;">
                                                    <h3 class="h5 mb-0 ml-2">High blood pressure?</h3>
                                                </div>
                                                <div class="col text-right text-right">
                                                    <button type="button" value="yes" :class="[ ticket.questionnairfirst.q6 == 'Yes' ? 'btn-dark' : 'btn-outline-info']" class="btn btn-sm" style="width:95px;">
                                                        Yes
                                                    </button>
                                                    <button type="button" value="no" :class="[ ticket.questionnairfirst.q6 == 'No' ? 'btn-dark' : 'btn-outline-info']" class="btn btn-sm" style="width:95px;">
                                                        No
                                                    </button>
                                                </div>
                                                <div class="col-12">
                                                    <p v-if="ticket.questionnairsecond.text2" class="form-control text-muted mt-3 mx-4">
                                                        {{ ticket.questionnairsecond.text2 }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="row justify-content-center my-4" style="">
                                                <div class="col-auto align-items-center" style="display:flex;align-items:center;">
                                                    <h3 class="h5 mb-0 ml-2">Kidney problems?</h3>
                                                </div>
                                                <div class="col text-right text-right">
                                                    <button type="button" value="yes" :class="[ ticket.questionnairfirst.q13 == 'Yes' ? 'btn-dark' : 'btn-outline-info']" class="btn btn-sm" style="width:95px;">
                                                        Yes
                                                    </button>
                                                    <button type="button" value="no" :class="[ ticket.questionnairfirst.q13 == 'No' ? 'btn-dark' : 'btn-outline-info']" class="btn btn-sm" style="width:95px;">
                                                        No
                                                    </button>
                                                </div>
                                                <div class="col-12">
                                                    <p v-if="ticket.questionnairsecond.text4" class="form-control text-muted mt-3 mx-4">
                                                        {{ ticket.questionnairsecond.text4 }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="row justify-content-center my-4" style="">
                                                <div class="col-auto align-items-center" style="display:flex;align-items:center;">
                                                    <h3 class="h5 mb-0 ml-2">Sensory problems?</h3>
                                                </div>
                                                <div class="col text-right text-right">
                                                    <button type="button" value="yes" :class="[ ticket.questionnairfirst.q14 == 'Yes' ? 'btn-dark' : 'btn-outline-info']" class="btn btn-sm" style="width:95px;">
                                                        Yes
                                                    </button>
                                                    <button type="button" value="no" :class="[ ticket.questionnairfirst.q14 == 'No' ? 'btn-dark' : 'btn-outline-info']" class="btn btn-sm" style="width:95px;">
                                                        No
                                                    </button>
                                                </div>
                                                <div class="col-12">
                                                    <p v-if="ticket.questionnairsecond.text5" class="form-control text-muted mt-3 mx-4">
                                                        {{ ticket.questionnairsecond.text5 }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="row justify-content-center my-4" style="">
                                                <div class="col-auto align-items-center" style="display:flex;align-items:center;">
                                                    <h3 class="h5 mb-0 ml-2">Any other complications?</h3>
                                                </div>
                                                <div class="col text-right text-right">
                                                    <button type="button" value="yes" :class="[ ticket.questionnairfirst.q15 == 'Yes' ? 'btn-dark' : 'btn-outline-info']" class="btn btn-sm" style="width:95px;">
                                                        Yes
                                                    </button>
                                                    <button type="button" value="no" :class="[ ticket.questionnairfirst.q15 == 'No' ? 'btn-dark' : 'btn-outline-info']" class="btn btn-sm" style="width:95px;">
                                                        No
                                                    </button>
                                                </div>
                                                <div class="col-12">
                                                    <p v-if="ticket.questionnairsecond.text6" class="form-control text-muted mt-3 mx-4">
                                                        {{ ticket.questionnairsecond.text6 }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mt-4">
                                            <div class="row justify-content-center my-4" style="">
                                                <div class="col-auto align-items-center" style="display:flex;align-items:center;">
                                                    <h3 class="h5 mb-0 ml-2"> Within the past 30 days, have you been diagnosed with, tested positive for, or been advised to take a test for, that has not yet been started or completed or the results of which are not yet known for, COVID-19 (Coronavirus)?</h3>
                                                </div>
                                                <div class="col text-right text-right mt-2">
                                                    <button type="button" value="yes" :class="[ ticket.questionnairfirst.q16 == 'Yes' ? 'btn-dark' : 'btn-outline-info']" class="btn btn-sm" style="width:95px;">
                                                        Yes
                                                    </button>
                                                    <button type="button" value="no" :class="[ ticket.questionnairfirst.q16 == 'No' ? 'btn-dark' : 'btn-outline-info']" class="btn btn-sm" style="width:95px;">
                                                        No
                                                    </button>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="row justify-content-center my-4" style="">
                                                <div class="col-auto align-items-center" style="display:flex;align-items:center;">
                                                    <h3 class="h5 mb-0 ml-2">Within the past 30 days, have you been advised to self-isolate or be quarantined, due to symptoms of, or for any other reason related to, COVID-19 (Coronavirus)?</h3>
                                                </div>
                                                <div class="col text-right text-right mt-2">
                                                    <button type="button" value="yes" :class="[ ticket.questionnairfirst.q17 == 'Yes' ? 'btn-dark' : 'btn-outline-info']" class="btn btn-sm" style="width:95px;">
                                                        Yes
                                                    </button>
                                                    <button type="button" value="no" :class="[ ticket.questionnairfirst.q17 == 'No' ? 'btn-dark' : 'btn-outline-info']" class="btn btn-sm" style="width:95px;">
                                                        No
                                                    </button>
                                                </div>
                                               
                                            </div>
                                        </div>
                                        
                                        </div>
                                        
                                       
                                        <div class="col-12 pb-4">
                                            
                                        </div>
                                        
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    </div>
                    
                    <div class="mt-5">
                        <div id="accordion-1" class="accordion accordion-spaced">
                        <div class="card">
                            <div class="card-header py-4 collapsed" id="heading-1-1" data-toggle="collapse" role="button" data-target="#collapse-1-1" aria-expanded="false" aria-controls="collapse-1-1">
                                <h6 class="mb-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file mr-3"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline>
                                    </svg>Additional Questions
                                </h6>
                            </div>
                            <div id="collapse-1-1" class="collapse" aria-labelledby="heading-1-1" data-parent="#accordion-1" style="">
                                <div class="card-body">
                                    <div v-if="ticket.additionala">
                                        <h5 class="mb-3">Doctor information</h5>
                                        
                                        
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Date Last Consulted with your Doctor</label> 
                                                        <p v-html="ticket.additionala.doctor_date" class="form-control" type="date"></p>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">
                                                            Name of Doctor or Medical Facility
                                                            </label> 
                                                        <p v-html="ticket.additionala.doctor_name" class="form-control" type="text"></p>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="row">
                                                        <div class="col-sm-9">
                                                            <div class="form-group">
                                                                <label class="form-control-label">Address of Doctor of Medical Facility</label> 
                                                                <p v-html="ticket.additionala.doctor_address" class="form-control" type="text"></p>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-3">
                                                            <div class="form-group">
                                                                <label class="form-control-label">Number</label> 
                                                                <p v-html="ticket.additionala.doctor_addressnum"  class="form-control" type="text"></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">
                                                        Phone Number of Doctor of Medical Facility
                                                            </label> 
                                                        <p v-html="ticket.additionala.doctor_phone" class="form-control" type="text"></p>
                                                    </div>
                                                </div>
                                                
                                                
                                                
                                                
                                                
                                            </div>
                                            
                                            <div class="mt-3">
                                            
                                                
                                            </div>
                                            <h5 class="mb-3">Policy information</h5>
                                        
                                        
                                            <div class="row">
                                            
                                                
                                                
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">List of Beneficiaries and Relationship to you</label> 
                                                    
                                                        <p v-html="ticket.additionala.beneficiaries" class="form-control"></p>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-md-12 llpshow">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Do you have a Life Insurance Policy Currently Active?</label> 
                                                        <p v-html="ticket.additionala.doctor_phone" class="form-control" type="text"></p>
                                                    </div>
                                                </div>
                                                <div v-if="ticket.additionala.policy_isactive == 'Yes'" class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">If Yes, Name of the Company </label> 
                                                        <p v-html="ticket.additionala.policy_company" class="form-control" type="text"></p>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Amount of Life Insurance Currently Active? </label>
                                                        <p v-html="ticket.additionala.policy_amount" class="form-control" type="text"></p>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Year Life Insurance went into Force? </label> 
                                                        <p v-html="ticket.additionala.policy_year" class="form-control" type="text"></p>
                                                    </div>
                                                </div>
                                                
                                                
                                                
                                            </div>
                                            
                                            <div class="mt-3">
                                            
                                                
                                            </div>
                                            <h5 class="mb-3">Bank information</h5>
                                        
                                        
                                            <div class="row">
                                                
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">To Provide Coverage immediately please provide the Name of your Bank: Note: This information can be collected at time of application. </label> 
                                                        <p v-html="ticket.additionala.bank_name" class="form-control" type="text"></p>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Routing Number: </label> 
                                                        <p v-html="ticket.additionala.bank_routing" class="form-control" type="text"></p>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Account Number: </label> 
                                                        <p v-html="ticket.additionala.bank_account" class="form-control" type="text"></p>
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                            
                                            <div class="mt-3">
                                            
                                                
                                            </div>
                                            
                                            
                                            
                                    </div>
                                    <div v-else>
                                        <h5 class="mb-3">Not yet filled</h5>
                                    </div>
                                    <hr>
                                    
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    
                    
                    
                    <div class="row justify-content-end pt-4 mt-4 mb-4 pb-4">
                        <div class="col-md-12 mt-6">
                            <div class="row" >
                                <div class="col-md-6 justify-content-start">
                                    <!-- <ul class="cancelOptions">
                                        <li>
                                            <button v-if="$store.getters.isEnroller && !ticket.complete" @click="showScheduler()" type="button" class="btn btn-danger">New Schedule</button><br>
                                        </li>
                                        <li>
                                            <button v-if="$store.getters.isEnroller && !ticket.complete && ticket.enroller_id" @click="showEnrCancel()" type="button" class="btn btn-danger">Enroller Cancel</button>
                                        </li>
                                        <li>
                                            <button v-if="$store.getters.isEnroller && !ticket.complete" @click="showEnrCancel()" type="button" class="btn btn-danger">Terminate Ticket</button>
                                        </li>
                                    </ul> -->
                                    <div class="row">
                                        <div class="col-6 mb-4">
                                            <button type="button" class="btn btn-danger px-6">
                                                Delete
                                            </button>
                                        <br>
                                        </div>
                                        
                                    </div>
                                    
                                    
                                    
                                    
                                </div>
                                <div class="col-6 justify-content-end" style="display:flex;justify-content:end">
                                    
                                    
                                    
                                    
                                    <div class="row justify-content-end">
                                        <div v-if="ticket.viewed == false" class="col-12 mb-4" style="display:flex;justify-content:end;height: fit-content;">
                                            <span v-if="!loading2" @click="QuestionnaireViewed()" type="button" class="btn btn-success px-6">
                                                Viewed
                                            </span>
                                            <span v-else type="button" class="btn btn-success px-6">
                                                <div class="spinner-border" role="status">
                                                <span class="sr-only">Loading...</span>
                                                </div>
                                            </span>
                                        </div>
                                        
                                        
                                    </div>

                                </div>
                            </div>
                            
                            
                        </div>
                    </div>
                    <div style="position:absolute;top:0px;margin-top:-14px;left:10px;">
                        <div class="col-auto">
                            <button @click="$router.go(-1)" type="button" class="btn btn-xs btn-primary btn-icon rounded-pill">
                                <span class="btn-inner--icon">
                                    <i class="uil uil-arrow-left"></i>
                                </span> 
                                <span class="btn-inner--text">Back</span>
                            </button>
                        </div>
                    </div>
                </div>
                
            </div>
            <div v-else style="height:145px;width:100%;display:flex;align-items:center;justify-content:center;">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </div>
    <enroller-accept2 
            ref="enrAccept"
            
        />
    <slot-assign 
        ref="slotAssign"
        
    />
    <enroller-cancel 
            ref="enrCancel"
            
        />
    <ticket-cancel 
            ref="ticketCancel"
            
        />
    <ticket-complete 
            ref="tComplete"
            
        />
    <ticket-signature 
        ref="tSignature"
    />
    <scheduler 
        ref="schedulerOpen"
    />
</div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import enrollerAccept2 from '../comps/EnrollerAccept2.vue';
import slotAssign from '../comps/SlotAssign.vue';
import enrollerCancel from '../comps/EnrollerCancel.vue';
import ticketCancel from '../comps/TicketCancel.vue';
import ticketComplete from '../comps/TicketComplete.vue';
import ticketSignature from '../comps/TicketSignature.vue';
import scheduler from '../comps/Scheduler.vue';
export default {
    components: {
        enrollerAccept2,
        slotAssign,
        enrollerCancel,
        ticketCancel,
        ticketSignature,
        ticketComplete,
        scheduler,
    },
    data() {
        return {
            ticket: '',
            loading: false,
            loading2: false,
            state: {
                AL: "Alabama",
                AZ: "Arizona",
                AR: "Arkansas",
                CA: "California",
                CO: "Colorado",
                CT: "Connecticut",
                DE: "Delaware",
                FL: "Florida",
                GA: "Georgia" ,    
                ID: "Idaho",
                IL: "Illinois",
                IN: "Indiana",
                IA: "Iowa",
                KS: "Kansas",
                KY: "Kentucky",
                LA: "Louisiana",
                ME: "Maine",
                MD: "Maryland",
                MA: "Massachusetts",
                MI: "Michigan",
                MN: "Minnesota",
                MS: "Mississippi",
                MO: "Missouri",
                MT: "Montana",
                NE: "Nebraska",
                NV: "Nevada",
                NH: "New Hampshire",
                NM: "New Mexico",
                NJ: "New Jersey",
                NC: "North Carolina",
                ND: "North Dakota",
                OH: "Ohio",
                OK: "Oklahoma",
                OR: "Oregon",
                PA: "Pennsylvania",
                RI: "Rhode Island",
                SC: "South Carolina",
                SD: "South Dakota",
                TN: "Tennessee",
                TX: "Texas",
                UT: "Utah",
                VT: "Vermont",
                VA: "Virginia",
                WA: "Washington",
                WV: "West Virginia",
                WI: "Wisconsin",
                WY: "Wyoming",
            },
            notes: '',
            showNoteUpdate: false,
            noteLoading: false,
        }
    },
    created() {
        window.scrollTo(0,0);
       // this.fetchTicket();
       this.ticket = this.$route.query.ticket;
       console.log('showin ticket')
        console.log(this.ticket);
       // this.zip();
       
    },
    computed: {
        
    },
    methods: {
        fetchTicket() {
            this.loading = true;
            console.log(this.$route.params.id);
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.get('/api/fetchsingleticket/'+this.$route.params.id).then(resp => {
                this.ticket = resp.data.t;
                this.loading = false;
            }).catch(err => { this.loading = false });
        },
        QuestionnaireViewed() {
            this.loading2 = true;
             axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
             axios.post('/api/questionnaireviewd', this.ticket).then(resp => {
                console.log(resp.data);
                
                this.loading2 = false;
                this.$toasted.success('Status Viewed');
             }).catch(err => {
                this.loading2 = false;
             });
        },
        formatCurrency(amount) {
            amount = (amount / 100);
            return amount.toLocaleString('en', { style: 'currency', currency: 'usd', minimumFractionDigits: 2 });
        },
        formatCurrency2(amount) {
            amount = (amount);
            return amount.toLocaleString('en', { style: 'currency', currency: 'usd', minimumFractionDigits: 0 });
        },
        printDate(i) {
            return i !== null ? moment.utc(i).local().format('dddd MMM D,   h:mm a') : '';
        },
        printDate2(i) {
            return i !== null ? moment.utc(i).local().format('dddd MMMM DD') : '';
        },
        printDateTimezone(i, t) {
            return moment.utc(i).tz(t).format('dddd MMM D,   h:mm a');
        },
        removeSchedule() {
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.post("/api/cancelslot", {id: this.ticket.id}).then(resp => {
                console.log(resp.data);
                this.ticket.enroller_id = null;
            }).then(resp2 => {
                this.$toasted.success('Cancelled');
            });
        },
        showEnrAccept() {          
                $(this.$refs.enrAccept.$el).modal('show');
        },
        showSlotAssign() {          
                $(this.$refs.slotAssign.$el).modal('show');
        },
        showEnrCancel() {          
                $(this.$refs.enrCancel.$el).modal('show');
        },
        showTicketCancel() {          
                $(this.$refs.ticketCancel.$el).modal('show');
        },
        showTComplete() { 
                if(this.ticket.note != '' && this.ticket.note != null) {
                    $(this.$refs.tComplete.$el).modal('show');
                } else {
                    this.$toasted.error('Notes are Missing');
                }      
                
        },
        showTSignature() {
            $(this.$refs.tSignature.$el).modal('show');
        },
        showPendingSignature() {
            console.log('pending');
        },
        showScheduler() {
            $(this.$refs.schedulerOpen.$el).modal('show');
        },
        showNoteButton() {
            this.showNoteUpdate = true;
        },
        updateNotes() {
            this.noteLoading = true;
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.post('/api/updatenotes', this.ticket).then(resp=> {
                console.log(resp);
                this.noteLoading = false;
                this.showNoteUpdate = false;
            }).then(resp2 => {
                this.$toasted.success('Updated');
            });
        }
        
    }
}
</script>
<style>
.cancelOptions {
    list-style-type: none;
    padding-left:0px;
}
.cancelOptions li {
    margin-bottom: 1rem;
}

.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 8px;
  padding: 5px 15px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 15px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, .1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}
</style>