<template>
    <div class="modal fade docs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

        <div class="modal-header">
            <h5 class="modal-title h6" id="myLargeModalLabel">Enroller Creation</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row mt-4 pb-4" style="display: flex; justify-content: center; margin-top: -24px;">
                <div class="col-md-8" style="display: flex; justify-content: center;">
                    <div class="row" style="justify-content: center;">
                        <div class="col-md-12 mb-4" style="display: flex; justify-content: center;">
                            <avatar :username="form.name" :size="90" class="rounded-circle"> </avatar>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label style="color: rgba(0, 0, 0, 0.6);">Name *</label>
                                <input v-model="form.name" name="contact-name" type="text" placeholder="Name" required="required" class="form-control">
                                <div class="invalid-feedback"> Please type your name. </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label style="color: rgba(0, 0, 0, 0.6);">Phone Number *</label>
                                <input v-model="form.phone" name="contact-email" type="email" placeholder="(123) 456-7890" required="required" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label style="color: rgba(0, 0, 0, 0.6);">Email Address *</label>
                                <input v-model="form.email" name="contact-email" type="email" placeholder="you@visionary.com" required="required" class="form-control" :class="{'outlineTicketSuccess' : emailc == 1, 'outlineTicketDanger' : emailc == 2}">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label style="color: rgba(0, 0, 0, 0.6);">Email Address *</label>
                                <input @keyup="confirmEmail" v-model="email" placeholder="you@visionary.com" name="contact-email" type="email" required="required" class="form-control" :class="{'outlineTicketSuccess' : emailc == 1, 'outlineTicketDanger' : emailc == 2}">
                            </div>
                        </div>
                        
                        <div class="col-md-12 pb-2" style="display: flex; justify-content: center; align-items: center; margin-top: 40px;">
                            
                            <button @click="createEnroller()" type="submit" data-loading-text="Sending" class="btn btn-primary btn-loading">
                                
                                <span v-if="!loading">Create Account</span>
                                <div v-else class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    </div>
</template>
<script>
import axios from 'axios';
import Avatar from 'vue-avatar'
export default {
    name: "enroller-create",
    components: {
        Avatar,
    },
    data() {
        return {
            loading: '',
            form: {
                name: '',
                phone: '',
                email: '',
            },
            emailc: '',
            email: '',
        }
    },
    methods: {
        confirmEmail(event) {
            
            if(event.target.value.length >= 4) {
                console.log(event.target.value);
                if(this.email == this.form.email) {
                    this.emailc = 1;
                    console.log("equals");
                }  else {
                    this.emailc = 2;
                }

            } else {
                this.emailc = '';
            }
        },
        createEnroller() {
           
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            if(this.form.name != '' && this.form.number != '' && this.form.email != '' && this.email == this.form.email) {
                 this.loading = true;
                axios.post('/api/createenroller', this.form).then(resp => {
                    console.log(resp.data);
                    this.form.name = '';
                    this.form.email = '';
                    this.form.phone = '';
                    this.email = '';
                    this.emailc = '';
                }).then(resp2 => {
                    this.loading = false;
                    $(this.$parent.$refs.enrollerCreate.$el).modal('hide');
                    this.$parent.fetchEnrollers();
                    this.$toasted.success('Created');
                }).catch(err => {
                    this.loading = false;
                    $(this.$parent.$refs.enrollerCreate.$el).modal('hide');
                });
            } else if(this.form.name == '') {
                this.$toasted.error('Name');
            } else if(this.form.phone == '') {
                this.$toasted.error('Phone');
            } else if(this.form.email == '') {
                this.$toasted.error('Email');
            } else if(this.form.email == '' || this.emailc == '' || this.emailc == 2) {
                this.$toasted.error('Email');
            }
            
        },
    }
}
</script>
<style scoped>
.outlineTicketSuccess {
    border-color: #5cc9a7;
}
.outlineTicketDanger {
    border-color: #ee283c;;
}
</style>