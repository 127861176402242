<template>
<div>
    <section class="pt-5 bg-section-secondary">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="row align-items-center">
                        <div class="col">
                            <span class="surtitle">Visionary INC</span>
                            <h1 class="h2 mb-0">Agent Program Registration</h1>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </section>
    <div class="slice slice-sm bg-section-secondary">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row row-grid align-items-center">
                                        <div class="col-lg-8">
                                            <div class="media align-items-center">
                                                <span class="avatar bg-danger text-white rounded-circle mr-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                                                    </svg>
                                                </span>
                                                <div class="media-body">
                                                    <h5 class="mb-0">Agent Account</h5>
                                                    <p class="text-muted lh-150 text-sm mb-0">Your account will auto-renew on January 10th, 2020</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-auto flex-fill mt-4 mt-sm-0 text-sm-right d-none d-lg-block">
                                            $21.00 / Month
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-3">General information</h5>
                                <form>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">First name</label> 
                                                <input v-model="form.first_name" class="form-control" type="text" placeholder="Enter your first name">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Last name</label> 
                                                <input v-model="form.last_name" class="form-control" type="text" placeholder="Also your last name">
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Email</label> 
                                                <input v-model="form.email" @blur="validateEmail" class="form-control" :class="{'outlineTicketSuccess' : (form.email == form.confirm_email) && form.email.length > 3}" type="email" placeholder="name@exmaple.com"> 
                                                
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Confirm Email</label> 
                                                <input class="form-control" v-model="form.confirm_email" :class="{'outlineTicketSuccess' : (form.email == form.confirm_email) && form.email.length > 3}" type="email" placeholder="name@exmaple.com"> 
                                                
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Phone</label> 
                                                <input v-model="form.phone" @input="acceptNumber" class="form-control" :class="{'outlineTicketSuccess' : (form.phone == form.confirm_phone) && form.phone.length > 3}" type="email" placeholder="name@exmaple.com"> 
                                                
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Confirm Phone</label> 
                                                <input v-model="form.confirm_phone" @input="acceptNumber2" class="form-control" :class="{'outlineTicketSuccess' : (form.phone == form.confirm_phone) && form.phone.length > 3}" type="text" placeholder="+40-777 245 549">
                                            </div>
                                        </div>
                                    </div>
                                    
                                <div class="mt-3">
                                    
                                </div>
                            </form>
                        </div>
                        <hr>
                        
                        <div>
                            <div>
                                <div class="row mb-4">
                                    <div class="col-auto col-lg-8">
                                        <h5 class="mb-0">Payment Information</h5>
                                    </div>
                                    <div class="col col-lg-4 text-right">
                                        
                                    </div>
                                </div>
                                <form>
                                    <div class="row mt-3">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Name on card</label> 
                                                <input v-model="form.name_card" type="text" class="form-control" placeholder="Your name">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Card number</label>
                                                <div class="input-group input-group-merge">
                                                    <div id="card-element"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-9">
                                            <div class="form-group">
                                                <label class="form-control-label">Address</label> 
                                                <input v-model="form.address" class="form-control" type="text" placeholder="Enter your home address">
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="form-group">
                                                <label class="form-control-label">Number</label> 
                                                <input v-model="form.address_no" class="form-control" type="tel" placeholder="No.">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <label class="form-control-label">City</label> 
                                                <input v-model="form.city" class="form-control" type="text" placeholder="City">
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <label class="form-control-label">State</label> 
                                                <select v-model="form.state" class="form-control select2-hidden-accessible" data-toggle="select" title="Country" data-live-search="true" data-live-search-placeholder="Country" data-select2-id="1" tabindex="-1" aria-hidden="true">
                                                    <option value="Select">Select</option>
                                                    <option v-for="i in $store.state.state" :key="i" :value="i">{{i}}</option>
                                                </select>
                                                
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <label class="form-control-label">ZIP</label> 
                                                <input v-model="form.zip" class="form-control" type="tel" placeholder="ZIP">
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                    <div class="text-right mt-5 mb-5">
                                        <button v-if="!submitLoading" @click.prevent="submitForm" type="button" class="btn btn-primary">test submit</button>
                                        <button v-else class="btn btn-primary">
                                            <div class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                            </div>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div> 
</template>
<script>
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';

export default {
    components: {

    },
    data() {
        return {
            submitLoading: false,
            form: {
                first_name: '',
                last_name: '',
                email: '',
                confirm_email: '',
                phone: '',
                confirm_phone: '',
                
                name_card: '',
                address: '',
                address_no: '',
                city: '',
                state: '',
                zip: '',

            },
            emailValid: '',
        }
    },
    created() {

    },
    async mounted() {
      this.stripe = await loadStripe("pk_test_51HmP9BId1CZ4fXCBLaKasrUWHQPdLMCMIzrXAIyXtT43BHk6Bc9doqy0IkOQoh3fwf5ZEik7H1555HyBNei4ukmX00UmB25wfZ");
      const elements = this.stripe.elements();
      this.cardElement = elements.create('card', {
        classes: {
          base: 'form-control',
        }
      });
      console.log('creating mounted component');
      this.cardElement.mount('#card-element');
    },
    methods: {
        acceptNumber() {
            var x = this.form.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.form.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        acceptNumber2() {
            var x = this.form.confirm_phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.form.confirm_phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        validateEmail() {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(this.form.email)) {
                console.log('email looks alright');  
                this.emailValid = true;
            } else {
                console.log('Please enter a valid email address');
                this.emailValid = false;
            }
        },
        submitForm() {
            if(this.first_name != '' && this.form.last_name != '' && this.form.email != '' && this.emailValid == true && this.form.email == this.form.confirm_email && this.form.phone != '' && this.form.phone == this.form.confirm_phone && this.form.name_card != '' && this.form.address != '' && this.form.city != '' && this.form.state != '' && this.form.zip != '') {
                this.submitLoading = true;
                this.processPayment();
            } else {
                this.$toasted.error('Include all values');
            } 
        },
        async processPayment() {
        const {paymentMethod, error} = await this.stripe.createPaymentMethod(
          'card', this.cardElement, {
            billing_details: {
              name: this.form.first_name + ' ' + this.form.last_name,
              email: this.form.email,
              
              
              address: {
                line1: this.form.address,
                city: this.form.city,
                state: this.form.state,
                postal_code: this.form.zip,
               
              }
            }
          }
        );
        
        if(error) {
          console.log(error);
        } else {
          this.form.payment_method_id = paymentMethod.id;
          
            /* this.createOrder(); */
            console.log(paymentMethod)
            axios.post('/api/submitoboardingdetails', this.form).then(resp => {
                console.log(resp.data);
                this.submitLoading = false;
                this.$router.push({name: 'Agent.Success'});
                
            }).then(resp2 => {
                
            }).catch(err => {
                this.submitLoading = false;
                this.$toasted.error("Something went wrong, please try again")
            });
          
        }
      },
    },
}
</script>
<style scoped>
.outlineTicketSuccess {
    border-color: #5cc9a7;
}
.outlineTicketDanger {
    border-color: #ee283c;;
}
</style>