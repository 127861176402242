<template>
<div class="modal fade docs-example-modal-lg" @click.self="closeModal()" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" >
        <div class="modal-content">

        <div class="modal-header">
            <h5 class="modal-title h6" id="myLargeModalLabel">Visionary Inc</h5>
            <button type="button" @click="closeModal()" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" style="padding:0px;">

            <div class="row mt-2 pb-4" style="display: flex; justify-content: center; margin-top: -24px;">
                <div class="col-lg-9 col-12" style="margin: 0 auto;">
                    <div class="rounded-bottom-right">
                        <div v-if="!quoteSubmitted" class="card-body">
                            <div class="d-flex align-items-center mb-4">
                                <h3 class="display-5 text-dark my-2 text-highlight-warning" style="margin: 0 auto;">Instant Life Insurance Quote</h3>
                            </div>
                            <div v-if="!$parent.showQuoter" class="row mx-n2 mt-5 justify-content-center align-items-center justify-content-center">
                               
                                <div class="col-md-6 col-sm-6 px-2">
                                    <!-- <div class="card hover-translate-y-n6 hover-shadow-lg" style="cursor: pointer;">
                                        <div class="card-body text-center" @click="clickFixed()">
                                            <div class="avatar-parent-child">
                                                <i class="uil uil-comment-lines" style="font-size: 6rem;"></i>
                                            </div>
                                            <a href="#" class="d-block h4 mt-4 mb-0">Peace of Mind</a>
                                            
                                        </div>
                                    </div> -->
                                    <div class="card mb-3" style="height:185px;cursor:pointer;" @click="clickFixed()">
                                        <div class="card-header pb-3">
                                            
                                        </div>
                                        <div class="card-body pt-0">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div>
                                                   
                                                    <span class="avatar bg-success text-white rounded-circle mr-3">
                                                        <span class="bg-success" style="font-size: 1.75rem;">1</span>
                                                    </span>
                                                </div>
                                                <div class="text-right">
                                                    <div class="d-flex align-items-center">
                                                        <div>
                                                            <span class="badge badge-xs badge-circle rounded-circle badge-success">
                                                                
                                                            </span>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-3">
                                                <h6 class="mb-0">Peace of Mind</h6>
                                                <p class="mb-0 text-sm text-muted">Death Benefit Coverage<br> $5,000 - $50,000</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-md-6 col-sm-6 px-2">
                                    <!-- <div class="card hover-translate-y-n6 hover-shadow-lg" style="cursor: pointer;">
                                        <div class="card-body text-center" @click="clickTerm()">
                                            <div class="avatar-parent-child">
                                                <i class="uil uil-signout" style="font-size: 6rem;"></i>
                                            </div>
                                            <a href="#" class="d-block h4 mt-4 mb-0">More Coverage Income Replacement</a>
                                            
                                        </div>
                                    </div> -->
                                    <div class="card mb-3" style="height:185px;cursor:pointer;" @click="clickTerm()">
                                        <div class="card-header pb-3">
                                            
                                        </div>
                                        <div class="card-body pt-0">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div>
                                                    
                                                    <span class="avatar bg-success text-white rounded-circle mr-3">
                                                        <span class="bg-success" style="font-size: 1.75rem;">2</span>
                                                    </span>
                                                </div>
                                                <div class="text-right">
                                                    <div class="d-flex align-items-center">
                                                        <div>
                                                            <span class="badge badge-xs badge-circle rounded-circle badge-success">
                                                                
                                                            </span>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-3">
                                                <h6 class="mb-0">More Coverage</h6>
                                                <p class="mb-0 text-sm text-muted">Income Replacement Death Benefits $50k - $500k</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            
                            <div v-if="$parent.showQuoter == 'fixed'" class="row justify-content-center list-group-flush mb-4">
                                <div class="col-12">
                                    <div id="form-contact">
                                        
                                        <div class="form-group">
                                            
                                            <!-- select @change="amountChange" class="form-control custom-select-lg">
                                                <option selected>Amount</option>
                                                <option value="400">$400,000</option>
                                                <option v-for="i in 14" :key="i" :value="400 - (i * 25)">${{ 400 - (i * 25) }},000</option>
                                            </select> -->
                                            <!-- <select @change="amountChange" class="form-control custom-select-lg">
                                                <option selected>Amount</option>
                                                <option value="40">$50,000</option>
                                                <option v-for="i in 96" :key="i" :value="50000 - (i * 500)">{{formatCurrency((50000 - (i * 500)))}}</option>
                                            </select> -->
                                            <select @change="amountChange" class="form-control custom-select-lg">
                                                <option selected>Amount</option>
                                                <option value="50000">$50,000</option>
                                                <option v-for="i in 45" :key="i" :value="50000 - (i * 1000)">{{formatCurrency((50000 - (i * 1000)))}}</option>
                                            </select>
                                            <!-- <select @change="amountChange" class="form-control custom-select-lg">
                                                <option selected>Amount</option>
                                                <option :value="100000">$100,000</option>
                                                <option v-for="i in 16" :key="i" :value="100000 - (i * 5000)">{{formatCurrency((100000 - (i * 5000)))}}</option>
                                            </select> -->
                                        </div>

                                        <!-- Select State --> 

                                        <div class="form-group">
                                            
                                            <select @change="stateChange" class="form-control custom-select-lg" v-model="form.state">
                                                <option value="">State</option>
                                                <option value="AL">Alabama</option>
                                                <option value="AZ">Arizona</option>
                                                <option value="AR">Arkansas</option>
                                                <option value="CA">California</option>
                                                <option value="CO">Colorado</option>
                                                <option value="CT">Connecticut</option>
                                                <option value="DE">Delaware</option>
                                                <option value="FL">Florida</option>
                                                <option value="GA">Georgia</option>     
                                                <option value="ID">Idaho</option>
                                                <option value="IL">Illinois</option>
                                                <option value="IN">Indiana</option>
                                                <option value="IA">Iowa</option>
                                                <option value="KS">Kansas</option>
                                                <option value="KY">Kentucky</option>
                                                <option value="LA">Louisiana</option>
                                                <option value="ME">Maine</option>
                                                <option value="MD">Maryland</option>
                                                <option value="MA">Massachusetts</option>
                                                <option value="MI">Michigan</option>
                                                <option value="MN">Minnesota</option>
                                                <option value="MS">Mississippi</option>
                                                <option value="MO">Missouri</option>
                                                <option value="MT">Montana</option>
                                                <option value="NE">Nebraska</option>
                                                <option value="NV">Nevada</option>
                                                <option value="NH">New Hampshire</option>
                                                <option value="NJ">New Jersey</option>
                                                <option value="NM">New Mexico</option>
                                                <option value="NC">North Carolina</option>
                                                <option value="ND">North Dakota</option>
                                                <option value="OH">Ohio</option>
                                                <option value="OK">Oklahoma</option>
                                                <option value="OR">Oregon</option>
                                                <option value="PA">Pennsylvania</option>
                                                <option value="RI">Rhode Island</option>
                                                <option value="SC">South Carolina</option>
                                                <option value="SD">South Dakota</option>
                                                <option value="TN">Tennessee</option>
                                                <option value="TX">Texas</option>
                                                <option value="UT">Utah</option>
                                                <option value="VT">Vermont</option>
                                                <option value="VA">Virginia</option>
                                                <option value="WA">Washington</option>
                                                <option value="WV">West Virginia</option>
                                                <option value="WI">Wisconsin</option>
                                                <option value="WY">Wyoming</option>

                                            </select>
                                        </div>

                                        <!-- Smoking Status -->

                                        <div class="form-group">
                                        
                                            <select @change="ssChange" class="form-control custom-select-lg">
                                                <option selected>Smoking Status</option>
                                                <option value="ns">No</option>
                                                <option value="ss">Yes</option>                                          
                                                </select>
                                        </div>

                                        <!-- Select Gender --> 

                                        <div class="form-group">
                                            <select @change="genderChange" class="form-control custom-select-lg">
                                                <option selected>Gender</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                </select>
                                        </div>
                                        
                                        <!-- Select DOB -->
                                        <div class="form-group">
                                            
                                            <div class="row">
                                                 
                                                <div class="col-4" style="padding-right:2px;">
                                                    <select @change="changeMonth" class="form-control custom-select-lg">
                                                        <option selected>Birth Month</option>
                                                        <option value="01">01 - January</option>
                                                        <option value="02">02 - February</option>
                                                        <option value="03">03 - March</option>
                                                        <option value="04">04 - April</option>
                                                        <option value="05">05 - May</option>
                                                        <option value="06">06 - June</option>
                                                        <option value="07">07 - July</option>
                                                        <option value="08">08 - August</option>
                                                        <option value="09">09 - September</option>
                                                        <option value="10">10 - October</option>
                                                        <option value="11">11 - November</option>
                                                        <option value="12">12 - December</option>
                                                    </select> 
                                                </div>
                                                <div class="col-4" style="padding-right:2px;padding-left:2px;">
                                                    
                                                    <select @change="changeDay" class="form-control custom-select-lg">
                                                        <option selected>Birth Day</option>
                                                        <option v-for="i in 31" :key="i" :value="i">{{i}}</option>
                                                        
                                                    </select> 
                                                </div>
                                                <div class="col-4" style="padding-left:2px;">
                                                    <select @change="changeYear" class="form-control custom-select-lg" >
                                                        <option selected>Birth Year</option>
                                                        <option v-for="i in 100" :key="i" :value="todayYear-i">{{ todayYear-i }}</option>
                                                    </select> 
                                                </div>
                                            </div>
                                        </div>

                                        
                                        <div class="text-center">
                                            <button type="reset" class="btn-reset d-none">

                                            </button> 
                                            <button type="submit" @click="submitQuote" class="btn btn-block btn-lg btn-primary mt-4 mb-2">
                                                <span v-if="loading == false">Get your Estimate</span>
                                                <span v-else>
                                                    <div class="spinner-border" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                    </div>
                                                </span>
                                            </button>
                                            
                                        </div>
                                    </div>
                                </div>
                                <span @click="firstBack()" class="btn btn-white btn-icon-only rounded-circle position-absolute zindex-101 left-4 top-4 d-none d-lg-inline-flex" data-toggle="tooltip" data-placement="right" title="" data-original-title="Go back">
                                    <span class="btn-inner--icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline>
                                        </svg>
                                    </span>
                                </span>
                            </div>
                            <div v-if="$parent.showQuoter == 'term'" class="row justify-content-center list-group-flush mb-4">
                                <div class="col-12">
                                    <div id="form-contact">
                                        
                                        <div class="form-group">
                                            
                                            <select @change="amountChange" class="form-control custom-select-lg">
                                                <option selected>Amount</option>
                                                <option value="500000">$500,000</option>
                                                <option v-for="i in 16" :key="i" :value="500000 - (i * 25000)">{{ formatCurrency(500000 - (i * 25000)) }}</option>
                                                <option v-for="i in 10" :key="i" :value="100000 - (i * 5000)">{{ formatCurrency(100000 - (i * 5000)) }}</option>

                                            </select>
                                            
                                        </div>

                                        <!-- Select State --> 

                                        <div class="form-group">
                                            
                                            <select @change="stateChange" class="form-control custom-select-lg" v-model="form.state">
                                                <option value="">State</option>
                                                <option value="AL">Alabama</option>
                                                <option value="AZ">Arizona</option>
                                                <option value="AR">Arkansas</option>
                                                <option value="CA">California</option>
                                                <option value="CO">Colorado</option>
                                                <option value="CT">Connecticut</option>
                                                <option value="DE">Delaware</option>
                                                <option value="FL">Florida</option>
                                                <option value="GA">Georgia</option>     
                                                <option value="ID">Idaho</option>
                                                <option value="IL">Illinois</option>
                                                <option value="IN">Indiana</option>
                                                <option value="IA">Iowa</option>
                                                <option value="KS">Kansas</option>
                                                <option value="KY">Kentucky</option>
                                                <option value="LA">Louisiana</option>
                                                <option value="ME">Maine</option>
                                                <option value="MD">Maryland</option>
                                                <option value="MA">Massachusetts</option>
                                                <option value="MI">Michigan</option>
                                                <option value="MN">Minnesota</option>
                                                <option value="MS">Mississippi</option>
                                                <option value="MO">Missouri</option>
                                                <option value="MT">Montana</option>
                                                <option value="NE">Nebraska</option>
                                                <option value="NV">Nevada</option>
                                                <option value="NH">New Hampshire</option>
                                                <option value="NJ">New Jersey</option>
                                                <option value="NM">New Mexico</option>
                                                <option value="NC">North Carolina</option>
                                                <option value="ND">North Dakota</option>
                                                <option value="OH">Ohio</option>
                                                <option value="OK">Oklahoma</option>
                                                <option value="OR">Oregon</option>
                                                <option value="PA">Pennsylvania</option>
                                                <option value="RI">Rhode Island</option>
                                                <option value="SC">South Carolina</option>
                                                <option value="SD">South Dakota</option>
                                                <option value="TN">Tennessee</option>
                                                <option value="TX">Texas</option>
                                                <option value="UT">Utah</option>
                                                <option value="VT">Vermont</option>
                                                <option value="VA">Virginia</option>
                                                <option value="WA">Washington</option>
                                                <option value="WV">West Virginia</option>
                                                <option value="WI">Wisconsin</option>
                                                <option value="WY">Wyoming</option>

                                            </select>
                                        </div>

                                        <!-- Smoking Status -->

                                        <div class="form-group">
                                        
                                            <select @change="ssChange" class="form-control custom-select-lg">
                                                <option selected>Smoking Status</option>
                                                <option value="ns">No</option>
                                                <option value="ss">Yes</option>                                          
                                                </select>
                                        </div>

                                        <!-- Select Gender --> 

                                        <div class="form-group">
                                            <select @change="genderChange" class="form-control custom-select-lg">
                                                <option selected>Gender</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                </select>
                                        </div>
                                        
                                        <!-- Select DOB -->
                                        <div class="form-group">
                                            
                                            <div class="row">
                                                <div class="col-4" style="padding-right:2px;">
                                                    <select @change="changeMonth" class="form-control custom-select-lg">
                                                        <option selected>Birth Month</option>
                                                        <option value="01">01 - January</option>
                                                        <option value="02">02 - February</option>
                                                        <option value="03">03 - March</option>
                                                        <option value="04">04 - April</option>
                                                        <option value="05">05 - May</option>
                                                        <option value="06">06 - June</option>
                                                        <option value="07">07 - July</option>
                                                        <option value="08">08 - August</option>
                                                        <option value="09">09 - September</option>
                                                        <option value="10">10 - October</option>
                                                        <option value="11">11 - November</option>
                                                        <option value="12">12 - December</option>
                                                    </select> 
                                                </div>
                                                <div class="col-4" style="padding-right:2px;padding-left:2px;">
                                                    
                                                    <select @change="changeDay" class="form-control custom-select-lg">
                                                        <option selected>Birth Day</option>
                                                        <option v-for="i in 31" :key="i" :value="i">{{i}}</option>
                                                        
                                                    </select> 
                                                </div>
                                                <div class="col-4" style="padding-left:2px;">
                                                    <select @change="changeYear" class="form-control custom-select-lg" >
                                                        <option selected>Birth Year</option>
                                                        <option v-for="i in 100" :key="i" :value="todayYear-i">{{ todayYear-i }}</option>
                                                    </select> 
                                                </div>
                                            </div>
                                        </div>

                                        
                                        <div class="text-center">
                                            <button type="reset" class="btn-reset d-none">

                                            </button> 
                                            <button v-if="!loading" type="submit" class="btn btn-block btn-lg btn-primary mt-4 mb-2">
                                                <span @click="submitTermQuote()">Get your Estimate</span>
                                                
                                            </button>
                                            <button v-else class="btn btn-block btn-lg btn-primary mt-4 mb-2">
                                                <span>
                                                    <div class="spinner-border" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                    </div>
                                                </span>
                                            </button>
                                            
                                        </div>
                                    </div>
                                </div>
                                <span @click="firstBack()" class="btn btn-white btn-icon-only rounded-circle position-absolute zindex-101 left-4 top-4 d-none d-lg-inline-flex" data-toggle="tooltip" data-placement="right" title="" data-original-title="Go back">
                                    <span class="btn-inner--icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline>
                                        </svg>
                                    </span>
                                </span>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</div>

</template>
<script>
import axios from 'axios';
import moment from 'moment';
export default {
    name: 'quote-all',
    data() {
        return {
            showQuoter: false,
            quoteSubmitted: false,
            loading: false,
            todayYear: new Date().getFullYear() - 18,
            form: {
                amount: '',
                state: '',
                ss: '',
                gender: '',

            },
            selectedDay: '',
            selectedMonth: '',
            selectedYear: '',
        }
    },
    created() {
        console.log('created');
    },
    mounted() {
        console.log('mounted');

    },
    methods: {
        firstBack() {
            this.$parent.showQuoter = false;
        },
        closeModal() {
            
            this.$emit('exit', true);

        },
        clickFixed() {
            this.$parent.showQuoter = 'fixed';

        },
        clickTerm() {
            this.$parent.showQuoter = 'term';
        },
        formatCurrency(amount) {
            amount = (amount);
            return amount.toLocaleString('en', { style: 'currency', currency: 'usd', minimumFractionDigits: 0 });
        },
        amountChange(event) {
            if(event.target.value !== "Amount") {
                this.form.amount = event.target.value;
                console.log('succesfully changed');
            } else {
                console.log("please select from the dropdown");
            }
        },
        stateChange(event) {
            if(event.target.value !== "") {
                this.form.state = event.target.value;
                console.log('succesfully changed states');
            } else {
                console.log("please select from the dropdown");
            }
        },
        ssChange(event) {
            if(event.target.value !== "Smoking Status") {
                this.form.ss = event.target.value;
                console.log('succesfully selected status');
            } else {
                console.log("please select from the dropdown");
            }
        },
        genderChange(event) {
            if(event.target.value !== "Gender") {
                this.form.gender = event.target.value;
                console.log('succesfully selected gender');
            } else {
                console.log("please select from the dropdown");
            }
        },
        changeDay(event) {
            if(event.target.value !== 'Day') {
                this.selectedDay = event.target.value;
                console.log(event.target.value);
            }
        },
        changeMonth(event) {
            if(event.target.value !== 'Month') {
                this.selectedMonth = event.target.value;
                console.log(event.target.value);
            }
        },
        changeYear(event) {
            if(event.target.value !== 'Year') {
                this.selectedYear = event.target.value;
                console.log(event.target.value);
            }
        },
        pushDirectQuote() {
            this.$parent.hideDirectQuote();
            this.$router.push({name: 'Quote'});
        },
        submitTermQuote() {
            console.log('within term quote')
            console.log(this.form.amount);
            this.$store.state.shelf = [];
            this.form.dob = this.selectedMonth + '/' + this.selectedDay + '/' + this.selectedYear;
            if(this.form.amount != '' && this.form.state != '' && this.form.ss != '' && this.form.gender != '' && this.selectedMonth != '' && this.form.selectedDay != '' && this.form.selectedYear != '') {
                this.$store.state.quoteType = this.$parent.showQuoter;
                this.$store.state.policy = this.form;
                this.$store.state.policy.age = moment(this.$store.state.policy.dob, "MM/DD/YYYY").fromNow().split(" ")[0];
                this.loading = true;
                this.pushDirectQuote()
            } else if(this.form.amount == '') {
                this.$toasted.error('Select Amount');
            } else if(this.form.state == '') {
                this.$toasted.error('Select State');
            } else if(this.form.ss == '') {
                this.$toasted.error('Smoking Status');
            } else if(this.form.gender == '') {
                this.$toasted.error('Gender');
            } else {
                console.log(this.form)
            }
        },
        submitQuote() {
            this.$store.state.shelf = [];
            this.form.dob = this.selectedMonth + '/' + this.selectedDay + '/' + this.selectedYear;
            if(this.form.amount != '' && this.form.state != '' && this.form.ss != '' && this.form.gender != '' && this.selectedMonth != '' && this.form.selectedDay != '' && this.form.selectedYear != '') {
                this.loading = true;
                axios.post('/api/fetchquoteall', this.form).then(resp => {
                    console.log(resp.data);
                    /* this.$store.state.allQuotes = resp.data.qval; */
                    
                    this.$store.state.allQuotes = [];
                    this.$store.state.quoteType = this.$parent.showQuoter;
                    this.$store.state.policy = this.form;
                    this.$store.state.policy.age = resp.data.diff;
                
                    resp.data.qval.forEach(this.iterateProducts);
                    this.loading = false;
                }).then(resp2 => {
                    this.pushDirectQuote()
                });
            } else if(this.form.amount == '') {
                this.$toasted.error('Select Amount');
            } else if(this.form.state == '') {
                this.$toasted.error('Select State');
            } else if(this.form.ss == '') {
                this.$toasted.error('Smoking Status');
            } else if(this.form.gender == '') {
                this.$toasted.error('Gender');
            } else {
                console.log(this.form)
            }
        },
        iterateProducts(i) {
            console.log('Inside iterate products');
            console.log(i)

            let rate = '';
            let quote = '';
            if(this.$store.state.policy.gender == 'Male' && this.$store.state.policy.ss == 'ns') {
                
                rate = i.m_ns_rate;
            }
            if(this.$store.state.policy.gender == 'Male' && this.$store.state.policy.ss == 'ss') {
                rate = i.m_ss_rate;
            }
            if(this.$store.state.policy.gender == 'Female' && this.$store.state.policy.ss == 'ns') {
                rate = i.f_ns_rate;
            }
            if(this.$store.state.policy.gender == 'Female' && this.$store.state.policy.ss == 'ss') {
                rate = i.f_ss_rate;
            }

            /* Set logic to set min and max values depending on product  */
            let amount = Number(this.$store.state.policy.amount).toFixed(2);

            if(amount < Number(i.min_coverage)) {
                amount = Number(i.min_coverage).toFixed(2);
            }
            if(amount > Number(i.max_coverage)) {
                amount = Number(i.max_coverage).toFixed(2);
            }
            amount = Number(amount) / 1000;
            

            
            
            console.log('Amount: ' + amount);
            quote = (((rate * amount) + Number(i.premium)) * i.mode_eft);
            i.amount = amount;
            i.quote = quote;
            if(Number(amount * 1000) == Number(this.$store.state.policy.amount)){
                this.$store.state.allQuotes.push(i);
            }
        },
    }
}
</script>