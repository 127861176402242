<template>
<div>

    <div class="slice slice-sm bg-section-secondary">
        <div class="container">
            
            <div class="row mt-2" style="display:flex;justify-content:center;">
          <div class="col-md-8" style="display:flex;justify-content:center;">
              
            <div class="row" style="displa:flex;justify-content:center">

            <div class="col-12">
                <h5 class="mb-4 pb-4">User information</h5>
            </div>
             
             
                  
              
                <div class="col-md-6">
                  <div class="form-group">
                    <label style="color:rgba(0,0,0,0.6)">Name *</label>
                    <input v-model="user.name" name="contact-name" type="text" :placeholder="this.$route.params.email" class="form-control" required="">
                    <div class="invalid-feedback">
                      Please type your name.
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label style="color: rgba(0,0,0,0.6)">Email Address *</label>
                    <input v-model="user.email" name="contact-email" type="email" :placeholder="this.$route.params.email" class="form-control" required="">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label style="color: rgba(0,0,0,0.6)">Password *</label>
                    <input v-model="user.password" name="password" type="password" placeholder="Password" class="form-control" required="">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label style="color: rgba(0,0,0,0.6)">Confirm Password *</label>
                    <input v-model="user.pconfirm" name="password" type="password" placeholder="Confirm Password" class="form-control" required="">
                  </div>
                </div>
                
                <div class="col-md-12 mb-6" style="display:flex;justify-content:center;align-items:center;margin-top:40px">
                  
                  
                  
                  
                  <button   type="submit" class="btn btn-primary btn-loading" data-loading-text="Sending">
                    
                                        <span>Update Account</span>
                  </button>
                  
                  
                </div>
                
              </div>
          
            
        </div>
       </div>
        </div>
        </div>
</div>
</template>
<script>
import Avatar from 'vue-avatar'
export default {
    components: {
        Avatar,
    },
    data() {
        return {
            user: {},
        }
    }
}
</script>