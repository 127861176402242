<template>
    
    <div class="modal fade docs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

        <div class="modal-header">
            <h5 class="modal-title h6" id="myLargeModalLabel">Visionary Inc</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" style="padding:0px;">

            <div class="row mt-4 pb-4" style="display: flex; justify-content: center; margin-top: -24px;">
                <div class="col-lg-9 col-12" style="margin: 0 auto;">
                <div class="rounded-bottom-right">
                    <!-- First Card Body -->
                    <div class="card-body">
                        <div class="d-flex align-items-center mb-4">
                            <h3 class="display-5 text-dark my-2 text-highlight-warning" style="margin: 0 auto;">Visionary Questionnaires</h3>
                        </div>
                        <div class="row justify-content-center mt-6 list-group-flush mb-4">
                            <div class="col-10 pb-5">
                                <h5 class="font-weight-bold">Ticket</h5>
                                <p class="mt-2 mb-0 lh-190">
                                    Concierge Service for VIP agents who have completed the Marketing Allowance Agreement. Use the ticket when your client is ready to apply for insurance
                                </p>
                                <button @click="$parent.preQual()" class="btn btn-primary btn-sm w-100 mt-4    ">
                                    Start Ticket
                                </button>
                            </div>
                            <div class="col-10 pb-5">
                                <h5 class="font-weight-bold">Diabetic Questionnaire</h5>
                                <p class="mt-2 mb-0 lh-190">
                                    Use this questionnaire if you have a prospective client who is a diabetic
                                </p>
                                <button @click="startDiabetic()" class="btn btn-primary btn-sm w-100 mt-4    ">
                                    Start Diabetic Questionnaire
                                </button>
                            </div>
                            <div class="col-10 pb-5">
                                <h5 class="font-weight-bold">Senior Life Questionnaire</h5>
                                <p class="mt-2 mb-0 lh-190">
                                    Use this questionnaire for clients 50+ seeking final expense coverage.
                                </p>
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSexEmobn-PM143ecj3ZTEb52O-a79raYtPnBmFWQzziQgrfuw/viewform?usp=sf_link" target="_blank" class="btn btn-primary btn-sm w-100 mt-4    ">
                                    Start Senior Life Questionnaire
                                </a>
                            </div>
                            <div class="col-10 pb-5">
                                <h5 class="font-weight-bold">Life Insurance Quote Request</h5>
                                <p class="mt-2 mb-0 lh-190">
                                    Use this questionnaire for everything else.
                                </p>
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSefxVd6k_qw4pr5PC2-3B4GMu_ZahAFY4MHrT3YWetNcR6VJA/viewform?usp=sf_link" target="_blank" class="btn btn-primary btn-sm w-100 mt-4    ">
                                    Start Quote Request
                                </a>
                            </div>
                            <div class="col-10 pb-5">
                                <h5 class="font-weight-bold">Insurance Application Appointment</h5>
                                <p class="mt-2 mb-0 lh-190">
                                    Use this link to schedule an application if you are part of our Concierge Co-Agent Program.
                                </p>
                                <a href="https://calendly.com/insuranceapplication/insurance-application-appointment?month=2023-03" target="_blank" class="btn btn-primary btn-sm w-100 mt-4    ">
                                    Schedule Appointment
                                </a>
                            </div>
                        </div>
                        
                    </div>
                   
                </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
export default {
    name: "questionair-modal",
    data() {
      return {
          loading: false,
          loadingEdit: false,
          todayYear: new Date().getFullYear() - 18,
          preLead: false,
          quoteSubmitted: false,
          policy: "",
          form: {
              amount: '',
              state: '',
              ss: '',
              gender: '',
              dob: '',
              age: '',
              term: 20,
          },
          prospect: {
              name: '',
              email: '',
              phone: '',
          },
          pLoad: false,
          selectedDay: '',
          selectedMonth: '',
          selectedYear: '',
          tempShowCard: false,
          coverageValue: 75,
          coverageTerm: 15,
          minCoverageRange: 50,
          maxCoverageRange: 400,
          minTermRange: 10,
          maxTermRange: 30,
          emailValid: '',
      }
  },
  mounted() {
     
  },
  created() {
      
  },
  methods: {
    startDiabetic() {
        this.$parent.hideQuestionairModal();
        this.$router.push({name: 'A'})
    }
  }
}
</script>