<template>
<div class="row">
    <div class="col-12">
                <h5 class="mb-4 pb-4">Account information</h5>
              </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label style="color:rgba(0,0,0,0.6)">Name *</label>
                    <input v-model="user.name" name="contact-name" type="text" :placeholder="this.$route.params.email" class="form-control" required="">
                    <div class="invalid-feedback">
                      Please type your name.
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label style="color: rgba(0,0,0,0.6)">Email Address *</label>
                    <input v-model="user.email" name="contact-email" type="email" :placeholder="this.$route.params.email" class="form-control" required="">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label style="color: rgba(0,0,0,0.6)">Password *</label>
                    <input v-model="user.password" name="password" type="password" placeholder="Password" class="form-control" required="" :class="{'outlineTicketSuccess' : passc == 1, 'outlineTicketDanger' : passc == 2}">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label style="color: rgba(0,0,0,0.6)">Confirm Password *</label>
                    <input @keyup="confirmPassword" v-model="pconfirm" name="password" type="password" placeholder="Confirm Password" class="form-control" required="" :class="{'outlineTicketSuccess' : passc == 1, 'outlineTicketDanger' : passc == 2}">
                  </div>
                </div>
                
                <div class="col-md-12 mb-6" style="display:flex;justify-content:center;align-items:center;margin-top:40px">
                  
                  
                  
                  
                  <button @click="updateAccount()"  type="submit" class="btn btn-primary btn-loading" data-loading-text="Sending">
                    
                                        <span v-if="!loading">Update Account</span>
                                        <div v-else class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                        </div>
                  </button>
                  
                  
                </div>          
</div>
</template>
<script>
import axios from 'axios';
export default {
    name: 'account-password',
    data() {
        return {
            user: {
              password: '',
            },
            pconfirm: '',
            passc: '',
            loading: false,
        }
    },
    created() {
        this.user.email = JSON.parse(localStorage.getItem('access_token2')).email;
        this.user.name = JSON.parse(localStorage.getItem('access_token2')).name;
    },
    methods: {
        confirmPassword(event) {
            
            if(event.target.value.length >= 4) {
                console.log(event.target.value);
                if(this.pconfirm == this.user.password) {
                    this.passc = 1;
                    console.log("equals");
                }  else {
                    this.passc = 2;
                }

            } else {
                this.passc = '';
            }
        },
        updateAccount() {
        if(this.user.name != '' && this.user.email != '' && this.user.password != '' && this.user.password == this.pconfirm) {
          this.loading = true;
          axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
          axios.post('/api/updateprofile', this.user).then(resp => {
            console.log(resp.data.t);
            this.user.password = '';
            this.pconfirm = '';
            this.passc = '';
          }).then(resp2 => {
              this.loading = false;
              this.$toasted.success("Updated");
          }).catch(err => {
            if(err.message == "Request failed with status code 401") {
                      
                        this.$router.push({name: 'Login'});
                        this.$store.dispatch('destroyToken').then(resp => {
                            console.log(resp.data);
                            
                            this.$router.push({name: 'Login'});
                        }).then(resp => {  }).catch(err => { this.outLoading = false });
                    }
          });
        } else if(this.user.name == '') {
          this.$toasted.error('Name');
        } else if(this.user.email == '') {
          this.$toasted.error('Email');
        } else if(this.user.password == '' || this.user.password != this.pconfirm) {
          this.$toasted.error('Password');
        }
        
      }
    }
}
</script>
<style scoped>
.outlineTicketSuccess {
    border-color: #5cc9a7;
}
.outlineTicketDanger {
    border-color: #ee283c;;
}
</style>