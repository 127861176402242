<template>
    <div>
        <form-header></form-header>
        
        <section>
            <div class="container d-flex flex-column">
                <div class="row justify-content-center min-vh-100" style="margin-top:12rem;">
                    <div class="col-md-7 col-lg-7 col-xl-6">
                        <div>
                            <div class="mb-5">
                                <div class="row justify-content-center mb-5" style="">
                                    <div class="col-12 align-items-center" style="display:flex;align-items:center;">
                                        <h3 class="h5 mb-0">
                                            Within the past 30 days, have you been diagnosed with, tested positive for, or been advised to take a test for, that has not yet been started or completed or the results of which are not yet known for, COVID-19 (Coronavirus)?
                                    </h3>
                                    </div>
                                    <div class="col text-right text-right mt-4">
                                        <button type="button" :class="[ covid1 == 'Yes' ? 'btn-dark' : 'btn-outline-info']" @click="toggleCovid1Button" value="Yes" class="btn btn-sm" style="width:95px;">
                                            Yes
                                        </button>
                                        <button type="button" :class="[ covid1 == 'No' ? 'btn-dark' : 'btn-outline-info']" @click="toggleCovid1Button" value="No" class="btn btn-sm" style="width:95px;">
                                            No
                                        </button>
                                    </div>
                                    
                                </div>
                                <div class="row justify-content-center mb-5" style="">
                                    <div class="col-12 align-items-center" style="display:flex;align-items:center;">
                                        <h3 class="h5 mb-0">Within the past 30 days, have you been advised to self-isolate or be quarantined, due to symptoms of, or for any other reason related to, COVID-19 (Coronavirus)?</h3>
                                    </div>
                                    <div class="col text-right text-right mt-4">
                                        <button type="button" :class="[ covid2 == 'Yes' ? 'btn-dark' : 'btn-outline-info']" @click="toggleCovid2Button" value="Yes" class="btn btn-sm" style="width:95px;">
                                            Yes
                                        </button>
                                        <button type="button" :class="[ covid2 == 'No' ? 'btn-dark' : 'btn-outline-info']" @click="toggleCovid2Button" value="No" class="btn btn-sm" style="width:95px;">
                                            No
                                        </button>
                                    </div>
                                    
                                </div>
                                
                               
                                
                            
                            </div>
                            <span class="clearfix"></span>
                            <form>
                                <div class="form-group" style="height:2px;">
                                    
                                    
                                </div>
                                <div class="mt-4">
                                    <button type="button" class="btn btn-block btn-primary hover-continue" @click="submit()" >Continue</button>
                                </div>
                            </form>
                            <div class="mt-4 text-center"><small>Feel free to call our toll free number: </small> 
                            <a href="tel:+8002565307" class="small font-weight-bold">(800) 256-5307</a>
                            
                            </div></div></div></div></div>
        </section>
    </div>
</template>
<script>
import formHeader from "./Header.vue";

export default {
    components: {
        formHeader,
    },
    data() {
        return {
            form: '',
            covid1: '',
            covid2: '',
            
            buttonYes: false,
            buttonNo: false,
        }
    },
    created() {
        if(this.$store.state.directForm.covid1 != '') {
            this.$store.state.directForm.covid1 == "Yes" ? this.covid1 = 'Yes' : this.covid1 = 'No';
        }
        if(this.$store.state.directForm.covid2 != '') {
            this.$store.state.directForm.covid2 == "Yes" ? this.covid2 = 'Yes' : this.covid2 = 'No';
        }
    },
    methods: {
        submit() {
            
           
            console.log(this.$store.state.directForm)
            if(this.covid1 != '' && this.covid2 != '') {
                console.log("yupp")
                
                this.$router.push({name: 'Name'})
            } else {
                this.$toasted.error('Select an option');
            }
            
        },
        toggleCovid1Button(e) {
            this.covid1 = e.target.value;
            console.log(e.target.value);
            this.$store.state.directForm.covid1 = e.target.value;
        },
        toggleCovid2Button(e) {
            this.covid2 = e.target.value;
            console.log(e.target.value)
            this.$store.state.directForm.covid2 = e.target.value;
        },
        test() {
            
            if(this.toggleEyeAdd ? (this.$store.state.directForm.eyeAdd.length > 4 ? true : false) : false) {
                console.log("toggle eye true")
            }
            if(this.toggleHeartAdd ? (this.$store.state.directForm.heartAdd.length > 4 ? true : false) : false) {
                console.log('toggle heart true')
            }
            if(this.toggleBloodAdd ? (this.$store.state.directForm.bloodAdd.length > 4 ? true : false) : false) {
                console.log('toggle blood true')
            }
        },
        toggleEyeButton(e) {
            if(e.target.value == 'Yes') {
                this.toggleEyeAdd = true;
            }
            if(e.target.value == "No") {
                this.toggleEyeAdd = false;
            }
            
            this.toggleEye = e.target.value;
            this.$store.state.directForm.eye = e.target.value;
        },
        toggleHeartButton(e) {
            if(e.target.value == 'Yes') {
                this.toggleHeartAdd = true;
            }
            if(e.target.value == "No") {
                this.toggleHeartAdd = false;
            }
            
            this.toggleHeart = e.target.value;
            this.$store.state.directForm.heart = e.target.value;
        },
        toggleBloodButton(e) {
            if(e.target.value == 'Yes') {
                this.toggleBloodAdd = true;
            }
            if(e.target.value == "No") {
                this.toggleBloodAdd = false;
            }
            
            this.toggleBlood = e.target.value;
            this.$store.state.directForm.blood = e.target.value;
        },
        toggleFormButton(e) {
            console.log(e.target.value);
            if(e.target.value == "yes") {
                this.buttonYes = true;
                this.buttonNo = false;
            }
            if(e.target.value == "no") {
                this.buttonYes = false;
                this.buttonNo = true;
            }
        }
    }
}
</script>
<style scoped>
 .hover-continue:hover {
     background-color: #171347;
 }
</style>