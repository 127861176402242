<template>
    
    <div class="modal fade docs-example-modal-xl hide-modal-scroll" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style="max-width:100%;margin-top:0px;padding-top:0px;">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">

        <div class="modal-header">
            <h5 class="modal-title h6" id="myLargeModalLabel">Visionary Inc</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" style="padding:0px;">

            <div class="row mt-4 pb-4" style="display: flex; justify-content: center; margin-top: -24px;">
                <div class="col-12" style="margin: 0 auto;height: calc(84vh)">
                <div class="rounded-bottom-right" style="margin-top:0px;padding-top:0px;">
                    <!-- First Card Body -->
                    <div class="card-body" style="padding-top:0px;">
                        <iframe src="/pdf/UW-TurboTerm.pdf" 
                                frameborder="0"
                                scrolling='no'
                                width="100%"
                                style="height: calc(84vh);"
                        >
                        </iframe>
                    </div>
                   
                </div>
                </div>
                
            </div>
        </div>
        </div>
    </div>
    </div>
</template>
<script>
export default {
    name: 'turbo-term',
}
</script>