<template>
<div>

    <div class="slice slice-sm bg-section-secondary">
        <div class="container">
            <div v-if="!loading" class="row justify-content-center">
                <div class="col-lg-10">

                    
                    <div class="row mt-4">
                        <div v-if="!loadingS" class="col-lg-12">
                            <div class="mb-10">
                                
                                <div class="table-responsive">
                                    <table class="table table-cards2 align-items-center">
                                        <thead>
                                            <tr style="">
                                                <th scope="col" style="max-width:200px;;border:0px solid white;">Name</th>
                                                <th v-if="$store.getters.isAdmin || $store.getters.isRecruiter || $store.getters.isEnroller" scope="col" style="border:0px solid white">Agent <span v-if="order == 'queued' || order == 'completed'">/ Enroller</span></th>
                                                <th scope="col" style="border:0px solid white;">City / State</th>
                                                <th scope="col" style="border: 0px solid white">Schedule Date</th>
                                                <th  scope="col" style="border:0px solid white">Status</th>
                                                <th scope="col" style="border: 0px solid white"></th>
                                            </tr>
                                        </thead>
                                        <div v-if="loadingSS" style="height:145px;width:100%;display:flex;align-items:center;justify-content:center;">
                                            <div class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                        
                                        
                                        <tbody> 
                                            <tr class="" v-for="i in ticketList" :key="i.id" >
                                                <th scope="row">
                                                    <div class="media align-items-center">
                                                        
                                                        <div class="media-body ml-4">
                                                            <a href="#" class="name h6 mb-0 text-sm">{{i.first_name + ' ' + i.last_name}}</a> 
                                                            <small class="d-block font-weight-bold">{{i.email}}</small>
                                                        </div>
                                                    </div>
                                                </th>
                                                
                                                <td>
                                                    <div class="media align-items-start">
                                                        
                                                        <div class="media-body">
                                                            <span class="name h5 mb-0 text-sm">{{i.city}}</span> 
                                                            <span class="d-block font-weight-light text-muted">{{$store.state.state[i.state]}}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="media align-items-start">
                                                        
                                                        <div class="media-body text-center">
                                                            <span v-if="i.hour" class="name h5 mb-0 text-sm">{{printDate(i.hour.hour)}}</span> 
                                                            <span v-if="i.hour" class="d-block font-weight-light text-muted">{{printDate2(i.hour.hour)}}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                        <div class="media align-items-center">
                                                            
                                                            <div class="media-body text-center">
                                                                <span class="name h6 mb-0 text-sm">{{i.status}}</span> 
                                                            
                                                            </div>
                                                        </div>
                                                    </td>
                                                <td class="text-right">
                                                    <div class="actions ml-3">
                                                        <router-link :to="{name: 'admin.ticket.view', params: {id: i.id}, query: {ticket: i}}" class="action-item mr-2" data-toggle="tooltip" title="" data-original-title="Quick view"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg> 
                                                        </router-link>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-observe-visibility="scrollBottom"></tr>
                                        </tbody>
                                        
                                </table>
                                <div v-if="ticketList.length == 0" class="" style="display:flex;align-items:center;justify-content:center;width:100%;margin-top:100px;">
                                                <div class="mt-4" style="">
                                                    <div class="h6">
                                                        Tickets Will Show Here
                                                    </div>
                                                </div>
                                            </div>
                            </div>
                        </div>
                        </div>
                        <div v-else style="height:145px;width:100%;display:flex;align-items:center;justify-content:center;">
                            <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                    <div style="position:absolute;top:0px;margin-top:-14px;left:10px;">
                        <div class="col-auto">
                            <button @click="$router.go(-1)" type="button" class="btn btn-xs btn-primary btn-icon rounded-pill">
                                <span class="btn-inner--icon">
                                    <i class="uil uil-arrow-left"></i>
                                </span> 
                                <span class="btn-inner--text">Back</span>
                            </button>
                        </div>
                    </div>
                   
                </div>
            </div>
            <div v-else style="height:145px;width:100%;display:flex;align-items:center;justify-content:center;">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        </div>
        <enroller-accept 
            ref="enrAccept"
            
        />
        <ticket-quote 
            ref="tQuote"
        />
</div>
</template>
<script>
import axios from 'axios';
import InfiniteLoading from 'vue-infinite-loading';
import moment from 'moment';
import enrollerAccept from '../comps/EnrollerAccept.vue';
import ticketQuote from '../comps/TicketQuoter.vue';
export default {
    components: {
        InfiniteLoading,
        enrollerAccept,
        ticketQuote
    },
    data() {
        return {
            role : JSON.parse(localStorage.getItem('access_token2')).role,
            loading: false,
            loadingS: false,
            loadingSS: false,
            order: 'default',
            ticketList: [],
            
            sInfo: {},
            sHour: '',
            sIndex: '',
            page: 1,
            last_page: 1,
            routeInitial: false,
        }
    },
    created() {
        this.fetchTickets();
        window.scrollTo(0,0);
    },
    computed: {
        
    },
    methods: {
        fetchTickets() {
            let role = JSON.parse(localStorage.getItem('access_token2')).role;
           
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
           if(this.$store.getters.isRecruiter) {
                console.log('fetching from agent');
                if(this.routeInitial == false) {
                    this.loading = true;
                    this.routeInitial = true;
                }
                axios.get(`/api/fetchticketsagentdynamic?page=${this.page}&slug=${this.$route.params.slug}`).then(resp => {
                    console.log(resp.data);
                    this.last_page = resp.data.t.last_page;
                    this.ticketList.push(...resp.data.t.data);
                    this.loading = false;
                }).catch(err => { this.loading = false });
            }
            
            
        },
        scrollBottom(isVisible) {
            if(!isVisible) { return }
            if(this.page >= this.last_page) { return }
            console.log('Bottom');
            this.page++;
            this.fetchTickets();
        },
        
        styleBorder1(i) {
            if(i.enroller_id == null) {
                return {borderLeft: '2px solid #5cc9a7', borderTop: '1px solid #5cc9a7', borderBottom: '1px solid #5cc9a7'}
            } else if(i.complete && i.enroller_id) {
                return {}
            } else if(i.enroller_id) {
                return {borderLeft: '2px solid #ffbe3d', borderTop: '1px solid #ffbe3d', borderBottom: '1px solid #ffbe3d'}
            }
            
        },
        styleBorder2(i) {
            if(i.enroller_id == null) {
                return { borderTop: '1px solid #5cc9a7', borderBottom: '1px solid #5cc9a7'}
            } else if(i.complete && i.enroller_id) {
                return {}
            } else if(i.enroller_id) {
                return {borderTop: '1px solid #ffbe3d', borderBottom: '1px solid #ffbe3d'}
            }
         },
        styleBorder3(i) {
            if(i.enroller_id == null) {
                return {borderRight: '2px solid #5cc9a7', borderTop: '1px solid #5cc9a7', borderBottom: '1px solid #5cc9a7'}
            } else if(i.complete && i.enroller_id) {
                return {}
            } else if(i.enroller_id) {
                return {borderRight: '2px solid #ffbe3d', borderTop: '1px solid #ffbe3d', borderBottom: '1px solid #ffbe3d'}
            }
           
        },
        styleSuccessRight(date) {
            return date == null ? {borderRight: '1px solid #5cc9a7;'} : {};
        },
        styleSuccessBottom(date) {
            return date == null ? {borderBottom: '1px solid #5cc9a7;'} : {};
        },
        printDate(i) {
            return moment.utc(i).local().format('hh:mm a');
        },
        printDate2(i) {
            return moment.utc(i).local().format('dddd MMMM DD');
        },
        changeOrder(event) {
            console.log(event.target.value);
            if(event.target.value == 'queued') {
                this.order = 'queued';
                this.ticketList = [];
                this.page = 1;
                this.loadingS = true;
                this.fetchTickets();
            } else if(event.target.value == 'default') {
                this.order = 'default';
                this.ticketList = [];
                this.page = 1;
                this.loadingS = true;
                this.fetchTickets();
            } else if(event.target.value == 'completed') {
                this.order = 'completed';
                this.ticketList = [];
                this.page = 1;
                this.loadingS = true;
                this.fetchTickets();
            } else if(event.target.value == 'new') {
                this.order = 'new';
                this.ticketList = [];
                this.page = 1;
                this.loadingS = true;
                this.fetchTickets();
            }
        },
        showEnrAccept(i, index) {
            this.sInfo = i;
            this.sHour = i.hour;
            this.sIndex = index;
            if(i.hour) {
                $(this.$refs.enrAccept.$el).modal('show');
            } else {
                this.sInfo = '';
                this.sHour = '';
            }
            
        },
        showQuote() {
            $(this.$refs.tQuote.$el).modal('show');
        }
    }
}
</script>
<style>
.tc2 tbody tr th {
    border-radius: .75rem 0 0 .75rem;
}

.tc2 tbody th {
    padding: 0 1rem;
}
.tc2 tbody th {
    position: relative;
}
.tc2 {
    border-spacing: 0 1rem;
}
.outlineTicketSuccess {
    border-color: #5cc9a7;
    border-left: 10px solid;
}
.outlineTicketDanger {
    border-color: #ee283c;;
}
</style>