<template>
    <div class="col-lg-10 table-responsive" style="margin: 0 auto">
        <table class="table table-cards2 align-items-center">
            
            <tbody class="">
                <div v-for="(i, index) in ticketList" :key="i.id" id="accordion-1" class="accordion accordion-spaced row" style="width:100%;margin:0;">
                        <div class="col-12 pb-4 card" style="padding: 0;" :style="styleBorder1(i.id)" >
                            <div class="collapsed row justify-content-between" style="width:100%;margin-top:22px;" id="heading-1-1" data-toggle="collapse" role="button" :data-target="'#collapse-' + index" aria-expanded="false" aria-controls="collapse-1-1">
                                
                              
                                    <div class="col-4"  style="display:flex:align-items:center">
                                        <div class="media align-items-center ml-2">
                                            <div>
                                                <div class="avatar-parent-child">
                                                    <avatar :username="i.name" :size="60" class="rounded-circle"> </avatar> 
                                                    
                                                </div>
                                            </div>
                                            <div class="media-body ml-4">
                                                <a href="#" class="name h6 mb-0 text-sm">{{i.name}}</a> 
                                                <small class="d-block font-weight-bold">{{i.email}}</small>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                    <div class="col-2" style="display:flex;align-items:center">
                                        <div class="media align-items-start">
                                            
                                            <div class="media-body">
                                                <span v-if="i.phone" class="name h5 mb-0 text-sm">{{ i.phone }}</span>
                                               
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2" style="display:flex;align-items:center">
                                        <div class="media align-items-between">
                                            
                                            <div class="media-body">
                                                <span class="name h5 mb-0 text-sm">{{$moment(i.created_at).local().format('ll')}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2 text-right" style="display:flex;align-items:center;">
                                        <div class="actions ml-3">
                                            <button @click="showPRemove(i, index)" type="button" class="btn btn-xs btn-outline-danger rounded-pill">Remove</button>
                                            
                                            
                                            
                                        </div>
                                    </div>
                               
                            </div>
                            <div :id="'collapse-' + index" class="collapse" aria-labelledby="heading-1-1" data-parent="#accordion-1" style="">
                                <div class="card mt-5 m-4" style="margin-bottom:4px;">
                                            <div class="card-body">
                                                <div class="row row-grid align-items-center">
                                                    <div class="col-lg-8">
                                                        <div class="media align-items-center">
                                                            <span class="avatar bg-success text-white rounded-circle mr-3">
                                                                <i class="uil uil-check" style="font-size: 1.75rem;"></i>
                                                            </span>
                                                            <div class="media-body">
                                                                
                                                                
                                                                <p class="text-muted h5 lh-150 text-sm mb-0">Age: {{i.age}}, <span style="">SS: {{i.ss}}</span> | Term: {{i.term + " Years"}} </p>
                                                                <p class="text-muted h5 lh-150 text-sm mb-0">Coverage Amount: ${{i.amount}} </p>
                                                                <p class="text-muted h5 lh-150 text-sm mb-0">Dob: {{i.dob}} | State: {{i.state}}</p>
                                                            
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-auto flex-fill mt-4 mt-sm-0 text-sm-right d-none d-lg-block">
                                                        <h4 class="h6">${{ i.quote }}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                </div>
                            </div>
                        </div>
                </div>
                
                <tr v-observe-visibility="scrollBottom"></tr>
            </tbody>
        </table>
        <inquiry-view
            ref="iView"
        />
        <prospect-remove
            ref="pRemove"
        />
    </div>
</template>
<script>
import axios from 'axios';
import InfiniteLoading from 'vue-infinite-loading';
import Avatar from 'vue-avatar';
import inquiryView from '../../comps/InquiryView.vue';
import prospectRemove from '../../comps/ProspectRemove.vue';


export default {
    name: 'message-prospect',
    
    components: {
        InfiniteLoading, Avatar, inquiryView,
        prospectRemove
    },
    data() {
        return {
            loading: false,
            loadingS: false,
            routeInitial: false,
            page: 0,
            last_page: 1,
            ticketList: [],
            contactInfo: '',
            sIndex: '',
            pViewed: '',
            pIndex: '',
            iviewConfirmPre: false,
            iviewConfirmPreDel: false,
            iviewConfirmSend: false,
            selectedRecruiter: '',
        }
        
    },
    methods: {
        showIView(i, index) {
            this.contactInfo = i;
            this.iIndex = index;
            this.iviewConfirmPre = false;
            this.iviewConfirmPreDel = false;
            this.iviewConfirmSend = false;
            this.selectedRecruiter = '';
            $(this.$refs.iView.$el).modal('show');
        },
        fetchApplicants() {
            console.log("fetching within new module");
            if(this.routeInitial == false) {
                    this.loading = true;
                    this.routeInitial = true;
                    this.loadingS = true;
                }
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.get(`api/fetchprospect?page=${this.page}`).then(resp => {
                console.log(resp);
                this.last_page = resp.data.t.last_page;
                this.ticketList.push(...resp.data.t.data);
            }).then(resp2 => {
                this.loading = false;
            }).catch(error => {
                if(err.message == "Request failed with status code 401") {
                      
                        this.$router.push({name: 'Login'});
                        this.$store.dispatch('destroyToken').then(resp => {
                            console.log(resp.data);
                            
                            this.$router.push({name: 'Login'});
                        }).then(resp => {  }).catch(err => { this.outLoading = false });
                    }
                this.loading= false;
            });
        },
        scrollBottom(isVisible) {
            console.log("Fetching bottom within new model");
            if(!isVisible) { return }
            if(this.page >= this.last_page) { return }
            console.log('Bottom');
            this.page++;
            this.fetchApplicants();
        },

        returnName(a, b) {
            return a + ' ' + b;
        },
        styleBorder(i) {
            if(i == false) {
                return {borderLeft: '3px solid #5cc9a7'}
            } else {
                return {}
            } 
            
        },
        styleBorder1(i) {
           
                return {borderLeft: '4px solid rgba(0,100,0,0.4)', borderTop: '0px solid #5cc9a7', borderBottom: '0px solid #5cc9a7'}
           
            
            
        },
        styleBorder2(i) {
            if(i == 0) {
                return { borderTop: '1px solid #5cc9a7', borderBottom: '1px solid #5cc9a7'}
            } 
         },
        styleBorder3(i) {
            if(i == 0) {
                return {borderRight: '2px solid #5cc9a7', borderTop: '1px solid #5cc9a7', borderBottom: '1px solid #5cc9a7'}
            } 
        },
        showPRemove(i, index) {
            this.pViewed = i;
            this.pIndex = index;
            $(this.$refs.pRemove.$el).modal('show');
        }
    }
}
</script>