<template>
    <div>
        <form-header></form-header>
        
        <section>
            <div class="container d-flex flex-column">
                <div class="row justify-content-center min-vh-100" style="margin-top:12rem;">
                    <div class="col-md-7 col-lg-7 col-xl-6">
                        <div>
                            <div class="mb-5">
                                
                                <div class="row justify-content-center mb-5" style="">
                                    <div class="col-12 align-items-center" style="display:flex;align-items:center;">
                                        <h3 class="h5 mb-0">Kidney problems? (including protein in your urine)</h3>
                                    </div>
                                    <div class="col text-right text-right mt-2">
                                        <button type="button" :class="[ toggleKidney == 'Yes' ? 'btn-dark' : 'btn-outline-info']" @click="toggleKidneyButton" value="Yes" class="btn btn-sm" style="width:95px;">
                                            Yes
                                        </button>
                                        <button type="button" :class="[ toggleKidney == 'No' ? 'btn-dark' : 'btn-outline-info']" @click="toggleKidneyButton" value="No" class="btn btn-sm" style="width:95px;">
                                            No
                                        </button>
                                    </div>
                                    <div v-if="toggleKidneyAdd" class="col-12 mt-4">
                                        <textarea class="form-control" v-model="$store.state.directForm.kidneyAdd" type="text" placeholder="If you answered “Yes” please provide details: " />
                                    
                                    
                                    
                                    
                                    </div>
                                </div>
                                <div class="row justify-content-center mb-5" style="">
                                    <div class="col-12 align-items-center" style="display:flex;align-items:center;">
                                        <h3 class="h5 mb-0">Sensory problems? (such as burning in your feet)</h3>
                                    </div>
                                    <div class="col text-right text-right mt-2">
                                        <button type="button" :class="[ toggleSensory == 'Yes' ? 'btn-dark' : 'btn-outline-info']" @click="toggleSensoryButton" value="Yes" class="btn btn-sm" style="width:95px;">
                                            Yes
                                        </button>
                                        <button type="button" :class="[ toggleSensory == 'No' ? 'btn-dark' : 'btn-outline-info']" @click="toggleSensoryButton" value="No" class="btn btn-sm" style="width:95px;">
                                            No
                                        </button>
                                    </div>
                                    <div v-if="toggleSensoryAdd" class="col-12 mt-4">
                                        <textarea class="form-control" v-model="$store.state.directForm.sensoryAdd" type="text" placeholder="If you answered “Yes” please provide details: " />
                                    
                                    
                                    
                                    
                                    </div>
                                </div>
                                <div class="row justify-content-center mb-5" style="">
                                    <div class="col-12 align-items-center" style="display:flex;align-items:center;">
                                        <h3 class="h5 mb-0">Any other complications? (i.e. diabetic coma)</h3>
                                    </div>
                                    <div class="col text-right text-right mt-2">
                                        <button type="button" :class="[ toggleOther == 'Yes' ? 'btn-dark' : 'btn-outline-info']" @click="toggleOtherButton" value="Yes" class="btn btn-sm" style="width:95px;">
                                            Yes
                                        </button>
                                        <button type="button" :class="[ toggleOther == 'No' ? 'btn-dark' : 'btn-outline-info']" @click="toggleOtherButton" value="No" class="btn btn-sm" style="width:95px;">
                                            No
                                        </button>
                                    </div>
                                    <div v-if="toggleOtherAdd" class="col-12 mt-4">
                                        <textarea class="form-control" v-model="$store.state.directForm.otherAdd" type="text" placeholder="If you answered “Yes” please provide details: " />
                                    
                                    
                                    
                                    
                                    </div>
                                </div>
                                
                            
                            </div>
                            <span class="clearfix"></span>
                            <form>
                                <div class="form-group" style="height:2px;">
                                    
                                    
                                </div>
                                <div class="mt-4">
                                    <button type="button" class="btn btn-block btn-primary hover-continue" @click="submit()" >Continue</button>
                                </div>
                            </form>
                            <div class="mt-4 text-center"><small>Feel free to call our toll free number: </small> 
                            <a href="tel:+8002565307" class="small font-weight-bold">(800) 256-5307</a>
                            
                            </div></div></div></div></div>
        </section>
    </div>
</template>
<script>
import formHeader from "./Header.vue";

export default {
    components: {
        formHeader,
    },
    data() {
        return {
            toggleKidney: '',
            toggleKidneyAdd: false,
            kidneyAdd: false,

            toggleSensory: '',
            toggleSensoryAdd: false,
            sensoryAdd: false,

            toggleOther: '',
            toggleOtherAdd: false,
            OtherAdd: false,

            form: '',
            buttonYes: false,
            buttonNo: false,
        }
    },
    created() {
        if(this.$store.state.directForm.kidney != '') {
            console.log('yupp for yee')
            this.$store.state.directForm.kidney == "Yes" ? this.toggleKidney = 'Yes'  : this.toggleKidney = 'No';
            if(this.$store.state.directForm.kidney == "Yes") { this.toggleKidneyAdd = true }
        }
        if(this.$store.state.directForm.sensory != '') {
            console.log('yupp for yee')
            this.$store.state.directForm.sensory == "Yes" ? this.toggleSensory = 'Yes'  : this.toggleSensory = 'No';
            if(this.$store.state.directForm.sensory == "Yes") { this.toggleSensoryAdd = true }
        }
        if(this.$store.state.directForm.other != '') {
            console.log('yupp for yee')
            this.$store.state.directForm.other == "Yes" ? this.toggleOther = 'Yes'  : this.toggleOther = 'No';
            if(this.$store.state.directForm.other == "Yes") { this.toggleOtherAdd = true }
        }
    },
    methods: {
        submit() {
            if(this.toggleKidney != '' && this.toggleSensory != '' && this.toggleOther) {
                
                this.$router.push({name: 'Covid'})
            } else {
                this.$toasted.error('Select an option');
            }
            
        },
        toggleKidneyButton(e) {
            if(e.target.value == 'Yes') {
                this.toggleKidneyAdd = true;
            }
            if(e.target.value == "No") {
                this.toggleKidneyAdd = false;
            }
            
            this.toggleKidney = e.target.value;
            this.$store.state.directForm.kidney = e.target.value;
        },
        toggleSensoryButton(e) {
            if(e.target.value == 'Yes') {
                this.toggleSensoryAdd = true;
            }
            if(e.target.value == "No") {
                this.toggleSensoryAdd = false;
            }
            
            this.toggleSensory = e.target.value;
            this.$store.state.directForm.sensory = e.target.value;
        },
        toggleOtherButton(e) {
            if(e.target.value == 'Yes') {
                this.toggleOtherAdd = true;
            }
            if(e.target.value == "No") {
                this.toggleOtherAdd = false;
            }
            
            this.toggleOther = e.target.value;
            this.$store.state.directForm.other = e.target.value;
        },
        toggleFormButton(e) {
            console.log(e.target.value);
            if(e.target.value == "yes") {
                this.buttonYes = true;
                this.buttonNo = false;
            }
            if(e.target.value == "no") {
                this.buttonYes = false;
                this.buttonNo = true;
            }
        }
    }
}
</script>
<style scoped>
 .hover-continue:hover {
     background-color: #171347;
 }
</style>