<template>
<div>
    
    <section class="slice py-8 bg-dark bg-cover bg-size--cover mb-6" style="background-image: url('/img/office.jfif');">
        <span class="mask bg-gradient-dark opacity-4"></span>
        <div class="container d-flex align-items-center text-center text-lg-left pt-5 pb-5 pt-lg-6">
            <div class="col px-0">
                <div class="row justify-content-center align-items-center mt-4">
                    <div class="col-lg-6 text-center text-lg-left">
                        <h1 class="text-white display-3">Visionary Agents Program</h1>
                        <h3 class="text-white mb-4">Digital life insurance platform for agents.</h3>
                        <p class="lead text-white opacity-9" style="font-size: 1.25rem;">
                            Join the Visionary Agent Program today—gain access to
                                    instant-issue life insurance products for only <span class="" style="font-size:1rem;margin-left:2px;">$21/Month</span>
                        </p>
                        <div class="mt-5">
                            <router-link :to="{name: 'Agent.Signup'}" class="btn btn-warning btn-lg btn-icon">
                                <span class="btn-inner--text">Get started</span>
                                <span class="btn-inner--icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line>
                                    </svg>
                                </span>
                            </router-link>
                        </div>
                    </div>
                    <div class="col-lg-4">

                    </div>
                </div>
            </div>
        </div>
        <div class="shape-container shape-line shape-position-bottom">
            <svg width="2560px" height="100px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" x="0px" y="0px" viewBox="0 0 2560 100" xml:space="preserve"><polygon points="2560 0 2560 100 0 100"></polygon>
            </svg>
        </div>
    </section>
    
    <section class="slice pt-6 py-5 pb-lg-7">
        <div class="container">
            <div class="row row-grid justify-content-between align-items-center">
                <div class="col-12 col-md-5 col-lg-6">
                    <figure>
                        <img alt="Image placeholder" src="/img/office.jfif" class="card-img shadow-lg position-relative zindex-100">
                    </figure>
                    <div class="w-75 position-absolute bottom-n5 left-n5 rotate-180">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 361.1 384.8" style="enable-background:new 0 0 361.1 384.8;" xml:space="preserve" class="injected-svg svg-inject fill-section-secondary">
                        <path d="M53.1,266.7C19.3,178-41,79.1,41.6,50.1S287.7-59.6,293.8,77.5c6.1,137.1,137.8,238,15.6,288.9 S86.8,355.4,53.1,266.7z"></path>
                        </svg>
                    </div>
                </div>
                <div class="col-12 col-md-7 col-lg-5">
                    <h1 class="h2 text-center text-md-left mb-4">Provide the best digital experiences</h1>
                    <p class="lead text-center text-md-left text-muted">
                        <ul>
                            <li>
                                Term and permanent products for the majority of US households.</li>
                            <li>
                                Clients are matched to the most favorable products with instant underwriting decisions.
                            </li>
                        </ul>
                    </p>
                    
                </div>
            </div>
        </div>
    </section>
    <section class="slice slice-lg mt-6">
        <div class="container">
            <div class="row justify-content-center mb-5">
                
                <div class="col-lg-8">
                    <p class="">
                        Visionary Inc's Executive staff has over 50 years of experience in the Life Insurance industry.  Visionary Inc. is a National Marketing Company that brings decades of expertise and hybrid products to Independent agents in every state.  Years of Life Insurance experience integrating state-of-the-art technology allow independent agents the ability to write more business, accurately and faster; spending more time procuring new customers.  Using our technology, independent agents eliminate back-office time-wasting issues
                    </p>
                    
                </div>
            </div>
           
            <div class="row justify-content-center mb-5">
              <div class="col-lg-8">
                <div class="text-center mt-4">
                  <router-link :to="{name:'Contact'}" class="btn btn-primary btn-lg btn-icon px-6">
                    <span class="btn-inner--text" style="font-size:1.15rem;">Have a question in mind?</span>
                    <span class="btn-inner--icon"> <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                    </span>
                  </router-link>
                </div>
              </div>
              
            </div>
            
            
           
       
    </div>
    </section>
    <section class="slice slice-lg">
        <div class="container">
            <span class="badge badge-primary badge-pill">Overview</span>
            <div class="row mt-4">
                <div class="col-lg-6 pr-lg-5">
                    <p class="h5 lh-180 mb-3">
                       Guaranteed Whole Life Insurance is an easy, reliable way to help your family manage financial burdens such as funeral costs, medical bills and outstanding debts by paying benefits from $5,000 to $100,000 in life insurance benefits. 
                    </p>
                </div>
                <div class="col-lg-6">
                    <p class="lead lh-180">
                        This policy is available to US citizens between 18 and 70 years of age. No medical exam or lengthy health questionnaires are required. Guaranteed coverage continues to age 95. Premiums are payable till age 95 and are guaranteed to never go up. Death Benefit remains Level as long as premiums are paid.
                    </p>
                    <p class="lead lh-180">
                        Coverage for your clients begins Immediately on the date of ISSUE.  
                    </p>
                    <p class="lead lh-180">
                        
                        By building cash value this policy also allows you to borrow against your policy when needed. With a fast application process and instant approval, there are no barriers between you and the protection you need.
                    </p>
                </div>
            </div>
        </div>
    </section>
   
</div>
</template>
<script>
import flatpickr from "flatpickr";
import axios from 'axios';

export default {
  name: 'Home',
  components: {
    flatpickr,
  },
  data() {
      return {
          loading: false,
          todayYear: new Date().getFullYear() - 18,
          form: {
              amount: '',
              state: '',
              ss: '',
              gender: '',
              dob: '',
          },
          selectedDay: '',
          selectedMonth: '',
          selectedYear: '',
      }
  },
  mounted() {
    flatpickr('#datepicker', {
        dateFormat: "m/d/Y",
        maxDate: "07/28/2003",
    });
  },
  methods: {
      submitQuote() {
          console.log('submitting quote');
          console.log(this.form);
          
          let policy = {};
          if(this.form.amount != '' && this.form.state != '' && this.form.ss != '' && this.form.gender != '' && this.selectedDay != '' && this.selectedMonth != '' && this.selectedYear != '') {
              this.loading = true;
              this.$store.dispatch('clearShelf');
              this.$store.state.policySpouse = '';
                this.$store.state.rider1 = '';
              this.form.dob = this.selectedMonth + '/' + this.selectedDay + '/' + this.selectedYear;
              axios.post("/api/fetchquote", this.form).then(resp => {
                console.log(resp.data.quote);
                console.log(resp);
                policy.quote = parseInt(resp.data.quote) + 400;
                policy.ss = this.form.ss;
                policy.amount = this.form.amount;
                policy.startdate = resp.data.policystart;
                policy.age = resp.data.diff;
                policy.dob = this.form.dob;
                policy.gender = this.form.gender;
                policy.state = this.form.state;
                this.$store.commit('addPolicy', policy);
            }). then(resp2 => {
                this.loading = false;
                this.$router.push({name: 'Quote'})
            });
          } else if(this.form.amount == '') {
              this.$toasted.error('Select Amount');
          } else if(this.form.state == '') {
              this.$toasted.error('Select State');
          } else if(this.form.ss == '') {
              this.$toasted.error('Select Smoking Status');
          } else if(this.form.gender == '') {
              this.$toasted.error('Select Gender');
          } else if(this.selectedDay == '') {
              this.$toasted.error('Select Day');
          } else if(this.selectedMonth == '') {
              this.$toasted.error('Select Month');
          } else if(this.selectedYear == '') {
              this.$toasted.error('Select Year');
          }
          
      },
      amountChange(event) {
          if(event.target.value !== "Amount") {
              this.form.amount = event.target.value;
              console.log('succesfully changed');
          } else {
              console.log("please select from the dropdown");
          }
      },
      stateChange(event) {
          if(event.target.value !== "State") {
              this.form.state = event.target.value;
              console.log('succesfully changed states');
          } else {
              console.log("please select from the dropdown");
          }
      },
      ssChange(event) {
          if(event.target.value !== "Smoking Status") {
              this.form.ss = event.target.value;
              console.log('succesfully selected status');
          } else {
              console.log("please select from the dropdown");
          }
      },
      genderChange(event) {
          if(event.target.value !== "Gender") {
              this.form.gender = event.target.value;
              console.log('succesfully selected gender');
          } else {
              console.log("please select from the dropdown");
          }
      },
      changeDay(event) {
          if(event.target.value !== 'Day') {
              this.selectedDay = event.target.value;
              console.log(event.target.value);
          }
      },
      changeMonth(event) {
          if(event.target.value !== 'Month') {
              this.selectedMonth = event.target.value;
              console.log(event.target.value);
          }
      },
      changeYear(event) {
          if(event.target.value !== 'Year') {
              this.selectedYear = event.target.value;
              console.log(event.target.value);
          }
      }
  },
}
</script>