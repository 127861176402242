<template>
<div>
    <section class="section-half-rounded bg-cover bg-size--cover py-4 py-sm-0" style="background-image:url(/img/canyon.jpg);background-size:cover;background-position:center center;">
    <div class="container-fluid d-flex flex-column">
        <div class="row align-items-center min-vh-100">
            <div class="col-md-6 col-lg-5 col-xl-4 mx-auto">
                <div class="mt-6 card shadow-lg border-0 mb-0">
                    <div class="card-body py-5 px-sm-5">
                        <div>
                            <div class="mb-5 text-center">
                                <h6 class="h3 mb-1">Login</h6>
                                <p class="text-muted mb-0">Sign in to your account to continue.</p>
                            </div>
                            <span class="clearfix"></span>
                            <form>
                                <div class="form-group">
                                    <label class="form-control-label">Email address</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle>
                                                </svg>
                                            </span>
                                        </div>
                                        <input v-model="username" type="email" class="form-control" id="input-email" placeholder="name@example.com">
                                    </div>
                                </div>
                                <div class="form-group mb-0">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div>
                                            <label class="form-control-label">Password</label>
                                        </div>
                                        <div class="mb-2">
                                            <a href="#" class="small text-muted text-underline--dashed border-primary" data-toggle="password-text" data-target="#input-password">Show password</a>
                                        </div>
                                    </div>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-key"><path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path>
                                                </svg>
                                            </span>
                                        </div>
                                        <input v-model="password" type="password" class="form-control" id="input-password" placeholder="Password">
                                    </div>
                                </div>
                                <div v-if="loading" class="mt-4" style="position:relative;margin-bottom:10px;width:100%;display:flex;justify-content:center" >
                                <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                                </div>
                                
                                </div>
                                <div class="mt-4" style="position:relative;margin-bottom:10px;text-align:center;" v-if="serverError">
                                <div class="server-error">{{ serverError }}</div>
                                </div>
                                <div class="mt-4">
                                    <button @click="logIn" type="button" class="btn btn-block btn-primary">Sign in</button>
                                </div>
                               
                            </form>
                            <div class="py-3 text-center">
                                <span class="text-xs text-uppercase"></span>
                            </div>
                            <div class="row">
                                
                                
                            </div>
                            <div class="mt-4 text-center">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</div>
</template>
<script>
import flatpickr from "flatpickr";
import axios from 'axios';

export default {
  name: 'Login',
  components: {
    flatpickr,
  },
  data() {
      return {
          loading: false,
          username: '',
          password: '',
          serverError: '',
      }
  },
  mounted() {
    flatpickr('#datepicker', {
        dateFormat: "m/d/Y",
        maxDate: "07/28/2003",
    });
  },
  methods: {
      logStateIn() {
          
         
                this.loading = true;
                this.serverError = '';
                this.$store.dispatch('retrieveToken', {
                username: this.username,
                password: this.password
                    }).then(resp => {
                        this.loading = false;
                        this.$router.go(-1);
                    }).catch(error => {
                        this.loading = false;
                        console.log("Invalid Creds");
                        this.serverError = "These credentials do not match our records.";
                        this.password = '';
                    });
                
         
        
      },
      logIn() {
          if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(this.username)) {
              this.logStateIn();
          } else {
              this.loading = false;
              this.$toasted.error('Invalid Credentials');
          }
      },
      
      getUser() {

          console.log(JSON.parse(localStorage.getItem('access_token2')).role);
      },
      
  },
}
</script>