<template>
<div class="card">
                                <div class="card-header">
                                    <div v-if="quoteType == 'fixed'">
                                        <h3 class="h3 mb-0">Peace of Mind</h3>
                                        <p class="text-sm mb-0">Death Benefit Coverage $5k - $40k</p>
                                    </div>
                                    <div v-if="quoteType == 'term'">
                                        <h3 class="h3 mb-0">More Coverage</h3>
                                        <p class="text-sm mb-0">Income Replacement Death Benefits $45k - $400k</p>
                                    </div>
                                    
                                </div>
                                <div class="card-body">
                                    <div class="row">

                                        <div class="col-6">
                                            <div class="form-group">
                                                    
                                                 
                                                   <!--  <select v-model="form.amount" class="form-control custom-select-sm">
                                                        <option selected>Amount</option>
                                                        <option value="25000">$25,000</option>
                                                        <option v-for="i in 4" :key="i" :value="25000 - (i * 5000)">{{ formatCurrency(25000 - (i * 5000)) }}</option>
                                                    </select> -->
                                                    <div v-if="quoteType == 'fixed'">
                                                        <label class="form-control-label">Coverage Amount:</label> 
                                                    <select v-model="form.amount" class="form-control custom-select-sm">
                                                        <option selected>Amount</option>
                                                        <option value="50000">$50,000</option>
                                                        <option v-for="i in 45" :key="i" :value="50000 - (i * 1000)">{{formatCurrency((50000 - (i * 1000)))}}</option>
                                                    </select>
                                                    </div>
                                                    <div v-if="quoteType == 'term'">
                                                        <label class="form-control-label">Coverage Amount:</label> 
                                                    <select v-model="form.amount" class="form-control custom-select-sm">
                                                        <option selected>Amount</option>
                                                        <option value="500000">$500,000</option>
                                                        <option v-for="i in 16" :key="i" :value="500000 - (i * 25000)">{{ formatCurrency(500000 - (i * 25000)) }}</option>
                                                        <option v-for="i in 10" :key="i" :value="100000 - (i * 5000)">{{ formatCurrency(100000 - (i * 5000)) }}</option>
                                                    </select>
                                                    </div>
                                                </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                    <label class="form-control-label">State:</label> 
                                                    <select v-model="form.state" class="form-control custom-select-sm">
                                                        <option value="">State</option>
                                                        <option value="AL">Alabama</option>
                                                        <option value="AZ">Arizona</option>
                                                        <option value="AR">Arkansas</option>
                                                        <option value="CA">California</option>
                                                        <option value="CO">Colorado</option>
                                                        <option value="CT">Connecticut</option>
                                                        <option value="DE">Delaware</option>
                                                        <option value="FL">Florida</option>
                                                        <option value="GA">Georgia</option>     
                                                        <option value="ID">Idaho</option>
                                                        <option value="IL">Illinois</option>
                                                        <option value="IN">Indiana</option>
                                                        <option value="IA">Iowa</option>
                                                        <option value="KS">Kansas</option>
                                                        <option value="KY">Kentucky</option>
                                                        <option value="LA">Louisiana</option>
                                                        <option value="ME">Maine</option>
                                                        <option value="MD">Maryland</option>
                                                        <option value="MA">Massachusetts</option>
                                                        <option value="MI">Michigan</option>
                                                        <option value="MN">Minnesota</option>
                                                        <option value="MS">Mississippi</option>
                                                        <option value="MO">Missouri</option>
                                                        <option value="MT">Montana</option>
                                                        <option value="NE">Nebraska</option>
                                                        <option value="NV">Nevada</option>
                                                        <option value="NH">New Hampshire</option>
                                                        <option value="NJ">New Jersey</option>
                                                        <option value="NM">New Mexico</option>
                                                        <option value="NC">North Carolina</option>
                                                        <option value="ND">North Dakota</option>
                                                        <option value="OH">Ohio</option>
                                                        <option value="OK">Oklahoma</option>
                                                        <option value="OR">Oregon</option>
                                                        <option value="PA">Pennsylvania</option>
                                                        <option value="RI">Rhode Island</option>
                                                        <option value="SC">South Carolina</option>
                                                        <option value="SD">South Dakota</option>
                                                        <option value="TN">Tennessee</option>
                                                        <option value="TX">Texas</option>
                                                        <option value="UT">Utah</option>
                                                        <option value="VT">Vermont</option>
                                                        <option value="VA">Virginia</option>
                                                        <option value="WA">Washington</option>
                                                        <option value="WV">West Virginia</option>
                                                        <option value="WI">Wisconsin</option>
                                                        <option value="WY">Wyoming</option>

                                                    </select>
                                                </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                    <label class="form-control-label">Smoking Status:</label> 
                                                    <select v-model="form.ss" class="form-control custom-select-sm">
                                                        <option selected>Smoking Status</option>
                                                        <option value="ns">No</option>
                                                        <option value="ss">Yes</option>                                          
                                                        </select>
                                                </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Gender:</label> 
                                                <select v-model="form.gender" class="form-control custom-select-sm">
                                                    <option selected>Gender</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                    <label class="form-control-label">Date of Birth:</label> 
                                                    <div class="row">
                                                        <div class="col-4" style="padding-right:2px;">
                                                            <select v-model="selectMonth" class="form-control custom-select-sm">
                                                                <option selected>Month</option>
                                                                <option value="01">01 - January</option>
                                                                <option value="02">02 - February</option>
                                                                <option value="03">03 - March</option>
                                                                <option value="04">04 - April</option>
                                                                <option value="05">05 - May</option>
                                                                <option value="06">06 - June</option>
                                                                <option value="07">07 - July</option>
                                                                <option value="08">08 - August</option>
                                                                <option value="09">09 - September</option>
                                                                <option value="10">10 - October</option>
                                                                <option value="11">11 - November</option>
                                                                <option value="12">12 - December</option>
                                                            </select> 
                                                        </div>
                                                        <div class="col-4" style="padding-right:2px;padding-left:2px;">
                                                            
                                                            <select v-model="selectDay" class="form-control custom-select-sm">
                                                                <option selected>Day</option>
                                                                <option v-for="i in 31" :key="i" :value="i">{{i}}</option>
                                                                
                                                            </select> 
                                                        </div>
                                                        <div class="col-4" style="padding-left:2px;">
                                                            <select v-model="selectYear" class="form-control custom-select-sm" >
                                                                <option selected>Year</option>
                                                                <option v-for="i in 100" :key="i" :value="todayYear-i">{{ todayYear-i }}</option>
                                                            </select> 
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                        <div class="col-12 text-right">
                                            <button @click="updateQuote()" type="button" class="btn btn-primary btn-sm arrow-up-right">
                                               Update Quote
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>  
</template>
<script>
import axios from 'axios';
export default {
    name: 'modal-quoter',
    data() {
        return {
            todayYear: new Date().getFullYear() - 18,
            quoteType: '',
            selectDay: '',
            selectMonth: '',
            selectYear: '',
            selectSS: '',
            selectAmount: '',
            form: {
                amount: '',
                state: '',
                ss: '',
                gender: '',
            },
        }
    },
    created() {
        this.quoteType = this.$store.state.quoteType;
        this.selectMonth =  this.$store.state.policy.dob.split('/')[0];
        this.selectDay =  this.$store.state.policy.dob.split('/')[1];
        this.selectYear =  this.$store.state.policy.dob.split('/')[2];

        this.form.ss = this.$store.state.policy.ss;
        this.form.amount = this.$store.state.policy.amount;
        this.form.gender = this.$store.state.policy.gender;
        this.form.state = this.$store.state.policy.state;

        console.log('Day: ' + this.$store.state.policy.dob.split('/')[0])
    },
    mounted() {

    },
    methods: {
        updateQuote() {
            this.$store.state.shelf = [];
            this.form.dob = this.selectMonth + '/' + this.selectDay + '/' + this.selectYear;
            
            this.$parent.showAig = true;
            this.$parent.showFidelitywl = true;
            this.$parent.showFidelityTerm = true;
            


            if(this.form.amount != '' && this.form.state != '' && this.form.ss != '' && this.form.gender != '' && this.selectedMonth != '' && this.form.selectedDay != '' && this.form.selectedYear != '') {
                axios.post('/api/fetchquoteall', this.form).then(resp => {
                    console.log(resp.data);
                    this.$store.state.allQuotes = [];
                    this.$store.state.quoteType = this.$parent.showQuoter;
                    this.$store.state.policy = this.form;
                    this.$store.state.policy.amount = this.form.amount;
                    this.$store.state.policy.age = resp.data.diff;

                    resp.data.qval.forEach(this.iterateProducts);
                }).then(resp2 => {
                    this.$toasted.success('Updated');
                    this.$parent.renderTransamerica();
                    this.$parent.renderFidelityterm();
                    this.$parent.renderForesters();
                    this.$parent.renderInstaterm();
                    this.$parent.renderAig();
                    this.$parent.renderFidelitywl();
                    this.$parent.returnTurboterm();
                    
                });
            } else if(this.form.amount == '') {
                this.$toasted.error('Select Amount');
            } else if(this.form.state == '') {
                this.$toasted.error('Select State');
            } else if(this.form.ss == '') {
                this.$toasted.error('Smoking Status');
            } else if(this.form.gender == '') {
                this.$toasted.error('Gender');
            } else {
                console.log(this.form)
            }
        },
        formatCurrency(amount) {
            amount = (amount);
            return amount.toLocaleString('en', { style: 'currency', currency: 'usd', minimumFractionDigits: 0 });
        },
        iterateProducts(i) {
            console.log('Inside iterate products');
            console.log(i)

            let rate = '';
            let quote = '';
            if(this.$store.state.policy.gender == 'Male' && this.$store.state.policy.ss == 'ns') {
                
                rate = i.m_ns_rate;
            }
            if(this.$store.state.policy.gender == 'Male' && this.$store.state.policy.ss == 'ss') {
                rate = i.m_ss_rate;
            }
            if(this.$store.state.policy.gender == 'Female' && this.$store.state.policy.ss == 'ns') {
                rate = i.f_ns_rate;
            }
            if(this.$store.state.policy.gender == 'Female' && this.$store.state.policy.ss == 'ss') {
                rate = i.f_ss_rate;
            }

            /* Set logic to set min and max values depending on product  */
            let amount = Number(this.$store.state.policy.amount).toFixed(2);

            if(amount < Number(i.min_coverage)) {
                amount = Number(i.min_coverage).toFixed(2);
            }
            if(amount > Number(i.max_coverage)) {
                amount = Number(i.max_coverage).toFixed(2);
            }
            amount = Number(amount) / 1000;
            

            
            
            console.log(i.products.name + 'Amount: ' + amount);
            quote = (((rate * amount) + Number(i.premium)) * i.mode_eft);
            i.amount = amount;
            i.quote = quote;
            if(Number(this.$store.state.policy.amount == Number(amount * 1000))) {
            this.$store.state.allQuotes.push(i);
            }
        },
    }
}
</script>