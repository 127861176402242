<template>
    <div class="row row-grid">
        <div class="col-lg-3">
            <div data-toggle="sticky" data-sticky-offset="50" class="" style="">
                <div class="card">
                    <div class="list-group list-group-flush">
                        <span data-scroll-to="" data-scroll-to-offset="50" class="list-group-item list-group-item-action d-flex justify-content-between">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layout mr-2"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="3" y1="9" x2="21" y2="9"></line><line x1="9" y1="21" x2="9" y2="9"></line>
                                </svg> 
                                <span>American General (AIG)</span>
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline>
                                </svg>
                            </div>
                        </span>
                        <span data-scroll-to="" data-scroll-to-offset="50" class="list-group-item list-group-item-action d-flex justify-content-between">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layout mr-2"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="3" y1="9" x2="21" y2="9"></line><line x1="9" y1="21" x2="9" y2="9"></line>
                                </svg> 
                                <span>Federal Life</span>
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline>
                                </svg>
                            </div>
                        </span>
                        <span data-scroll-to="" data-scroll-to-offset="50" class="list-group-item list-group-item-action d-flex justify-content-between">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layout mr-2"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="3" y1="9" x2="21" y2="9"></line><line x1="9" y1="21" x2="9" y2="9"></line>
                                </svg> 
                                <span>Fidelity Life</span>
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline>
                                </svg>
                            </div>
                        </span>
                        <span data-scroll-to="" data-scroll-to-offset="50" class="list-group-item list-group-item-action d-flex justify-content-between">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layout mr-2"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="3" y1="9" x2="21" y2="9"></line><line x1="9" y1="21" x2="9" y2="9"></line>
                                </svg> 
                                <span>Mutual of Omaha</span>
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline>
                                </svg>
                            </div>
                        </span>
                        <span data-scroll-to="" data-scroll-to-offset="50" class="list-group-item list-group-item-action d-flex justify-content-between">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layout mr-2"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="3" y1="9" x2="21" y2="9"></line><line x1="9" y1="21" x2="9" y2="9"></line>
                                </svg> 
                                <span>Transamerica</span>
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline>
                                </svg>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-8 ml-lg-auto">
            <div class="mb-5">
                <h4 class="mb-4" id="theme-integration">American General (AIG)</h4>
                <div id="accordion-1" class="accordion accordion-spaced">
                    <div class="card">
                        <div class="card-header py-4" @click="ShowUnderwritting('AIG Guaranteed Issue Whole Life','/pdf/AIG-GIWL-Annual Rates.pdf')" id="heading-1-1" data-toggle="collapse" role="button">
                            <h6 class="mb-0">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file mr-3"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline>
                                </svg>
                                AIG Guaranteed Issue Whole Life
                            </h6>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="mb-5">
                <h4 class="mb-4" id="theme-integration">Federal Life</h4>
                <div id="accordion-1" class="accordion accordion-spaced">
                    <div class="card">
                        <div class="card-header py-4" @click="ShowUnderwritting('Federal Life Final Expense','/pdf/Federal-Life-Final-Expense.pdf')" id="heading-1-1" data-toggle="collapse" role="button">
                            <h6 class="mb-0">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file mr-3"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline>
                                </svg>
                                Federal Life Final Expense
                            </h6>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="mb-5">
                <h4 class="mb-4" id="theme-integration">Fidelity Life</h4>
                <div id="accordion-1" class="accordion accordion-spaced">
                    <div class="card">
                        <div class="card-header py-4" @click="ShowUnderwritting('Fidelity Rapid Decision Senior Life','/pdf/RAPIDecision-Senior-Life.pdf')" id="heading-1-1" data-toggle="collapse" role="button">
                            <h6 class="mb-0">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file mr-3"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline>
                                </svg>
                                Fidelity Rapid Decision Senior Life
                            </h6>
                        </div>
                        
                        
                    </div>
                    <div class="card">
                        <div class="card-header py-4" @click="ShowUnderwritting('Fidelity Rapid Decision Term Life','/pdf/RAPIDecision-Life.pdf')" id="heading-1-1" data-toggle="collapse" role="button">
                            <h6 class="mb-0">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file mr-3"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline>
                                </svg>
                                Fidelity Rapid Decision Term Life
                            </h6>
                        </div>
                        
                        
                    </div>
                    <div class="card">
                        <div class="card-header py-4" @click="ShowUnderwritting('Fidelity Rapid Decision Final Expense and Guaranteed Issue Graded Life','/pdf/RAPIDecision-Final-Expense-GI.pdf')" id="heading-1-1" data-toggle="collapse" role="button">
                            <h6 class="mb-0">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file mr-3"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline>
                                </svg>
                                Fidelity Rapid Decision Final Expense and Guaranteed Issue Graded Life
                            </h6>
                        </div>
                        
                        
                    </div>
                    <div class="card">
                        <div class="card-header py-4" @click="ShowUnderwritting('Fidelity InstaTerm', '/pdf/InstaTerm.pdf')" id="heading-1-1" data-toggle="collapse" role="button">
                            <h6 class="mb-0">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file mr-3"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline>
                                </svg>
                                Fidelity InstaTerm
                            </h6>
                        </div>
                        
                        
                    </div>
                </div>
            </div>
            <div class="mb-5">
                <h4 class="mb-4" id="theme-integration">Mutual of Omaha</h4>
                <div id="accordion-1" class="accordion accordion-spaced">
                    <div class="card">
                        <div class="card-header py-4" @click="ShowUnderwritting('Mutual of Omaha Living Promise','/pdf/Mutual-of-Omaha-Living-Promise.pdf')" id="heading-1-1" data-toggle="collapse" role="button">
                            <h6 class="mb-0">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file mr-3"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline>
                                </svg>
                                Mutual of Omaha Living Promise
                            </h6>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="mb-5">
                <h4 class="mb-4" id="theme-integration">Transamerica</h4>
                <div id="accordion-1" class="accordion accordion-spaced">
                    <div class="card">
                        <div class="card-header py-4" @click="ShowUnderwritting('Transamerica Final Expense','/pdf/Transamerica Final_Expense_Agent_Guide.pdf')" id="heading-1-1" data-toggle="collapse" role="button">
                            <h6 class="mb-0">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file mr-3"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline>
                                </svg>
                                Transamerica Final Expense
                            </h6>
                        </div>
                        
                    </div>
                </div>
            </div>
            
            
        </div>
       
    <general-view
        ref="showGeneralView"
    />
</div>
        
    </div>
</template>
<script>
import generalView from './UModals/GeneralView.vue';
export default {
    name: 'carrier-underwriting',
    components: {
        generalView,
    },
    data() {
        return {
            productInfo: {
                name: '',
                pdf: '',
            }
        }
    },
    methods: {
        ShowUnderwritting(a,b) {
            this.productInfo.name = a;
            this.productInfo.pdf = b;
            $(this.$refs.showGeneralView.$el).modal('show');
        }
    }
}
</script>