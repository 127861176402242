<template>
    <div class="modal fade docs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

        <div class="modal-header">
            <h5 class="modal-title h6" id="myLargeModalLabel">Manager User</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            
            <div class="row mt-6 pb-4" style="display: flex; justify-content: center; margin-top: -24px;">
                <div class="col-md-10" style="display: flex; justify-content: center;">
                    <div class="row" style="justify-content: center;">
                        
                        
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label style="color:rgba(0,0,0,0.6)">Name *</label>
                                        <input v-model="$parent.userModValues2.name" name="contact-name" type="text" placeholder="Name Here" class="form-control" required="">
                                        <div class="invalid-feedback">
                                        Please type your name.
                                        </div>
                                    </div>
                                    </div>
                                    <div class="col-md-6">
                                    <div class="form-group">
                                        <label style="color: rgba(0,0,0,0.6)">Email Address *</label>
                                        <input v-model="$parent.userModValues2.email" @blur="validateEmail" name="contact-email" type="email" placeholder="Email@email.com" class="form-control" required="">
                                    </div>
                                    </div>
                                    <div class="col-md-6">
                                    <div class="form-group">
                                        <label style="color: rgba(0,0,0,0.6)">Password *</label>
                                        <input name="password" v-model="$parent.confirm.pass" type="password" placeholder="Password" class="form-control" required="" :class="{'outlineTicketSuccess' : $parent.confirm.pc == 1, 'outlineTicketDanger' : $parent.confirm.pc == 2}">
                                    </div>
                                    </div>
                                    <div class="col-md-6">
                                    <div class="form-group">
                                        <label style="color: rgba(0,0,0,0.6)">Confirm Password *</label>
                                        <input  name="password" @keyup="confirmPassword" v-model="$parent.confirm.passC" type="password" placeholder="Confirm Password" class="form-control" required="" :class="{'outlineTicketSuccess' : $parent.confirm.pc == 1, 'outlineTicketDanger' : $parent.confirm.pc == 2}">
                                    </div>
                                    </div>
                                    <div  class="col-md-12">
                                        <div class="form-group">
                                            <label class="mb-2" style="color: rgba(0,0,0,0.6)">Select Roles:</label>
                                            <div class="row mt-1 ml-2">
                                            <div v-if="!$parent.ucRoles.recruiter" class="col-4">
                                                <div class="">
                                                <input v-model="$parent.ucRoles.agent" class=" mr-1" type="checkbox">
                                                <label>Agent</label>
                                                </div>
                                            </div>
                                            <div v-if="!$parent.ucRoles.agent" class="col-4">
                                                <div class="">
                                                <input v-model="$parent.ucRoles.recruiter" class="mr-1" type="checkbox">
                                                <label>Recruiter</label>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="">
                                                <input v-model="$parent.ucRoles.enroller" class="mr-1" type="checkbox">
                                                <label>Enroller</label>
                                                </div>
                                            </div>
                                            
                                           
    
                                            </div>
                                        </div>
                                        </div>
                            </div>
                        </div>
                        <div v-if="this.$parent.ucRoles.agent" class="col-md-12">
                            <div class="form-group">
                                <label style="color:rgba(0,0,0,0.6)">Select Recruiter *</label>
                                <select @change="selectEnroller" class="custom-select custom-select-lg mb-3">
                                    <option selected>Open this select menu</option>
                                    <option v-for="i in enrollersList" :key="i.id" :value="i.id">{{i.user.name}}</option>
                                    
                                </select>
                            </div>
                        </div>
                        
                        <div class="col-md-12 pb-2" style="display: flex; justify-content: center; align-items: center; margin-top: 40px;">
                            
                            <button @click="createUser()"  type="submit" class="btn btn-primary btn-loading">
                                
                                <span v-if="!submitCredentials">Create User</span>
                                <div v-else class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                                </div>
                            </button>
                        </div>

                        
                       
                        
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    </div>
</template>
<script>
import Avatar from 'vue-avatar'
import axios from 'axios';
export default {
    name: "user-create",
   
    components: {
        Avatar,
    },
    
    data() {
        return {
            slot: '',
            loading: false,
            pLoad: false,
            submitCredentials: false,
            submitAgentLoading: false,
            emailValid: false,
            enrollersList: [],
            
            form: {
                name: '',
            },
            confirm: {
                agent: false,
                recruiter: false,
                enroller: false,
            }
        }
    },
   computed: {
       
       
   },
   created() {
       this.fetchEnrollers();
   },
    methods: {
       showPassChange() {
           this.pLoad = true;
           this.$parent.userModValueRoles.changePassword = true;
       },
       confirmAgent() {
           this.$parent.confirm.agent = true;
       },
       confirmAgent2() {
           this.$parent.confirm.agent = true;
           this.$parent.confirm.agentAdd = true;
       },
       confirmRecruiter() {
           this.$parent.confirm.recruiter = true;
       },
       confirmEnroller() {
           this.$parent.confirm.enroller = true;
       },
        fetchEnrollers() {
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.get('/api/fetchrecruitors2').then(resp => {
                console.log(resp);
                this.enrollersList = resp.data.t;
            });
        },
        selectEnroller(event) {
          
            console.log(event.target.value);
            if(event.target.value !== '') {
            
                this.$parent.userModValues2.recruiter_id = event.target.value;
            }
        },
        confirmPassword(event) {
            
            if(event.target.value.length >= 4) {
                console.log(event.target.value);
                if(this.$parent.confirm.pass == this.$parent.confirm.passC) {
                    this.$parent.confirm.pc = 1;
                    console.log("equals");
                }  else {
                    this.$parent.confirm.pc = 2;
                }

            } else {
                this.passc = '';
            }
        },
        validateEmail() {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(this.$parent.userModValues2.email)) {
                console.log('email looks alright');  
                this.emailValid = true;
            } else {
                console.log('Please enter a valid email address');
                this.emailValid = false;
            }
        },
        createUser() {
            if(this.$parent.userModValues2 != '' && this.$parent.userModValues2.email != '' && this.emailValid && (this.$parent.confirm.pass == this.$parent.confirm.passC && this.$parent.pass != '') && ((this.$parent.ucRoles.agent && this.$parent.userModValues2.recruiter_id != '') || this.$parent.ucRoles.recruiter || this.$parent.ucRoles.enroller)) {
                
                this.submitCredentials = true;
                this.$parent.userModValues2.password = this.$parent.confirm.pass;
                this.$parent.userModValues2.agent = this.$parent.ucRoles.agent;
                this.$parent.userModValues2.recruiter = this.$parent.ucRoles.recruiter;
                this.$parent.userModValues2.enroller = this.$parent.ucRoles.enroller;
                axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
                axios.post('/api/modifyusercreate', this.$parent.userModValues2).then(resp => {
                    console.log(resp);
                    this.submitCredentials = false;
                    this.$parent.page = 1;
                    this.$parent.userList = [];
                }).then(resp2 => {
                    this.$toasted.success('Created');
                     $(this.$parent.$refs.userCreate.$el).modal('hide');
                     this.$parent.fetchUsers()
                     
                }).catch(err => {
                    console.log(err);
                    this.$toasted.error('Try using another email address');
                    this.submitCredentials = false;
                });
            } else if(this.$parent.userModValues2.name == '') {
                this.$toasted.error('Name');
            } else if(this.$parent.userModValues2.email == '' || this.emailValid == false) {
                this.$toasted.error('Email');
            } else if((this.$parent.confirm.pass != this.$parent.confirm.passC) || this.$parent.confirm.pass == "") {
                this.$toasted.error('Password Must Match');
            } else if(this.$parent.ucRoles.agent && this.$parent.userModValues2.recruiter_id == '') {
                this.$toasted.error('Select a Recruiter')
            } else if(!(this.$parent.ucRoles.agent || this.$parent.ucRoles.recruiter || this.$parent.ucRoles.enroller)) {
                this.$toasted.error('Select Role');
            }
        },  
        submitAddAgentCancel() {
            this.$parent.confirm.agent = false;
            this.$parent.confirm.agentAdd = false;
        },
       subitRemoveAgent() {
           
           axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
           axios.post('/api/modifyremoveagent', this.$parent.userModValues).then(resp => {

               console.log(resp);
               this.$parent.userModValueRoles.agent = false;
               this.$parent.userModValues = resp.data.u;
               this.$parent.userList[this.$parent.modIndex] = resp.data.u;
               this.$parent.confirm.agent = false;

           }).then(resp2 => {
               this.$toasted.success('Updated');
           }).catch(err => { this.$parent.userModValueRoles.agent = false;this.$parent.confirm.agent = false; });
       },
       submitAddAgent() {
           this.submitAgentLoading = true;
           axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
           axios.post('/api/modifyaddagent', this.$parent.userModValues).then(resp => {
               console.log(resp);
               this.$parent.userModValueRoles.agent = true;
               this.$parent.userModValues = resp.data.u;
               this.$parent.userList[this.$parent.modIndex] = resp.data.u;
               this.$parent.confirm.agent = false;
               this.$parent.confirm.agentAdd = false;
               console.log(this.$parent.userList);
               this.submitAgentLoading = false;
           }).then(resp2 => {
               this.$toasted.success('Updated');
           }).catch(err => { this.submitAgentLoading = false;this.$parent.confirm.agentAdd = false; });
       },
       submitRemoveRecruiter() {
           
           axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
           axios.post('/api/modifyremoverecruiter   ', this.$parent.userModValues).then(resp => {

               console.log(resp);
               this.$parent.userModValueRoles.recruiter = false;
               this.$parent.userModValues = resp.data.u;
               this.$parent.userList[this.$parent.modIndex] = resp.data.u;
               this.$parent.confirm.recruiter = false;

           }).then(resp2 => {
               this.$toasted.success('Updated');
           }).catch(err => { this.$parent.userModValueRoles.recruiter = false;this.$parent.confirm.recruiter = false; });
       },
       submitAddRecruiter() {
           axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
           axios.post('/api/modifyaddrecruiter', this.$parent.userModValues).then(resp => {
               console.log(resp);
               this.$parent.userModValueRoles.recruiter = true;
               this.$parent.userModValues = resp.data.u;
               this.$parent.userList[this.$parent.modIndex] = resp.data.u;
               this.$parent.confirm.recruiter = false;
               
           }).then(resp2 => {
               this.$toasted.success('Updated');
           }).catch(err => { this.$parent.userModValueRoles.recruiter = true;this.$parent.confirm.recruiter = false; });
       },
       submitRemoveEnroller() {
           
           axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
           axios.post('/api/modifyremoveenroller', this.$parent.userModValues).then(resp => {

               console.log(resp);
               this.$parent.userModValueRoles.enroller = false;
               this.$parent.userModValues = resp.data.u;
               this.$parent.userList[this.$parent.modIndex] = resp.data.u;
               this.$parent.confirm.enroller = false;

           }).then(resp2 => {
               this.$toasted.success('Updated');
           }).catch(err => { this.$parent.userModValueRoles.enroller = false;this.$parent.confirm.enroller = false; });
       },
       submitAddEnroller() {
           axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
           axios.post('/api/modifyaddenroller', this.$parent.userModValues).then(resp => {
               console.log(resp);
               this.$parent.userModValueRoles.enroller = true;
               this.$parent.userModValues = resp.data.u;
               this.$parent.userList[this.$parent.modIndex] = resp.data.u;
               this.$parent.confirm.enroller = false;
               
           }).then(resp2 => {
               this.$toasted.success('Updated');
           }).catch(err => { this.$parent.userModValueRoles.enroller = true;this.$parent.confirm.enroller = false;  });
       },
       submitModifyCredentials() {
           if(this.$parent.confirm.pc = 1) {
               this.submitCredentials = true;
               this.$parent.userModValues.password = this.$parent.confirm.pass;
               axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
               axios.post('/api/modifyupdatecredentials', this.$parent.userModValues).then(resp => {
                   this.$parent.userModValues = resp.data.u;
                   this.$parent.userList[this.$parent.modIndex] = resp.data.u;
                   this.$parent.userModValueRoles.changePassword = false;
                   this.pLoad = false;
                   this.submitCredentials = false;

               }).then(resp2 => {
                   this.$toasted.success('Updated');
               }).catch(err => { this.submitCredentials = false;this.$parent.userModValueRoles.changePassword = false;this.pLoad = false; });
           }
       }
    }
    
}
</script>
<style scoped>
.outlineTicketSuccess {
    border-color: #5cc9a7;
}
.outlineTicketDanger {
    border-color: #ee283c;;
}
</style>