<template>
    <div class="modal fade docs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

        <div class="modal-header">
            <h5 class="modal-title h6" id="myLargeModalLabel">Agent Contact Information</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row pb-4" style="display: flex; justify-content: center; margin-top: -24px;">
                <div class="col-md-12" style="display: flex; justify-content: center;">
                    <div class="row p-4">
                       <div class="col-12">
                           <h5 class=" mb-2">Contact information</h5>
                        </div>
                        <div class="col-12">
                            <div class="card">
                        <div class="list-group list-group-flush">
                            <div class="list-group-item d-flex w-100 justify-content-between" style="border: 0px;">
                                <div class="row w-100">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">First name</label> 
                                                <p class="form-control text-muted">{{$parent.contactInfo.first_name}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Last name</label> 
                                                <p class="form-control text-muted">{{$parent.contactInfo.last_name}}</p>
                                            </div>
                                        </div>
                                </div>
                            </div>
                           
                            <div class="list-group-item d-flex w-100 justify-content-between" style="border: 0px;">
                                <div class="row w-100">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Email</label> 
                                                <p class="form-control text-muted">{{$parent.contactInfo.email}}</p> 
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Phone</label> 
                                                <p class="form-control text-muted">{{$parent.contactInfo.phone}}</p>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div class="list-group-item d-flex w-100 justify-content-between" style="border: 0px;">
                                <div class="row w-100">
                                        <div class="col-sm-9">
                                            <div class="form-group">
                                                <label class="form-control-label">Address</label> 
                                                <p class="form-control text-muted">{{$parent.contactInfo.address}}</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="form-group">
                                                <label class="form-control-label">Number</label> 
                                                <p class="form-control text-muted">{{$parent.contactInfo.address_no}}</p>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div class="list-group-item d-flex w-100 justify-content-between" style="border: 0px;">
                                <div class="row w-100">
                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <label class="form-control-label">City</label> 
                                                <p class="form-control text-muted">{{$parent.contactInfo.city}}</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <label class="form-control-label">State</label> 
                                                <p class="form-control text-muted">{{$store.state.state[$parent.contactInfo.state]}}</p>
                                               
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <label class="form-control-label">ZIP</label> 
                                                <p class="form-control text-muted">{{$parent.contactInfo.zip}}</p>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            
                        </div>
                    </div>
                        </div> 
                        <div class="col-12">
                            <h5 class="mb-3">Notes</h5>
                        </div>
                        <div class="col-12">
                            <div class="card">
                            <div class="list-group list-group-flush">
                                <div class="list-group-item d-flex w-100 justify-content-center pb-3" style="border: 0px;">
                                    <div class="row w-100 pt-3">
                                        <div class="col-md-12">
                                                
                                            </div>
                                            <div class="col-md-12 text-center">
                                                <div class="form-group">
                                                    <textarea v-model="$parent.contactInfo.notes"  @keyup="showNoteButton()" class="form-control" placeholder="Complete Ticket Notes" rows="6"></textarea>
                                                </div>
                                                
                                            </div>
                                            <div v-if="$store.getters.isRecruiter && $parent.showNoteUpdate" class="col-md-12" style="display:flex;justify-content:end;">
                                                <div class="form-group">
                                                    <button type="button" @click="updateApplicant()" class="btn btn-primary btn-xs">
                                                        <span v-if="!noteLoading">Update</span>
                                                        <div v-else class="spinner-border" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                        </div>    
                                                    </button>
                                                    
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-12 pb-2" style="display: flex; justify-content: center; align-items: center; margin-top: 40px;">
                            <button v-if="!$parent.showPassword" @click="showUpdatePassword()" type="submit" class="btn btn-warning btn-loading" style="left:0px;">
                                
                                <span>Update Password</span>
                                
                            </button>
                            <button v-else type="submit" @click="submitUpdatePassword()" class="btn btn-success btn-loading">
                                
                                <span v-if="!loading2">Confirm Update</span>
                                <div v-else class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                                </div>
                            </button>
                            <button @click="close()" type="submit" class="btn btn-primary btn-loading">
                                
                                <span v-if="!loading">Close</span>
                                <div v-else class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                                </div>
                            </button>
                        </div>
                    </div>
                    
                    
                    
                    
                </div>
            </div>
        </div>
        </div>
    </div>
    </div>
</template>
<script>
import Avatar from 'vue-avatar'
import axios from 'axios';
export default {
    name: "agent-contact2",
   
    components: {
        Avatar,
    },
    
    data() {
        return {
            slot: '',
            loading: false,
            loading2: false,
            showNoteUpdate: false,
            showPassword: false,
            noteLoading: false,
            form: {
                name: '',
            },
        }
    },
   
    methods: {
        close() {
            $(this.$parent.$refs.agentContact.$el).modal('hide');
        },
        showNoteButton() {
            console.log(this.$parent.contactInfo);
            this.$parent.showNoteUpdate = true;
        },
        updateApplicant() {
            this.noteLoading = true;
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.post('/api/updateapplicantnotes', this.$parent.contactInfo).then(resp => {
                console.log(resp);
                this.noteLoading = false;
                this.$toasted.success('Updated');
                this.$parent.updateValues(resp.data.app);
            }).catch(err => {
                this.noteLoading = false;
            });
        },
        showUpdatePassword() {
            this.$parent.showPassword = true;
            
        },
        submitUpdatePassword() {
            this.loading2 = true;
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.post("/api/modifyupdatecredentials2", this.$parent.contactInfo).then(resp => {
                console.log(resp);
                this.loading2 = false;
                this.$parent.showPassword = false;
                this.$toasted.success('Credentials Updated');
            }).catch(err => {
                this.loading2 = false;
                this.$parent.showPassword = false;
                this.$toasted.error('Please Try Again');
            })
        },
        acceptSlot() {
            this.loading = true;
            console.log(this.$parent.sInfo.id);
            this.form.hour_id = this.$parent.sHour.id;
            this.form.user_id = JSON.parse(localStorage.getItem('access_token2')).id;
            this.form.ticket_id = this.$parent.sInfo.id;
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.post('/api/scheduleslot', this.form).then(resp => {
                console.log(resp.data);
                this.$parent.ticketList[this.$parent.sIndex].enroller_id = resp.data.e;
            }).then(resp2 => {
                this.loading = false;
                
                $(this.$parent.$refs.enrAccept.$el).modal('hide');
                this.$parent.$toasted.success("Scheduled");
            }).catch(err => {
                this.loading = false;
                $(this.$parent.$refs.enrAccept.$el).modal('hide');
            });

        }
    }
    
}
</script>