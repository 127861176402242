<template>
<div>

    <div class="slice slice-sm bg-section-secondary">
        <div class="container">
            <div class="row mx-n2 justify-content-between">
                
                <div class="col-lg-3 col-sm-6 px-2">
                    <div class="card" style="cursor:pointer;">
                        <div class="card-body text-center" @click="SelectUserTab('carrier underwriting')">
                            <div class="mb-3">
                                <div class="icon icon-shape icon-md bg-primary shadow-primary text-white">
                                    <i class="fuil uil-book-open"></i>
                                </div>
                            </div>
                            <span class="d-block text-sm text-muted font-weight-bold">
                                Carrier Underwritting
                                
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 px-2">
                    <div class="card" style="cursor:pointer;">
                        <div class="card-body text-center" @click="SelectUserTab('account settings')">
                            <div class="mb-3">
                                <div class="icon icon-shape icon-md bg-warning shadow-warning text-white">
                                    <i class="uil uil-user-exclamation"></i>
                                </div>
                            </div>
                            <span class="d-block text-sm text-muted font-weight-bold">
                                Instant Underwriting Tool
                                
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 px-2">
                    <div class="card" style="cursor:pointer;">
                        <div class="card-body text-center" @click="SelectUserTab('contracting info')">
                            <div class="mb-3">
                                <div class="icon icon-shape icon-md bg-success shadow-success text-white">
                                    <i class="uil uil-file-question-alt"></i>
                                </div>
                            </div>
                            <span class="d-block text-sm text-muted font-weight-bold">
                                Contracting Information
                                
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 px-2">
                    <div class="card" style="cursor:pointer;" @click="SelectUserTab('account settings')">
                        <div class="card-body text-center">
                            <div class="mb-3">
                                <div class="icon icon-shape icon-md bg-danger shadow-danger text-white">
                                    <i class="uil uil-setting"></i>
                                </div>
                            </div>
                            <span class="d-block text-sm text-muted font-weight-bold">
                                Account Settings
                                
                            </span>
                        </div>
                    </div>
                </div>
                
                
                
            </div>
            <div class="row mx-n2">
                
                <div class="col-lg-3 col-sm-6 px-2">
                    <div class="card" style="cursor:pointer;">
                        <div class="card-body text-center" @click="showDirectQuote()">
                            
                            <div class="mb-3">
                                <div class="icon icon-shape border-primary quote-client">
                                    <i class="uil uil-plus" style="font-size:1rem;"></i>
                                </div>
                            </div>
                            <span class="d-block text-sm text-muted font-weight-bold">
                                Quote Client
                                
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 px-2">
                    <div class="card" style="cursor:pointer;">
                        <div class="card-body text-center" @click="showQuestionairModal()">
                            <div class="mb-3">
                                <div class="icon icon-shape questionnaire-modal" style="">
                                    <i class="uil uil-plus" style="font-size:1rem;"></i>
                                </div>
                            </div>
                            <span class="d-block text-sm text-muted font-weight-bold">
                                All Questionnaires
                                
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 px-2">
                   
                </div>
                <div class="col-lg-3 col-sm-6 px-2 text-end" style="display:flex;align-items:center;">
                    <select @change="changeOrder" class="form-control custom-select-sm">
                        <option value="default" selected>Default</option>
                        <option value="new">Scheduled</option> 
                        <option value="enrollment">Pending Enrollment</option>
                        <option value="signature">Pending Signature</option>
                        <option value="queued">All Pending</option>
                        <option value="completed">Completed</option>
                        <!-- <option value="missed">Missed</option> -->
                                                                    
                    </select>
                </div>
                
                
                
                
            </div>
            
            
            <div v-if="!loading" class="row justify-content-center">
                <div class="col-lg-11">
                    <!-- <div class="row justify-content-between">
                                <div class="col-auto">  
                                    <div class="row mb-3">
                                        <div class="col-auto">
                                            <button @click="showDirectQuote()" type="button" class="btn btn-xs btn-outline-primary btn-icon">
                                                <span class="btn-inner--text">Quote Client</span> 
                                                <span class="btn-inner--icon"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                                                </span>
                                            </button>
                                        </div>
                                        <div class="col-auto">
                                            <button @click="showQuestionairModal()" type="button" class="btn btn-xs btn-outline-primary btn-icon">
                                                <span class="btn-inner--text">All Questionnaires</span> 
                                                <span class="btn-inner--icon"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                                                </span>
                                            </button>  
                                        </div>
                                    </div>  
                                    
                                                                  
                                </div>
                                <div class="col-auto">
                                    <select @change="changeOrder" class="form-control custom-select-sm">
                                        <option value="default" selected>Default</option>
                                        <option value="new">Scheduled</option> 
                                        <option value="enrollment">Pending Enrollment</option>
                                        <option value="signature">Pending Signature</option>
                                        <option value="queued">All Pending</option>
                                        <option value="completed">Completed</option>
                                        
                                                                                 
                                    </select>
                                </div>
                            </div>  -->

                    
                    <div class="row mt-2">
                        <div v-if="!loadingS" class="col-lg-12">
                            <div class="mb-10">
                                
                                
                                <div class="table-responsive">
                                    <table class="table table-cards2 align-items-center">
                                        <thead>
                                            <tr style="">
                                                <th scope="col" style="max-width:200px;;border:0px solid white;">Name</th>
                                                <th v-if="$store.getters.isAdmin || $store.getters.isRecruiter || $store.getters.isEnroller" scope="col" style="border:0px solid white">Agent <span v-if="order == 'queued' || order == 'completed'">/ Enroller</span></th>
                                                <th scope="col" style="border:0px solid white;">City / State</th>
                                                <th scope="col" style="border: 0px solid white">Schedule Date</th>
                                                <th  scope="col" style="border:0px solid white">Status</th>
                                                <th scope="col" style="border: 0px solid white"></th>
                                            </tr>
                                        </thead>
                                        
                                       <!--  <tbody v-if="(role.toLowerCase().includes('enroller') || role.toLowerCase().includes('admin')) && order == 'queued'">
                                            <tr v-for="(i, index) in ticketList" :key="i.id" >
                                                    <th  scope="row" :style="styleBorder1(i.ticket)" >
                                                        <div class="media align-items-center">
                                                            
                                                            <div class="media-body ml-4">
                                                                <a href="#" class="name h6 mb-0 text-sm">{{i.ticket.first_name}} {{i.ticket.last_name}}</a> 
                                                                <small class="d-block font-weight-bold">{{i.ticket.email}}</small>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <td :style="styleBorder2(i.ticket)">
                                                        <div class="media align-items-center">
                                                            
                                                            <div class="media-body">
                                                                <span class="name h6 mb-0 text-sm">{{i.ticket.agent.user.name}}</span> 
                                                                <span v-if="i.ticket.enroller" class="d-block font-weight-light text-muted">{{i.ticket.enroller.user.name}}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td :style="styleBorder2(i.ticket)">
                                                        <div class="media align-items-start">
                                                            
                                                            <div class="media-body">
                                                                <span class="name h5 mb-0 text-sm">{{i.ticket.city}}</span> 
                                                                <span class="d-block font-weight-light text-muted">{{$store.state.state[i.ticket.state]}}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td :style="styleBorder2(i.ticket)">
                                                        <div class="media align-items-start">
                                                            
                                                            <div  v-if="i.hour" class="media-body text-center">
                                                            <span class="name h5 mb-0 text-sm">{{printDate(i.ticket.hour.hour)}}</span> 
                                                            <span class="d-block font-weight-light text-muted">{{printDate2(i.ticket.hour.hour)}}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td :style="styleBorder2(i.ticket)">
                                                        <div class="media align-items-center">
                                                            
                                                            <div class="media-body">
                                                                <span class="name h6 mb-0 text-sm" style="text-transform: capitalize;">{{i.ticket.status}}</span> 
                                                            
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="text-right" :style="styleBorder3(i.ticket)">
                                                        <div class="actions ml-3" style="display:flex;align-items:center;justify-content:end;">
                                                            <button v-if="$store.getters.isEnroller && i.enroller_id == null && !i.complete && i.hour" 
                                                                @click="showEnrAccept(i, index)" class="action-item mr-2" data-toggle="tooltip" title="">
                                                                <i class="uil uil-user-plus" style="font-size:1rem;margin-top:-3px;"></i> 
                                                            </button>
                                                            <router-link :to="{name: 'admin.ticket.view', params: {id: i.id}, query: {ticket: i.ticket}}" class="action-item mr-2" data-toggle="tooltip" title="" >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg> 
                                                            </router-link>
                                                        </div>
                                                    </td>
                                            </tr>
                                            <tr v-observe-visibility="scrollBottom"></tr>
                                            <tr v-if="loadingSS" class="bg-dark text-center  justify-content-center">
                                                <th></th>
                                                <td></td>
                                                
                                                <td class="text-center">
                                                    <div class="spinner-border" role="status" style="margin: 0 auto;">
                                                <span class="sr-only">Loading...</span>
                                                </div>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                
                                            </tr>
                                        </tbody> -->

                                        <tbody>
                                            <tr v-for="(i, index) in ticketList" :key="i.id" >
                                                    <th  scope="row" :style="styleBorder1(i)" >
                                                        <div class="media align-items-center">
                                                            
                                                            <div class="media-body ml-4">
                                                                <a href="#" class="name h6 mb-0 text-sm">{{i.first_name}} {{i.last_name}}</a> 
                                                                <small class="d-block font-weight-bold">{{i.email}}</small>
                                                                <small v-if="i.ticket_type == 'chat'" class="d-block font-weight-bold">Speak with representative</small>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <td :style="styleBorder2(i)">
                                                        <div class="media align-items-center">
                                                            
                                                            <div class="media-body">
                                                                <span class="name h6 mb-0 text-sm">{{i.agent.user.name}}</span> 
                                                                <span v-if="i.enroller" class="d-block font-weight-light text-muted">{{i.enroller.user.name}}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td :style="styleBorder2(i)">
                                                        <div class="media align-items-start">
                                                            
                                                            <div v-if="i.city" class="media-body">
                                                                <span class="name h5 mb-0 text-sm" style="text-transform:capitalize">{{i.city}}</span> 
                                                                <span class="d-block font-weight-light text-muted">{{$store.state.state[i.state]}}</span>
                                                            </div>
                                                            <div v-else class="media-body">
                                                                <span class="name h5 mb-0 text-sm">{{$store.state.state[i.state]}}</span> 
                                                               
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td :style="styleBorder2(i)">
                                                        <div class="media align-items-start">
                                                            
                                                            <div  v-if="i.hour" class="media-body text-center">
                                                    
                                                            <span class="name h5 mb-0 text-sm">{{ printDate(i.hour.hour)}}</span> 
                                                            <span class="d-block font-weight-light text-muted">{{printDate2(i.hour.hour)}}</span>
                                                            </div>
                                                            <div  v-else class="media-body text-center">
                                                    
                                                            
                                                            <span class="d-block font-weight-light text-muted">Unscheduled</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td :style="styleBorder2(i)">
                                                        <div class="media align-items-center">
                                                            
                                                            <div class="media-body">
                                                                <span class="name h6 mb-0 text-sm" style="text-transform: capitalize;">{{i.status}}</span> 
                                                            
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="text-right" :style="styleBorder3(i)">
                                                        
                                                        <div class="actions ml-3" style="display:flex;align-items:center;justify-content:end;">
                                                            <button v-if="$store.getters.isEnroller && i.enroller_id == null && !i.complete && i.hour" 
                                                                @click="showEnrAccept(i, index)" class="action-item mr-2" data-toggle="tooltip" title="">
                                                                <i class="uil uil-user-plus" style="font-size:1rem;margin-top:-3px;"></i> 
                                                            </button>
                                                            
                                                            <router-link v-if="i.product_name == 'TRANS FE Qualifcation Questions ONLY'" :to="{name: 'prequalticket.questions', params: {id: i.id}, query: {ticket: i}}" class="action-item mr-2" data-toggle="tooltip" title="" >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg> 
                                                            </router-link>
                                                            <router-link v-else-if="i.product_name == 'Transamerica - Solutions Immediate Whole Life Preferred, Health Class Excellent'
                                                                        || i.product_name == 'Transamerica - Solutions Immediate Standard, Health Class Average'
                                                                        || i.product_name == 'Transamerica - Easy Solutions Graded, Health Class Average'
                                                                " 
                                                            :to="{name: 'prequalticket.questions', params: {id: i.id}, query: {ticket: i}}" class="action-item mr-2" data-toggle="tooltip" title="" >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg> 
                                                            </router-link>
                                                            <router-link v-else-if="i.product_name != ''" :to="{name: 'forester.questions', params: {id: i.id}, query: {ticket: i}}" class="action-item mr-2" data-toggle="tooltip" title="" >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg> 
                                                            </router-link>
                                                            <router-link v-else-if="i.is_direct" :to="{name: 'admin.ticket.direct', params: {id: i.id}, query: {ticket: i}}" class="action-item mr-2" data-toggle="tooltip" title="" >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg> 
                                                            </router-link>
                                                            <router-link v-else-if="i.ticket_type == 'inquiry'" :to="{name: 'admin.ticket.direct', params: {id: i.id}, query: {ticket: i}}" class="action-item mr-2" data-toggle="tooltip" title="" >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg> 
                                                            </router-link>
                                                            <router-link v-else :to="{name: 'admin.ticket.view', params: {id: i.id}, query: {ticket: i}}" class="action-item mr-2" data-toggle="tooltip" title="" >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg> 
                                                            </router-link>
                                                            
                                                        </div>
                                                    </td>
                                            </tr>
                                            <tr v-observe-visibility="scrollBottom"></tr>
                                            <tr v-if="loadingSS" class="bg-dark text-center  justify-content-center">
                                                <th></th>
                                                <td></td>
                                                
                                                <td class="text-center">
                                                    <div class="spinner-border" role="status" style="margin: 0 auto;">
                                                <span class="sr-only">Loading...</span>
                                                </div>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                
                                            </tr>
                                            
                                        </tbody>
                                        
                                        
                                        
                                </table>
                                <div v-if="ticketList.length == 0" class="" style="display:flex;align-items:center;justify-content:center;width:100%;margin-top:100px;">
                                                <div class="mt-4" style="">
                                                    <div class="h6">
                                                        Tickets Will Show Here
                                                    </div>
                                                </div>
                                            </div>
                            </div>
                        </div>
                        </div>
                        <!-- <div v-else style="height:145px;width:100%;display:flex;align-items:center;justify-content:center;">
                            <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                        </div> -->
                    </div>
                    
                    <div style="position:absolute;top:-10px;margin-top:0px;left:20px;">
                        <!-- showDirectQuote() - method for button -->
                        
                        <!-- <button v-if="$store.getters.isAdmin" @click="preQual()" type="button" class="btn btn-xs btn-outline-primary btn-icon">
                            <span class="btn-inner--text"> FE Ticket</span> 
                            <span class="btn-inner--icon"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                            </span>
                        </button>
                        
                        <button v-if="$store.getters.isAdmin" type="button" @click="$router.push({name: 'A'})" class="btn btn-xs btn-outline-primary btn-icon">
                            <span class="btn-inner--text">(test) Diabetic Questionnaire</span> 
                            <span class="btn-inner--icon"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                            </span>
                        </button>
                        <a v-if="$store.getters.isAdmin" href="https://docs.google.com/forms/d/e/1FAIpQLSefxVd6k_qw4pr5PC2-3B4GMu_ZahAFY4MHrT3YWetNcR6VJA/viewform?usp=sf_link" type="button" class="btn btn-xs btn-outline-primary btn-icon">
                            <span class="btn-inner--text"> Impaired Risk Questionnaire</span> 
                            <span class="btn-inner--icon"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                            </span>
                        </a> -->
                        
                        <!-- <button @click="$router.push({ name: 'Home' })" type="button" class="btn btn-xs btn-outline-danger btn-icon">
                            <span class="btn-inner--text">Quote Client (Disabled Untill Further)</span> 
                            <span class="btn-inner--icon"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                            </span>
                        </button> -->
                        <!-- <button @click="showQuote()" type="button" class="btn btn-xs btn-primary btn-icon rounded-pill">
                             
                            <span class="btn-inner--text">Quoter</span>
                            <span class="btn-inner--icon">
                                <i class="uil uil-plus"></i>
                            </span>
                        </button> -->
                    </div>
                </div>
            </div>
            <div v-else style="height:145px;width:100%;display:flex;align-items:center;justify-content:center;">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        </div>
        <enroller-accept 
            ref="enrAccept"
            
        />
        <ticket-quote 
            ref="tQuote"
        />
        <quote-all @exit='closeQuoter'
            ref="directQuoter"
        />
        <direct-quoter 
            ref="testQuoter"
        />
        <questionair-modal 
            ref="questionairModal"
        />

</div>
</template>
<script>
import axios from 'axios';
import InfiniteLoading from 'vue-infinite-loading';
import moment from 'moment';

import enrollerAccept from '../comps/EnrollerAccept.vue';
import ticketQuote from '../comps/TicketQuoter.vue';
import directQuoter from '../comps/DirectQuoter.vue'
import quoteAll from '../comps/QuoteAll.vue'
import questionairModal from '../comps/QuestionairModal.vue';
export default {
    components: {
        InfiniteLoading,
        enrollerAccept,
        ticketQuote,
        directQuoter,
        quoteAll,
        questionairModal,
    },
    data() {
        return {
            role : JSON.parse(localStorage.getItem('access_token2')).role,
            loading: false,
            loadingS: false,
            loadingSS: false,
            order: 'default',
            ticketList: [],
            state: {
                AL: "Alabama",
                AZ: "Arizona",
                AR: "Arkansas",
                CA: "California",
                CO: "Colorado",
                CT: "Connecticut",
                DE: "Delaware",
                FL: "Florida",
                GA: "Georgia" ,    
                ID: "Idaho",
                IL: "Illinois",
                IN: "Indiana",
                IA: "Iowa",
                KS: "Kansas",
                KY: "Kentucky",
                LA: "Louisiana",
                ME: "Maine",
                MD: "Maryland",
                MA: "Massachusetts",
                MI: "Michigan",
                MN: "Minnesota",
                MS: "Mississippi",
                MO: "Missouri",
                MT: "Montana",
                NE: "Nebraska",
                NV: "Nevada",
                NH: "New Hampshire",
                NM: "New Mexico",
                NY: "New York",
                NC: "North Carolina",
                ND: "North Dakota",
                OH: "Ohio",
                OK: "Oklahoma",
                OR: "Oregon",
                PA: "Pennsylvania",
                RI: "Rhode Island",
                SC: "South Carolina",
                SD: "South Dakota",
                TN: "Tennessee",
                TX: "Texas",
                UT: "Utah",
                VT: "Vermont",
                VA: "Virginia",
                WA: "Washington",
                WV: "West Virginia",
                WI: "Wisconsin",
                WY: "Wyoming",
            },
            sInfo: {},
            sHour: '',
            sIndex: '',
            page: 0,
            last_page: 1,
            routeInitial: false,
            showQuoter: false,
            quoteSubmitted: false,
        }
    },
    created() {
        //this.fetchTickets();
        window.scrollTo(0,0);
        console.log('is recruiter? ' + this.$store.getters.isRecruiter)
        console.log('is agent? ' + this.$store.getters.isAgent)
        console.log('is enroller? ' + this.$store.getters.isRecruiter)
    },
    computed: {
        
    },
    methods: {
        closeQuoter(e) {
            console.log(e);
            this.showQuoter = false;
            this.quoteSubmitted = false;
        },  
        fetchTickets() {
            let role = JSON.parse(localStorage.getItem('access_token2')).role;
           
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            
                if(this.routeInitial == false) {
                    this.loading = true;
                    this.routeInitial = true;
                    this.loadingS = false;
                } 
                if(this.routeInitial == true) {
                    this.loadingSS = true;
                }
                axios.get(`/api/fetchticketsall?order=${this.order}&page=${this.page}`).then(resp => {
                    console.log(resp);
                    this.last_page = resp.data.t.last_page;
                    this.ticketList.push(...resp.data.t.data);
                    
                }).then(resp2 => {
                    this.loading = false;
                    this.loadingS = false;
                    this.loadingSS = false;
                    console.log(resp2);
                
                }).catch(err => { 
                    this.loading = false; 
                    this.loadingSS = false;
                    
                    console.log('dude something failed'); 
                    if(err.message == "Request failed with status code 401") {
                      
                        this.$router.push({name: 'Login'});
                        this.$store.dispatch('destroyToken').then(resp => {
                            console.log(resp.data);
                            
                            this.$router.push({name: 'Login'});
                        }).then(resp => {  }).catch(err => { this.outLoading = false });
                    }
                });

             
            
            
        },
        scrollBottom(isVisible) {
            if(!isVisible) { return }
            if(this.page >= this.last_page) { return }
            console.log('Bottom');
            this.page++;
            console.log('fetching again')
            this.fetchTickets();
        },
        
        styleBorder1(i) {
            if(i.status.toLowerCase().includes('scheduled') || i.status == 'Pre Qual' || i.status == 'Pre Qual Scheduled') {
                return {borderLeft: '2px solid #5cc9a7', borderTop: '1px solid #5cc9a7', borderBottom: '1px solid #5cc9a7'}
            } else if(i.status.toLowerCase().includes('complete') || i.status.toLowerCase().includes('cancelled') || i.status.toLowerCase().includes('inquiry')) {
                return {}
            } else if(i.status.toLowerCase().includes('pending')) {
                return {borderLeft: '2px solid #ffbe3d', borderTop: '1px solid #ffbe3d', borderBottom: '1px solid #ffbe3d'}
            }
            
        },
        styleBorder2(i) {
            if(i.status.toLowerCase().includes('scheduled') || i.status ==  'Pre Qual' || i.status ==  'Pre Qual Scheduled') {
                return { borderTop: '1px solid #5cc9a7', borderBottom: '1px solid #5cc9a7'}
            } else if(i.status == 'complete' || i.status == 'cancelled' || i.status == 'inquiry') {
                return {}
            } else if(i.status.toLowerCase().includes('pending')) {
                return {borderTop: '1px solid #ffbe3d', borderBottom: '1px solid #ffbe3d'}
            }
         },
        styleBorder3(i) {
            if(i.status.toLowerCase().includes('scheduled') || i.status == 'Pre Qual' || i.status == 'Pre Qual Scheduled') {
                return {borderRight: '2px solid #5cc9a7', borderTop: '1px solid #5cc9a7', borderBottom: '1px solid #5cc9a7'}
            } else if(i.status == 'complete' || i.status == 'cancelled' || i.status == 'inquiry') {
                return {}
            } else if(i.status.toLowerCase().includes('pending')) {
                return {borderRight: '2px solid #ffbe3d', borderTop: '1px solid #ffbe3d', borderBottom: '1px solid #ffbe3d'}
            }
           
        },
        styleSuccessRight(date) {
            return date == null ? {borderRight: '1px solid #5cc9a7;'} : {};
        },
        styleSuccessBottom(date) {
            return date == null ? {borderBottom: '1px solid #5cc9a7;'} : {};
        },
        printDate(i) {
            return moment.utc(i).local().format('hh:mm a');
        },
        printDate2(i) {
            return moment.utc(i).local().format('dddd MMMM DD');
        },
        changeOrder(event) {
            console.log(event.target.value);
            if(event.target.value == 'queued') {
                this.order = 'queued';
                this.ticketList = [];
                this.page = 1;
                this.loadingS = true;
                this.fetchTickets();
            } else if(event.target.value == 'default') {
                this.order = 'default';
                this.ticketList = [];
                this.page = 1;
                this.loadingS = true;
                this.fetchTickets();
            } else if(event.target.value == 'signature') {
                this.order = 'signature';
                this.ticketList = [];
                this.page = 1;
                this.loadingS = true;
                this.fetchTickets();
            } else if(event.target.value == 'enrollment') {
                this.order = 'enrollment';
                this.ticketList = [];
                this.page = 1;
                this.loadingS = true;
                this.fetchTickets();
            } else if(event.target.value == 'completed') {
                this.order = 'completed';
                this.ticketList = [];
                this.page = 1;
                this.loadingS = true;
                this.fetchTickets();
            } else if(event.target.value == 'new') {
                this.order = 'new';
                this.ticketList = [];
                this.page = 1;
                this.loadingS = true;
                this.fetchTickets();
            }
        },
        showEnrAccept(i, index) {
            this.sInfo = i;
            this.sHour = i.hour;
            this.sIndex = index;
            if(i.hour) {
                $(this.$refs.enrAccept.$el).modal('show');
            } else {
                this.sInfo = '';
                this.sHour = '';
            }
            
        },
        SelectUserTab(i) {
            this.$store.state.userTabSelected = i;
            this.$router.push({name: 'admin.account'});
        },
        showQuote() {
            $(this.$refs.tQuote.$el).modal('show');
        },
        showDirectQuote() {
            $(this.$refs.directQuoter.$el).modal('show');
        },
        hideDirectQuote() {
            $(this.$refs.directQuoter.$el).modal('hide');
        },
        showQuestionairModal() {
            $(this.$refs.questionairModal.$el).modal('show');
        },
        hideQuestionairModal() {
            $(this.$refs.questionairModal.$el).modal('hide');
        },
        showDirectQuote2() {
            $(this.$refs.testQuoter.$el).modal('show');
        },
        preQual() {
            this.$store.state.policy.age = '';
            this.$store.state.policy.amount = '';
            this.$store.state.policy.dob = '';
            this.$store.state.policy.gender = '';
            this.$store.state.policy.ss = '';
            this.$store.state.policy.state = '';
            this.$store.state.p.product_name = 'TRANS FE Qualifcation Questions ONLY';

            this.$store.state.shelf = [];
            this.hideQuestionairModal();
            this.$router.push({name: 'prequal.contactinfo'});
        },
        
    }
}
</script>
<style>
.tc2 tbody tr th {
    border-radius: .75rem 0 0 .75rem;
}

.tc2 tbody th {
    padding: 0 1rem;
}
.tc2 tbody th {
    position: relative;
}
.tc2 {
    border-spacing: 0 1rem;
}
.outlineTicketSuccess123123 {
    border-color: #5cc9a7;
    border-left: 10px solid;
}
.outlineTicketDanger {
    border-color: #ee283c;;
}
.quote-client {
    border: 1px solid #008aff;
    color: #008aff;
}
.quote-client:hover {
        box-shadow: 0 .2rem 0.5rem rgba(0,138,255,.4)!important;
        background-color: rgba(0,138,255,.4);
        color: white;
        border: 0px solid;
}
.questionnaire-modal {
    border: 1px solid #ffbe3d;
    color: #ffbe3d;
}
.questionnaire-modal:hover {
        border: 0px solid;
        box-shadow: 0 .2rem 0.5rem rgba(255,190,61,.4)!important;
        background-color: rgba(255,190,61,.4);
        color: white;
        
}
</style>