<template>
<div>
    
    <section class="pt-10 bg-section-secondary">
        <div class=container>
            <div class="row justify-content-center">
                <div class=col-lg-9>
                    <div class="row align-items-center">
                        <div class=col>
                            <span class=surtitle></span>
                            <h1 class="h2 mb-0 text-center">Register</h1>
                            <p class="text-muted mb-0 text-center">Register to become an agent with us</p>
                        </div>
                    </div>
                    <div class="row align-items-center mt-4">
                        <div class=col>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="slice slice-sm bg-section-secondary">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div v-if="nope" class="row">
                        <div class="col-lg-12">
                            
                          
                            <div>
                                <h5 class="mb-3">Agent information</h5>
                                
                                
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">First name</label> 
                                                <input v-model="form.first_name" class="form-control" type="text" placeholder="Enter your first name">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Last name</label> 
                                                <input v-model="form.last_name" class="form-control" type="text" placeholder="Also your last name">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Email</label> 
                                                <input v-model="form.email" @blur="validateEmail()" class="form-control" type="email" placeholder="name@exmaple.com"> <small class="form-text text-muted mt-2">This is the main email address that we'll send notifications.</small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Phone</label> 
                                                <input v-model="form.phone" @input="acceptNumber" class="form-control" type="text" placeholder="(777) 245 549"><small class="form-text text-muted mt-2">This is the main phone number that we'll call.</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-sm-9">
                                            <div class="form-group">
                                                <label class="form-control-label">Address</label> 
                                                <input v-model="form.address" class="form-control" type="text" placeholder="Enter your home address">
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="form-group">
                                                <label class="form-control-label">Number</label> 
                                                <input v-model="form.address_no" class="form-control" type="tel" placeholder="No.">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <label class="form-control-label">City</label> 
                                                <input v-model="form.city" class="form-control" type="text" placeholder="City">
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <label class="form-control-label">State</label> 
                                                <select @change="stateChange" class="form-control custom-select-lg">
                                                    <option selected>State</option>
                                                    <option value="AL">Alabama</option>
                                                    <option value="AZ">Arizona</option>
                                                    <option value="AR">Arkansas</option>
                                                    <option value="CA">California</option>
                                                    <option value="CO">Colorado</option>
                                                    <option value="CT">Connecticut</option>
                                                    <option value="DE">Delaware</option>
                                                    <option value="FL">Florida</option>
                                                    <option value="GA">Georgia</option>     
                                                    <option value="ID">Idaho</option>
                                                    <option value="IL">Illinois</option>
                                                    <option value="IN">Indiana</option>
                                                    <option value="IA">Iowa</option>
                                                    <option value="KS">Kansas</option>
                                                    <option value="KY">Kentucky</option>
                                                    <option value="LA">Louisiana</option>
                                                    <option value="ME">Maine</option>
                                                    <option value="MD">Maryland</option>
                                                    <option value="MA">Massachusetts</option>
                                                    <option value="MI">Michigan</option>
                                                    <option value="MN">Minnesota</option>
                                                    <option value="MS">Mississippi</option>
                                                    <option value="MO">Missouri</option>
                                                    <option value="MT">Montana</option>
                                                    <option value="NE">Nebraska</option>
                                                    <option value="NV">Nevada</option>
                                                    <option value="NH">New Hampshire</option>
                                                    <option value="NJ">New Jersey</option>
                                                    <option value="NM">New Mexico</option>
                                                    <option value="NC">North Carolina</option>
                                                    <option value="ND">North Dakota</option>
                                                    <option value="OH">Ohio</option>
                                                    <option value="OK">Oklahoma</option>
                                                    <option value="OR">Oregon</option>
                                                    <option value="PA">Pennsylvania</option>
                                                    <option value="RI">Rhode Island</option>
                                                    <option value="SC">South Carolina</option>
                                                    <option value="SD">South Dakota</option>
                                                    <option value="TN">Tennessee</option>
                                                    <option value="TX">Texas</option>
                                                    <option value="UT">Utah</option>
                                                    <option value="VT">Vermont</option>
                                                    <option value="VA">Virginia</option>
                                                    <option value="WA">Washington</option>
                                                    <option value="WV">West Virginia</option>
                                                    <option value="WI">Wisconsin</option>
                                                    <option value="WY">Wyoming</option>

                                                </select>
                                               
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <label class="form-control-label">ZIP</label> 
                                                <input v-model="form.zip" class="form-control" type="tel" placeholder="ZIP">
                                            </div>
                                        </div>
                                    </div>
                                    <h5 class="mb-4 mt-3">General information</h5>
                                    <div class="row mt-2">
                                        
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Have you completed our marketing agreement?</label> 
                                                <select @change="fullState" class="form-control custom-select-lg">
                                                    <option selected>Select option</option>
                                                    <option value="1">Yes</option>
                                                    <option value="0">No</option>                                          
                                                </select>
                                            </div>
                                        </div>
                                        
                                        
                                
                                        
                                      
                                       
                                      
                                        
                                       
                                      
                                    </div>
                                    
                                    <div class="mt-3">
                                        
                                    </div>
                                
                            </div><hr>
                            <div>
                                <div class="page-inner-header mb-4">
                                    <h5 class="mb-1"></h5>
                                    <p class="text-muted mb-0"></p>
                                </div>
                                <div class="row justify-content-end">
                                    <div class="col-md-4">
                                        <button @click="registerAgent()" type="button" class="btn btn-success">
                                            <span v-if="!loading">Register</span>
                                            <div v-else class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>  
</template>
<script>
import axios from 'axios';
import flatpickr from "flatpickr";
export default {
    
    components: {
        flatpickr,
    },
    data() {
        return {
            recr: '',
            nope: false,
            form: {
                recruitor_id: '',
               first_name: '',
                last_name: '',

                dob: '',
                gender: '',
                email: '',
                phone: '',
                address: '',
                address_no: '',
                city: '',
                state: '',
                zip: '',
                fulltime: '',
                yearselling: '',
                industry_designations: ' ',
                mainsource: '',
                signedperyear: '',
                thinkcase: '',
                errorsomissions: '',
                amltraining: '',
                oweinsurance: '',
                vectorrecord: '',
                credithistory: '', 
            },
            loading: false,
            emailValid: '',
        }
    },
    created() {
        window.scrollTo(0,0);
        this.fetchRecruiter();
    },
    mounted() {
        flatpickr('#datepicker', {
            dateFormat: "m/d/Y",
            maxDate: "07/28/2003",
        });
    },
    methods: {
        fetchRecruiter() {
            axios.get('/api/registrationslug/' + this.$route.params.slug).then(resp => {
                console.log(resp);
                if(resp.data.recr == 'nope') {
                    
                    this.nope = false, this.recr = null
                } else { 
                    console.log('recruiter pulled: ' + resp.data.recr);
                    this.form.recruitor_id = resp.data.recr;
                    this.nope = true;
                }
            });
        },
        formatCurrency(amount) {
            amount = (amount / 100);
            return amount.toLocaleString('en', { style: 'currency', currency: 'usd', minimumFractionDigits: 2 });
        },
        formatCurrency2(amount) {
            amount = (amount);
            return amount.toLocaleString('en', { style: 'currency', currency: 'usd', minimumFractionDigits: 0 });
        },
        stateChange(event) {
          if(event.target.value !== "State") {
              this.form.state = event.target.value;
              console.log('succesfully changed states');
          } else {
              console.log("please select from the dropdown");
          }
        },
        fullState(event) {
            if(event.target.value !== '') {
                this.form.fulltime = event.target.value;
                
            }
        },
        sellingState(event) {
            if(event.target.value !== '') {
                this.form.yearselling = event.target.value;
                
            }
        },
        sourceState(event) {
            if(event.target.value !== '') {
                this.form.mainsource = event.target.value;
                
            }
        },
        thinkState(event) {
            if(event.target.value !== '') {
                this.form.thinkcase = event.target.value;
                
            }
        },
        errorsState(event) {
            if(event.target.value !== '') {
                this.form.errorsomissions = event.target.value;
                
            }
        },
        amlState(event) {
            if(event.target.value !== '') {
                this.form.amltraining = event.target.value;
                
            }
        },
        oweState(event) {
            if(event.target.value !== '') {
                this.form.oweinsurance = event.target.value;
                
            }
        },
        signedState(event) {
            if(event.target.value !== '') {
                this.form.signedperyear = event.target.value;
                
            }
        },
        vectorState(event) {
            if(event.target.value !== '') {
                this.form.vectorrecord = event.target.value;
                
            }
        },
        acceptNumber() {
            var x = this.form.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.form.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        creditState(event) {
            if(event.target.value !== '') {
                this.form.credithistory = event.target.value;
                
            }
        },
        validateEmail() {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(this.form.email)) {
                console.log('email looks alright');  
                this.emailValid = true;
            } else {
                console.log('Please enter a valid email address');
                this.emailValid = false;
            }
        },
        registerAgent() {
            console.log(this.form);
            if(this.form.first_name != '' && this.form_last_name != '' && this.form.email !== '' && this.emailValid == true && this.form.phone != '' && this.form.address != '' && this.form.city != '' && this.form.state != '' && this.form.zip != '' && this.form.fulltime != '' && this.form.fulltime == true) {
                this.loading = true;
                
                axios.post('/api/submitregistration', this.form).then(resp => {
                    console.log(resp.data);
                }).then(resp2 => {
                    this.loading = false;
                    this.$router.push({name: 'Applied'});
                }).catch(error => {
                    this.loading = false;
                });
            } else if(this.form.first_name == '') {
                this.$toasted.error('First Name');
            } else if(this.form.last_name == '') {
                this.$toasted.error('Last Name')
            } else if(this.form.email == '') {
                this.$toasted.error('Email');
            } else if(this.emailValid != true) {
                this.$toasted.error('Email Invalid');
            } else if(this.form.phone == '') {
                this.$toasted.error('Phone');
            } else if(this.form.address == '') {
                this.$toasted.error('Address');
            } else if(this.form.city == '') {
                this.$toasted.error('City');
            } else if(this.form.state == '') {
                this.$toasted.error('State');
            } else if(this.form.zip == '') {
                this.$toasted.error('Zip');
            } else if(this.form.fulltime == '') {
                this.$toasted.error('Have you completed our marketing agreement??')
            } else if(this.form.fulltime !== true) {
                this.$toasted.error('Have you completed our marketing agreement??')
            }
            
        }
    }

}
</script>