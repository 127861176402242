<template>
    <div>
        <form-header></form-header>
        
        <section>
            <div class="container d-flex flex-column">
                <div class="row justify-content-center min-vh-100" style="margin-top:12rem;">
                    <div class="col-md-7 col-lg-7 col-xl-6">
                        <div>
                            <div class="mb-5">
                                <div class="row justify-content-center mb-5" style="">
                                    <div class="col-12 align-items-center" style="display:flex;align-items:center;">
                                        <h3 class="h5 mb-0">Eye problems?</h3>
                                    </div>
                                    <div class="col text-right text-right mt-2">
                                        <button type="button" :class="[ toggleEye == 'Yes' ? 'btn-dark' : 'btn-outline-info']" @click="toggleEyeButton" value="Yes" class="btn btn-sm" style="width:95px;">
                                            Yes
                                        </button>
                                        <button type="button" :class="[ toggleEye == 'No' ? 'btn-dark' : 'btn-outline-info']" @click="toggleEyeButton" value="No" class="btn btn-sm" style="width:95px;">
                                            No
                                        </button>
                                    </div>
                                    <div v-if="toggleEyeAdd" class="col-12 mt-4">
                                        <textarea class="form-control" v-model="$store.state.directForm.eyeAdd" type="text" placeholder="If you answered “Yes” please provide details: " />
                                    
                                    
                                    
                                    
                                    </div>
                                </div>
                                <div class="row justify-content-center mb-5" style="">
                                    <div class="col-12 align-items-center" style="display:flex;align-items:center;">
                                        <h3 class="h5 mb-0">Heart problems?</h3>
                                    </div>
                                    <div class="col text-right text-right mt-2">
                                        <button type="button" :class="[ toggleHeart == 'Yes' ? 'btn-dark' : 'btn-outline-info']" @click="toggleHeartButton" value="Yes" class="btn btn-sm" style="width:95px;">
                                            Yes
                                        </button>
                                        <button type="button" :class="[ toggleHeart == 'No' ? 'btn-dark' : 'btn-outline-info']" @click="toggleHeartButton" value="No" class="btn btn-sm" style="width:95px;">
                                            No
                                        </button>
                                    </div>
                                    <div v-if="toggleHeartAdd" class="col-12 mt-4">
                                        <textarea class="form-control" v-model="$store.state.directForm.heartAdd" type="text" placeholder="If you answered “Yes” please provide details: " />
                                    
                                    
                                    
                                    
                                    </div>
                                </div>
                                <div class="row justify-content-center mb-5" style="">
                                    <div class="col-12 align-items-center" style="display:flex;align-items:center;">
                                        <h3 class="h5 mb-0">High blood pressure?</h3>
                                    </div>
                                    <div class="col text-right text-right mt-2">
                                        <button type="button" :class="[ toggleBlood == 'Yes' ? 'btn-dark' : 'btn-outline-info']" @click="toggleBloodButton" value="Yes" class="btn btn-sm" style="width:95px;">
                                            Yes
                                        </button>
                                        <button type="button" :class="[ toggleBlood == 'No' ? 'btn-dark' : 'btn-outline-info']" @click="toggleBloodButton" value="No" class="btn btn-sm" style="width:95px;">
                                            No
                                        </button>
                                    </div>
                                    <div v-if="toggleBloodAdd" class="col-12 mt-4">
                                        <textarea class="form-control" v-model="$store.state.directForm.bloodAdd" type="text" placeholder="If you answered “Yes” please provide details: " />
                                    
                                    
                                    
                                    
                                    </div>
                                </div>
                               
                                
                            
                            </div>
                            <span class="clearfix"></span>
                            <form>
                                <div class="form-group" style="height:2px;">
                                    
                                    
                                </div>
                                <div class="mt-4">
                                    <button type="button" class="btn btn-block btn-primary hover-continue" @click="submit()" >Continue</button>
                                </div>
                            </form>
                            <div class="mt-4 text-center"><small>Feel free to call our toll free number: </small> 
                            <a href="tel:+8002565307" class="small font-weight-bold">(800) 256-5307</a>
                            
                            </div></div></div></div></div>
        </section>
    </div>
</template>
<script>
import formHeader from "./Header.vue";

export default {
    components: {
        formHeader,
    },
    data() {
        return {
            form: '',
            toggleEye: '',
            toggleEyeAdd: false,
            eyeAdd: false,

            toggleHeart: '',
            toggleHeartAdd: false,
            heartAdd: false,

            toggleBlood: '',
            toggleBloodAdd: false,
            bloodAdd: false,
            
            buttonYes: false,
            buttonNo: false,
        }
    },
    created() {
        if(this.$store.state.directForm.eye != '') {
            console.log('yupp for yee')
            this.$store.state.directForm.eye == "Yes" ? this.toggleEye = 'Yes'  : this.toggleEye = 'No';
            if(this.$store.state.directForm.eye == "Yes") { this.toggleEyeAdd = true }
        }
        if(this.$store.state.directForm.heart != '') {
            console.log('yupp for yee')
            this.$store.state.directForm.heart == "Yes" ? this.toggleHeart = 'Yes'  : this.toggleHeart = 'No';
            if(this.$store.state.directForm.heart == "Yes") { this.toggleHeartAdd = true }
        }
        if(this.$store.state.directForm.blood != '') {
            console.log('yupp for yee')
            this.$store.state.directForm.blood == "Yes" ? this.toggleBlood = 'Yes'  : this.toggleBlood = 'No';
            if(this.$store.state.directForm.blood == "Yes") { this.toggleBloodAdd = true }
        }
    },
    methods: {
        submit() {
            
           
            console.log(this.$store.state.directForm)
            if(this.toggleEye != '' && this.toggleHeart != '' && this.toggleBlood) {
                console.log("yupp")
                
                this.$router.push({name: 'Questions2'})
            } else {
                this.$toasted.error('Select an option');
            }
            
        },
        test() {
            
            if(this.toggleEyeAdd ? (this.$store.state.directForm.eyeAdd.length > 4 ? true : false) : false) {
                console.log("toggle eye true")
            }
            if(this.toggleHeartAdd ? (this.$store.state.directForm.heartAdd.length > 4 ? true : false) : false) {
                console.log('toggle heart true')
            }
            if(this.toggleBloodAdd ? (this.$store.state.directForm.bloodAdd.length > 4 ? true : false) : false) {
                console.log('toggle blood true')
            }
        },
        toggleEyeButton(e) {
            if(e.target.value == 'Yes') {
                this.toggleEyeAdd = true;
            }
            if(e.target.value == "No") {
                this.toggleEyeAdd = false;
            }
            
            this.toggleEye = e.target.value;
            this.$store.state.directForm.eye = e.target.value;
        },
        toggleHeartButton(e) {
            if(e.target.value == 'Yes') {
                this.toggleHeartAdd = true;
            }
            if(e.target.value == "No") {
                this.toggleHeartAdd = false;
            }
            
            this.toggleHeart = e.target.value;
            this.$store.state.directForm.heart = e.target.value;
        },
        toggleBloodButton(e) {
            if(e.target.value == 'Yes') {
                this.toggleBloodAdd = true;
            }
            if(e.target.value == "No") {
                this.toggleBloodAdd = false;
            }
            
            this.toggleBlood = e.target.value;
            this.$store.state.directForm.blood = e.target.value;
        },
        toggleFormButton(e) {
            console.log(e.target.value);
            if(e.target.value == "yes") {
                this.buttonYes = true;
                this.buttonNo = false;
            }
            if(e.target.value == "no") {
                this.buttonYes = false;
                this.buttonNo = true;
            }
        }
    }
}
</script>
<style scoped>
 .hover-continue:hover {
     background-color: #171347;
 }
</style>