<template>
    <div>
        <form-header></form-header>
        
        <section>
            <div class="container d-flex flex-column">
                <div class="row align-items-center justify-content-center min-vh-100">
                    <div class="col-md-7 col-lg-7 col-xl-6">
                        <div>
                            <div class="mb-5">
                                <div class="row" style="">
                                    <div class="col-auto align-items-center" style="display:flex;align-items:center;">
                                        <h3 class="h5 mb-0">How many years have you had Diabetes?</h3>
                                    </div>
                                    
                                </div>
                                
                            
                            </div>
                            <span class="clearfix"></span>
                            <form>
                                <div class="form-group mb-5">
                                    <div class="row justify-content-center" style="">
                                    
                                    <!-- <div class="col text-center">
                                        <button type="button" :class="[ onetwo ? 'btn-dark' : 'btn-outline-info']" @click="toggleFormButton" value="1 - 2" class="btn btn-sm mx-2 mb-2" style="width:84px;">
                                           1 - 2
                                        </button>
                                        <button type="button" :class="[ threefour ? 'btn-dark' : 'btn-outline-info']" @click="toggleFormButton" value="3 - 4" class="btn btn-sm mx-2 mb-2" style="width:84px;">
                                           3 - 4
                                        </button>
                                        <button type="button" :class="[ fivesix ? 'btn-dark' : 'btn-outline-info']" @click="toggleFormButton" value="5 - 6" class="btn btn-sm mx-2 mb-2" style="width:84px;">
                                        5 - 6
                                        </button>
                                        <button type="button" :class="[ seveneight ? 'btn-dark' : 'btn-outline-info']" @click="toggleFormButton" value="7 - 8" class="btn btn-sm mx-2 mb-2" style="width:84px;">
                                        7 - 8
                                        </button>
                                        <button type="button" :class="[ nineplus ? 'btn-dark' : 'btn-outline-info']" @click="toggleFormButton" value="9+" class="btn btn-sm mx-2 mb-2" style="width:84px;">
                                        9+
                                        </button>
                                    </div> -->
                                    <div class="h4 text-center mb-0 mt-2 mb-4">
                                        <span v-if="!changed" class="price font-weight-bolder">Select Value</span>
                                        <span v-else class="price font-weight-bolder">{{  value == 4 || value == 16 ? (value == 4 ? 'Less than 5 Years' : '15+ Years') : value + " Years" }}</span>
                                    </div>
                                    <div class="col-12">
                                        <input class="form-range" type="range" @input="showValue" :min="min" :max="max" v-model="value" style="width:100%;" />
                                    </div>
                                </div>
                                    
                                </div>
                                <div class="mt-4">
                                    <button type="button" class="btn btn-block btn-primary hover-continue" @click="submit()" >Continue</button>
                                </div>
                            </form>
                            <div class="mt-4 text-center"><small>Feel free to call our toll free number: </small> 
                            <a href="tel:+8002565307" class="small font-weight-bold">(800) 256-5307</a>
                            
                            </div></div></div></div></div>
        </section>
    </div>
</template>
<script>
import formHeader from "./Header.vue";

export default {
    components: {
        formHeader,
    },
    data() {
        return {
            value: 6,
            min: 4,
            max: 16,
            form: '',
            changed: false,
            onetwo: false,
            threefour: false,
            fivesix: false,
            seveneight: false,
            nineplus: false,
        }
    },
    created() {
        if(this.$store.state.directForm.years != '') {
            this.value = this.$store.state.directForm.years.split(' ')[0];
            this.changed = true;
        }
    },
    methods: {
        showValue(e) {
           this.changed = true;
            
            this.value == 4 || this.value == 16 ? (this.value == 4 ? this.$store.state.directForm.years = 'Less than 5 Years' : this.$store.state.directForm.years = '15+ Years') : this.$store.state.directForm.years = this.value + " Years" 

        },
        submit() {
            
            if(this.changed) {
                this.$router.push({name: 'Height'})
            } else {
                this.$toasted.error('Select an option');
            }
                
           
        },
        toggleFormButton(e) {
            console.log(e.target.value);
            if(e.target.value == "1 - 2") {
                this.onetwo = true;
                this.threefour = false;
                this.fivesix = false;
                this.seveneight = false;
                this.nineplus = false;
            }
            if(e.target.value == "3 - 4") {
                this.onetwo = false;
                this.threefour = true;
                this.fivesix = false;
                this.seveneight = false;
                this.nineplus = false;
            }
            if(e.target.value == "5 - 6") {
                this.onetwo = false;
                this.threefour = false;
                this.fivesix = true;
                this.seveneight = false;
                this.nineplus = false;
            }
            if(e.target.value == "7 - 8") {
                this.onetwo = false;
                this.threefour = false;
                this.fivesix = false;
                this.seveneight = true;
                this.nineplus = false;
            }
            if(e.target.value == "9+") {
                this.onetwo = false;
                this.threefour = false;
                this.fivesix = false;
                this.seveneight = false;
                this.nineplus = true;
            }
            this.$store.state.directForm.years = e.target.value;
            console.log(this.$store.state.directForm);
        }
    }
}
</script>
<style scoped>
 .hover-continue:hover {
     background-color: #171347;
 }
</style>