<template>
<div class=mb-2>
                            
    <div class=card>
        <div class=card-header>
            <h3 class="h3 mb-0">AIG - Guaranteed Issue</h3>
            <p class="text-sm mb-0">No Health Questions, Full Coverage After Two Years, (During First Two Years, Full Coverage For Accidental Death, or Return of Premium Plus Interest)</p>
            <p class="mt-1 mb-0">
                <button class="btn btn-xs btn-outline-info" @click="ShowAIGUnderwritting()">Underwritting Guide</button>
            </p>
        </div>
        <ul class="list-group list-group-flush">
            <li class=list-group-item>
                <div class="row align-items-center">
                    <div class=col-md-4>
                        <div class="card hover-translate-y-n4 hover-shadow-lg" style="">
                            <div class="card-body text-center">
                                <div class="">
                                    
                                    <h2>{{ $parent.formatCurrency2($parent.aigForm.quote) }}</h2>
                                </div>
                                <div class="">
                                    <a href="#" class="h6 stretched-link mb-0">Monthly Price</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=col-md-5>
                        <div class="card hover-translate-y-n4 hover-shadow-lg" style="">
                            <div class="card-body text-center">
                                <!-- <div v-if="editQuote" class="pb-3 pt-2">
                                    <h4>{{formatCurrency2(this.editQuoteValue * 1000)}}</h4>
                                    
                                </div>
                                <div v-else class="pb-3 pt-2">
                                    <h4>{{formatCurrency2(this.$store.state.policy.amount * 1000)}}</h4>
                                </div> -->
                                <div class="">
                                    <h4>${{parseInt($parent.aigForm.amount * 1000).toLocaleString('en') }}</h4>
                                </div>
                                <div class="">
                                    <span class="h6 stretched-link mb-0">Coverage Amount</span>
                                </div>
                                <!-- <div  style="position:absolute;top:0px;left:10%;z-index:44;">
                                
                                        <i @click="changeQuoteCoverage" class="uil uil-ellipsis-h" style="font-size:1.45rem;"></i>
                                    
                                    
                                </div>
                                <div v-if="editQuote == true" style="position:absolute;z-index:44;top:5px;right:21%;cursor:pointer;">
                                    
                                    <i @click="showTicketQuoter" class="uil uil-pen" style="margin: 0px;padding:0px;font-size:1rem;"></i>
                                    
                                </div>
                                <div v-if="editQuote == true" style="position:absolute;z-index:44;top:6px;right:8%">
                                    
                                    <i @click="changeQuoteCoverageExit" class="uil uil-multiply" style="margin: 0px;padding:0px"></i>
                                </div>
                                <div v-if="editQuote" style="position:absolute;height:100%;top:0px;left:0px;z-index:40;display:flex;align-items:center;">
                                    <i @click="changeQuoteCoverageLeft" class="uil uil-angle-left" style="font-size:4rem;"></i>
                                </div>
                                <div v-if="editQuote" style="position:absolute;height:100%;top:0px;right:0px;z-index:40;display:flex;align-items:center;">
                                    <i @click="changeQuoteCoverageRight" class="uil uil-angle-right" style="font-size:4rem;"></i>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class=col-md-3>
                        <div class="">
                            <div class=" p-5 text-center">
                                <div class="pb-4">
                                    <button @click="addToShelf()" type="button" class="btn btn-primary arrow-up-right">
                                        Apply
                                    </button>
                                </div>
                                <div class="pt-2">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                    
                </div>
                
            </li>
            
                
        </ul>
    </div>
    <aig-guaranteed
        ref="aigGuaranteed"
    />
</div>  
</template>
<script>
import axios from 'axios';
import aigGuaranteed from '../UModals/AIGGuaranteed.vue'
export default {
    name: 'aig-component',
    components: {
        aigGuaranteed,
    },
    data() {
        return {

        }
    },
    created() {
        this.fetchQuote();
    },
    mounted() {
        
    },
    methods: {
        addToShelf() {
            this.$parent.aigForm.id = 'AIG - Guaranteed Issue';
            this.$store.state.shelf = [];
            this.$store.commit('addToShelf', this.$parent.aigForm);
            this.$router.push({name: 'q.contactinfo'});
            console.log(i);
        },
        fetchQuote() {
            /* this.$parent.aigForm.amount = this.$store.state.policy.amount; */
            this.$parent.aigForm.dob = this.$store.state.policy.dob;
            let rate = '';
            axios.post('/api/fetchaigquote', this.$parent.aigForm).then(resp => {
                console.log(resp.data);
                if(this.$store.state.policy.gender == 'Male') {
                    if(this.$store.state.policy.amount < 20000) {
                        rate = resp.data.qval.m_ns_rate_1;
                    } else {
                        rate = resp.data.qval.m_ns_rate_2;
                    }
                }
                if(this.$store.state.policy.gender == 'Female'){
                    if(this.$store.state.policy.amount < 20000) {
                        rate = resp.data.qval.f_ns_rate_1;
                    } else {
                        rate = resp.data.qval.f_ns_rate_2;
                    }
                }
                console.log('Aig rate: ' + rate);
                let amount = Number(this.$store.state.policy.amount).toFixed(2);
                if(amount >= 5000 && amount <= 25000) {
                    amount = Number(this.$store.state.policy.amount).toFixed(2) / 1000;
                } else if(amount > 25000) {
                    amount = Number(25000).toFixed(2) / 1000;
                } else if(amount < 5000) {
                    amount = Number(5000).toFixed(2) / 1000;
                }
                
                this.$parent.aigForm.amount = Number(amount).toFixed(2);
                this.$parent.aigForm.quote = (((rate * amount) + Number(resp.data.qval.premium)) * resp.data.qval.mode_eft);
                if(Number(this.$store.state.policy.amount) == Number(amount * 1000)) {
                    this.$parent.showAig = true;
                } else {
                    this.$parent.showAig = false;
                }
            }).catch(err => {
                this.$parent.showAig = false;
            });
        },
        ShowAIGUnderwritting() {
            $(this.$refs.aigGuaranteed.$el).modal('show');
        }
    }
}
</script>