<template>
<div class=mb-2>
                            
    <div class=card>
        <div class=card-header>
            <h3 class="h3 mb-0">Fidelity - Whole Life Rapid Decision Senior Life</h3>
            <p class="text-sm mb-0">Average to Below Average Health, No Life-Threatening Illnesses, Three Year Graded</p>
            <p class="mt-1 mb-0">
                <button class="btn btn-xs btn-outline-info" @click="ShowSeniorLifeUnderwritting()">Underwritting Guide</button>
            </p>
        </div>
        <ul class="list-group list-group-flush">
            <li class=list-group-item>
                <div class="row align-items-center">
                    <div class=col-md-4>
                        <div class="card hover-translate-y-n4 hover-shadow-lg" style="">
                            <div class="card-body text-center">
                                <div class="">
                                    
                                    <h2>{{ $parent.formatCurrency2($parent.fidelitywlForm.quote) }}</h2>
                                </div>
                                <div class="">
                                    <a href="#" class="h6 stretched-link mb-0">Monthly Price</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=col-md-5>
                        <div class="card hover-translate-y-n4 hover-shadow-lg" style="">
                            <div class="card-body text-center">
                                <!-- <div v-if="editQuote" class="pb-3 pt-2">
                                    <h4>{{formatCurrency2(this.editQuoteValue * 1000)}}</h4>
                                    
                                </div>
                                <div v-else class="pb-3 pt-2">
                                    <h4>{{formatCurrency2(this.$store.state.policy.amount * 1000)}}</h4>
                                </div> -->
                                <div class="">
                                    <h4>${{parseInt($parent.fidelitywlForm.amount * 1000).toLocaleString('en') }}</h4>
                                </div>
                                <div class="">
                                    <span class="h6 stretched-link mb-0">Coverage Amount</span>
                                </div>
                                <!-- <div  style="position:absolute;top:0px;left:10%;z-index:44;">
                                
                                        <i @click="changeQuoteCoverage" class="uil uil-ellipsis-h" style="font-size:1.45rem;"></i>
                                    
                                    
                                </div>
                                <div v-if="editQuote == true" style="position:absolute;z-index:44;top:5px;right:21%;cursor:pointer;">
                                    
                                    <i @click="showTicketQuoter" class="uil uil-pen" style="margin: 0px;padding:0px;font-size:1rem;"></i>
                                    
                                </div>
                                <div v-if="editQuote == true" style="position:absolute;z-index:44;top:6px;right:8%">
                                    
                                    <i @click="changeQuoteCoverageExit" class="uil uil-multiply" style="margin: 0px;padding:0px"></i>
                                </div>
                                <div v-if="editQuote" style="position:absolute;height:100%;top:0px;left:0px;z-index:40;display:flex;align-items:center;">
                                    <i @click="changeQuoteCoverageLeft" class="uil uil-angle-left" style="font-size:4rem;"></i>
                                </div>
                                <div v-if="editQuote" style="position:absolute;height:100%;top:0px;right:0px;z-index:40;display:flex;align-items:center;">
                                    <i @click="changeQuoteCoverageRight" class="uil uil-angle-right" style="font-size:4rem;"></i>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class=col-md-3>
                        <div class="">
                            <div class=" p-5 text-center">
                                <div class="pb-4">
                                    <button @click="addToShelf()" type="button" class="btn btn-primary arrow-up-right">
                                        Apply
                                    </button>
                                </div>
                                <div class="pt-2">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                    
                </div>
                
            </li>
            
                
        </ul>
    </div>
    <fidelity-seniorlife
        ref="seniorLifeUnderwritting"
    />
</div>  
</template>
<script>
import axios from 'axios';
import fidelitySeniorlife from '../UModals/FidelitySeniorlife.vue';
export default {
    name: 'fidelitywl-component',
    components: {
        fidelitySeniorlife,
    },
    data() {
        return {

        }
    },
    created() {
        this.fetchQuote();
    },
    mounted() {
        
    },
    methods: {
        addToShelf() {
            this.$parent.fidelitywlForm.id = 'Fidelity Whole Life';
            this.$store.state.shelf = [];
            this.$store.commit('addToShelf', this.$parent.fidelitywlForm);
            this.$router.push({name: 'q.contactinfo'})
            
        },
        fetchQuote() {
            /* this.$parent.aigForm.amount = this.$store.state.policy.amount; */
            this.$parent.fidelitywlForm.dob = this.$store.state.policy.dob;
            let rate = '';
            axios.post('/api/fetchfidelitywholelifequote', this.$parent.fidelitywlForm).then(resp => {
                console.log('Fidelity Whole Life');
                
                /* resp.data.qval. */
                let i = resp.data.qval;
                console.log(i);
                let rate = '';
                let quote = '';
                if(this.$store.state.policy.gender == 'Male' && this.$store.state.policy.ss == 'ns') {
                    
                    rate = i.m_ns_rate;
                }
                if(this.$store.state.policy.gender == 'Male' && this.$store.state.policy.ss == 'ss') {
                    rate = i.m_ss_rate;
                }
                if(this.$store.state.policy.gender == 'Female' && this.$store.state.policy.ss == 'ns') {
                    rate = i.f_ns_rate;
                }
                if(this.$store.state.policy.gender == 'Female' && this.$store.state.policy.ss == 'ss') {
                    rate = i.f_ss_rate;
                }

                /* Set logic to set min and max values depending on product  */
                let amount = Number(this.$store.state.policy.amount).toFixed(2);

                if(amount < Number(i.min_coverage)) {
                    amount = Number(i.min_coverage).toFixed(2);
                }
                if(amount > Number(i.max_coverage)) {
                    amount = Number(i.max_coverage).toFixed(2);
                }
                console.log('Fidelity whole life amount - ' + amount);
                amount = Number(amount) / 1000;

                
                this.$parent.fidelitywlForm.amount = Number(amount).toFixed(2);
                this.$parent.fidelitywlForm.quote = (((rate * amount) + Number(resp.data.qval.premium)) * resp.data.qval.mode_eft);

                if(Number(this.$store.state.policy.amount) == Number(amount * 1000)) {
                    this.$parent.showFidelitywl = true;
                } else {
                    this.$parent.showFidelitywl = false;
                }

            }).catch(err => {
                this.$parent.showFidelitywl = false;
            });
        },
        ShowSeniorLifeUnderwritting() {
            $(this.$refs.seniorLifeUnderwritting.$el).modal('show');
        },
    }
}
</script>