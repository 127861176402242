<template>
    <div class="row mb-6 pb-6">
        <div class="col-12">
            <div class="row justify-content-center">
                <div class="col-lg-12 text-center">
                    <h4 class="">Final Expense Underwriting<br>
                         </h4>
                    <h5 class="mb-4">
                        Decisions Now Available in less than 60 seconds 
                    </h5>
                    <div class="row-12">
                        <span class="btn btn-primary" @click="ShowVideo()">Video Demostration</span>
                    </div>
                    <div class="row-12 mt-4">
                        <router-link :to="{name: 'Learnmore.Signup'}">
                            Learn More
                        </router-link> 
                        or 
                        <a href="https://checkout.stripe.com/c/pay/cs_live_a1615AoBnHTSgAuctvZKjMERy9XKrMhsjcR4KUorTcdUJc4EcCnwEY5oz7#fidkdWxOYHwnPyd1blppbHNgWjA0SURiSkhPb1dXVzNIZlI1QXc3MEhRZE58UmB0TTxEdlRHfXRSSE5KaUJIMU9PMmBTUFNRSH0yZzxwcmA0cXFUMnNwM2FKaktrREhXcUJGamNcMWY0MX1yNTVmX0ZHfUwwdCcpJ3VpbGtuQH11anZgYUxhJz8nZks3ZktSMmB0ZDBdMGFWZkhKJyknd2BjYHd3YHdKd2xibGsnPydtcXF1dj8qKmZtYGZuanBxK3Zxd2x1YCtmamgqJ3gl">
                        Subscribe Now
                        </a>
                    </div>
                    <div class="col-12 mt-2">
                        <a href="https://checkout.stripe.com/c/pay/cs_live_a1THFS1zvw14fofdFUfLzIihvcUfwnepzQTzSUXzCIxrFpSakYkrkuxAhq#fidkdWxOYHwnPyd1blppbHNgWjA0SURiSkhPb1dXVzNIZlI1QXc3MEhRZE58UmB0TTxEdlRHfXRSSE5KaUJIMU9PMmBTUFNRSH0yZzxwcmA0cXFUMnNwM2FKaktrREhXcUJGamNcMWY0MX1yNTVmX0ZHfUwwdCcpJ3VpbGtuQH11anZgYUxhJz8nZks3ZktSMmB0ZDBdMGFWZkhKJyknd2BjYHd3YHdKd2xibGsnPydtcXF1dj8qKmZtYGZuanBxK3Zxd2x1YCtmamgqJ3gl">
                            Monthly Subscription
                        </a><br> 
                        <a href="https://checkout.stripe.com/c/pay/cs_live_a14K3vTm8kWTIzZS6NpzP7LKpaypVw5hZF13qt0RD1WfMNvpuMVDdGs7e7#fidkdWxOYHwnPyd1blppbHNgWjA0SURiSkhPb1dXVzNIZlI1QXc3MEhRZE58UmB0TTxEdlRHfXRSSE5KaUJIMU9PMmBTUFNRSH0yZzxwcmA0cXFUMnNwM2FKaktrREhXcUJGamNcMWY0MX1yNTVmX0ZHfUwwdCcpJ3VpbGtuQH11anZgYUxhJz8nZks3NmFoMDNsNXNrZHxmY180Jyknd2BjYHd3YHdKd2xibGsnPydtcXF1dj8qKmZtYGZuanBxK3Zxd2x1YCtmamgqJ3gl">
                             Annual Subscription
                        </a><br>
                        <a href="https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=baabe9d8-e9a2-4e24-944f-de82ea52e0f6&env=na3&acct=141ce13e-f1f8-4a3a-a859-604b91d0a931&v=2">
                             FE BPP Bonus Contract
                        </a>

                    </div>
                </div>
                
            </div>
            <hr class="mb-5">
            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right" style="display:flex;align-items:center;margin-top:-2.4rem;">
                    <h4 class="mb-4">Simplify Sales + Underwriting </h4>
                </div>
                <div class="col-lg-8">
                    <p>
                        Wish you could have an all-in-one underwriting and quoting tool at your fingertips?
                        <ul>
                            <li>
                                Never call your upline
                            </li>
                            <li>
                                Never use a cheat sheet
                            </li>
                            <li>
                                Accurately underwrite and quote within 60 seconds
                            </li>
                            <li>
                                Faster fact finding process with your client 
                            </li>
                        </ul>
                    </p>
                    <p>
                        Here’s how we can reduce the time you’re underwriting and managing client information. Process medications, generics, combo lists, dosage & other restrictions automatically.  Don’t think about what conditions yield graded.  Don’t worry about if your carrier sells graded plans to 76yo Females in North Carolina.There’s too much to think about
                    </p>
                </div>
            </div>
            
            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h5 class="mb-4">Health Management</h5>
                </div>
                <div class="col-lg-8">
                    <p>
                        Systematic Way of noting and organizing client health profile information.
                    </p>
                </div>
            </div>
            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h5 class="mb-4">Various Quoting Options</h5>
                </div>
                <div class="col-lg-8">
                    <p>
                       Run multiple quotes at the same time (by desired face amount or by desired budget) 
                    </p>
                
                </div>
            </div>
            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h5 class="mb-4">Online & Offline</h5>
                </div>
                <div class="col-lg-8">
                    <p>
                       Windows Edition works Offline and BPP Online works on all modern internet-connected devices.
                    </p>
                
                </div>
            </div>
            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h5 class="mb-4">Data Entry Friendliness</h5>
                </div>
                <div class="col-lg-8">
                    <p>
                       Nearly 100k medication and use combinations (with autofill, autocomplete, and typo corrections).  Get known uses of medications and find medications based on a health condition.
                    </p>
                
                </div>
            </div>
            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h5 class="mb-4">Competitive Analysis</h5>
                </div>
                <div class="col-lg-8">
                    <p>
                       Identify Replacement Risk and other carriers you may want to add to your portfolio all with a single click of a button..
                    </p>
                
                </div>
            </div>
            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h5 class="mb-4">Non-Medical Intuitiveness</h5>
                </div>
                <div class="col-lg-8">
                    <p>
                        Through our Plan Info, view all non-medical information about a plan (including payment methods, riders, 2 or 3 year wait, social security number requirements, etc)
                    </p>
                
                </div>
            </div>
            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h5 class="mb-4">Rapid Training</h5>
                </div>
                <div class="col-lg-8">
                    <p>
                        It takes about 15 minutes to learn Best Plan Pro via our onboarding process.  That training process is all you need to succeed with Best Plan Pro.
                    </p>
                
                </div>
            </div>
            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h5 class="mb-4">Intuitive Date Management</h5>
                </div>
                <div class="col-lg-8">
                    <p>
                        Handle maintenance treatment, ongoing treatment, and other types of dates intuitively and without challenges
                    </p>
                
                </div>
            </div>
            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h5 class="mb-4">Simple Pricing</h5>
                </div>
                <div class="col-lg-8">
                    <p>
                        We do not believe in nickel-and-diming.  When you sign up for BPP, you are grandfathered into that price and will receive all features. Monthly subscription is only $26.50, Annual payment is $300.00, which saves you $18.00.
                    </p>
                    
                    
                
                </div>
            </div>
        </div>
        <video-demonstration ref="refShowVideo">
        </video-demonstration>

    </div>
</template>
<script>
import videoDemonstration from './UModals/VideoDemonstration.vue'
export default {
    name: 'agent-guides',
    components: {
        videoDemonstration,
    },
    data() {
        return {

        }
    },
    created() {

    },
    methods: {
        ShowVideo() {
          $(this.$refs.refShowVideo.$el).modal('show');  
        },
    }
}
</script>