<template>
<div>
    
    <div class="slice slice-sm bg-section-secondary">
        <div class="container">
            <div v-if="!loading" class="row justify-content-center">
                
                <div class="col-lg-9">
                    <div class="card mt-5" style="margin-bottom:4px;">
                        <div class="card-body">
                            <div class="row row-grid align-items-center">
                                <div class="col-lg-8">
                                    <div class="media align-items-center">
                                        <span class="avatar bg-success text-white rounded-circle mr-3">
                                            <i class="uil uil-check" style="font-size: 1.75rem;"></i>
                                        </span>
                                        <div class="media-body">
                                            <h5 class="mb-0">name here</h5>
                                            <!-- <p class="text-muted lh-150 text-sm mb-0">Age {{i.age}} | Date of policy start: {{i.startdate}} </p> -->
                                            <p class="text-muted lh-150 text-sm mb-0">Coverage Amount: amount </p>
                                            <p class="text-muted lh-150 text-sm mb-0">Term: term </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-auto flex-fill mt-4 mt-sm-0 text-sm-right">
                                    <p v-if="i.quote" class="text-muted lh-150 text-sm mb-0">i.quote </p>
                                </div>
                                <div class="col-auto flex-fill mt-4 mt-sm-0 text-sm-right">
                                
                                </div>
                            </div>
                        </div>
                    </div>
                   
                
                    <h5 class="mt-4 mb-3">Contact information</h5>
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-control-label">First name</label> 
                                        <p class="form-control">$store.state.q.first_name</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-control-label">Last name</label> 
                                        <p class="form-control">$store.state.q.last_name</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-control-label">Date of Birth</label> 
                                        <p type="text" class="form-control flatpickr-input">$store.state.q.dob</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-control-label">Gender</label> 
                                        <select v-model="q.gender" class="custom-select">
                                            <option disabled="disabled" selected="selected">Select option</option>
                                            <option value="Female">Female</option>
                                            <option value="Male">Male</option>
                                            <option value="Rather not say">Rather not say</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-control-label">Email</label> 
                                        <input v-model="$store.state.q.email" @blur="validateEmail" class="form-control" :class="{'outlineTicketSuccess' : emailc == 1, 'outlineTicketDanger' : emailc == 2}" type="email" placeholder="name@exmaple.com"> <small class="form-text text-muted mt-2">This is the main email address that we'll send notifications.</small>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-control-label">Email Confirmation</label> 
                                        <input @keyup="emailConfirm" class="form-control" :class="{'outlineTicketSuccess' : emailc == 1, 'outlineTicketDanger' : emailc == 2}" type="email" placeholder="name@exmaple.com"> 
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-control-label">Phone</label> 
                                        <input v-model="$store.state.q.phone" @input="acceptNumber" class="form-control" :class="{'outlineTicketSuccess' : phonec == 1, 'outlineTicketDanger' : phonec == 2}"  type="text" placeholder="(777) 245-549"><small class="form-text text-muted mt-2">This is the main phone number that we'll contact.</small>
                                    </div>
                                </div>
                                
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-control-label">Phone</label> 
                                        <input @keyup="phoneConfirm" v-model="$store.state.q.phone_confirm" @input="acceptNumber2" class="form-control" :class="{'outlineTicketSuccess' : phonec == 1, 'outlineTicketDanger' : phonec == 2}" type="text" placeholder="(777) 245-549">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-9">
                                    <div class="form-group">
                                        <label class="form-control-label">Address</label> 
                                        <input v-model="$store.state.q.address" class="form-control" type="text" placeholder="Enter your home address">
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="form-group">
                                        <label class="form-control-label">Number</label> 
                                        <input v-model="$store.state.q.address_no" class="form-control" type="tel" placeholder="No.">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="form-control-label">City</label> 
                                        <input v-model="$store.state.q.city" class="form-control" type="text" placeholder="City">
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="form-control-label">State</label> 
                                        <select v-model="$store.state.policy.state" class="form-control custom-select">
                                            <option selected>State</option>
                                            <option value="AL">Alabama</option>
                                            <option value="AZ">Arizona</option>
                                            <option value="AR">Arkansas</option>
                                            <option value="CA">California</option>
                                            <option value="CO">Colorado</option>
                                            <option value="CT">Connecticut</option>
                                            <option value="DE">Delaware</option>
                                            <option value="FL">Florida</option>
                                            <option value="GA">Georgia</option>     
                                            <option value="ID">Idaho</option>
                                            <option value="IL">Illinois</option>
                                            <option value="IN">Indiana</option>
                                            <option value="IA">Iowa</option>
                                            <option value="KS">Kansas</option>
                                            <option value="KY">Kentucky</option>
                                            <option value="LA">Louisiana</option>
                                            <option value="ME">Maine</option>
                                            <option value="MD">Maryland</option>
                                            <option value="MA">Massachusetts</option>
                                            <option value="MI">Michigan</option>
                                            <option value="MN">Minnesota</option>
                                            <option value="MS">Mississippi</option>
                                            <option value="MO">Missouri</option>
                                            <option value="MT">Montana</option>
                                            <option value="NE">Nebraska</option>
                                            <option value="NV">Nevada</option>
                                            <option value="NH">New Hampshire</option>
                                            <option value="NJ">New Jersey</option>
                                            <option value="NM">New Mexico</option>
                                            <option value="NC">North Carolina</option>
                                            <option value="ND">North Dakota</option>
                                            <option value="OH">Ohio</option>
                                            <option value="OK">Oklahoma</option>
                                            <option value="OR">Oregon</option>
                                            <option value="PA">Pennsylvania</option>
                                            <option value="RI">Rhode Island</option>
                                            <option value="SC">South Carolina</option>
                                            <option value="SD">South Dakota</option>
                                            <option value="TN">Tennessee</option>
                                            <option value="TX">Texas</option>
                                            <option value="UT">Utah</option>
                                            <option value="VT">Vermont</option>
                                            <option value="VA">Virginia</option>
                                            <option value="WA">Washington</option>
                                            <option value="WV">West Virginia</option>
                                            <option value="WI">Wisconsin</option>
                                            <option value="WY">Wyoming</option>

                                        </select>
                                    
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label class="form-control-label">ZIP</label> 
                                        <input v-model="$store.state.q.zip" class="form-control" type="tel" placeholder="ZIP">
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="mt-5">
                        <h5 class="mb-3">General information</h5>
                        <div class="card">
                        <div class="list-group list-group-flush">
                            <div class="list-group-item d-flex w-100 justify-content-between" style="border: 0px;">
                                <div class="row w-100">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Has client used tabacco in the past 12 months?</label> 
                                                <p class="form-control text-muted">{{ticket.tobacco}}</p>
                                            </div>
                                        </div>
                                        <div v-if="ticket.spouse_fn" class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Has client's spouse used tabacco in the past 12 months?</label> 
                                                <p class="form-control text-muted">{{ticket.spouse_tobacco}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Has client been actively working at least 20 hours a week for the past 90 days?</label> 
                                                <p class="form-control text-muted">{{ticket.hours}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Does client have a Life Expectancy of 12 months or Less?</label> 
                                                <p class="form-control text-muted">{{ticket.medical}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Name of Employeer</label> 
                                                <p class="form-control text-muted">{{ticket.employeer_name}}</p>
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Client Occupation</label> 
                                                <p class="form-control text-muted">{{ticket.occupation}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Approx Annual Income </label> 
                                                <p class="form-control text-muted">{{ticket.income}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Did agent explained limmited partnership? </label> 
                                                <p class="form-control text-muted">{{ticket.agentexplainedllp}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">How much coverage is the client applying for?  </label> 
                                                <p class="form-control text-muted">{{formatCurrency2(ticket.policy_coverage * 1000)}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Will anyone other than the client pay for coverage? (if yes, name will be below)  </label> 
                                                <p class="form-control text-muted">{{ticket.paymentalternative}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Scheduled Day </label> 
                                                <p v-if="ticket.hour"  class="form-control text-muted"><span v-tooltip="printDate(ticket.hour.hour)">{{printDateTimezone(ticket.hour.hour, ticket.timezone)}} {{ticket.timezone}}</span></p>
                                            
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            
                        </div>
                    </div>
                    </div>
                    <h5 class="mb-3">Notes</h5>
                    <div class="card">
                        <div class="list-group list-group-flush">
                            <div class="list-group-item d-flex w-100 justify-content-center pb-3" style="border: 0px;">
                                <div class="row w-100 pt-3">
                                    
                                    <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Enroller: 
                                                </label> 
                                                <p class="form-control text-muted"><span v-if="ticket.enroller">
                                                                                                    {{ticket.enroller.user.name}}
                                                                                            </span> 
                                                                                            <span v-else>Not Assigned</span></p>
                                                
                                            </div>
                                        </div>
                                        
                                        <div v-if="ticket.enrollment_id" class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Enrollment ID: 
                                                                                            
                                                </label> 
                                                <p class="form-control text-muted"><span>{{ticket.enrollment_id}}</span></p>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Ticket Created: 
                                                </label> 
                                                <p class="form-control text-muted">
                                                    {{printDateTimezone(ticket.created_at, ticket.timezone)}} {{ticket.timezone}}
                                                </p>
                                                
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Ticket Notes:</span>
                                                </label> 
                                                <textarea v-model="ticket.note" @keyup="showNoteButton()" class="form-control" placeholder="Complete Ticket Notes" rows="12"></textarea>
                                            </div>
                                            
                                        </div>
                                        <div v-if="$store.getters.isEnroller && !ticket.complete && showNoteUpdate" class="col-md-12" style="display:flex;justify-content:end;">
                                            <div class="form-group">
                                                <button @click="updateNotes" type="button" class="btn btn-primary btn-xs">
                                                    <span v-if="!noteLoading">Update</span>
                                                    <div v-else class="spinner-border" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                    </div>    
                                                </button>
                                                
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-end pt-4 mb-4 pb-4">
                        <div class="col-md-12">
                            <div class="row" v-if="ticket.status != 'canceled' || ticket.status == 'completed'">
                                <div class="col-md-6 justify-content-start">
                                    <!-- <ul class="cancelOptions">
                                        <li>
                                            <button v-if="$store.getters.isEnroller && !ticket.complete" @click="showScheduler()" type="button" class="btn btn-danger">New Schedule</button><br>
                                        </li>
                                        <li>
                                            <button v-if="$store.getters.isEnroller && !ticket.complete && ticket.enroller_id" @click="showEnrCancel()" type="button" class="btn btn-danger">Enroller Cancel</button>
                                        </li>
                                        <li>
                                            <button v-if="$store.getters.isEnroller && !ticket.complete" @click="showEnrCancel()" type="button" class="btn btn-danger">Terminate Ticket</button>
                                        </li>
                                    </ul> -->
                                    <div class="row">
                                        <div class="col-6 mb-4">
                                            <button v-if="$store.getters.isEnroller && !ticket.complete" @click="showScheduler()" type="button" class="btn btn-primary">New Schedule</button><br>
                                        </div>
                                        <div class="col-6 mb-4">
                                            <button v-if="$store.getters.isEnroller && !ticket.complete && ticket.enroller_id" @click="showEnrCancel()" type="button" class="btn btn-warning">Enroller Cancel</button>
                                        </div>
                                        <div class="col-6 mb-4">
                                            <button v-if="$store.getters.isEnroller && !ticket.complete" @click="showTicketCancel()" type="button" class="btn btn-danger">Terminate Ticket</button>
                                        </div>
                                    </div>
                                    
                                    
                                    
                                    
                                </div>
                                <div class="col-6 justify-content-end" style="display:flex;justify-content:end">
                                    
                                    
                                    
                                    
                                    <div class="row justify-content-end">
                                        <div class="col-12 mb-4" v-if="$store.getters.isAdmin && $store.getters.isEnroller && !ticket.enroller_id" style="display:flex;justify-content:end;height: fit-content;">
                                            <button  @click="showSlotAssign()" type="button" class="btn btn-success px-6 py-3">Assign Schedule</button>
                                        </div>
                                        <div class="col-12 mb-4"  v-if="$store.getters.isEnroller && !ticket.enroller_id" @click="showEnrAccept()" style="display:flex;justify-content:end;height: fit-content;">
                                            <button type="button" class="btn btn-success px-6 py-3">Accept Schedule</button>
                                        </div>
                                        <div class="col-12 mb-4" v-if="$store.getters.isEnroller && !ticket.complete && ticket.enroller_id && ticket.enrolled == false" style="display:flex;justify-content:end;height: fit-content;">
                                            <button  @click="showTSignature()" type="button" class="btn btn-success px-6 py-3">Pend Signature</button>
                                        </div>
                                        <div class="col-12 mb-4" v-if="$store.getters.isEnroller && !ticket.complete && ticket.enroller_id && ticket.enrolled" style="display:flex;justify-content:end;height: fit-content;">
                                            <button  @click="showTComplete()" type="button" class="btn btn-success px-6 py-3">Complete</button>
                                        </div>
                                        
                                    </div>

                                </div>
                            </div>
                            
                            
                        </div>
                    </div>
                    <div style="position:absolute;top:0px;margin-top:-14px;left:10px;">
                        <div class="col-auto">
                            <button @click="$router.go(-1)" type="button" class="btn btn-xs btn-primary btn-icon rounded-pill">
                                <span class="btn-inner--icon">
                                    <i class="uil uil-arrow-left"></i>
                                </span> 
                                <span class="btn-inner--text">Back</span>
                            </button>
                        </div>
                    </div>
                </div>
                
            </div>
            <div v-else style="height:145px;width:100%;display:flex;align-items:center;justify-content:center;">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </div>
    <enroller-accept2 
            ref="enrAccept"
            
        />
    <slot-assign 
        ref="slotAssign"
        
    />
    <enroller-cancel 
            ref="enrCancel"
            
        />
    <ticket-cancel 
            ref="ticketCancel"
            
        />
    <ticket-complete 
            ref="tComplete"
            
        />
    <ticket-signature 
        ref="tSignature"
    />
    <scheduler 
        ref="schedulerOpen"
    />
</div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import enrollerAccept2 from '../comps/EnrollerAccept2.vue';
import slotAssign from '../comps/SlotAssign.vue';
import enrollerCancel from '../comps/EnrollerCancel.vue';
import ticketCancel from '../comps/TicketCancel.vue';
import ticketComplete from '../comps/TicketComplete.vue';
import ticketSignature from '../comps/TicketSignature.vue';
import scheduler from '../comps/Scheduler.vue';
export default {
    components: {
        enrollerAccept2,
        slotAssign,
        enrollerCancel,
        ticketCancel,
        ticketSignature,
        ticketComplete,
        scheduler,
    },
    data() {
        return {
            ticket: '',
            loading: false,
            state: {
                AL: "Alabama",
                AZ: "Arizona",
                AR: "Arkansas",
                CA: "California",
                CO: "Colorado",
                CT: "Connecticut",
                DE: "Delaware",
                FL: "Florida",
                GA: "Georgia" ,    
                ID: "Idaho",
                IL: "Illinois",
                IN: "Indiana",
                IA: "Iowa",
                KS: "Kansas",
                KY: "Kentucky",
                LA: "Louisiana",
                ME: "Maine",
                MD: "Maryland",
                MA: "Massachusetts",
                MI: "Michigan",
                MN: "Minnesota",
                MS: "Mississippi",
                MO: "Missouri",
                MT: "Montana",
                NE: "Nebraska",
                NV: "Nevada",
                NH: "New Hampshire",
                NM: "New Mexico",
                NJ: "New Jersey",
                NC: "North Carolina",
                ND: "North Dakota",
                OH: "Ohio",
                OK: "Oklahoma",
                OR: "Oregon",
                PA: "Pennsylvania",
                RI: "Rhode Island",
                SC: "South Carolina",
                SD: "South Dakota",
                TN: "Tennessee",
                TX: "Texas",
                UT: "Utah",
                VT: "Vermont",
                VA: "Virginia",
                WA: "Washington",
                WV: "West Virginia",
                WI: "Wisconsin",
                WY: "Wyoming",
            },
            notes: '',
            showNoteUpdate: false,
            noteLoading: false,
            q: {
                policy_age: '',
                policy_amount: '',
                policy_dob: '',
                policy_gender: '',
                policy_quote: '',
                policy_ss: '',
                policy_startdate: '',
                policy_state: '',
                policy_term: '',

                first_name: '',
                last_name: '',
                dob: '',
                gender: '',
                email: '',
                phone: '',
                phone_confirm: '',
                address: '',
                address_no: '',
                city: '',
                state: '',
                zip: '',

                license_number: '',
                license_state: '',
                height_ft: '',
                height_in: '',
                weight: '',
                weight_ten: '',
                weight_loss: '',
                weight_reason: '',
                tobacco: '',
                med_one: '',
                med_two: '',
                med_three: '',
                med_four: '',
                med_five: '',
                med_six: '',
                med_seven: '',
                med_eight: '',
                med_nine: '',
                med_ten: '',
                med_eleven: '',
                med_twelve: '',
                med_thirteen: '',
                med_fourteen: '',

                med_fifteen: '',
                med_sixteen: '',
                med_seventeen: '',
                med_eighteen: '',
                additional_one: '',
                additional_two: '',
                additional_three: '',
                owner_one: '',
                owner_two: '',
                owner_three: '',

                owner_address: '',
                owner_addressno: '',
                owner_city: '',
                owner_state: '',
                owner_zip: '',
                owner_phone: '',

                secondaddres_one: '',
                secondaddress_two: '',

                secondaddress_address: '',
                secondaddress_addressno: '',
                secondaddress_city: '',
                secondaddress_state: '',
                secondaddress_zip: '',
                secondaddress_phone: '',

                ben_one: '',
                ben_two: '',
                ben_three: '',
                ben_four: '',
                ben_five: '',
                ben_six: '',

                addben_one: '',
                addben_two: '',
                addben_three: '',
                addben_four: '',
                addben_five: '',
                addben_six: '',

                contben_one: '',
                contben_two: '',
                contben_three: '',
                contben_four: '',
                contben_five: '',
                contben_six: '',

                exist_one: '',
                exist_two: '',
                exist_three: '',
                exist_four: '',
                exist_five: '',

                exist_six: '',
                thirdparty: '',

                payment_one: '',
                payment_two: '',

                payment_three: '',
                payment_four: '',
                payment_five: '',
                payment_six: '',
                payment_seven: '',
                payment_eight: '',

                payment_nine: '',
                payment_ten: '',
                payment_eleven: '',
                payment_expday: '',
                payment_expyear: '',
                payment_twelve: '',
                comments: '',

                timezone: '',
                schedule_hourid: '',
            },
        }
    },
    created() {
        window.scrollTo(0,0);
       
       
      
       
       
    },
    computed: {
        
    },
    methods: {
        fetchTicket() {
            this.loading = true;
            console.log(this.$route.params.id);
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.get('/api/fetchsingleticket/'+this.$route.params.id).then(resp => {
                this.ticket = resp.data.t;
                this.loading = false;
            }).catch(err => { this.loading = false });
        },
        formatCurrency(amount) {
            amount = (amount / 100);
            return amount.toLocaleString('en', { style: 'currency', currency: 'usd', minimumFractionDigits: 2 });
        },
        formatCurrency2(amount) {
            amount = (amount);
            return amount.toLocaleString('en', { style: 'currency', currency: 'usd', minimumFractionDigits: 0 });
        },
        printDate(i) {
            return moment.utc(i).local().format('dddd MMM D,   h:mm a');
        },
        printDate2(i) {
            return moment.utc(i).local().format('dddd MMMM DD');
        },
        printDateTimezone(i, t) {
            return moment.utc(i).tz(t).format('dddd MMM D,   h:mm a');
        },
        removeSchedule() {
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.post("/api/cancelslot", {id: this.ticket.id}).then(resp => {
                console.log(resp.data);
                this.ticket.enroller_id = null;
            }).then(resp2 => {
                this.$toasted.success('Cancelled');
            });
        },
        showEnrAccept() {          
                $(this.$refs.enrAccept.$el).modal('show');
        },
        showSlotAssign() {          
                $(this.$refs.slotAssign.$el).modal('show');
        },
        showEnrCancel() {          
                $(this.$refs.enrCancel.$el).modal('show');
        },
        showTicketCancel() {          
                $(this.$refs.ticketCancel.$el).modal('show');
        },
        showTComplete() { 
                if(this.ticket.note != '' && this.ticket.note != null) {
                    $(this.$refs.tComplete.$el).modal('show');
                } else {
                    this.$toasted.error('Notes are Missing');
                }      
                
        },
        showTSignature() {
            $(this.$refs.tSignature.$el).modal('show');
        },
        showPendingSignature() {
            console.log('pending');
        },
        showScheduler() {
            $(this.$refs.schedulerOpen.$el).modal('show');
        },
        showNoteButton() {
            this.showNoteUpdate = true;
        },
        updateNotes() {
            this.noteLoading = true;
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.post('/api/updatenotes', this.ticket).then(resp=> {
                console.log(resp);
                this.noteLoading = false;
                this.showNoteUpdate = false;
            }).then(resp2 => {
                this.$toasted.success('Updated');
            });
        }
        
    }
}
</script>
<style>
.cancelOptions {
    list-style-type: none;
    padding-left:0px;
}
.cancelOptions li {
    margin-bottom: 1rem;
}

.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 8px;
  padding: 5px 15px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 15px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, .1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}
</style>