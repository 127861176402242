<template>
<div class="card">
            <div class="card-header">
                <h3 class="h3 mb-0">Fidelity Life - Term Rapid Decision Senior Life</h3>
                <p class="text-sm mb-1">
                    Average to Below Average Health, No Life-Threatening Illnesses, Three Year Graded
                </p>
                <p class=" mb-4">
                    <button class="btn btn-xs btn-outline-info" @click="ShowRapidTermUnderwritting()">Underwritting Guide</button>
                </p>
                <div class="h1 text-center mb-0">
                    $<span class="price font-weight-bolder">{{ Number($parent.fidelitytermForm.quote).toFixed(2) }}

                    </span>
                    
                </div>  
                <div>
                    <span class="h6 text-center" style="margin: 0 auto;">Monthly Price</span>
                </div>
                
            </div>
            <div class="card-body">
                <ul class="list-unstyled text-sm mt-2 mb-2">
                
                    <div class="form-group">
                        <div class="row justify-content-between">
                            <div class="col-auto"><span class="h6 text-center">Coverage Amount</span></div>
                            
                        </div>
                        
                        <div class="h4 text-center mb-0 mt-3">
                            $<span class="price font-weight-bolder">{{ $parent.fidelitytermForm.coverageValue }},000</span>
                        </div>
                        
                    </div>
                    <input type="range" class="form-range" :min="$parent.fidelitytermForm.minCoverageRange" :max="$parent.fidelitytermForm.maxCoverageRange" step="5" :value="$parent.fidelitytermForm.coverageValue" @input="showValue" @change="showValueChanges" id="customRange3" style="width:100%;">
                    
                    
                    <div class="form-group mt-2">
                        <div class="row justify-content-between">
                            <div class="col-auto"><span class="h6 text-center">Term Length</span></div>
                            <div class="col-auto" style="display:flex;justify-content:end;">
                                
                            </div>
                        </div>
                        
                        <div class="h4 text-center mb-0 mt-3">
                            <span class="price font-weight-bolder">{{ $parent.fidelitytermForm.coverageTerm }}</span> Years
                        </div>
                        
                    </div>
                    <input type="range" class="form-range" :min="$parent.fidelitytermForm.minTermRange" :max="$parent.fidelitytermForm.maxTermRange" :value="$parent.fidelitytermForm.coverageTerm" step="10" @input="showTerm" @change="showValueChanges" id="customRange3" style="width:100%;">
                    
                </ul>
                <div id="form-contact">
            
                    <div class="text-center">
                        
                        <button @click="addToShelf()" type="submit" class="btn btn-block btn-lg btn-primary mt-4 mb-2">
                            <span>Apply</span>
                            
                        </button>
                        
                    </div>
                </div>
            </div>
            <fidelity-rapidlife
                ref="rapidTermUnderwritting"
            />
        </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import fidelityRapidlife from '../UModals/FidelityRapidlife.vue';

export default {
    name: 'fidelityterm-component',
    components: {
        fidelityRapidlife
    },
    data() {
        return {
            loading: false,
            loadingEdit: false,
            todayYear: new Date().getFullYear() - 18,
            preLead: false,
            quoteSubmitted: false,
            policy: "",
            qValArray: [],
            nsFemale_1: [],
            ssFemale_1: [],
            nsMale_1: [],
            ssMale_1: [],
            form: {
                amount: '',
                state: '',
                ss: '',
                gender: '',
                dob: '',
                age: '',
                term: 20,
            },
            prospect: {
                name: '',
                email: '',
                phone: '',
            },
            pLoad: false,
            selectedDay: '',
            selectedMonth: '',
            selectedYear: '',
            tempShowCard: false,
            coverageValue: 75,
            coverageTerm: 15,
            minCoverageRange: 50,
            maxCoverageRange: 400,
            minTermRange: 10,
            maxTermRange: 30,
            emailValid: '',
            allValues: {},
        }
    },
    created() {
        console.log('inside fidelity term')
        console.log('Coverage Value: ' + this.$parent.coverageValue)
        
        this.$parent.fidelitytermForm.coverageValue = this.$store.state.policy.amount / 1000;

        this.$parent.fidelitytermForm.dob = this.$store.state.policy.dob;
        this.$parent.fidelitytermForm.amount = this.$parent.fidelitytermForm.coverageValue;
        this.$parent.fidelitytermForm.ss = this.$store.state.policy.ss;
        this.$parent.fidelitytermForm.gender = this.$store.state.policy.gender;


        this.fetchQuote();
    },
    mounted() {

    },
    methods: {
        showValue(event) {
          console.log(event.target.value);
          this.$parent.fidelitytermForm.coverageValue = event.target.value;
        },
        showTerm(event) {
            console.log(event.target.value);
            this.$parent.fidelitytermForm.coverageTerm = event.target.value;
        },
        fetchQuote() {
            /* this.$parent.aigForm.amount = this.$store.state.policy.amount; */
            /* this.$parent.fidelitytermForm.dob = this.$store.state.policy.dob; */


            this.$parent.fidelitytermForm.coverageTerm = this.$parent.fidelitytermForm.term;
            this.$parent.fidelitytermForm.dob = this.$store.state.policy.dob;
            this.$parent.fidelitytermForm.age = moment(this.$store.state.policy.dob, "MM/DD/YYYY").fromNow().split(" ")[0];

            let rate = '';
            axios.post('/api/fetchfidelitytermquote', this.$parent.fidelitytermForm).then(resp => {
               
                this.intepretQuote(resp.data.qval);
                this.allValues = resp.data.qval;
                this.$parent.fidelitytermForm.age = resp.data.qval.age;
            
            }).catch(err => {
                console.log('Fidelity term false');
                this.$parent.showFidelityterm = false;
            });
        },
        intepretQuote(i) {
            console.log('intepret fidelity term')
            console.log(i)

            let age = i.age;
            console.log('age - ' + age);            

            /* Set Term Max */
            if(age > 70 ) {
               this.$parent.showFidelityterm = false; 
            }
            if(age <= 70) {
                this.$parent.fidelitytermForm.maxTermRange = 10;
            }
            if(age <= 65) {
                this.$parent.fidelitytermForm.maxTermRange = 20;
            }
            if(age <= 60) {
                this.$parent.fidelitytermForm.maxTermRange = 30;
            }
            
            /* Set Term Value */

            if(this.$parent.fidelitytermForm.maxTermRange == 30) {
                this.$parent.fidelitytermForm.coverageTerm = 20;
            }
            if(this.$parent.fidelitytermForm.maxTermRange == 20) {
                this.$parent.fidelitytermForm.coverageTerm = 20;
            }
            if(this.$parent.fidelitytermForm.maxTermRange == 10) {
                this.$parent.fidelitytermForm.coverageTerm = 10;
            }
            
            
            /* Set Amount Max & maybe set coverage range here */
            if(this.$parent.fidelitytermForm.coverageTerm == 30) {
                if(age >= 50 && age <=55) {
                    this.$parent.fidelitytermForm.maxCoverageRange = 150;
                    
                }
                if(age >= 56 && age <= 60) {
                    this.$parent.fidelitytermForm.maxCoverageRange = 100;
                    if(this.$parent.fidelitytermForm.coverageValue > 100) {
                        this.$parent.fidelitytermForm.coverageValue = 100;
                    }
                }
            }
            if(this.$parent.fidelitytermForm.coverageTerm == 20) {
                if(age >= 50 && age <=55) {
                    this.$parent.fidelitytermForm.maxCoverageRange = 150;
                    
                }
                if(age >= 56 && age <= 65) {
                    this.$parent.fidelitytermForm.maxCoverageRange = 100;
                    if(this.$parent.fidelitytermForm.coverageValue > 100) {
                        this.$parent.fidelitytermForm.coverageValue = 100;
                    }
                }
            }
            if(this.$parent.fidelitytermForm.coverageTerm == 10) {
                if(age >= 50 && age <=55) {
                    this.$parent.fidelitytermForm.maxCoverageRange = 150;
                    
                }
                if(age >= 56 && age <= 65) {
                    this.$parent.fidelitytermForm.maxCoverageRange = 100;
                    if(this.$parent.fidelitytermForm.coverageValue > 100) {
                        this.$parent.fidelitytermForm.coverageValue = 100;
                    }
                }
                if(age >= 66 && age <=70) {
                    this.$parent.fidelitytermForm.maxCoverageRange = 50;
                    if(this.$parent.fidelitytermForm.coverageValue > 50) {
                        this.$parent.fidelitytermForm.coverageValue = 50;
                    }
                }
            }

            

            /* Calculate Quote */
            let rate = '';
            let quote = '';

            if(this.$store.state.policy.gender == 'Male') {

                if(this.$parent.fidelitytermForm.coverageTerm == 10) {
                   rate = i.ns_male_10;
                }
                if(this.$parent.fidelitytermForm.coverageTerm == 20) {
                   rate = i.ns_male_20;
                }
                if(this.$parent.fidelitytermForm.coverageTerm == 30) {
                   rate = i.ns_male_30;
                }
            }
            if(this.$store.state.policy.gender == 'Female') {
                if(this.$parent.fidelitytermForm.coverageTerm == 10) {
                   rate = i.ns_female_10;
                }
                if(this.$parent.fidelitytermForm.coverageTerm == 20) {
                   rate = i.ns_female_20;
                }
                if(this.$parent.fidelitytermForm.coverageTerm == 30) {
                   rate = i.ns_female_30;
                }
            }
            if(this.$parent.fidelitytermForm.coverageValue > this.$parent.fidelitytermForm.maxCoverageRange) {
                this.$parent.fidelitytermForm.coverageValue = this.$parent.fidelitytermForm.maxCoverageRange;
            }
            
            quote = (((rate * (Number(this.$parent.fidelitytermForm.coverageValue))) + Number(i.premium)) * i.mode_eft);

            this.$parent.fidelitytermForm.quote = quote;

            console.log('Fidelity term rate: ' + rate);
        },
        secondInterpretQuote(i) {
            let nsFemale_1 = [];
            let nsFemale_2 = [];
            let nsFemale_3 = [];
            let nsFemale_4 = [];
            let ssFemale_1 = [];
            let ssFemale_2 = [];
            let ssFemale_3 = [];
            let ssFemale_4 = [];

            let nsMale_1 = [];
            let nsMale_2 = [];
            let nsMale_3 = [];
            let nsMale_4 = [];
            let ssMale_1 = [];
            let ssMale_2 = [];
            let ssMale_3 = [];
            let ssMale_4 = [];

            let funcArray = [];
            let termsArray = [];

                this.nsFemale.push({name: 10, value: i.qval.ns_female_10});
                this.nsFemale.push({name: 20, value: i.qval.ns_female_20});
                this.nsFemale.push({name: 25, value: i.qval.ns_female_30});

                

                this.ssFemale.push({name: 10, value: i.qval.ss_female_10});
                this.ssFemale.push({name: 20, value: i.qval.ss_female_20});
                this.ssFemale.push({name: 25, value: i.qval.ss_female_30});
                
               
                
                this.nsMale.push({name: 10, value: i.qval.ns_male_10});
                this.nsMale.push({name: 20, value: i.qval.ns_male_20});
                this.nsMale.push({name: 25, value: i.qval.ns_male_30});
                
                

                this.ssMale.push({name: 10, value: i.qval.ss_male_10});
                this.ssMale.push({name: 20, value: i.qval.ss_male_20});
                this.ssMale.push({name: 25, value: i.qval.ss_male_30});

                
                
                this.$parent.fidelitytermForm.coverageValue = this.$store.state.policy.amount/1000;
                if(this.$parent.fidelitytermForm.coverageValue > 500) {
                    this.$parent.fidelitytermForm.coverageValue = 500;
                }

                if(this.$store.state.policy.gender == 'Female') {
                    if(this.$store.state.policy.ss == 'ns') {
                        if(this.$parent.instatermForm.coverageValue < 100) {
                            funcArray = this.nsFemale_1;
                        }
                        if(this.$parent.instatermForm.coverageValue >= 100 && this.$parent.instatermForm.coverageValue < 250) {
                            funcArray = this.nsFemale_2;
                        }
                        if(this.$parent.instatermForm.coverageValue >= 250 && this.$parent.instatermForm.coverageValue < 500) {
                            funcArray = this.nsFemale_3;
                        }
                        if(this.$parent.instatermForm.coverageValue == 500) {
                            funcArray = this.nsFemale_4;
                        }
                    
                    }
                    if(this.$store.state.policy.ss == 'ss') {
                        
                        if(this.$parent.instatermForm.coverageValue < 100) {
                            funcArray = this.ssFemale_1;
                        }
                        if(this.$parent.instatermForm.coverageValue >= 100 && this.$parent.instatermForm.coverageValue < 250) {
                            funcArray = this.ssFemale_2;
                        }
                        if(this.$parent.instatermForm.coverageValue >= 250 && this.$parent.instatermForm.coverageValue < 500) {
                            funcArray = this.ssFemale_3;
                        }
                        if(this.$parent.instatermForm.coverageValue == 500) {
                            funcArray = this.ssFemale_4;
                        }
                    }
                    
                }
                if(this.$store.state.policy.gender == 'Male') {
                    if(this.$store.state.policy.ss == 'ns') {
                       
                        if(this.$parent.instatermForm.coverageValue < 100) {
                            funcArray = this.nsMale_1;
                        }
                        if(this.$parent.instatermForm.coverageValue >= 100 && this.$parent.instatermForm.coverageValue < 250) {
                            funcArray = this.nsMale_2;
                        }
                        if(this.$parent.instatermForm.coverageValue >= 250 && this.$parent.instatermForm.coverageValue < 500) {
                            funcArray = this.nsMale_3;
                        }
                        if(this.$parent.instatermForm.coverageValue == 500) {
                            funcArray = this.nsMale_4;
                        }
                    }
                    if(this.$store.state.policy.ss == 'ss') {
                       
                        if(this.$parent.instatermForm.coverageValue < 100) {
                            funcArray = this.ssMale_1;
                        }
                        if(this.$parent.instatermForm.coverageValue >= 100 && this.$parent.instatermForm.coverageValue < 250) {
                            funcArray = this.ssMale_2;
                        }
                        if(this.$parent.instatermForm.coverageValue >= 250 && this.$parent.instatermForm.coverageValue < 500) {
                            funcArray = this.ssMale_3;
                        }
                        if(this.$parent.instatermForm.coverageValue == 500) {
                            funcArray = this.ssMale_4;
                        }
                    }
                    
                    
                }
                termsArray = funcArray.filter(item => item.value !== null);
               
                this.qValArray = termsArray;
                console.log('insta Min term: ' + Number(termsArray[0].name) + ' insta Max term: ' + Number(termsArray[termsArray.length - 1].name));

                console.log('insta terms array');
                console.log(termsArray);
                console.log('insta q val array');
                console.log(this.qValArray);

                console.log('inside insta amount -> ' + this.$store.state.policy.amount)
                
                this.$parent.instatermForm.minTermRange = Number(termsArray[0].name);
                this.$parent.instatermForm.maxTermRange = Number(termsArray[termsArray.length - 1].name);

                if(this.$parent.instatermForm.minTermRange < this.$parent.instatermForm.coverageTerm) {
                    this.$parent.instatermForm.coverageTerm = this.$parent.instatermForm.minTermRange 
                }
                if(this.$parent.instatermForm.coverageTerm > this.$parent.instatermForm.maxTermRange) {
                    this.$parent.instatermForm.coverageTerm = this.$parent.instatermForm.maxCoverageRange 
                }   
                
                /* if(this.$store.state.policy.age >= 18 && this.$store.state.policy.age <= 55) {
                    this.$parent.instaterm.maxCoverageRange = 400;
                }
                if(this.$store.state.policy.age >= 56) {
                    this.$parent.foresterstermForm.maxCoverageRange = 150;
                } */
                
                var term = termsArray.filter(item => item.name == this.$parent.instatermForm.coverageTerm)[0].value;
                if((term == 10 || term == 15 || 20) && this.$store.state.policy.age >= 18 && this.$store.state.policy.age <= 55) {
                    this.$parent.instatermForm.maxCoverageRange = 500;
                }
                if((term == 10 || term == 15) && this.$store.state.policy.age >= 65) {
                    this.$parent.instatermForm.maxCoverageRange = 250;
                }
                if(term == 20 && this.$store.state.policy.age >= 56) {
                    this.$parent.instatermForm.maxCoverageRange = 250;
                }
                if(term == 25 && this.$store.state.policy.age >= 51) {
                    this.$parent.instatermForm.maxCoverageRange = 250;
                }
                if(this.$parent.instatermForm.coverageValue > this.$parent.instatermForm.maxCoverageRange) {
                    this.$parent.instatermForm.coverageValue = this.$parent.instatermForm.maxCoverageRange;
                }

                console.log('insta term');
                console.log(term);
                if(term != null) {
                    this.$parent.instatermForm.quote = (((this.$parent.instatermForm.coverageValue * (term)) + 65) *  0.087).toFixed(2);
                }
                
                /* console.log(nsFemale);
                console.log(ssFemale);
                console.log(nsMale);
                console.log(ssMale); */
        },
        showValueChanges(e) {
             /* Calculate Quote */
                let rate = '';
                let quote = '';
                let i = this.allValues;

                if(this.$store.state.policy.gender == 'Male') {
                    if(this.$parent.fidelitytermForm.coverageTerm == 10) {
                    rate = i.ns_male_10;
                    }
                    if(this.$parent.fidelitytermForm.coverageTerm == 20) {
                    rate = i.ns_male_20;
                    }
                    if(this.$parent.fidelitytermForm.coverageTerm == 30) {
                    rate = i.ns_male_30;
                    }
                }
                if(this.$store.state.policy.gender == 'Female') {
                    if(this.$parent.fidelitytermForm.coverageTerm == 10) {
                    rate = i.ns_female_10;
                    }
                    if(this.$parent.fidelitytermForm.coverageTerm == 20) {
                    rate = i.ns_female_20;
                    }
                    if(this.$parent.fidelitytermForm.coverageTerm == 30) {
                    rate = i.ns_female_30;
                    }
                }
                
                quote = (((rate * (Number(this.$parent.fidelitytermForm.coverageValue))) + Number(i.premium)) * i.mode_eft);

                this.$parent.fidelitytermForm.quote = quote;

                console.log('Fidelity term rate: ' + rate);
        },
        submitQuote() {
            this.loading = true;

            console.log('start forest contraption');
            console.log(this.$parent.forestForm)
            this.$parent.coverageTerm = this.$parent.forestForm.term;
            this.$parent.forestForm.dob = this.$store.state.policy.dob;
            this.$parent.forestForm.age = moment(this.$store.state.policy.dob, "MM/DD/YYYY").fromNow().split(" ")[0];

            // Logic for quoter 
            if(this.$store.state.policy.gender == "Male" && this.$store.state.policy.ss == "ns") {
                console.log("inside male ns")
                if(this.$parent.forestForm.term == 10 && (this.$parent.forestForm.age == 18 || this.$parent.forestForm.age == 19 || this.$parent.forestForm.age == 20 || this.$parent.forestForm.age == 21 || this.forestForm.age == 22)) {
                    console.log('ages under 22');
                    this.$parent.minTermRange = 15;
                } 
                if((this.$parent.forestForm.age == 18 || this.$parent.forestForm.age == 19 || this.$parent.forestForm.age == 20 || this.$parent.forestForm.age == 21 || this.$parent.forestForm.age == 22)) {
                    console.log('ages under 22');
                    this.$parent.minTermRange = 15;
                }
                if(this.$parent.forestForm.term == 20 && this.$parent.forestForm.age >= 71) {
                    console.log('client is older than 65 for 20 year policy 10');
                    this.$parent.forestForm.term = 10;
                    this.$parent.maxTermRange = 10;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.$parent.forestForm.age > 71) {
                    console.log('client is older than 71'); 
                    this.$parent.maxTermRange = 10;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                }
                if(this.$parent.forestForm.term > 15 && this.$parent.forestForm.age > 65 && this.$parent.forestForm.age < 71) {
                    console.log('client is older than 65 for 20 year policy 15');
                    this.$parent.forestForm.term = parseInt(15);
                    this.$parent.maxTermRange = 15;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.$parent.forestForm.age > 65 && this.$parent.forestForm.age < 71) {
                    console.log('client is older than 65 within index ' + this.$parent.forestForm.term); 
                    this.$parent.maxTermRange = 15;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                }

                if(this.$parent.forestForm.term > 20 && this.$parent.forestForm.age > 55 && this.$parent.forestForm.age < 65) {
                    console.log('client is older than 55 for 25 year policy');
                    this.$parent.forestForm.term = 20;
                    this.$parent.maxTermRange = 20;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.$parent.forestForm.age > 55 && this.$parent.forestForm.age < 65) {
                    console.log('client is older than 55'); 
                    this.$parent.maxTermRange = 20;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                }

                if(this.$parent.forestForm.term > 25 && this.$parent.forestForm.age > 50 && this.$parent.forestForm.age < 55) {
                    console.log('client is older than 50 for 20 year policy');
                    this.$parent.forestForm.term = 25;
                    this.$parent.maxTermRange = 25;
                    this.reSubmit();
                }
                if(this.$parent.forestForm.age > 50 && this.$parent.forestForm.age < 55) {
                    console.log('client is older than 50'); 
                    this.$parent.maxTermRange = 25;
                }
            } else if(this.$parent.forestForm.gender == "Male" && this.$parent.forestForm.ss == "ss") {
                console.log("inside male ss");
                if(this.$parent.forestForm.term == 20 && this.$parent.forestForm.age >= 61) {
                    console.log('client is older than 61 for 20 year policy 10');
                    this.$parent.forestForm.term = 15;
                    this.$parent.maxTermRange = 15;
                    this.reSubmit();
                }
                if(this.$parent.forestForm.age > 71) {
                    console.log('client is older than 71 ss'); 
                    this.$parent.maxTermRange = 10;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                }
                if(this.$parent.forestForm.term > 15 && this.$parent.forestForm.age > 60 && this.$parent.forestForm.age < 71) {
                    console.log('client is older than 60 ss');
                    this.$parent.forestForm.term = parseInt(15);
                    this.$parent.maxTermRange = 15;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.$parent.forestForm.age > 60 && this.$parent.forestForm.age < 71) {
                    console.log('client is older than 60 ss'); 
                    this.$parent.maxTermRange = 15;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                }

                if(this.$parent.forestForm.term > 20 && this.$parent.forestForm.age > 50 && this.$parent.forestForm.age < 60) {
                    console.log('client is older than 55 ss');
                    this.$parent.forestForm.term = 20;
                    this.$parent.maxTermRange = 20;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.$parent.forestForm.age > 50 && this.$parent.forestForm.age < 60) {
                    console.log('client is older than 50'); 
                    this.$parent.maxTermRange = 20;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                }

                if(this.$parent.forestForm.term > 25 && this.$parent.forestForm.age > 45 && this.$parent.forestForm.age < 50) {
                    console.log('client is older than 45');
                    this.$parent.forestForm.term = 25;
                    this.$parent.maxTermRange = 25;
                    this.reSubmit();
                }
                if(this.$parent.forestForm.age > 45 && this.$parent.forestForm.age < 50) {
                    console.log('client is older than 45'); 
                    this.$parent.maxTermRange = 25;
                }
            } else if(this.$parent.forestForm.gender == "Female" && this.$parent.forestForm.ss == "ns") {
                console.log("inside female ns");
                if(this.$parent.forestForm.term == 10 && (this.$parent.forestForm.age == 18 || this.$parent.forestForm.age == 19 || this.$parent.forestForm.age == 20 || this.$parent.forestForm.age == 21 || this.$parent.forestForm.age == 22 || this.$parent.forestForm.age == 23)) {
                    console.log('ages under 23');
                    this.$parent.minTermRange = 15;
                    this.$parent.forestForm.term = 15;
                    
                    this.reSubmit();
                } 
                if((this.$parent.forestForm.age == 18 || this.$parent.forestForm.age == 19 || this.$parent.forestForm.age == 20 || this.$parent.forestForm.age == 21 || this.$parent.forestForm.age == 22 || this.$parent.forestForm.age == 23)) {
                    console.log('ages under 23');
                    this.$parent.minTermRange = 15;
                }
                if(this.$parent.forestForm.term >= 15 && this.$parent.forestForm.age >= 71) {
                    console.log('client is older than 72');
                    this.$parent.forestForm.term = 10;
                    this.$parent.maxTermRange = 10;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.$parent.forestForm.age > 71) {
                    console.log('client is older than 71'); 
                    this.$parent.maxTermRange = 10;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                }
                if(this.$parent.forestForm.term > 15 && this.$parent.forestForm.age > 65 && this.$parent.forestForm.age < 71) {
                    console.log('client is older than 65 for 20 year policy 15');
                    this.$parent.forestForm.term = parseInt(15);
                    this.$parent.maxTermRange = 15;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.$parent.forestForm.age > 65 && this.$parent.forestForm.age < 71) {
                    console.log('client is older than 65 within index ' + this.$parent.forestForm.term); 
                    this.$parent.maxTermRange = 15;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                }

                if(this.$parent.forestForm.term > 20 && this.$parent.forestForm.age > 55 && this.$parent.forestForm.age < 65) {
                    console.log('client is older than 55 for 25 year policy');
                    this.$parent.forestForm.term = 20;
                    this.$parent.maxTermRange = 20;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.$parent.forestForm.age > 55 && this.$parent.forestForm.age < 65) {
                    console.log('client is older than 55'); 
                    this.$parent.maxTermRange = 20;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                }

                if(this.$parent.forestForm.term > 25 && this.$parent.forestForm.age > 50 && this.$parent.forestForm.age < 55) {
                    console.log('client is older than 50 for 20 year policy');
                    this.$parent.forestForm.term = 25;
                    this.$parent.maxTermRange = 25;
                    this.reSubmit();
                }
                if(this.$parent.forestForm.age > 50 && this.$parent.forestForm.age < 55) {
                    console.log('client is older than 50'); 
                    this.$parent.maxTermRange = 25;
                }
            } else if(this.$parent.forestForm.gender == "Female" && this.$parent.forestForm.ss == "ss") {
                console.log("inside female ss");
                if(this.$parent.forestForm.term == 10 && (this.$parent.forestForm.age == 18 || this.$parent.forestForm.age == 19 || this.$parent.forestForm.age == 20 || this.$parent.forestForm.age == 21 || this.$parent.forestForm.age == 22)) {
                    console.log('ages under 23');
                    this.$parent.minTermRange = 15;
                    this.$parent.forestForm.term = 15;
                    
                    this.reSubmit();
                } 
                if((this.$parent.forestForm.age == 18 || this.$parent.forestForm.age == 19 || this.$parent.forestForm.age == 20 || this.$parent.forestForm.age == 21 || this.$parent.forestForm.age == 22)) {
                    console.log('ages under 23');
                    this.$parent.minTermRange = 15;
                }
                
                if(this.$parent.forestForm.age > 71) {
                    console.log('client is older than 71 ss'); 
                    this.$parent.maxTermRange = 10;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                }
                if(this.$parent.forestForm.term > 15 && this.$parent.forestForm.age > 60 && this.$parent.forestForm.age < 71) {
                    console.log('client is older than 60 ss');
                    this.$parent.forestForm.term = parseInt(15);
                    this.$parent.maxTermRange = 15;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.$parent.forestForm.age > 60 && this.$parent.forestForm.age < 71) {
                    console.log('client is older than 60 ss'); 
                    this.$parent.maxTermRange = 15;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                }

                if(this.$parent.forestForm.term > 20 && this.$parent.forestForm.age > 55 && this.$parent.forestForm.age < 60) {
                    console.log('client is older than 55 ss');
                    this.$parent.forestForm.term = 20;
                    this.$parent.maxTermRange = 20;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.$parent.forestForm.age > 55 && this.$parent.forestForm.age < 60) {
                    console.log('client is older than 55'); 
                    this.$parent.maxTermRange = 20;
                    this.$paren.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                }

                if(this.$parent.forestForm.term > 25 && this.$parent.forestForm.age > 50 && this.$parent.forestForm.age < 55) {
                    console.log('client is older than 55');
                    this.$parent.forestForm.term = 25;
                    this.$parent.maxTermRange = 25;
                    this.reSubmit();
                }
                if(this.$parent.forestForm.age > 50 && this.$parent.forestForm.age < 55) {
                    console.log('client is older than 45'); 
                    this.$parent.maxTermRange = 25;
                }
            }

            console.log(this.$parent.forestForm);

            axios.post('/api/fetchforestquote', this.$parent.forestForm).then(resp => {
                console.log(resp.data);
                this.$parent.forestForm.quote = resp.data.quote;
                this.$parent.forestForm.startdate = resp.data.policystart;
            });


        },
        reSubmit() {
            console.log("resubmitting ");
            console.log(this.$parent.forestForm);
            /* this.submitQuoteAlt(); */
        },
        submitQuoteAlt() {
            this.$parent.coverageTerm = this.$parent.forestForm.term;
            this.$parent.coverageValue = this.$parent.forestForm.amount;
            this.$parent.forestForm.dob = this.$store.state.policy.dob;

            axios.post('/api/fetchaltforestquote', this.$parent.forestForm).then(resp => {
                this.$parent.forestForm.quote = resp.data.quote;
                this.$parent.forestForm.startdate = resp.data.policystart;
            })
        },
        showValueChange(e) {
            this.$parent.forestForm.amount = this.$parent.coverageValue;
            this.$parent.forestForm.term = this.$parent.coverageTerm;

            axios.post('/api/fetchforestquote', this.$parent.forestForm).then(resp => {
                this.$parent.forestForm.quote = resp.data.quote;
                this.$parent.forestForm.startdate = resp.data.policystart;
            });
        },
        addToShelf() {
            this.$parent.fidelitytermForm.id = 'Fidelity Life - Term Rapid Decision Senior Life';
            this.$store.state.shelf = [];
            this.$parent.fidelitytermForm.amount = this.$parent.fidelitytermForm.coverageValue;
            this.$parent.fidelitytermForm.term = this.$parent.fidelitytermForm.coverageTerm;
            this.$store.commit('addToShelf', this.$parent.fidelitytermForm);
            
            this.$router.push({name: 'q.contactinfo'})
        },
        ShowRapidTermUnderwritting() {
           $(this.$refs.rapidTermUnderwritting.$el).modal('show'); 
        }
    }
}
</script>