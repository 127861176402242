<template>
<div>
    <section class="slice pt-9 pb-7 bg-light">
        <img src="/img/about.jfif" alt="Image" class="img-as-bg blend-mode--multiply">
        <span class="mask bg-gradient-dark opacity-2"></span>
        <div data-offset-top="#navbar-main">
            <div class="container d-flex align-items-center text-center text-lg-left">
                <div class="col px-0">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 text-center">
                            <h2 class="h1 text-white mt-6 mb-2">About Visionary</h2>
                            <h4 class="font-weight-light text-white opacity-9">Envision what your financial future looks like and let us help you plan today for a better tomorrow.</h4>
                            <h3 class="font-weight-light text-white opacity-8"></h3>
                            <div class="my-3">
                                
                            </div>
                            <div class="row mt-5">
                                
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="slice slice-lg">
        <div class="container">
            <div class="row justify-content-center mb-5">
                
                <div class="col-lg-8">
                    <p class="">
                        Visionary Inc's Executive staff has over 50 years of experience in the Life Insurance industry.  Visionary Inc. is a National Marketing Company that brings decades of expertise and hybrid products to Independent agents in every state.  Years of Life Insurance experience integrating state-of-the-art technology allow independent agents the ability to write more business, accurately and faster; spending more time procuring new customers.  Using our technology, independent agents eliminate back-office time-wasting issues
                    </p>
                    
                </div>
            </div>
           
            <div class="row justify-content-center mb-5">
              <div class="col-lg-8">
                <div class="text-center mt-4">
                  <router-link :to="{name:'Contact'}" class="btn btn-primary btn-lg btn-icon px-6">
                    <span class="btn-inner--text" style="font-size:1.15rem;">Have a question in mind?</span>
                    <span class="btn-inner--icon"> <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                    </span>
                  </router-link>
                </div>
              </div>
              
            </div>
            
            
           
       
    </div>
    </section>
    <section class="slice py-5 pb-lg-7">
    <div class="container">
        
        <div class="row row-grid justify-content-between align-items-center mt-4">
            <div class="col-12 col-md-5 col-lg-6">
                <figure>
                    <img alt="Image placeholder" src="/img/jess.jpg" class="card-img shadow-lg position-relative zindex-100">
                </figure>
                <div class="w-75 position-absolute bottom-n5 left-n5 rotate-180">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 361.1 384.8" style="enable-background:new 0 0 361.1 384.8;" xml:space="preserve" class="injected-svg svg-inject fill-section-secondary">
	                <path d="M53.1,266.7C19.3,178-41,79.1,41.6,50.1S287.7-59.6,293.8,77.5c6.1,137.1,137.8,238,15.6,288.9 S86.8,355.4,53.1,266.7z"></path>
                    </svg>
                </div>
            </div>
            <div class="col-12 col-md-7 col-lg-5">
                <h1 class="h2 text-center text-md-left mb-4">Jessica Rose Lopez CEO</h1>
                <p class="lead text-center text-md-left text-muted">
                    You can rest easy knowing that you are working with the best of the best. CEO Jessica Rose Lopez, with 22+ years experience, brings a blend of modern-day life insurance and retirement solutions and traditional top insurance company relationships to the table. 
                    <br>Call 1-800-256-5307 x 402 or email  jessica@visionaryinsurance.life 
                </p>
                <div class="text-center text-md-left mt-5">
                    <router-link :to="{name: 'Contact'}" class="btn btn-primary btn-icon">
                        <span class="btn-inner--text">Contact us</span>
                        <span class="btn-inner--icon"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline>
                            </svg>
                        </span>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="row row-grid justify-content-between align-items-center mt-6">
            <div class="col-12 col-md-5 col-lg-6">
                <figure>
                    <img alt="Image placeholder" src="/img/joe.jpg" class="card-img shadow-lg position-relative zindex-100">
                </figure>
                <div class="w-75 position-absolute bottom-n5 left-n5 rotate-180">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 361.1 384.8" style="enable-background:new 0 0 361.1 384.8;" xml:space="preserve" class="injected-svg svg-inject fill-section-secondary">
	                <path d="M53.1,266.7C19.3,178-41,79.1,41.6,50.1S287.7-59.6,293.8,77.5c6.1,137.1,137.8,238,15.6,288.9 S86.8,355.4,53.1,266.7z"></path>
                    </svg>
                </div>
            </div>
            <div class="col-12 col-md-7 col-lg-5">
                <h1 class="h2 text-center text-md-left mb-4">Joseph M Racich CMO </h1>
                <p class="lead text-center text-md-left text-muted">
                    Joseph M Racich; CMO of Visionary Inc. Prior Experience: Offered Life Insurance plans across the Kitchen Table starting in 1978. I have spent over 40 years focused on providing the right Life Insurance policy to families.  
                    <br>Call Joe at: 800-290-7226 x 403 or email  joe@visionaryinsurance.life

                </p>
                <div class="text-center text-md-left mt-5">
                    <router-link :to="{name: 'Contact'}" class="btn btn-primary btn-icon">
                        <span class="btn-inner--text">Contact us</span>
                        <span class="btn-inner--icon"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline>
                            </svg>
                        </span>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="row row-grid justify-content-between align-items-center mt-6">
            <div class="col-12 col-md-5 col-lg-6">
                <figure>
                    <img alt="Image placeholder" src="/img/fred.PNG" class="card-img shadow-lg position-relative zindex-100">
                </figure>
                <div class="w-75 position-absolute bottom-n5 left-n5 rotate-180">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 361.1 384.8" style="enable-background:new 0 0 361.1 384.8;" xml:space="preserve" class="injected-svg svg-inject fill-section-secondary">
	                <path d="M53.1,266.7C19.3,178-41,79.1,41.6,50.1S287.7-59.6,293.8,77.5c6.1,137.1,137.8,238,15.6,288.9 S86.8,355.4,53.1,266.7z"></path>
                    </svg>
                </div>
            </div>
            <div class="col-12 col-md-7 col-lg-5">
                <h1 class="h2 text-center text-md-left mb-4">Fred Tiberi NSD </h1>
                <p class="lead text-center text-md-left text-muted">
                    Fred has worked with thousands of clients over the years providing the right plan for his customers.  He has over 40 years of experience and his focus is on customer first.  
                    <br>Call Fred at:  800-256-5307 x 429 or email  fred@visionaryinsurance.life

                </p>
                <div class="text-center text-md-left mt-5">
                    <router-link :to="{name: 'Contact'}" class="btn btn-primary btn-icon">
                        <span class="btn-inner--text">Contact us</span>
                        <span class="btn-inner--icon"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline>
                            </svg>
                        </span>
                    </router-link>
                </div>
            </div>
        </div>
        
    </div>
</section>
</div>
</template>
<script>
import flatpickr from "flatpickr";
import axios from 'axios';

export default {
  name: 'Home',
  components: {
    flatpickr,
  },
  data() {
      return {
          loading: false,
          todayYear: new Date().getFullYear() - 18,
          form: {
              amount: '',
              state: '',
              ss: '',
              gender: '',
              dob: '',
          },
          selectedDay: '',
          selectedMonth: '',
          selectedYear: '',
      }
  },
  mounted() {
    flatpickr('#datepicker', {
        dateFormat: "m/d/Y",
        maxDate: "07/28/2003",
    });
  },
  methods: {
      submitQuote() {
          console.log('submitting quote');
          console.log(this.form);
          
          let policy = {};
          if(this.form.amount != '' && this.form.state != '' && this.form.ss != '' && this.form.gender != '' && this.selectedDay != '' && this.selectedMonth != '' && this.selectedYear != '') {
              this.loading = true;
              this.$store.dispatch('clearShelf');
              this.$store.state.policySpouse = '';
                this.$store.state.rider1 = '';
              this.form.dob = this.selectedMonth + '/' + this.selectedDay + '/' + this.selectedYear;
              axios.post("/api/fetchquote", this.form).then(resp => {
                console.log(resp.data.quote);
                console.log(resp);
                policy.quote = parseInt(resp.data.quote) + 400;
                policy.ss = this.form.ss;
                policy.amount = this.form.amount;
                policy.startdate = resp.data.policystart;
                policy.age = resp.data.diff;
                policy.dob = this.form.dob;
                policy.gender = this.form.gender;
                policy.state = this.form.state;
                this.$store.commit('addPolicy', policy);
            }). then(resp2 => {
                this.loading = false;
                this.$router.push({name: 'Quote'})
            });
          } else if(this.form.amount == '') {
              this.$toasted.error('Select Amount');
          } else if(this.form.state == '') {
              this.$toasted.error('Select State');
          } else if(this.form.ss == '') {
              this.$toasted.error('Select Smoking Status');
          } else if(this.form.gender == '') {
              this.$toasted.error('Select Gender');
          } else if(this.selectedDay == '') {
              this.$toasted.error('Select Day');
          } else if(this.selectedMonth == '') {
              this.$toasted.error('Select Month');
          } else if(this.selectedYear == '') {
              this.$toasted.error('Select Year');
          }
          
      },
      amountChange(event) {
          if(event.target.value !== "Amount") {
              this.form.amount = event.target.value;
              console.log('succesfully changed');
          } else {
              console.log("please select from the dropdown");
          }
      },
      stateChange(event) {
          if(event.target.value !== "State") {
              this.form.state = event.target.value;
              console.log('succesfully changed states');
          } else {
              console.log("please select from the dropdown");
          }
      },
      ssChange(event) {
          if(event.target.value !== "Smoking Status") {
              this.form.ss = event.target.value;
              console.log('succesfully selected status');
          } else {
              console.log("please select from the dropdown");
          }
      },
      genderChange(event) {
          if(event.target.value !== "Gender") {
              this.form.gender = event.target.value;
              console.log('succesfully selected gender');
          } else {
              console.log("please select from the dropdown");
          }
      },
      changeDay(event) {
          if(event.target.value !== 'Day') {
              this.selectedDay = event.target.value;
              console.log(event.target.value);
          }
      },
      changeMonth(event) {
          if(event.target.value !== 'Month') {
              this.selectedMonth = event.target.value;
              console.log(event.target.value);
          }
      },
      changeYear(event) {
          if(event.target.value !== 'Year') {
              this.selectedYear = event.target.value;
              console.log(event.target.value);
          }
      }
  },
}
</script>