<template>
    <div>
        <form-header></form-header>
        
        <section>
            <div class="container d-flex flex-column">
                <div class="row align-items-center justify-content-center min-vh-100">
                    <div class="col-md-7 col-lg-7 col-xl-6">
                        <div>
                            <div class="mb-5">
                                <div class="row" style="">
                                    <div class="col-auto align-items-center" style="display:flex;align-items:center;">
                                        <h3 class="h5 mb-0">Which describes you best?</h3>
                                    </div>
                                    
                                </div>
                                
                            
                            </div>
                            <span class="clearfix"></span>
                            <form>
                                <div class="form-group mb-5">
                                    <div class="row justify-content-end" style="">
                                    
                                    <div class="col">
                                        <button type="button" :class="[ buttonOne ? 'btn-dark' : 'btn-outline-info']" @click="toggleFormButton" value="I am Type I" class="btn btn-sm mx-2 my-2" style=";">
                                           I am Type I
                                        </button>
                                        <button type="button" :class="[ buttonTwo ? 'btn-dark' : 'btn-outline-info']" @click="toggleFormButton" value="I am Type II" class="btn btn-sm mx-4" style="">
                                           I am Type II
                                        </button>
                                        <button type="button" :class="[ buttonPre ? 'btn-dark' : 'btn-outline-info']" @click="toggleFormButton" value="I am Pre-Diabetic" class="btn btn-sm mx-2" style="">
                                            I am Pre-Diabetic
                                        </button>
                                    </div>
                                </div>
                                    
                                </div>
                                <div class="mt-4">
                                    <button type="button" class="btn btn-block btn-primary hover-continue" @click="submit()" >Continue</button>
                                </div>
                            </form>
                            <div class="mt-4 text-center"><small>Feel free to call our toll free number: </small> <a href="tel:+8002565307" class="small font-weight-bold">(800) 256-5307</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import formHeader from "./Header.vue";

export default {
    components: {
        formHeader,
    },
    data() {
        return {
            form: '',
            buttonOne: false,
            buttonTwo: false,
            buttonPre: false,
        }
    },
    created() {
        if(this.$store.state.directForm.type != '') {
          
            if(this.$store.state.directForm.type == "I am Type I") {
                this.buttonOne = true;
            }
            if(this.$store.state.directForm.type == "I am Type II") {
                this.buttonTwo = true;
            }
            if(this.$store.state.directForm.type == "I am Pre-Diabetic") {
                this.buttonPre = true;
            }
        }
    },
    methods: {
        submit() {
            if(this.buttonOne || this.buttonTwo || this.buttonPre) {
                console.log("yupp")
                this.$router.push({name: 'Insulin'})
            } else {
                this.$toasted.error('Select an option');
            }
            
        },
        toggleFormButton(e) {
           
            if(e.target.value == "I am Type I") {
                this.buttonOne = true;
                this.buttonTwo = false;
                this.buttonPre = false;
            }
            if(e.target.value == "I am Type II") {
                this.buttonOne = false;
                this.buttonTwo = true;
                this.buttonPre = false;
            }
            if(e.target.value == "I am Pre-Diabetic") {
                this.buttonOne = false;
                this.buttonTwo = false;
                this.buttonPre = true;
            }
            
            this.$store.state.directForm.type = e.target.value
            console.log(this.$store.state.directForm.type)
            
        }
    }
}
</script>
<style scoped>
 .hover-continue:hover {
     background-color: #171347;
 }
</style>