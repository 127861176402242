<template>
  <div>
    <header-light style="background-color: #fafbfe!important"></header-light>
    <section class="mt-5 bg-section-secondary">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="row align-items-center">
                        <div v-if="$route.name != 'admin.account'" class="col">
                            <span class="surtitle">Your account</span>
                            <h1 v-if="$route.name == 'admin.tickets'" class="h2 mb-0">Ticket Management</h1>
                            <h1 v-if="$route.name == 'admin.ticket.view'" class="h2 mb-0">Ticket View</h1>
                            <h1 v-if="$route.name == 'admin.scheduled'" class="h2 mb-0">Scheduled Tickets</h1>
                            <h1 v-if="$route.name == 'admin.agents'" class="h2 mb-0">Agent List</h1>
                            <h1 v-if="$route.name == 'admin.applicants'" class="h2 mb-0">Applicant Management</h1>
                            <h1 v-if="$route.name == 'admin.recruitors'" class="h2 mb-0">Recruiter Management</h1>
                            <h4 v-if="$route.name == 'admin.tickets.list'" class="h4 mb-0">{{$store.state.agentRouteName}}</h4>
                            <h1 v-if="$route.name == 'admin.recruitor.agents'" class="h2 mb-0">Recruiter Management Agents</h1>
                            <h1 v-if="$route.name == 'admin.enrollers'" class="h2 mb-0">Enroller Management</h1>
                            <h1 v-if="$route.name == 'admin.userroles'" class="h2 mb-0">User Management</h1>
                           
                        </div>
                        <div v-if="$route.name == 'admin.account'" class="col-auto">
                                
                                <avatar :username="returnName()" :size="90"  class=" avatar-xl rounded-circle"></avatar>
                        </div>
                        <div v-if="$route.name == 'admin.account'" class="col "><h2 class="mb-0"><b>{{(returnName().split(' '))[0]}}</b> {{(returnName().split(' '))[1]}}</h2>
                            
                            <span class="text-muted d-block" style="text-transform: capitalize">{{returnRole()}}</span>
                            <button v-if="!recruiterLink && recrSlug" @click="showRecruiterLink(recrSlug)" class="btn btn-sm btn-outline-info mt-2">Recruiter Link</button>
                            <button v-if="!recruiterLink && recrSlug" @click="showRegisterLink(recrSlug)" class="btn btn-sm btn-outline-info mt-2">Register All</button>
                            
                        </div>
                    </div>
                    <div class="row align-items-center mt-4">
                    <div class="col">
                        <ul class="nav nav-tabs overflow-x">
                            <li class="nav-item">
                                <router-link :to="{name: 'admin.tickets'}" :class="{'active': this.$route.name == 'admin.tickets'}" class="nav-link">Tickets</router-link>
                            </li>
                            <li v-if="$store.getters.isEnroller || $store.getters.isAdmin" class="nav-item">
                                <router-link :to="{name: 'admin.scheduled'}" :class="{'active': this.$route.name == 'admin.scheduled'}" class="nav-link">Pending</router-link>
                            </li>
                            <li v-if="$store.getters.isEnroller || this.$store.getters.isRecruiter || $store.getters.isAdmin" class="nav-item">
                                <router-link :to="{name: 'admin.agents'}" :class="{'active': this.$route.name == 'admin.agents'}" class="nav-link">Agents</router-link>
                            </li>
                            <li v-if="this.$store.getters.isAdmin" class="nav-item">
                                <router-link :to="{name: 'admin.recruitors'}" :class="{'active': this.$route.name == 'admin.recruitors'}" class="nav-link">Recruiters</router-link>
                            </li>
                            <li v-if="this.$store.getters.isRecruiter || $store.getters.isAdmin" class="nav-item">
                                <router-link :to="{name: 'admin.applicants'}" :class="{'active': this.$route.name == 'admin.applicants'}" class="nav-link">Applicants</router-link>
                            </li>
                            
                            
                            <li v-if="JSON.parse($store.state.token2).role.toLowerCase().includes('admin')" class="nav-item">
                                <router-link :to="{name: 'admin.enrollers'}" :class="{'active': this.$route.name == 'admin.enrollers'}" class="nav-link">Enrollers</router-link>
                            </li>
                            <li v-if="JSON.parse($store.state.token2).role.toLowerCase().includes('admin')" class="nav-item">
                                <router-link :to="{name: 'admin.userroles'}" :class="{'active': this.$route.name == 'admin.userroles'}" class="nav-link">Users</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{name: 'admin.messages'}" :class="{'active': this.$route.name == 'admin.messages'}" class="nav-link">Messages</router-link>
                            </li>
                            
                            <li class="nav-item ml-auto">
                                <router-link :to="{name: 'admin.account'}" :class="{'active': this.$route.name == 'admin.accounts'}" class="nav-link"><i class="uil uil-setting"></i> {{returnName()}}</router-link>
                            </li>
                        </ul>
                        
                    </div>
                </div>
                </div>
            </div>
        </div>
    </section>
    <router-view/>
    
  </div>  
</template>
<script>
import headerLight from '../comps/HeaderLight.vue'
import Avatar from 'vue-avatar'
export default {
    components: {
        headerLight,
        Avatar,
    },
    data() {
        return {
            recrSlug: '',
            recruiterLink: false,
        }
    },
    methods: {
        logStateOut() {
            this.$store.dispatch('destroyToken').then(resp => {
                console.log(resp.data);
                this.$router.push({name: 'Home'})
            }).catch(err => {
                this.$router.push({name: 'Home'});
            });
        },
        returnName() {
            return JSON.parse(localStorage.getItem('access_token2')).name
        },
        returnRole() {
            return JSON.parse(localStorage.getItem('access_token2')).role
        },
        showRegisterLink(i) {
            navigator.clipboard.writeText("https://visionarylifeinsurance.com/register/become-an-agent");
            this.$toasted.success('Copied');
        }
        
    }
}
</script>
<style scoped>
 .navlink-black {
     color:black;
 }

 .nav::-webkit-scrollbar {
  display: none;
}
.nav {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>