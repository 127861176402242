<template>
    <div class="row mt-6">
        
            <div v-if="loadingS" style="height:145px;width:100%;display:flex;align-items:center;justify-content:center;">
                <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="col-md-6 col-sm-12" v-for="(i, index) in messagesList" :key="i.id">
                <div class="card card-fluid" :style="styleBorder(i.viewed)">
                    
                <div class="card-body">
                    <div class="row pt-3" style="">
                        <div class="col-8 text-start pl-4">
                            <span class="h6"><i class="uil uil-user"></i> {{i.name}}</span><br>
                            <span class="h6 mb-0"><i class="uil uil-envelope"></i> {{i.email}}</span>
                        </div>
                        
                        <div class="col-4 text-center">
                            <span class="h6 mb-0"><i class="uil uil-phone"></i> {{i.phone}}</span>
                        </div>
                    </div>
                    <div class="row pt-4 justify-content-center">
                        <div class="col-10">
                            <p v-html="i.message" class="text mb-0"></p>
                        </div>
                        
                    </div>
                </div>
                <div class="card-footer py-2">
                    <div class="row align-items-center">
                        <div class="col-6">
                            <span class="text-muted text-sm">
                                {{printDate2(i.created_at) }}
                            </span>
                        </div>
                        <div class="col-6 text-right">
                            <button @click="showMViewed(i.id, index)" type="button" class="btn btn-xs btn-outline-primary btn-icon rounded-pill">
                                <span class="btn-inner--icon"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                </span> 
                                <span class="btn-inner--text">Viewed</span>
                            </button>
                            <button @click="showMRemove(i, index)" type="button" class="btn btn-xs btn-outline-danger rounded-pill">Remove</button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div v-observe-visibility="scrollBottom2"></div>
            <message-viewed
            ref="mViewed"
        />
        <message-remove
            ref="mRemove"
        />
    </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import InfiniteLoading from 'vue-infinite-loading';
import messageViewed from '../../comps/MessageViewed.vue';
import messageRemove from '../../comps/MessageRemove.vue';
export default {
    name: 'message-general',
    components: {
        InfiniteLoading,
        messageViewed, messageRemove
    },
    data() {
        return {
            loadingS: false,
            pagem: 0,
            last_pagem: 1,
            loadingS: false,
            messagesList: [],
            mViewed: '',
            mIndex: '',
        }
    },
    methods: {
        fetchMessages() {
                axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
                axios.get(`/api/fetchmessages?page=${this.page}`).then(resp => {
                    console.log(resp);
                    this.last_pagem = resp.data.t.last_page;
                    this.messagesList.push(...resp.data.t.data)
                    this.loadingS = false;
                }).catch(err => {
                    if(err.message == "Request failed with status code 401") {
                      
                        this.$router.push({name: 'Login'});
                        this.$store.dispatch('destroyToken').then(resp => {
                            console.log(resp.data);
                            
                            this.$router.push({name: 'Login'});
                        }).then(resp => {  }).catch(err => { this.outLoading = false });
                    }
                });
        
            
        },
        printDate2(i) {
            return moment.utc(i).local().format('dddd MMMM DD');
        },
        scrollBottom2(isVisible) {
            if(!isVisible) { return }
            if(this.pagem >= this.last_pagem) { return }
            console.log('Bottom');
            this.pagem++;
            this.fetchMessages();
        },
        styleBorder(i) {
            if(i == false) {
                return {borderLeft: '3px solid #5cc9a7'}
            } else {
                return {}
            } 
            
        },
        styleBorder1(i) {
            if(i == 0) {
                return {borderLeft: '4px solid rgba(0,100,0,0.4)', borderTop: '0px solid #5cc9a7', borderBottom: '0px solid #5cc9a7'}
            }  
            
        },
        styleBorder2(i) {
            if(i == 0) {
                return { borderTop: '1px solid #5cc9a7', borderBottom: '1px solid #5cc9a7'}
            } 
         },
        styleBorder3(i) {
            if(i == 0) {
                return {borderRight: '2px solid #5cc9a7', borderTop: '1px solid #5cc9a7', borderBottom: '1px solid #5cc9a7'}
            } 
        },
        styleSuccessRight(date) {
            return date == null ? {borderRight: '1px solid #5cc9a7;'} : {};
        },
        styleSuccessBottom(date) {
            return date == null ? {borderBottom: '1px solid #5cc9a7;'} : {};
        },
        showMViewed(i, index) {
            this.mViewed = i;
            this.mIndex = index,
            $(this.$refs.mViewed.$el).modal('show');
        },
        showMRemove(i, index) {
            this.mViewed = i;
            this.mIndex = index;
            $(this.$refs.mRemove.$el).modal('show');
        }
    }
}
</script>