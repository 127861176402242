<template>
<div>
    
    <section class="pt-4 bg-section-secondary">
        <div class=container>
            <div class="row justify-content-center">
                <div class=col-lg-9>
                    <div class="row align-items-center">
                        <div class=col>
                            <span class=surtitle></span>
                            <h1 class="h2 mb-0">Ticket Creation</h1>
                        </div>
                    </div>
                    <div class="row align-items-center mt-4">
                        <div class=col>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="slice slice-sm bg-section-secondary">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="row">
                        <div class="col-lg-12 mb-4">
                            <div v-for="(i, index) in this.$store.state.shelf" :key="i.id" class="card" style="margin-bottom:4px;">
                                <div class="card-body">
                                    <div class="row row-grid align-items-center">
                                        <div class="col-lg-8">
                                            <div class="media align-items-center">
                                                <span class="avatar bg-success text-white rounded-circle mr-3">
                                                    <i class="uil uil-check" style="font-size: 1.75rem;"></i>
                                                </span>
                                                <div class="media-body">
                                                    <h5 class="mb-0">{{i.id}}</h5>
                                                    <!-- <p class="text-muted lh-150 text-sm mb-0">Age {{i.age}} | Date of policy start: {{i.startdate}} </p> -->
                                                    <p v-if="i.amount" class="text-muted lh-150 text-sm mb-0">Coverage Amount: {{formatCurrency2(i.amount * 1000)}} </p>
                                                    <p v-if="i.term" class="text-muted lh-150 text-sm mb-0">Term: {{ i.term }} </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-auto flex-fill mt-4 mt-sm-0 text-sm-right">
                                            <p v-if="i.quote" class="text-muted lh-150 text-sm mb-0">{{ formatCurrency2(i.quote) }} </p>
                                        </div>
                                        <div class="col-auto flex-fill mt-4 mt-sm-0 text-sm-right">
                                            <i @click="clearShelf(index)" class="uil uil-multiply" style="cursor: pointer;"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                            
                            <div class="mt-5">
                                
                               
                            
                            <div>
                                <h5 class="mt-4 mb-3">Medical Questions</h5>
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Are you currently hospitalized, confined to a nursing home, hospice, bed, assisted living, rehabilitation or psychiatric facility, receiving home health care; or have you been hospitalized two or more times in the past 12 months?</label> 
                                                <div class="row">
                                                    <div class="col-5">
                                                        <select @change="SelectMedOne" class="form-control custom-select">
                                                            <option selected>Select Option</option>
                                                            
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>

                                                        </select>
                                                    </div>
                                                    <div class="col-1">

                                                    </div>
                                                    <div class="col-4">
                                                        
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Do you need ongoing personal assistance performing regular activities of daily living: bathing, dressing, eating, taking medication, toleting, or moving around the house: or are you onfined to a wheelchair?</label> 
                                                <div class="row">
                                                    <div class="col-5">
                                                        <select @change="SelectMedTwo" class="form-control custom-select">
                                                            <option selected>Select Option</option>
                                                            
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>

                                                        </select>
                                                    </div>
                                                    <div class="col-1">

                                                    </div>
                                                    <div class="col-4">
                                                        
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Have you ever been diagnoes with or received treatment or medication for:
                                                    Acquired Immune Deficiency Syndrome (AIDS) HIV, or ARC (Aids related complex)</label> 
                                                <div class="row">
                                                    <div class="col-5">
                                                        <select @change="SelectMedThree" class="form-control custom-select">
                                                            <option selected>Select Option</option>
                                                            
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>

                                                        </select>
                                                    </div>
                                                    <div class="col-1">

                                                    </div>
                                                    <div class="col-4">
                                                        
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Heart Failure (HF), Congestive Heart Failure (CHF), cardiomyopathy or pulminary hypertension?</label> 
                                                <div class="row">
                                                    <div class="col-5">
                                                        <select @change="SelectMedFour" class="form-control custom-select">
                                                            <option selected>Select Option</option>
                                                            
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>

                                                        </select>
                                                    </div>
                                                    <div class="col-1">

                                                    </div>
                                                    <div class="col-4">
                                                        
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Amyotrophic Lateral Sclerosis (ALS), Alzheimer's diseas, dementia, cognitive impairment, memroy loss, schizophrenia, or any neurologic or neuromuscular disease, or a terminal illness?</label> 
                                                <div class="row">
                                                    <div class="col-5">
                                                        <select @change="SelectMedFive" class="form-control custom-select">
                                                            <option selected>Select Option</option>
                                                            
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>

                                                        </select>
                                                    </div>
                                                    <div class="col-1">

                                                    </div>
                                                    <div class="col-4">
                                                        
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Chronic Kidney (renal) disease, cirrhosis of the liver, or had a bone marrow or organ transplant?</label> 
                                                <div class="row">
                                                    <div class="col-5">
                                                        <select @change="SelectMedSix" class="form-control custom-select">
                                                            <option selected>Select Option</option>
                                                            
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>

                                                        </select>
                                                    </div>
                                                    <div class="col-1">

                                                    </div>
                                                    <div class="col-4">
                                                        
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Complications of diabetes: diabetic coma, insulin shock, retinopathy (eye) nephropathy (kidney), neuropathy (nerve or numbness), or peripheral vascular disease (PVD/PAD)?</label> 
                                                <div class="row">
                                                    <div class="col-5">
                                                        <select @change="SelectMedSeven" class="form-control custom-select">
                                                            <option selected>Select Option</option>
                                                            
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>

                                                        </select>
                                                    </div>
                                                    <div class="col-1">

                                                    </div>
                                                    <div class="col-4">
                                                        
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                        </div>
                                        
                                        </div>
                                        <div class="mt-4 mb-4 mr-2 row justify-content-end">
                                            <div class="col-md-4">
                                                <button v-if="!submitLoading" @click="continueNextSection()" type="button" class="btn btn-block btn-primary hover-continue">Continue</button>
                                                <div v-else class="spinner-border" role="status">
                                                <span class="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                
                                
                                    
                                    
                                        
                                        
                                    
                                    
                                   
                                 
                                   
                                    
                            </div><hr>
                            <div>
                                <div class="page-inner-header mb-4">
                                    <h5 class="mb-1"></h5>
                                    <p class="text-muted mb-0"></p>
                                </div>
                                <!-- <div class="row justify-content-end">
                                    <div class="col-md-4">
                                        <button v-if="!submitLoading" @click="submitQuestions()" type="button" class="btn btn-success">Create Ticket</button>
                                        <div v-else class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <button v-if="!submitLoading" @click="pulltestquestions()" type="button" class="btn btn-success">test pull</button>
                                        <div v-else class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="modal fade" id="modal_account_deactivate" tabindex="-1" role="dialog" aria-labelledby="modal_account_deactivate" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                        <div class="modal-content">
                                            <div class="modal-body">
                                                <div class="pt-5 text-center">
                                                    <div class="icon text-danger">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-x"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><line x1="18" y1="8" x2="23" y2="13"></line><line x1="23" y1="8" x2="18" y2="13"></line>
                                                        </svg>
                                                    </div>
                                                    <h4 class="h5 mt-5 mb-3">Extremely important</h4>
                                                    <p>We will immediately delete all of your personal data from our database. This action can not be undone. Are you sure you want to do this?</p>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-sm btn-link text-danger btn-zoom--hover font-weight-600">Delete</button> 
                                                <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
</div>
</div>  
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import flatpickr from "flatpickr";
import VuePhoneNumberInput from 'vue-phone-number-input';
import _ from 'lodash';
export default {
    
    components: {
        flatpickr, VuePhoneNumberInput
    },
    data() {
        return {
            sSlots: '',
            sHours: '',
            emailc: '',
            phonec: '',
            timeZone: '',
            submitLoading: false,
            q: {
                policy_age: '',
                policy_amount: '',
                policy_dob: '',
                policy_gender: '',
                policy_quote: '',
                policy_ss: '',
                policy_startdate: '',
                policy_state: '',
                policy_term: '',

                first_name: '',
                last_name: '',
                dob: '',
                gender: '',
                email: '',
                phone: '',
                phone_confirm: '',
                address: '',
                address_no: '',
                city: '',
                state: '',
                zip: '',

                license_number: '',
                license_state: '',
                height_ft: '',
                height_in: '',
                weight: '',
                weight_10: '',
                weight_loss: '',
                weight_reason: '',
                tobacco: '',
                med_one: '',
                med_two: '',
                med_three: '',
                med_four: '',
                med_five: '',
                med_six: '',
                med_seven: '',
                med_eight: '',
                med_nine: '',
                med_ten: '',
                med_eleven: '',
                med_twelve: '',
                med_thirteen: '',
                med_fourteen: '',
                med_fifteen: '',
                med_sixteen: '',
                med_seventeen: '',
                med_eighteen: '',
                additional_one: '',
                additional_two: '',
                additional_three: '',
                owner_one: '',
                owner_two: '',
                owner_three: '',

                owner_address: '',
                owner_addressno: '',
                owner_city: '',
                owner_state: '',
                owner_zip: '',
                owner_phone: '',

                secondaddres_one: '',
                secondaddress_two: '',

                secondaddress_address: '',
                secondaddress_addressno: '',
                secondaddress_city: '',
                secondaddress_state: '',
                secondaddress_zip: '',
                secondaddress_phone: '',

                ben_one: '',
                ben_two: '',
                ben_three: '',
                ben_four: '',
                ben_five: '',
                ben_six: '',

                addben_one: '',
                addben_two: '',
                addben_three: '',
                addben_four: '',
                addben_five: '',
                addben_six: '',

                contben_one: '',
                contben_two: '',
                contben_three: '',
                contben_four: '',
                contben_five: '',
                contben_six: '',

                exist_one: '',
                exist_two: '',
                exist_three: '',
                exist_four: '',
                exist_five: '',

                exist_six: '',
                thirdparty: '',

                payment_one: '',
                payment_two: '',

                payment_three: '',
                payment_four: '',
                payment_five: '',
                payment_six: '',
                payment_seven: '',
                payment_eight: '',

                payment_nine: '',
                payment_ten: '',
                payment_eleven: '',
                paymet_exp: '',
                payment_twelve: '',
                comments: '',

                timezone: '',
                schedule_hourid: '',
            },
            form: {
                timezone: '',
                policy_coverage: '',
                policy_start: '',
                policy_age: '',
                policy_monthly: '',

                first_name: '',
                last_name: '',
                dob: '',
                gender: '',
                email: '',
                phone: '',
                address: '',
                address_no: '',
                city: '',
                state: '',
                zip: '',

                tobacco: '',
                hours: '',
                medical: '',
                employeer_name: '',
                dateofhire: '',
                occupation: '',
                income: '',
                agentexplainedllp: '',
                paymentalternative: '',

                schedule_hourid: '',
            },
            email: "",
            emailValid: '',
            isSS: false,
            isSS2: false,
            
        }
    },
    created() {
        window.scrollTo(0,0);
        
        
    },
    mounted() {
        
        

       

        
        
        
    },
    methods: {
        continueNextSection() {
            if(this.$store.state.q.med_one != '' && this.$store.state.q.med_two != '' && this.$store.state.q.med_three != '' && this.$store.state.q.med_four != '' && this.$store.state.q.med_five != '' && this.$store.state.q.med_six != '' && this.$store.state.q.med_seven != '') {
                this.$router.push({name: 'q.past12'})
            } else if(this.$store.state.q.med_one == '') {
                this.$toasted.error('Are you currently hospitalized?')
            } else if(this.$store.state.q.med_two == '') {
                this.$toasted.error('Do you need ongoing personal assistance?')
            } else if(this.$store.state.q.med_three == '') {
                this.$toasted.error('Have you ever been diagnoes with or received treatment');
            } else if(this.$store.state.q.med_four == '') {
                this.$toasted.error('Heart Failure (HF), Congestive Heart Failure (CHF), cardiomyopathy or pulminary hypertension?')
            } else if(this.$store.state.q.med_five == '') {
                this.$toasted.error("Amyotrophic Lateral Sclerosis (ALS), Alzheimer's diseas, dementia, cognitive impairment, memroy loss, schizophrenia, or any neurologic or neuromuscular disease, or a terminal illness?");
            } else if(this.$store.state.q.med_six == '') {
                this.$toasted.error('Chronic Kidney (renal) disease, cirrhosis of the liver, or had a bone marrow or organ transplant?')
            } else if(this.$store.state.q.med_seven == '') {
                this.$toasted.error('Complications of diabetes: diabetic coma, insulin shock, retinopathy (eye) nephropathy (kidney), neuropathy (nerve or numbness), or peripheral vascular disease (PVD/PAD)?')
            }
            
        },
        fetchScheduler() {
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.get('/api/fetchscheduler').then(resp => {
               console.log(resp.data);
                this.sSlots = resp.data.t;
            });
            
        },
        
        formatCurrency(amount) {
            amount = (amount / 100);
            return amount.toLocaleString('en', { style: 'currency', currency: 'usd', minimumFractionDigits: 2 });
        },
        formatCurrency2(amount) {
            amount = (amount);
            return amount.toLocaleString('en', { style: 'currency', currency: 'usd', minimumFractionDigits: 0 });
        },
        cartTotal() {
                let amount = this.$store.state.shelf.reduce((acc, item) => acc + (item.quote), 0);
                if(this.$store.state.rider1.cost) {
                    amount += this.$store.state.rider1.cost;
                }
                return amount;
            },
        emailConfirm(event) {
            
            if(event.target.value.length >= 4) {
                console.log(event.target.value);
                if(event.target.value == this.form.email) {
                    this.emailc = 1;
                }  else {
                    this.emailc = 2;
                }

            } else {
                this.emailc = '';
            }
        },
        validateEmail() {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(this.form.email)) {
                console.log('email looks alright');  
                this.emailValid = true;
            } else {
                console.log('Please enter a valid email address');
                this.emailValid = false;
            }
        },
        phoneConfirm(event) {
            
            if(event.target.value.length >= 4) {
                console.log(event.target.value);
                if(event.target.value == this.q.phone) {
                    this.phonec = 1;
                } else {
                    this.phonec = 2;
                    console.log('phone not equal ' + this.phonec);
                }

            } else {
                this.phonec = '';
            }
        },
        acceptNumber() {
            var x = this.q.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.form.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        acceptNumber2() {
            var x = this.q.phoneConfirm.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.q.phoneConfirm = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        tState(event) {
          if(event.target.value !== '') {
              this.form.tobacco = event.target.value;

              if(event.target.value == 'No' && this.$store.state.policy.ss == 1) {
                  this.isSS = true;
                  
              } else if(event.target.value == 'Yes' && this.$store.state.policy.ss == 2) {
                  this.isSS = true;
                  
              } else {
                  this.isSS = false;
                  
              }
              
          }
        },
        tState2(event) {
          if(event.target.value !== '') {
              this.form.spouse_tobacco = event.target.value;
              if(event.target.value == 'No' && this.$store.state.policySpouse.ss == 1) {
                  this.isSS2 = true;
              } else if(event.target.value == 'Yes' && this.$store.state.policySpouse.ss == 2) {
                  this.isSS2 = true;
              }
              
          }
        },
        hState(event) {
          if(event.target.value !== '') {
              this.form.hours = event.target.value;
              
          }
        },
        mState(event) {
          if(event.target.value !== '') {
              this.form.medical = event.target.value;
              
          }
        },
        llpState(event) {
          if(event.target.value !== '') {
              this.form.agentexplainedllp = event.target.value;
              
          }
        },
        selectTimezone(event) {
            this.timeZone = event.target.value;
            this.q.timezone = event.target.value;
        },
        selectDateChange(event) {
            console.log(event.target.value);
            this.sHours = this.sSlots[parseInt(event.target.value)].hours;
        },
        shouldDisableSlot(i) {
            if(i.ticket_id) {
                console.log(i);
                return true;
            } 
        },
        selectedHour(event) {
            if(event.target.value != 'Select time' || event.target.value == '') {
                this.q.schedule_hourid = event.target.value;
            }
            
        },
        printDate(i) {
            return moment.utc(i).local().format('hh:mm a');
        },
        printDateTimezone(i) {
            return moment.utc(i).tz(this.timeZone).format('hh:mm a');
        },
        printDate2(i) {
            return moment.utc(i).local().format('dddd MMMM DD');
        },
        submitTicket() {
            if(this.form.first_name != '' && this.form.last_name != '' && this.form.dob != '' && this.form.gender != '' && this.form.email != '' && this.emailValid == true && this.emailc == 1 && this.form.phone != '' && this.phonec == 1 && this.form.address != '' && this.form.city != '' && this.form.state != '' && this.form.zip != '' && this.isSS == true && this.form.hours == 'Yes' && this.form.medical == "No" && this.employeer_name != '' && this.form.occupation != '' && this.form.income != '' && this.form.agentexplainedllp == 'Yes' && this.form.schedule_hourid != '') {
                this.submitLoading = true;
                let role = JSON.parse(localStorage.getItem('access_token2')).role;
                axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
                axios.post('/api/createticket', this.form).then(resp => {
                    console.log(resp);
                    
                }).then(resp2 => {
                    this.submitLoading = false;
                    this.$toasted.success('Ticket Created');
                    this.$router.push({name: 'admin.tickets'});
                });
            } else if(this.form.first_name == '') {
                this.$toasted.error('First Name');
            } else if(this.form.last_name == '') {
                this.$toasted.error('Last Name');
            } else if(this.form.dob == '') {
                this.$toasted.error('Dob');
            } else if(this.form.gender == '') {
                this.$toasted.error('Gender');
            } else if(this.form.email == '') {
                this.$toasted.error('Email');
            } else if(this.emailValid != true) {
                this.$toasted.error('Invalid Email');
            } else if(this.emailc != 1) {
                this.$toasted.error('Confirm Email');
            } else if(this.form.phone == '') {
                this.$toasted.error('Phone');
            } else if(this.phonec != 1) {
                this.$toasted.error('Confirm Phone');
            } else if(this.form.address == '') {
                this.$toasted.error('Address');
            } else if(this.form.city == '') {
                this.$toasted.error('City');
            } else if(this.form.state == '') {
                this.$toasted.error('State');
            } else if(this.form.zip == '') {
                this.$toasted.error('Zip Code');
            } else if(this.form.gender == '') {
                this.$toasted.error('Gender');
            } else if(this.isSS == false) {
                this.$toasted.error('Tobacco Usage Not matching Quoted');
            } else if(this.form.tobacco == '') {
                this.$toasted.error('Tobacco Usage');
            } else if(this.form.hours != 'Yes') {
                this.$toasted.error('Must be working');
            } else if(this.form.medical != 'No') {
                this.$toasted.error('Medical Status');
            } else if(this.form.employeer_name == '') {
                this.$toasted.error('Employeer Name');
            } else if(this.form.occupation == '') {
                this.$toasted.error("Client's Occupation");
            } else if(this.form.income == '') {
                this.$toasted.error("Client's Income");
            } else if(this.form.agentexplainedllp != 'Yes') {
                this.$toasted.error('Explained LP');
            } else if(this.form.schedule_hourid == '') {
                this.$toasted.error('Schedule Enrollment Hour');
            }
            
        },
        
        focusForm:_.debounce(function() {
            const el = this.$el.getElementsByClassName('llpshow')[0];
            console.log(el);
            if (el) {
                // Use el.scrollIntoView() to instantly scroll to the element
                el.scrollIntoView({behavior: 'smooth'});
            }
        }, 500),

        // Starting new ticket question dynamics 
        selectLicenseState(e) {
            if(e.target.value != '') {
                this.q.license_state = e.target.value;
                console.log(e.target.value)
            }
        },
        selectHeightFt(e) {
            if(e.target.value != '') {
                this.q.height_ft = e.target.value;
            }
        },
        selectHeightIn(e) {
            if(e.target.value != '') {
                this.q.height_in = e.target.value;
            }
        },
        selectWeight(e) {
            if(e.target.value != '') {
                this.q.weight = e.target.value;
            }
        },
        SelectWeightTen(e) {
            if(e.target.value != '') {
                this.q.weight_ten = e.target.value;
                console.log('weight 10 ' + e.target.value)
            }
        },
        SelectTobacco(e) {
            if(e.target.value != '') {
                this.q.tobacco = e.target.value;
            }
        },
        SelectMedOne(e) {
            if(e.target.value != '') {
                this.$store.state.q.med_one = e.target.value;
            }
        },
        SelectMedTwo(e) {
            if(e.target.value != '') {
                this.$store.state.q.med_two = e.target.value;
            }
        },
        SelectMedThree(e) {
            if(e.target.value != '') {
                this.$store.state.q.med_three = e.target.value;
            }
        },
        SelectMedFour(e) {
            if(e.target.value != '') {
                this.$store.state.q.med_four = e.target.value;
            }
        },
        SelectMedFive(e) {
            if(e.target.value != '') {
                this.$store.state.q.med_five = e.target.value;
            }
        },
        SelectMedSix(e) {
            if(e.target.value != '') {
                this.$store.state.q.med_six = e.target.value;
            }
        },
        SelectMedSeven(e) {
            if(e.target.value != '') {
                this.$store.state.q.med_seven = e.target.value;
            }
        },
        SelectMedEight(e) {
            if(e.target.value != '') {
                this.$store.state.q.med_eight = e.target.value;
            }
        },
        SelectMedNine(e) {
            if(e.target.value != '') {
                this.q.med_nine = e.target.value;
            }
        },
        SelectMedTen(e) {
            if(e.target.value != '') {
                this.q.med_ten = e.target.value;
            }
        },
        SelectMedEleven(e) {
            if(e.target.value != '') {
                this.q.med_eleven = e.target.value;
            }
        },
        SelectMedTwelve(e) {
            if(e.target.value != '') {
                this.q.med_twelve = e.target.value;
            }
        },
        SelectMedThirteen(e) {
            if(e.target.va,lue != '') {
                this.q.med_thirteen = e.target.value;
            }
        },
        SelectMedFourteen(e) {
            if(e.target.value != '') {
                this.q.med_fourteen = e.target.value;
            }
        },
        SelectMedFifteen(e) {
            if(e.target.value != '') {
                this.q.med_fifteen = e.target.value;
            }
        },
        SelectMedSixteen(e) {
            if(e.target.value != '') {
                this.q.med_sixteen = e.target.value;
            }
        },
        SelectMedSeventeen(e) {
            if(e.target.value != '') {
                this.q.med_seventeen = e.target.value;
            }
        },
        SelectMedEighteen(e) {
            if(e.target.value != '') {
                this.q.med_eighteen = e.target.value;
            }
        },
        SelectAdditionalOne(e) {
            if(e.target.value != '') {
                this.q.additional_one = e.target.value;
            }
        },
        SelectAdditionalTwo(e) {
            if(e.target.value != '') {
                this.q.additional_two = e.target.value;
            }
        },
        SelectAdditionalThree(e) {
            if(e.target.value != '') {
                this.q.additional_three = e.target.value;
            }
        },
        SelectSecondAddressOne(e) {
            if(e.target.value != '') {
                this.q.secondaddress_one = e.target.value;
            }
        },
        SelectBenSix(e) {
            if(e.target.value != '') {
                this.q.ben_six = e.target.value;
            }
        },
        SelectExistOne(e) {
            if(e.target.value != '') {
                this.q.exist_one = e.target.value;
            }
        },
        SelectExistSix(e) {
            if(e.target.value != '') {
                this.q.exist_six = e.target.value;
            }
        },
        SelectPaymentOne(e) {
            if(e.target.value != '') {
                this.q.payment_one = e.target.value;
            }
        },
        SelectPaymentTwo(e) {
            if(e.target.value != '') {
                this.q.payment_two = e.target.value;
            }
        },
        SelectPaymentFour(e) {
            if(e.target.value != '') {
                this.q.payment_four = e.target.value;
            }
        },
        SelectPaymentTen(e) {
            if(e.target.value != '') {
                this.q.payment_ten = e.target.value;
            }
        },
        SelectOwnerState(e) {
            if(e.target.value != '') {
                this.q.owner_state = e.target.value;
            }
        },
        SelectSecondState(e) {
            if(e.target.value != '') {
                this.q.secondaddress_state = e.target.value;
            }
        },
        acceptOwnerNumber() {
            var x = this.q.owner_phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.q.owner_phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        submitQuestions() {
            this.submitLoading = true;

            
            

           
            console.log(this.q);
            this.submitLoading = false;
        },
        pulltestquestions() {
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.get('pulltestquestions').then(resp => {
                console.log(resp.data);
            });
        }
        

    }

}
</script>
<style scoped>
.form-control-label {
    color: #525458;
    font-size: 0.975rem;
}
#datepicker {
    background-color: white;
    
}
.outlineTicketSuccess {
    border-color: #5c5e5d;
}
.outlineTicketDanger {
    border-color: #ee283c;;
}
.form-control option:disabled {
    display: none;
}
</style>