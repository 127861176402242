<template>
<div>
    
    <section class="pt-4 bg-section-secondary">
        <div class=container>
            <div class="row justify-content-center">
                <div class=col-lg-9>
                    <div class="row align-items-center">
                        <div class=col>
                            <span class=surtitle></span>
                            <h1 class="h2 mb-0">Ticket Creation</h1>
                        </div>
                    </div>
                    <div class="row align-items-center mt-4">
                        <div class=col>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="slice slice-sm bg-section-secondary">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="row">
                        <div class="col-lg-12 mb-4">
                            <div v-for="(i, index) in this.$store.state.shelf" :key="i.id" class="card" style="margin-bottom:4px;">
                                <div class="card-body">
                                    <div class="row row-grid align-items-center">
                                        <div class="col-lg-8">
                                            <div class="media align-items-center">
                                                <span class="avatar bg-success text-white rounded-circle mr-3">
                                                    <i class="uil uil-check" style="font-size: 1.75rem;"></i>
                                                </span>
                                                <div class="media-body">
                                                    <h5 class="mb-0">{{i.id}}</h5>
                                                    <!-- <p class="text-muted lh-150 text-sm mb-0">Age {{i.age}} | Date of policy start: {{i.startdate}} </p> -->
                                                    <p v-if="i.amount" class="text-muted lh-150 text-sm mb-0">Coverage Amount: {{formatCurrency2(i.amount * 1000)}} </p>
                                                    <p v-if="i.term" class="text-muted lh-150 text-sm mb-0">Term: {{ i.term }} </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-auto flex-fill mt-4 mt-sm-0 text-sm-right">
                                            <p v-if="i.quote" class="text-muted lh-150 text-sm mb-0">{{ formatCurrency2(i.quote) }} </p>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                           
                            
                            <div class="mt-5">
                                
                               
                            
                            <div>
                                <h5 class="mt-4 mb-3">Medical History Part 2</h5>
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-12 mb-4">
                                                <div class="form-group">
                                                    <label class="form-control-label">11 A. PRIOR to the age of 20; Have you been diagnosed with, treated for, tested positive for or been given medical advice by a member of the medical profession for any of the following:     Diabetes (other than gestational diabetes)?</label> 
                                                    <div class="row">
                                                        
                                                        <div class="col-12">
                                                            
                                                            <multiselect  v-model="$store.state.p.q28" :options="options" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Check all that APPLY" row="6" :preselect-first="false">
                                                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                                                    
                                                                </template>
                                                            </multiselect>
                                                            
                                                        </div>
                                                        <div class="col-1">

                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                            <div class="col-md-12 mb-4">
                                                <div class="form-group">
                                                    <label class="form-control-label">11 B. PRIOR to the age of 26 with Crohn’s Disease?</label> 
                                                    <div class="row">
                                                        
                                                        <div class="col-12">
                                                            
                                                            <multiselect  v-model="$store.state.p.q29" :options="options" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Check all that APPLY" row="6" :preselect-first="false">
                                                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                                                    
                                                                </template>
                                                            </multiselect>
                                                            
                                                        </div>
                                                        <div class="col-1">

                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                            <div class="col-md-12 mb-4">
                                                <div class="form-group">
                                                    <label class="form-control-label">11 C. PRIOR to the age of 45 with: (Check all that APPLY)</label> 
                                                    <div class="row">
                                                        
                                                        <div class="col-12">
                                                            
                                                            <multiselect  v-model="$store.state.p.q30" :options="options2" :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Check all that APPLY" row="6" :preselect-first="false">
                                                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                                                    
                                                                </template>
                                                            </multiselect>
                                                            
                                                        </div>
                                                        <div class="col-1">

                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                            <div class="col-md-12 mb-4">
                                                <div class="form-group">
                                                    <label class="form-control-label">11 D. HAVE YOU EVER been diagnosed with, treated for, tested positive for or been given medical advice by a member of the medical profession for any of the following:  (Check all that APPLY)</label> 
                                                    <div class="row">
                                                        
                                                        <div class="col-12">
                                                            
                                                            <multiselect  v-model="$store.state.p.q31" :options="options3" :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Check all that APPLY" row="6" :preselect-first="false">
                                                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                                                    
                                                                </template>
                                                            </multiselect>
                                                            
                                                        </div>
                                                        <div class="col-1">

                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                            <div class="col-md-12 mb-4">
                                                <div class="form-group">
                                                    <label class="form-control-label">11 E. Hepatitis C? (If yes, proceed to E1 & E2.)</label> 
                                                    <div class="row">
                                                        
                                                        <div class="col-12">
                                                            
                                                            <multiselect  v-model="$store.state.p.q32" :options="options" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Check all that APPLY" row="6" :preselect-first="false">
                                                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                                                    
                                                                </template>
                                                            </multiselect>
                                                            
                                                        </div>
                                                        <div class="col-1">

                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                            <div class="col-md-12 mb-4">
                                                <div class="form-group">
                                                    <label class="form-control-label">11 E. Hepatitis C? (If yes, proceed to E1 & E2.)</label> 
                                                    <div class="row">
                                                        
                                                        <div class="col-12">
                                                            
                                                            <multiselect  v-model="$store.state.p.q33" :options="options4" :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Check all that APPLY" row="6" :preselect-first="false">
                                                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                                                    
                                                                </template>
                                                            </multiselect>
                                                            
                                                        </div>
                                                        <div class="col-1">

                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                            <div class="col-md-12 mb-4">
                                                <div class="form-group">
                                                    <label class="form-control-label">E2. If CURED, when was the last blood test (RNA PCR Titer) showing the Hepatitis C was cured?</label> 
                                                    <div class="row">
                                                        
                                                        <div class="col-12">
                                                            
                                                            <multiselect  v-model="$store.state.p.q34" :options="options5" :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Check all that APPLY" row="6" :preselect-first="false">
                                                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                                                    
                                                                </template>
                                                            </multiselect>
                                                            
                                                        </div>
                                                        <div class="col-1">

                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                            <div class="col-md-12 mb-4">
                                                <div class="form-group">
                                                    <label class="form-control-label">11 F. DURING the LAST 4 YEARS... have you been diagnosed with, treated for, tested positive for or been given medical advice by a member of the medical profession for cancer (other than basal cell carcinoma)?</label> 
                                                    <div class="row">
                                                        
                                                        <div class="col-12">
                                                            
                                                            <multiselect  v-model="$store.state.p.q35" :options="options" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Check all that APPLY" row="6" :preselect-first="false">
                                                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                                                    
                                                                </template>
                                                            </multiselect>
                                                            
                                                        </div>
                                                        <div class="col-1">

                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                            <div class="col-md-12 mb-4">
                                                <div class="form-group">
                                                    <label class="form-control-label">11 G. During the LAST 2 YEARS,  or been diagnosed with, treated for, tested positive for or been given medical advice by a member of the medical profession for alcoholism, alcohol use/abuse, drug use/abuse(including prescription drugs), muscular dystrophy, or systemic lupus erythematosus (SLE)? (Check ALL that APPLY)</label> 
                                                    <div class="row">
                                                        
                                                        <div class="col-12">
                                                            
                                                            <multiselect  v-model="$store.state.p.q36" :options="options6" :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Check all that APPLY" row="6" :preselect-first="false">
                                                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                                                    
                                                                </template>
                                                            </multiselect>
                                                            
                                                        </div>
                                                        <div class="col-1">

                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                            <div class="col-md-12 mb-4">
                                                <div class="form-group">
                                                    <label class="form-control-label"> 11 H. During the LAST 2 YEARS have you: Required assistance with:  activities of daily living (ADL’s) such as:  (Check ALL that APPLY)</label> 
                                                    <div class="row">
                                                        
                                                        <div class="col-12">
                                                            
                                                            <multiselect  v-model="$store.state.p.q37" :options="options7" :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Check all that APPLY" row="6" :preselect-first="false">
                                                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                                                    
                                                                </template>
                                                            </multiselect>
                                                            
                                                        </div>
                                                        <div class="col-1">

                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                            <div class="col-md-12 mb-4">
                                                <div class="form-group">
                                                    <label class="form-control-label">11 I. Used a wheelchair, electric scooter or electric cart?</label> 
                                                    <div class="row">
                                                        
                                                        <div class="col-12">
                                                            
                                                            <multiselect  v-model="$store.state.p.q38" :options="options" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Check all that APPLY" row="6" :preselect-first="false">
                                                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                                                    
                                                                </template>
                                                            </multiselect>
                                                            
                                                        </div>
                                                        <div class="col-1">

                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                            <div class="col-md-12 mb-4">
                                                <div class="form-group">
                                                    <label class="form-control-label">I1. If YES to above, regarding Wheelchair USE, provide details regarding use:</label> 
                                                    <div class="row">
                                                        
                                                        <div class="col-12">
                                                            
                                                            <multiselect  v-model="$store.state.p.q39" :options="options8" :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Check all that APPLY" row="6" :preselect-first="false">
                                                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                                                    
                                                                </template>
                                                            </multiselect>
                                                            
                                                        </div>
                                                        <div class="col-1">

                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                            <div class="col-md-12 mb-4">
                                                <div class="form-group">
                                                    <label class="form-control-label">11 J. During the LAST 1 YEAR; have you been diagnosed with, treated for, tested positive for, treated or been given medical advice by a member of the medical profession or advised to receive treatment for any of the following:  (Check ALL that APPLY)  </label> 
                                                    <div class="row">
                                                        
                                                        <div class="col-12">
                                                            
                                                            <multiselect  v-model="$store.state.p.q40" :options="options9" :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Check all that APPLY" row="6" :preselect-first="false">
                                                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                                                    
                                                                </template>
                                                            </multiselect>
                                                            
                                                        </div>
                                                        <div class="col-1">

                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                            <div class="col-md-12 mb-4">
                                                <div class="form-group">
                                                    <label class="form-control-label">11 K. During the LAST 1 YEAR; have you been diagnosed with, treated for, tested positive for, treated or been given medical advice by a member of the medical profession or advised to receive treatment for any of the following:  (Check ALL that APPLY)     </label> 
                                                    <div class="row">
                                                        
                                                        <div class="col-12">
                                                            
                                                            <multiselect  v-model="$store.state.p.q41" :options="options10" :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Check all that APPLY"  row="6" :preselect-first="false">
                                                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                                                    
                                                                </template>
                                                            </multiselect>
                                                            
                                                        </div>
                                                        <div class="col-1">

                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                            <div class="col-md-12 mb-4">
                                                <div class="form-group">
                                                    <label class="form-control-label">11 L. During the LAST 1 YEAR; have you been diagnosed with, treated for, tested positive for, treated or been given medical advice by a member of the medical profession or advised to receive treatment for any of the following:  : (Check ALL that APPLY)   or have you been: unemployed or disabled and been diagnosed with, treated for or been given medical advice by a member of the medical profession for chronic pain?    </label> 
                                                    <div class="row">
                                                        
                                                        <div class="col-12">
                                                            
                                                            <multiselect  v-model="$store.state.p.q42" :options="options11" :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Check all that APPLY"  row="6" :preselect-first="false">
                                                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                                                    
                                                                </template>
                                                            </multiselect>
                                                            
                                                        </div>
                                                        <div class="col-1">

                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                            <div class="col-md-12 mb-4">
                                                <div class="form-group">
                                                    <label class="form-control-label">11 M. During the LAST 1 YEAR; have you been diagnosed with, treated for, tested positive for, treated or been given medical advice by a member of the medical profession or advised to receive treatment for any of the following:  (Check ALL that APPLY)    </label> 
                                                    <div class="row">
                                                        
                                                        <div class="col-12">
                                                            
                                                            <multiselect  v-model="$store.state.p.q43" :options="options12" :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Check all that APPLY" row="6" :preselect-first="false">
                                                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                                                    
                                                                </template>
                                                            </multiselect>
                                                            
                                                        </div>
                                                        <div class="col-1">

                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                            <div class="col-md-12 mb-4">
                                                <div class="form-group">
                                                    <label class="form-control-label">11 M1. IF checked YES to ANGINA above; when was the angina (chest pain) first diagnosed?    </label> 
                                                    <div class="row">
                                                        
                                                        <div class="col-12">
                                                            
                                                            <multiselect  v-model="$store.state.p.q44" :options="options13" :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Check all that APPLY"  row="6" :preselect-first="false">
                                                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                                                    
                                                                </template>
                                                            </multiselect>
                                                            
                                                        </div>
                                                        <div class="col-1">

                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                            <div class="col-md-12 mb-4">
                                                <div class="form-group">
                                                    <label class="form-control-label">IF YES to any question in PART 2 Please Provide details.  Reference the Question Number and Letter.  IF all answers are NO please type NONE...</label> 
                                                    <div class="row">
                                                        
                                                        <div class="col-12">
                                                            
                                                            <textarea v-model="$store.state.p.q_text_2" name="" id="" class="form-control" rows="6"></textarea>
                                                            
                                                        </div>
                                                        <div class="col-1">

                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                            
                                            
                                        </div>
                                        <div class="mt-4 mb-4 mr-2 row justify-content-end">
                                            <div class="col-md-4">
                                                <button v-if="!submitLoading" @click="continueNextSection()" type="button" class="btn btn-block btn-primary hover-continue">Continue</button>
                                                <div v-else class="spinner-border" role="status">
                                                <span class="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                
                                
                                    
                                    
                                        
                                        
                                    
                                    
                                   
                                 
                                   
                                    
                            </div><hr>
                            <div>
                                <div class="page-inner-header mb-4">
                                    <h5 class="mb-1"></h5>
                                    <p class="text-muted mb-0"></p>
                                </div>
                                <!-- <div class="row justify-content-end">
                                    <div class="col-md-4">
                                        <button v-if="!submitLoading" @click="submitQuestions()" type="button" class="btn btn-success">Create Ticket</button>
                                        <div v-else class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <button v-if="!submitLoading" @click="pulltestquestions()" type="button" class="btn btn-success">test pull</button>
                                        <div v-else class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="modal fade" id="modal_account_deactivate" tabindex="-1" role="dialog" aria-labelledby="modal_account_deactivate" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                        <div class="modal-content">
                                            <div class="modal-body">
                                                <div class="pt-5 text-center">
                                                    <div class="icon text-danger">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-x"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><line x1="18" y1="8" x2="23" y2="13"></line><line x1="23" y1="8" x2="18" y2="13"></line>
                                                        </svg>
                                                    </div>
                                                    <h4 class="h5 mt-5 mb-3">Extremely important</h4>
                                                    <p>We will immediately delete all of your personal data from our database. This action can not be undone. Are you sure you want to do this?</p>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-sm btn-link text-danger btn-zoom--hover font-weight-600">Delete</button> 
                                                <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
</div>
</div>  
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import axios from 'axios';
import Multiselect from 'vue-multiselect'
import moment from 'moment';
import flatpickr from "flatpickr";
import VuePhoneNumberInput from 'vue-phone-number-input';
import _ from 'lodash';
export default {
    
    components: {
        Multiselect, VuePhoneNumberInput
    },
    data() {
        return {
            sSlots: '',
            sHours: '',
            emailc: '',
            phonec: '',
            timeZone: '',
            submitLoading: false,
           
            email: "",
            emailValid: '',
            isSS: false,
            isSS2: false,
            value: [],
            options: [
                'Yes',
                'No',
                
            ],
            options2: [
                'Parkinson\' Disease',
                'Coronary Artery Disease',
                'Peripheral Vascular Disease',
                'Cerebral Vascular Disease',
                'Heart Attack',
                'TIA - Transient Ischemic Attack',
                'Stroke',
                'Cardiac Surgery',
                'Bypass Surgery',
                'Stent Implant',
                'Angioplasty',
                'Pacemaker',
                'Defibrillator Implant',
                'Heart Valve Replacement',
                'NONE of the above apply',
                
            ],
            options3: [
                'Cirrhosis',
                'Heart Failure' ,
                'Congestive Heart Failure CHF' ,
                'Aneurysm that has NOT been surgically corrected and STILL PRESENT?',
                'NONE of these',
            ],
            options4: [
                'CURED',
                'NOT CURED' ,
                'NOT Applicable',
            ],
            options5: [
                '0-24 months after treatment ended',
                'More that 24 Months after treatment ended' ,
                'NOT Applicable',
            ],
            options6: [
                'Illegal Drugs',
                'Alcoholism',
                'Alcohol Use or Abuse' ,
                'Drug Use or Abuse',
                'Prescribed Drugs Excess or Abuse',
                'Muscular Dystrophy',
                'Systemic Lupus Erythematosus (SLE)',
                'NONE of the above APPLY',
            ],
            options7: [
                'Bathing',
                'Dressing',
                'Getting in and out of a chair or bed',
                'Incontinence',
                'Been Recommended to be Confined to a Nursing Home' ,
                'NONE of the Above APPLY',
            ],
            options8: [
                'Currently use or use occasionally at facilities such as, but not limited to, the grocery store, department stores, warehouse stores, airports',
                'Reason for use is expected to resolve in the next 3 months or the reason for use has resolved',
                'NOT Applicable',
            ],
            options9: [
                'More than SIX (6) seizures in the last year',
                'Liver Disease (including but not limited to Hepatitis C)',
                'NONE of the above APPLY',
            ],
            options10: [
                'Heart Attack',
                'Stroke (CVA)',
                '(TIA) Transient Ischemic Attack',
                'NONE of the above APPLY',
            ],
            options11: [
                'Used Oxygen to assist in breathing (including for Sleep Apnea)',
                'Received Kidney dialysis',
                'Kidney Failure',
                'Chronic Kidney Disease (Stage 4 or 5)',
                'Encephalitis',
                'have you been Unemployed',
                'Disabled',
                'Chronic Pain  (defined as): Pain lasting more than 6 months or requiring 6 or more fills of narcotic pain prescriptions in any 12 month period.',
                'NONE of the above APPLY',
            ],
            options12: [
                'Angina (Chest Pain)',
                'Advised to have Heart Surgery of any kind',
                'Including Bypass Surgery',
                'Angioplasty',
                'Stent Implant or Pacemaker Implant',
                'had aneurysm surgically corrected',
                'NONE of the above APPLY',
            ],
            options13: [
                '0-12 months ago',
                '13-24 months ago',
                'Greater than 24 months ago',
                'NONE of the above APPLY',
            ]
            
        }
    },
    created() {
        window.scrollTo(0,0);
        
        
    },
    mounted() {
        
        

        
        
        

        
        
    },
    methods: {
        SelectFirst(e) {
            if(e.target.value != '') {
                this.$store.state.q.med_six = e.target.value;
                console.log(e.target.value);
            }
        },
        SelectSecond(e) {
            if(e.target.value != '') {
                this.$store.state.q.med_five = e.target.value
            }
        },
        
        continueNextSection() {
            if(
                this.$store.state.p.q28 != '' &&
                this.$store.state.p.q29 != '' &&
                this.$store.state.p.q30 != '' &&
                this.$store.state.p.q31 != '' &&
                this.$store.state.p.q32 != '' &&
                this.$store.state.p.q33 != '' &&
                this.$store.state.p.q34 != '' &&
                this.$store.state.p.q35 != '' &&
                this.$store.state.p.q36 != '' &&
                this.$store.state.p.q37 != '' &&
                this.$store.state.p.q38 != '' &&
                this.$store.state.p.q39 != '' &&
                this.$store.state.p.q40 != '' &&
                this.$store.state.p.q41 != '' &&
                this.$store.state.p.q42 != '' &&
                this.$store.state.p.q43 != ''
            ) {
                this.$router.push({name: 'prequal.medical3'});
            } else {
                this.$toasted.error('Missing Required Question');
            } 
        },
        fetchScheduler() {
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.get('/api/fetchscheduler').then(resp => {
               console.log(resp.data);
                this.sSlots = resp.data.t;
            });
            
        },
        
        formatCurrency(amount) {
            amount = (amount / 100);
            return amount.toLocaleString('en', { style: 'currency', currency: 'usd', minimumFractionDigits: 2 });
        },
        formatCurrency2(amount) {
            amount = (amount);
            return amount.toLocaleString('en', { style: 'currency', currency: 'usd', minimumFractionDigits: 0 });
        },
        cartTotal() {
                let amount = this.$store.state.shelf.reduce((acc, item) => acc + (item.quote), 0);
                if(this.$store.state.rider1.cost) {
                    amount += this.$store.state.rider1.cost;
                }
                return amount;
            },
        emailConfirm(event) {
            
            if(event.target.value.length >= 4) {
                console.log(event.target.value);
                if(event.target.value == this.form.email) {
                    this.emailc = 1;
                }  else {
                    this.emailc = 2;
                }

            } else {
                this.emailc = '';
            }
        },
        validateEmail() {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(this.form.email)) {
                console.log('email looks alright');  
                this.emailValid = true;
            } else {
                console.log('Please enter a valid email address');
                this.emailValid = false;
            }
        },
        phoneConfirm(event) {
            
            if(event.target.value.length >= 4) {
                console.log(event.target.value);
                if(event.target.value == this.q.phone) {
                    this.phonec = 1;
                } else {
                    this.phonec = 2;
                    console.log('phone not equal ' + this.phonec);
                }

            } else {
                this.phonec = '';
            }
        },
        acceptNumber() {
            var x = this.q.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.form.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        acceptNumber2() {
            var x = this.q.phoneConfirm.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.q.phoneConfirm = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        tState(event) {
          if(event.target.value !== '') {
              this.form.tobacco = event.target.value;

              if(event.target.value == 'No' && this.$store.state.policy.ss == 1) {
                  this.isSS = true;
                  
              } else if(event.target.value == 'Yes' && this.$store.state.policy.ss == 2) {
                  this.isSS = true;
                  
              } else {
                  this.isSS = false;
                  
              }
              
          }
        },
        tState2(event) {
          if(event.target.value !== '') {
              this.form.spouse_tobacco = event.target.value;
              if(event.target.value == 'No' && this.$store.state.policySpouse.ss == 1) {
                  this.isSS2 = true;
              } else if(event.target.value == 'Yes' && this.$store.state.policySpouse.ss == 2) {
                  this.isSS2 = true;
              }
              
          }
        },
        hState(event) {
          if(event.target.value !== '') {
              this.form.hours = event.target.value;
              
          }
        },
        mState(event) {
          if(event.target.value !== '') {
              this.form.medical = event.target.value;
              
          }
        },
        llpState(event) {
          if(event.target.value !== '') {
              this.form.agentexplainedllp = event.target.value;
              
          }
        },
        selectTimezone(event) {
            this.timeZone = event.target.value;
            this.q.timezone = event.target.value;
        },
        selectDateChange(event) {
            console.log(event.target.value);
            this.sHours = this.sSlots[parseInt(event.target.value)].hours;
        },
        shouldDisableSlot(i) {
            if(i.ticket_id) {
                console.log(i);
                return true;
            } 
        },
        selectedHour(event) {
            if(event.target.value != 'Select time' || event.target.value == '') {
                this.q.schedule_hourid = event.target.value;
            }
            
        },
        printDate(i) {
            return moment.utc(i).local().format('hh:mm a');
        },
        printDateTimezone(i) {
            return moment.utc(i).tz(this.timeZone).format('hh:mm a');
        },
        printDate2(i) {
            return moment.utc(i).local().format('dddd MMMM DD');
        },
        submitTicket() {
            if(this.form.first_name != '' && this.form.last_name != '' && this.form.dob != '' && this.form.gender != '' && this.form.email != '' && this.emailValid == true && this.emailc == 1 && this.form.phone != '' && this.phonec == 1 && this.form.address != '' && this.form.city != '' && this.form.state != '' && this.form.zip != '' && this.isSS == true && this.form.hours == 'Yes' && this.form.medical == "No" && this.employeer_name != '' && this.form.occupation != '' && this.form.income != '' && this.form.agentexplainedllp == 'Yes' && this.form.schedule_hourid != '') {
                this.submitLoading = true;
                let role = JSON.parse(localStorage.getItem('access_token2')).role;
                axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
                axios.post('/api/createticket', this.form).then(resp => {
                    console.log(resp);
                    
                }).then(resp2 => {
                    this.submitLoading = false;
                    this.$toasted.success('Ticket Created');
                    this.$router.push({name: 'admin.tickets'});
                });
            } else if(this.form.first_name == '') {
                this.$toasted.error('First Name');
            } else if(this.form.last_name == '') {
                this.$toasted.error('Last Name');
            } else if(this.form.dob == '') {
                this.$toasted.error('Dob');
            } else if(this.form.gender == '') {
                this.$toasted.error('Gender');
            } else if(this.form.email == '') {
                this.$toasted.error('Email');
            } else if(this.emailValid != true) {
                this.$toasted.error('Invalid Email');
            } else if(this.emailc != 1) {
                this.$toasted.error('Confirm Email');
            } else if(this.form.phone == '') {
                this.$toasted.error('Phone');
            } else if(this.phonec != 1) {
                this.$toasted.error('Confirm Phone');
            } else if(this.form.address == '') {
                this.$toasted.error('Address');
            } else if(this.form.city == '') {
                this.$toasted.error('City');
            } else if(this.form.state == '') {
                this.$toasted.error('State');
            } else if(this.form.zip == '') {
                this.$toasted.error('Zip Code');
            } else if(this.form.gender == '') {
                this.$toasted.error('Gender');
            } else if(this.isSS == false) {
                this.$toasted.error('Tobacco Usage Not matching Quoted');
            } else if(this.form.tobacco == '') {
                this.$toasted.error('Tobacco Usage');
            } else if(this.form.hours != 'Yes') {
                this.$toasted.error('Must be working');
            } else if(this.form.medical != 'No') {
                this.$toasted.error('Medical Status');
            } else if(this.form.employeer_name == '') {
                this.$toasted.error('Employeer Name');
            } else if(this.form.occupation == '') {
                this.$toasted.error("Client's Occupation");
            } else if(this.form.income == '') {
                this.$toasted.error("Client's Income");
            } else if(this.form.agentexplainedllp != 'Yes') {
                this.$toasted.error('Explained LP');
            } else if(this.form.schedule_hourid == '') {
                this.$toasted.error('Schedule Enrollment Hour');
            }
            
        },
        
        focusForm:_.debounce(function() {
            const el = this.$el.getElementsByClassName('llpshow')[0];
            console.log(el);
            if (el) {
                // Use el.scrollIntoView() to instantly scroll to the element
                el.scrollIntoView({behavior: 'smooth'});
            }
        }, 500),

        // Starting new ticket question dynamics 
        selectLicenseState(e) {
            if(e.target.value != '') {
                this.q.license_state = e.target.value;
                console.log(e.target.value)
            }
        },
        selectHeightFt(e) {
            if(e.target.value != '') {
                this.q.height_ft = e.target.value;
            }
        },
        selectHeightIn(e) {
            if(e.target.value != '') {
                this.q.height_in = e.target.value;
            }
        },
        selectWeight(e) {
            if(e.target.value != '') {
                this.q.weight = e.target.value;
            }
        },
        SelectWeightTen(e) {
            if(e.target.value != '') {
                this.q.weight_ten = e.target.value;
                console.log('weight 10 ' + e.target.value)
            }
        },
        SelectTobacco(e) {
            if(e.target.value != '') {
                this.q.tobacco = e.target.value;
            }
        },
        SelectMedOne(e) {
            if(e.target.value != '') {
                this.q.med_one = e.target.value;
            }
        },
        SelectMedTwo(e) {
            if(e.target.value != '') {
                this.q.med_two = e.target.value;
            }
        },
        SelectMedThree(e) {
            if(e.target.value != '') {
                this.q.med_three = e.target.value;
            }
        },
        SelectMedFour(e) {
            if(e.target.value != '') {
                this.q.med_four = e.target.value;
            }
        },
        SelectMedFive(e) {
            if(e.target.value != '') {
                this.q.med_five = e.target.value;
            }
        },
        SelectMedSix(e) {
            if(e.target.value != '') {
                this.q.med_six = e.target.value;
            }
        },
        SelectMedSeven(e) {
            if(e.target.value != '') {
                this.q.med_seven = e.target.value;
            }
        },
        SelectMedEight(e) {
            if(e.target.value != '') {
                this.$store.state.q.med_eight = e.target.value;
            }
        },
        SelectMedNine(e) {
            if(e.target.value != '') {
                this.$store.state.q.med_nine = e.target.value;
            }
        },
        SelectMedTen(e) {
            if(e.target.value != '') {
                this.$store.state.q.med_ten = e.target.value;
            }
        },
        SelectMedEleven(e) {
            if(e.target.value != '') {
                this.$store.state.q.med_eleven = e.target.value;
            }
        },
        SelectMedTwelve(e) {
            if(e.target.value != '') {
                this.q.med_twelve = e.target.value;
            }
        },
        SelectMedThirteen(e) {
            if(e.target.va,lue != '') {
                this.q.med_thirteen = e.target.value;
            }
        },
        SelectMedFourteen(e) {
            if(e.target.value != '') {
                this.q.med_fourteen = e.target.value;
            }
        },
        SelectMedFifteen(e) {
            if(e.target.value != '') {
                this.q.med_fifteen = e.target.value;
            }
        },
        SelectMedSixteen(e) {
            if(e.target.value != '') {
                this.q.med_sixteen = e.target.value;
            }
        },
        SelectMedSeventeen(e) {
            if(e.target.value != '') {
                this.q.med_seventeen = e.target.value;
            }
        },
        SelectMedEighteen(e) {
            if(e.target.value != '') {
                this.q.med_eighteen = e.target.value;
            }
        },
        SelectAdditionalOne(e) {
            if(e.target.value != '') {
                this.q.additional_one = e.target.value;
            }
        },
        SelectAdditionalTwo(e) {
            if(e.target.value != '') {
                this.q.additional_two = e.target.value;
            }
        },
        SelectAdditionalThree(e) {
            if(e.target.value != '') {
                this.q.additional_three = e.target.value;
            }
        },
        SelectSecondAddressOne(e) {
            if(e.target.value != '') {
                this.q.secondaddress_one = e.target.value;
            }
        },
        SelectBenSix(e) {
            if(e.target.value != '') {
                this.q.ben_six = e.target.value;
            }
        },
        SelectExistOne(e) {
            if(e.target.value != '') {
                this.q.exist_one = e.target.value;
            }
        },
        SelectExistSix(e) {
            if(e.target.value != '') {
                this.q.exist_six = e.target.value;
            }
        },
        SelectPaymentOne(e) {
            if(e.target.value != '') {
                this.q.payment_one = e.target.value;
            }
        },
        SelectPaymentTwo(e) {
            if(e.target.value != '') {
                this.q.payment_two = e.target.value;
            }
        },
        SelectPaymentFour(e) {
            if(e.target.value != '') {
                this.q.payment_four = e.target.value;
            }
        },
        SelectPaymentTen(e) {
            if(e.target.value != '') {
                this.q.payment_ten = e.target.value;
            }
        },
        SelectOwnerState(e) {
            if(e.target.value != '') {
                this.q.owner_state = e.target.value;
            }
        },
        SelectSecondState(e) {
            if(e.target.value != '') {
                this.q.secondaddress_state = e.target.value;
            }
        },
        acceptOwnerNumber() {
            var x = this.q.owner_phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.q.owner_phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        submitQuestions() {
            this.submitLoading = true;

            
            

           
            console.log(this.q);
            this.submitLoading = false;
        },
        pulltestquestions() {
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.get('pulltestquestions').then(resp => {
                console.log(resp.data);
            });
        }
        

    }

}
</script>
<style scoped>
.form-control-label {
    color: #525458;
    font-size: 0.975rem;
}
#datepicker {
    background-color: white;
    
}
.outlineTicketSuccess {
    border-color: #5c5e5d;
}
.outlineTicketDanger {
    border-color: #ee283c;;
}
.form-control option:disabled {
    display: none;
}
</style>