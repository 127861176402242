<template>
    <div class="col-lg-10 table-responsive" style="margin: 0 auto">
        <table class="table table-cards2 align-items-center">
            <thead>
                <tr style="">
                    <th scope="col" style="max-width:200px;;border:0px solid white;">Name</th>
                    <th scope="col" style="border:0px solid white;">Product</th>
                    <th scope="col" style="border: 0px solid white">Created / Agent</th>
                    <th scope="col" style="border: 0px solid white"></th>
                </tr>
            </thead>
            <tbody class="">
                <tr v-for="(i, index) in questionnaireList" :key="i.id">
                    <th scope="row" :style="styleBorder1(i.viewed)">
                        <div class="media align-items-center">
                            <div>
                                <div class="avatar-parent-child">
                                    <avatar :username="returnName(i.first_name, i.last_name)" :size="60" class="rounded-circle"> </avatar> 
                                    
                                </div>
                            </div>
                            <div class="media-body ml-4">
                                <a href="#" class="name h6 mb-0 text-sm">{{i.first_name + ' ' + i.last_name}}</a> 
                                <small class="d-block font-weight-bold">{{i.email}}</small>
                            </div>
                        </div>
                    </th>
                    <td>
                        <div class="media align-items-start">
                            
                            <div class="media-body">
                                <span class="name h5 mb-0 text-sm">{{i.questionnaire_name}}</span> 
                                
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="media align-items-between">
                            
                            <div class="media-body">
                                <span class="name h5 mb-0 text-sm">{{$moment(i.created_at).local().format('ll')}}</span>
                                <span class="d-block font-weight-light text-muted">{{i.agent.user.name}}</span>
                            </div>
                        </div>
                    </td>
                    <td class="text-right">
                        <div class="actions ml-3">
                            <button @click="showIView(i, index)" class="action-item mr-2" data-toggle="tooltip" title="">
                                <i class="uil uil-user-square " style="font-size:1.18em;"></i>
                            </button>
                            
                        </div>
                    </td>
                </tr>
                <tr v-observe-visibility="scrollBottom"></tr>
            </tbody>
        </table>
        <inquiry-view
            ref="iView"
        />
    </div>
</template>
<script>
import axios from 'axios';
import InfiniteLoading from 'vue-infinite-loading';
import Avatar from 'vue-avatar';
import inquiryView from '../../comps/InquiryView.vue';


export default {
    name: 'message-questionnaire',
    
    components: {
        InfiniteLoading, Avatar, inquiryView
    },
    data() {
        return {
            loading: false,
            loadingS: false,
            routeInitial: false,
            page: 0,
            last_page: 1,
            questionnaireList: [],
            ticketList: [],
            contactInfo: '',
            sIndex: '',
            iviewConfirmPre: false,
            iviewConfirmPreDel: false,
            iviewConfirmSend: false,
            selectedRecruiter: '',
        }
        
    },
    methods: {
        showIView(i, index) {
            if(i.questionnaire_name == 'Diabetic Questionaire') {
                this.$router.push({name: 'admin.ticket.direct', params: {id: i.id}, query: {ticket: i}});
            }
            if(i.questionnaire_name == 'PreQual Questionnaire') {
                this.$router.push({name: 'prequalquestionnaire', params: {id: i.id}, query: {ticket: i}});
            }
        },
        QuestionnaireViewed() {
            this.loading = true;
             axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
             axios.post('/api/questionnaireviewd', this.ticket.id).then(resp => {
                console.log(resp.data);
                this.lading = false;
                this.$toasted.success('Status Viewed');
             }).catch(err => {
                this.loading = false;
             });
        },
        fetchApplicants() {
            console.log("fetching within new module");
            if(this.routeInitial == false) {
                    this.loading = true;
                    this.routeInitial = true;
                    this.loadingS = true;
                }
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.get(`api/fetchquestionnaires?page=${this.page}`).then(resp => {
                console.log(resp);
                this.last_page = resp.data.t.last_page;
                this.questionnaireList.push(...resp.data.t.data);
            }).then(resp2 => {
                this.loading = false;
            }).catch(error => {
                if(err.message == "Request failed with status code 401") {
                      
                        this.$router.push({name: 'Login'});
                        this.$store.dispatch('destroyToken').then(resp => {
                            console.log(resp.data);
                            
                            this.$router.push({name: 'Login'});
                        }).then(resp => {  }).catch(err => { this.outLoading = false });
                    }
                this.loading= false;
            });
        },
        scrollBottom(isVisible) {
            console.log("Fetching bottom within new model");
            if(!isVisible) { return }
            if(this.page >= this.last_page) { return }
            console.log('Bottom');
            this.page++;
            this.fetchApplicants();
        },

        returnName(a, b) {
            return a + ' ' + b;
        },
        styleBorder(i) {
            if(i == false) {
                return {borderLeft: '3px solid #5cc9a7'}
            } else {
                return {}
            } 
            
        },
        styleBorder1(i) {
            if(i == 0) {
                return {borderLeft: '4px solid rgba(0,100,0,0.4)', borderTop: '0px solid #5cc9a7', borderBottom: '0px solid #5cc9a7'}
            }  
            
        },
        styleBorder2(i) {
            if(i == 0) {
                return { borderTop: '1px solid #5cc9a7', borderBottom: '1px solid #5cc9a7'}
            } 
         },
        styleBorder3(i) {
            if(i == 0) {
                return {borderRight: '2px solid #5cc9a7', borderTop: '1px solid #5cc9a7', borderBottom: '1px solid #5cc9a7'}
            } 
        },
    }
}
</script>