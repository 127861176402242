<template>
<div>

    <div class="slice slice-sm bg-section-secondary">
        <div class="container">
            <div v-if="!loading" class="row justify-content-center">
                <div class="col-lg-10">

                    
                    <div class="row mt-4">
                        <div class="col-lg-12">
                            <div class="mb-10">
                                
                                <div class="table-responsive">
                                    <table class="table table-cards align-items-center">
                                        <thead>
                                            <tr>
                                                <th scope="col" style="min-width:240px">Name</th>
                                                <th v-if="order == 'all'" scope="col">Recruiter</th>
                                                <th scope="col">City / State</th>
                                                <th scope="col">Applied</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody> 
                                            <tr v-for="i in appList" :key="i.id">
                                                <th scope="row">
                                                    <div class="media align-items-center">
                                                        <div>
                                                            <div class="avatar-parent-child">
                                                                <avatar :username="returnName(i.first_name, i.last_name)" :size="60" class="rounded-circle"> </avatar> 
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="media-body ml-4">
                                                            <a href="#" class="name h6 mb-0 text-sm">{{i.first_name + ' ' + i.last_name}}</a> 
                                                            <small class="d-block font-weight-bold">{{i.email}}</small>
                                                        </div>
                                                    </div>
                                                </th>
                                                <td v-if="order == 'all'">
                                                    <div class="media align-items-start">
                                                        
                                                        <div v-if="i.recruitor" class="media-body">
                                                            <span class="name h5 mb-0 text-sm">{{i.recruitor.user.name}}</span> 
                                                            
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="media align-items-start">
                                                        
                                                        <div class="media-body">
                                                            <span class="name h5 mb-0 text-sm">{{i.city}}</span> 
                                                            <span class="d-block font-weight-light text-muted">{{$store.state.state[i.state]}}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="media align-items-between">
                                                        
                                                        <div class="media-body">
                                                            <span class="name h5 mb-0 text-sm">{{$moment(i.created_at).local().format('ll')}}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="text-right">
                                                    <div class="actions ml-3">
                                                        <router-link :to="{name: 'admin.applicants.view', params: {id: 4}, query:{applicant: i}}" class="action-item mr-2" data-toggle="tooltip" title="" data-original-title="Quick view">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg> 
                                                        </router-link>
                                                        
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-observe-visibility="scrollBottom"></tr>
                                            

                                    </tbody>
                                </table>
                                <div v-if="appList.length == 0" class="" style="display:flex;align-items:center;justify-content:center;width:100%;margin-top:100px;">
                                    <div class="mt-4" style="">
                                        <div class="h6">
                                            Applicants Will Show Here
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                    
                    </div>
                    <div style="position:absolute;top:0px;margin-top:-10px;right:10px;">
                                <div class="col-auto">
                                    <div class="row">
                                        <div class="col-auto">                                    
                                        </div>
                                        <div class="col-auto">
                                            <select @change="changeOrder" class="form-control custom-select-sm">
                                                <option v-if="!$store.getters.isAdmin" value="default" selected>Default</option>
                                                <option v-if="$store.getters.isAdmin" value="all" selected>View All</option>
                                                <option v-if="$store.getters.isAdmin" value="main">Visionary Main</option> 
                                                
                                                <!-- <option value="missed">Missed</option> -->
                                                                                        
                                            </select>
                                        </div>
                                    </div>   
                                </div>
                            </div>
                </div>
            </div>
            <div v-else style="height:145px;width:100%;display:flex;align-items:center;justify-content:center;">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
            </div>
            
        </div>
        </div>
</div>
</template>
<script>
import axios from 'axios';
import Avatar from 'vue-avatar'
export default {
    components: {
        Avatar,
    },
    data() {
        return {
            appList: [],
            loading: false,
            loadingS: false,
            order: 'default',
            state: {
                AL: "Alabama",
                AZ: "Arizona",
                AR: "Arkansas",
                CA: "California",
                CO: "Colorado",
                CT: "Connecticut",
                DE: "Delaware",
                FL: "Florida",
                GA: "Georgia" ,    
                ID: "Idaho",
                IL: "Illinois",
                IN: "Indiana",
                IA: "Iowa",
                KS: "Kansas",
                KY: "Kentucky",
                LA: "Louisiana",
                ME: "Maine",
                MD: "Maryland",
                MA: "Massachusetts",
                MI: "Michigan",
                MN: "Minnesota",
                MS: "Mississippi",
                MO: "Missouri",
                MT: "Montana",
                NE: "Nebraska",
                NV: "Nevada",
                NH: "New Hampshire",
                NM: "New Mexico",
                NY: "New York",
                NC: "North Carolina",
                ND: "North Dakota",
                OH: "Ohio",
                OK: "Oklahoma",
                OR: "Oregon",
                PA: "Pennsylvania",
                RI: "Rhode Island",
                SC: "South Carolina",
                SD: "South Dakota",
                TN: "Tennessee",
                TX: "Texas",
                UT: "Utah",
                VT: "Vermont",
                VA: "Virginia",
                WA: "Washington",
                WV: "West Virginia",
                WI: "Wisconsin",
                WY: "Wyoming",
            },
            page: 1,
            last_page: 1,
            routeInitial: false,
        }
    },
    created() {
        if(this.$store.getters.isAdmin) { this.order = 'all' }
        this.fetchApplicants();
    },
    methods: {
        fetchApplicants() {
            if(this.routeInitial == false) {
                    this.loading = true;
                    this.routeInitial = true;
                }
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.get(`api/fetchappbyrecr?order=${this.order}&page=${this.page}`).then(resp => {
                console.log(resp);
                this.appList.push(...resp.data.u.data);
                this.last_page = resp.data.u.last_page;
            }).then(resp2 => {
                this.loading = false;
            }).catch(error => {
                if(err.message == "Request failed with status code 401") {
                      
                        this.$router.push({name: 'Login'});
                        this.$store.dispatch('destroyToken').then(resp => {
                            console.log(resp.data);
                            
                            this.$router.push({name: 'Login'});
                        }).then(resp => {  }).catch(err => { this.outLoading = false });
                    }
                this.loading= false;
            });
        },
        scrollBottom(isVisible) {
            if(!isVisible) { return }
            if(this.page >= this.last_page) { return }

            console.log('Bottom');
            this.page++;
            this.fetchApplicants();
        },
        returnName(a, b) {
            return a + ' ' + b;
        },
        changeOrder(event) {
            console.log(event.target.value);
            if(event.target.value == 'all') {
                this.order = 'all';
                this.appList = [];
                this.page = 1;
                this.loadingS = true;
                this.fetchApplicants();
            } else if(event.target.value == 'default') {
                this.order = 'default';
                this.appList = [];
                this.page = 1;
                this.loadingS = true;
                this.fetchApplicants();
            } else if(event.target.value == 'main') {
                this.order = 'main';
                this.appList = [];
                this.page = 1;
                this.loadingS = true;
                this.fetchApplicants();
            }
            
            
        },
    }
    
}
</script>