<template>
<div>
    <section class="pt-5 bg-section-secondary">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="row align-items-center">
                        <div class="col">
                            <span class="surtitle">Visionary INC</span>
                            <h1 class="h2 mb-0">Request for More Information</h1>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </section>
    <div class="slice slice-sm bg-section-secondary">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="row">
                        <div class="col-lg-12">
                            
                            <div>
                                <h5 class="mb-3">General information</h5>
                                <form>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">First name</label> 
                                                <input v-model="form.first_name" class="form-control" type="text" placeholder="Enter your first name">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Last name</label> 
                                                <input v-model="form.last_name" class="form-control" type="text" placeholder="Also your last name">
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Email</label> 
                                                <input v-model="form.email" @blur="validateEmail" class="form-control" :class="{'outlineTicketSuccess' : (form.email == form.confirm_email) && form.email.length > 3}" type="email" placeholder="name@exmaple.com"> 
                                                
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Confirm Email</label> 
                                                <input class="form-control" v-model="form.confirm_email" :class="{'outlineTicketSuccess' : (form.email == form.confirm_email) && form.email.length > 3}" type="email" placeholder="name@exmaple.com"> 
                                                
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Phone</label> 
                                                <input v-model="form.phone" @input="acceptNumber" class="form-control" :class="{'outlineTicketSuccess' : (form.phone == form.confirm_phone) && form.phone.length > 3}" type="email" placeholder="(908) 245-549"> 
                                                
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Confirm Phone</label> 
                                                <input v-model="form.confirm_phone" @input="acceptNumber2" class="form-control" :class="{'outlineTicketSuccess' : (form.phone == form.confirm_phone) && form.phone.length > 3}" type="text" placeholder="(908) 245-549">
                                            </div>
                                        </div>
                                    </div>
                                    
                                <div class="mt-3">
                                    
                                </div>
                            </form>
                        </div>
                        <hr>
                        
                        <div>
                            <div>
                                <div class="row mb-4">
                                    <div class="col-auto col-lg-8">
                                        <h5 class="mb-0">Comments</h5>
                                    </div>
                                    <div class="col col-lg-4 text-right">
                                        
                                    </div>
                                </div>
                                <form>
                                    <div class="row mt-3">
                                        <div class="col-12">
                                            <div class="form-group">
                                            <textarea v-model="form.comment" class="form-control form-control-lg" data-toggle="autosize" placeholder="Tell us a few words ..." rows="3" style="overflow: hidden; overflow-wrap: break-word; resize: none; height: 106px;">
                                            </textarea>
                                        </div>
                                        </div>
                                        <span v-if="!loading" @click="SubmitLearnMore()" type="submit" class="btn btn-block btn-lg btn-primary mt-6">
                                            Send your request
                                            
                                        </span>
                                        <span v-else type="submit" class="btn btn-block btn-lg btn-primary mt-4">
                                            <span>
                                                <div class="spinner-border" role="status">
                                                <span class="sr-only">Loading...</span>
                                                </div>
                                            </span>
                                        </span>

                                    </div>
                                    
                                </form>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div> 
</template>
<script>
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';

export default {
    components: {

    },
    data() {
        return {
            loading: false,
            submitLoading: false,
            form: {
                first_name: '',
                last_name: '',
                email: '',
                confirm_email: '',
                phone: '',
                confirm_phone: '',
                
                name_card: '',
                address: '',
                address_no: '',
                city: '',
                state: '',
                zip: '',
                comment: '',

            },
            emailValid: '',
        }
    },
    created() {

    },
    async mounted() {
      /* this.stripe = await loadStripe("pk_test_51HmP9BId1CZ4fXCBLaKasrUWHQPdLMCMIzrXAIyXtT43BHk6Bc9doqy0IkOQoh3fwf5ZEik7H1555HyBNei4ukmX00UmB25wfZ");
      const elements = this.stripe.elements();
      this.cardElement = elements.create('card', {
        classes: {
          base: 'form-control',
        }
      });
      console.log('creating mounted component');
      this.cardElement.mount('#card-element'); */
    },
    methods: {
        acceptNumber() {
            var x = this.form.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.form.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        acceptNumber2() {
            var x = this.form.confirm_phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.form.confirm_phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        validateEmail() {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(this.form.email)) {
                console.log('email looks alright');  
                this.emailValid = true;
            } else {
                console.log('Please enter a valid email address');
                this.emailValid = false;
            }
        },
        SubmitLearnMore() {
            if(this.form.first_name != '' && this.form.last_name != '' && this.form.email != '' && this.emailValid == true && this.form.email == this.form.confirm_email && this.form.phone != '' && this.form.phone == this.form.confirm_phone) {
                this.loading = true;
                axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
                axios.post('/api/submitlearnmore', this.form).then(resp => {
                    this.loading = false;
                    this.$router.push({name: 'Requestinfo.Success'});
                }).catch(err => {
                    this.loading = false;
                    this.$toasted.error('Please try again');
                });

            }
        },
        submitForm() {
            if(this.first_name != '' && this.form.last_name != '' && this.form.email != '' && this.emailValid == true && this.form.email == this.form.confirm_email && this.form.phone != '' && this.form.phone == this.form.confirm_phone && this.form.name_card != '' && this.form.address != '' && this.form.city != '' && this.form.state != '' && this.form.zip != '') {
                this.submitLoading = true;
                this.processPayment();
            } else {
                this.$toasted.error('Include all values');
            } 
        },
        async processPayment() {
        const {paymentMethod, error} = await this.stripe.createPaymentMethod(
          'card', this.cardElement, {
            billing_details: {
              name: this.form.first_name + ' ' + this.form.last_name,
              email: this.form.email,
              
              
              address: {
                line1: this.form.address,
                city: this.form.city,
                state: this.form.state,
                postal_code: this.form.zip,
               
              }
            }
          }
        );
        
        if(error) {
          console.log(error);
        } else {
          this.form.payment_method_id = paymentMethod.id;
          
            /* this.createOrder(); */
            console.log(paymentMethod)
            axios.post('/api/submitoboardingdetails', this.form).then(resp => {
                console.log(resp.data);
                this.submitLoading = false;
                this.$router.push({name: 'Agent.Success'});
                
            }).then(resp2 => {
                
            }).catch(err => {
                this.submitLoading = false;
                this.$toasted.error("Something went wrong, please try again")
            });
          
        }
      },
    },
}
</script>
<style scoped>
.outlineTicketSuccess {
    border-color: #5cc9a7;
}
.outlineTicketDanger {
    border-color: #ee283c;;
}
</style>