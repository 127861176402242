<template>
<header>
    
    <div class="container-fluid">
    <a @click="$router.go(-1)" class="btn btn-neutral btn-icon-only rounded-circle position-absolute left-4 top-4 d-none d-lg-inline-flex" data-toggle="tooltip" data-placement="right" title="" data-original-title="Go back">
            <span class="btn-inner--icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline>
                </svg>
            </span>
        </a>
        <router-link class="position-absolute center top-4 d-none d-lg-inline-flex" :to="{name: 'Home'}">
        
            <h2 class="display-5 my-4" style="font-weight:100">Visionary Inc</h2> 
        </router-link>
    </div>
</header>

</template>
<script>
export default {
    name: "form-header",
}
</script>