<template>
<div class="card">
            <div class="card-header">
                <h3 class="h3 mb-0">Foresters - Term Life<br></h3>
                <p class="text-sm mb-1">Average to Below Average Health, No Life-Threatening Illnesses, Full Coverage Immediately</p>
                <p class=" mb-4">
                    <button class="btn btn-xs btn-outline-info" @click="ShowForestersUnderwritting()">Underwritting Guide</button>
                </p>
                <div class="h1 text-center mb-0">
                    $<span class="price font-weight-bolder">{{ $parent.foresterstermForm.quote }}

                    </span>
                    
                </div>  
                <div>
                    <span class="h6 text-center" style="margin: 0 auto;">Monthly Price</span>
                </div>
                
            </div>
            <div class="card-body">
                <ul class="list-unstyled text-sm mt-2 mb-2">
                
                    <div class="form-group">
                        <div class="row justify-content-between">
                            <div class="col-auto"><span class="h6 text-center">Coverage Amount</span></div>
                            
                        </div>
                        
                        <div class="h4 text-center mb-0 mt-3">
                            $<span class="price font-weight-bolder">{{ $parent.foresterstermForm.coverageValue }},000</span>
                        </div>
                        
                    </div>
                   
                    <input type="range" class="form-range" :min="$parent.foresterstermForm.minCoverageRange" :max="$parent.foresterstermForm.maxCoverageRange" step="5" :value="$parent.foresterstermForm.coverageValue" @input="showValue" @change="showValueChanges" id="customRange3" style="width:100%;">
                     <!-- <input type="range" class="form-range" :min="$parent.fidelitytermForm.minCoverageRange" :max="$parent.fidelitytermForm.maxCoverageRange" step="5" :value="$parent.fidelitytermForm.coverageValue" @input="showValue" @change="showValueChanges" id="customRange3" style="width:100%;"> -->
                    
                    <div class="form-group mt-2">
                        <div class="row justify-content-between">
                            <div class="col-auto"><span class="h6 text-center">Term Length</span></div>
                            <div class="col-auto" style="display:flex;justify-content:end;">
                                
                            </div>
                        </div>
                        
                        <div class="h4 text-center mb-0 mt-3">
                            <span class="price font-weight-bolder">{{ $parent.foresterstermForm.coverageTerm }}</span> Years
                        </div>
                        
                        
                    </div>
                    <input type="range" class="form-range" :min="$parent.foresterstermForm.minTermRange" :max="$parent.foresterstermForm.maxTermRange" :value="$parent.foresterstermForm.coverageTerm" step="5" @input="showTerm" @change="showValueChanges" id="customRange3" style="width:100%;">
                    
                </ul>
                <div id="form-contact">
            
                    <div class="text-center">
                        
                        <button @click="addToShelf()" type="submit" class="btn btn-block btn-lg btn-primary mt-4 mb-2">
                            <span>Add</span>
                            
                        </button>
<!-- 
                        <button type="submit" class="btn btn-block btn-lg btn-primary mt-4 mb-2">
                            <span>Pending</span>
                            
                        </button> -->
                        
                    </div>
                </div>
            </div>
            <foresters-term
                ref="forestersUnderwritting"
            />
        </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import forestersTerm from '../UModals/ForestersTerm.vue';

export default {
    name: 'fidelityterm-component',
    components: {
        forestersTerm,
    },
    data() {
        return {
            loading: false,
            loadingEdit: false,
            todayYear: new Date().getFullYear() - 18,
            preLead: false,
            quoteSubmitted: false,
            policy: "",
            qValArray: [],
            form: {
                amount: '',
                state: '',
                ss: '',
                gender: '',
                dob: '',
                age: '',
                term: 20,
            },
            prospect: {
                name: '',
                email: '',
                phone: '',
            },
            pLoad: false,
            selectedDay: '',
            selectedMonth: '',
            selectedYear: '',
            tempShowCard: false,
            coverageValue: 75,
            coverageTerm: 15,
            minCoverageRange: 50,
            maxCoverageRange: 400,
            minTermRange: 10,
            maxTermRange: 30,
            emailValid: '',
            allValues: {},
        }
    },
    created() {
        console.log('inside fidelity term')
        console.log('Coverage Value: ' + this.$parent.coverageValue)
        
        if(this.$parent.foresterstermForm.maxCoverageRange < this.$store.state.policy.amount/100) {
            this.$parent.foresterstermForm.coverageValue = this.$parent.foresterstermForm.maxCoverageRange
        }
        if(this.$parent.foresterstermForm.maxCoverageRange > this.$store.state.policy.amount/100) {
            this.$parent.foresterstermForm.coverageValue = this.$store.state.policy.amount / 1000;
        }

        this.$parent.foresterstermForm.amount = this.$parent.fidelitytermForm.coverageValue;

        this.$parent.foresterstermForm.dob = this.$store.state.policy.dob;
        
        this.$parent.foresterstermForm.ss = this.$store.state.policy.ss;
        this.$parent.foresterstermForm.gender = this.$store.state.policy.gender;


        this.fetchQuote();
    },
    mounted() {

    },
    methods: {
        showValue(event) {
          console.log(event.target.value);
          this.$parent.foresterstermForm.coverageValue = event.target.value;
        },
        showTerm(event) {
            console.log(event.target.value);
            this.$parent.foresterstermForm.coverageTerm = event.target.value;
        },
        fetchQuote() {
            /* this.$parent.aigForm.amount = this.$store.state.policy.amount; */
            /* this.$parent.fidelitytermForm.dob = this.$store.state.policy.dob; */


         
            this.$parent.foresterstermForm.age = moment(this.$store.state.policy.dob, "MM/DD/YYYY").fromNow().split(" ")[0];

            let rate = '';
            axios.post('/api/fetchforesterstermquote2', this.$parent.foresterstermForm).then(resp => {
               
                console.log('foresters component quote');
                if(resp.data.qval == null) {
                    this.$parent.showForestersTerm == false;
                    
                }
                console.log('qval length');
                console.log(resp.data.qval == null);
                this.secondInterpretQuote(resp.data);
            
            }).catch(err => {
                console.log('Fidelity term false');
                this.$parent.showForestersTerm = false;
            });
        },
        secondInterpretQuote(i) {
            let nsFemale = [];
            let ssFemale = [];
            let nsMale = [];
            let ssMale = [];

            let funcArray = [];
            let termsArray = [];

                nsFemale.push({name: 10, value: i.qval.ns_female_10});
                nsFemale.push({name: 15, value: i.qval.ns_female_15});
                nsFemale.push({name: 20, value: i.qval.ns_female_20});
                nsFemale.push({name: 25, value: i.qval.ns_female_25});
                nsFemale.push({name: 30, value: i.qval.ns_female_30});

                ssFemale.push({name: 10, value: i.qval.ss_female_10});
                ssFemale.push({name: 15, value: i.qval.ss_female_15});
                ssFemale.push({name: 20, value: i.qval.ss_female_20});
                ssFemale.push({name: 25, value: i.qval.ss_female_25});
                ssFemale.push({name: 30, value: i.qval.ss_female_30});
                
                nsMale.push({name: 10, value: i.qval.ns_male_10});
                nsMale.push({name: 15, value: i.qval.ns_male_15});
                nsMale.push({name: 20, value: i.qval.ns_male_20});
                nsMale.push({name: 25, value: i.qval.ns_male_25});
                nsMale.push({name: 30, value: i.qval.ns_male_30});

                ssMale.push({name: 10, value: i.qval.ss_male_10});
                ssMale.push({name: 15, value: i.qval.ss_male_15});
                ssMale.push({name: 20, value: i.qval.ss_male_20});
                ssMale.push({name: 25, value: i.qval.ss_male_25});
                ssMale.push({name: 30, value: i.qval.ss_male_30});

                if(this.$store.state.policy.gender == 'Female') {
                    if(this.$store.state.policy.ss == 'ns') {
                        funcArray = nsFemale;
                    }
                    if(this.$store.state.policy.ss == 'nss') {
                        funcArray = ssFemale;
                    }
                    
                }
                if(this.$store.state.policy.gender == 'Male') {
                    if(this.$store.state.policy.ss == 'ns') {
                        funcArray = nsMale;
                    }
                    if(this.$store.state.policy.ss == 'ss') {
                        funcArray = ssMale;
                    }
                    console.log('showing func array');
                    console.log(funcArray);
                    
                }
                termsArray = funcArray.filter(item => item.value !== null);
                console.log(termsArray);
                this.qValArray = termsArray;
                console.log('Min term: ' + Number(termsArray[0].name) + ' Max term: ' + Number(termsArray[termsArray.length - 1].name));
              
                this.$parent.foresterstermForm.coverageValue = this.$store.state.policy.amount/1000;
                this.$parent.foresterstermForm.minTermRange = Number(termsArray[0].name);
                this.$parent.foresterstermForm.maxTermRange = Number(termsArray[termsArray.length - 1].name);

                if(this.$parent.foresterstermForm.minTermRange < this.$parent.foresterstermForm.coverageTerm) {
                    this.$parent.foresterstermForm.coverageTerm = this.$parent.foresterstermForm.minTermRange 
                }
                if(this.$parent.foresterstermForm.coverageTerm > this.$parent.foresterstermForm.maxTermRange) {
                    this.$parent.foresterstermForm.coverageTerm = this.$parent.foresterstermForm.maxCoverageRange 
                }   
                
                if(this.$store.state.policy.age >= 18 && this.$store.state.policy.age <= 55) {
                    this.$parent.foresterstermForm.maxCoverageRange = 500;
                }
                if(this.$store.state.policy.age >= 56) {
                    this.$parent.foresterstermForm.maxCoverageRange = 150;
                }
                if(this.$parent.foresterstermForm.coverageValue > this.$parent.foresterstermForm.maxCoverageRange) {
                    this.$parent.foresterstermForm.coverageValue = this.$parent.foresterstermForm.maxCoverageRange;
                }
                var term = termsArray.filter(item => item.name == this.$parent.foresterstermForm.coverageTerm)[0].value;

                if(term != null) {
                    this.$parent.foresterstermForm.quote = (((this.$parent.foresterstermForm.coverageValue * (term/100)) + 60) *  0.0875).toFixed(2);
                }
                console.log('forest term ');
                console.log(term);
                /* console.log(nsFemale);
                console.log(ssFemale);
                console.log(nsMale);
                console.log(ssMale); */
        },
        intepretQuote(i) {
            console.log('intepret fidelity term')
            console.log(i)

            let age = this.$parent.foresterstermForm.age;
            

            /* Set Term Max */
            if(age <= 80 && this.$store.state.policy.gender == 'Male') {
                this.$parent.foresterstermForm.maxTermRange = 10;
            }
            if(age <= 71) {
                this.$parent.foresterstermForm.maxTermRange = 15;
            }
            if(age <= 65) {
                this.$parent.foresterstermForm.maxTermRange = 20;
            }
            if(age <= 55) {
                this.$parent.foresterstermForm.maxTermRange = 25;
            }
            if(age <= 55) {
                this.$parent.foresterstermForm.maxTermRange = 30;
            }

            /* Set Term Value */

            if(this.$parent.foresterstermForm.maxTermRange == 30) {
                this.$parent.foresterstermForm.coverageTerm = 20;
            }
            if(this.$parent.foresterstermForm.maxTermRange == 25) {
                this.$parent.foresterstermForm.coverageTerm = 20;
            }
            if(this.$parent.foresterstermForm.maxTermRange == 20) {
                this.$parent.fidelitytermForm.coverageTerm = 20;
            }
            if(this.$parent.foresterstermForm.maxTermRange == 15) {
                this.$parent.fidelitytermForm.coverageTerm = 15;
            }
            if(this.$parent.foresterstermForm.maxTermRange == 10) {
                this.$parent.foresterstermForm.coverageTerm = 10;
            }
            
            
            /* Set Amount Max & maybe set coverage range here */
            
            this.$parent.fidelitytermForm.maxCoverageRange = 150;

            /* Calculate Quote */
            let rate = '';
            let quote = '';

            if(this.$store.state.policy.gender == 'Male' && this.$store.state.policy.ss == 'ns') {
                if(this.$parent.fidelitytermForm.coverageTerm == 10) {
                   rate = i.ns_male_10;
                }
                if(this.$parent.fidelitytermForm.coverageTerm == 15) {
                   rate = i.ns_male_15;
                }
                if(this.$parent.fidelitytermForm.coverageTerm == 20) {
                   rate = i.ns_male_20;
                }
                if(this.$parent.fidelitytermForm.coverageTerm == 25) {
                   rate = i.ns_male_25;
                }
                if(this.$parent.fidelitytermForm.coverageTerm == 30) {
                   rate = i.ns_male_30;
                }
            }
            if(this.$store.state.policy.gender == 'Female') {
                if(this.$parent.fidelitytermForm.coverageTerm == 10) {
                   rate = i.ns_female_10;
                }
                if(this.$parent.fidelitytermForm.coverageTerm == 20) {
                   rate = i.ns_female_20;
                }
                if(this.$parent.fidelitytermForm.coverageTerm == 30) {
                   rate = i.ns_female_30;
                }
            }
            if(this.$parent.fidelitytermForm.coverageValue > this.$parent.fidelitytermForm.maxCoverageRange) {
                this.$parent.fidelitytermForm.coverageValue = this.$parent.fidelitytermForm.maxCoverageRange;
            }
            
            quote = (((rate * (Number(this.$parent.fidelitytermForm.coverageValue))) + Number(i.premium)) * i.mode_eft);

            this.$parent.fidelitytermForm.quote = quote;

            console.log('Fidelity term rate: ' + rate);
        },
        showValueChanges(e) {
            let rate = this.qValArray.filter(item => item.name == this.$parent.foresterstermForm.coverageTerm)[0].value;
            console.log('from value change');
            console.log(rate);
            if(rate != null) {
                    this.$parent.foresterstermForm.quote = (((this.$parent.foresterstermForm.coverageValue * (rate/100)) + 60) *  0.0875).toFixed(2);
                }
        },
        showValueChanges123123(e) {
             /* Calculate Quote */
                let rate = '';
                let quote = '';
                let i = this.allValues;

                if(this.$store.state.policy.gender == 'Male') {
                    if(this.$parent.fidelitytermForm.coverageTerm == 10) {
                    rate = i.ns_male_10;
                    }
                    if(this.$parent.fidelitytermForm.coverageTerm == 20) {
                    rate = i.ns_male_20;
                    }
                    if(this.$parent.fidelitytermForm.coverageTerm == 30) {
                    rate = i.ns_male_30;
                    }
                }
                if(this.$store.state.policy.gender == 'Female') {
                    if(this.$parent.fidelitytermForm.coverageTerm == 10) {
                    rate = i.ns_female_10;
                    }
                    if(this.$parent.fidelitytermForm.coverageTerm == 20) {
                    rate = i.ns_female_20;
                    }
                    if(this.$parent.fidelitytermForm.coverageTerm == 30) {
                    rate = i.ns_female_30;
                    }
                }
                
                quote = (((rate * (Number(this.$parent.foresterstermForm.coverageValue))) + Number(i.premium)) * i.mode_eft);

                this.$parent.fidelitytermForm.quote = quote;

                console.log('Fidelity term rate: ' + rate);
        },
        
        submitQuote() {
            this.loading = true;

            console.log('start forest contraption');
            console.log(this.$parent.forestForm)
            this.$parent.coverageTerm = this.$parent.forestForm.term;
            this.$parent.forestForm.dob = this.$store.state.policy.dob;
            this.$parent.forestForm.age = moment(this.$store.state.policy.dob, "MM/DD/YYYY").fromNow().split(" ")[0];

            // Logic for quoter 
            if(this.$store.state.policy.gender == "Male" && this.$store.state.policy.ss == "ns") {
                console.log("inside male ns")
                if(this.$parent.forestForm.term == 10 && (this.$parent.forestForm.age == 18 || this.$parent.forestForm.age == 19 || this.$parent.forestForm.age == 20 || this.$parent.forestForm.age == 21 || this.forestForm.age == 22)) {
                    console.log('ages under 22');
                    this.$parent.minTermRange = 15;
                } 
                if((this.$parent.forestForm.age == 18 || this.$parent.forestForm.age == 19 || this.$parent.forestForm.age == 20 || this.$parent.forestForm.age == 21 || this.$parent.forestForm.age == 22)) {
                    console.log('ages under 22');
                    this.$parent.minTermRange = 15;
                }
                if(this.$parent.forestForm.term == 20 && this.$parent.forestForm.age >= 71) {
                    console.log('client is older than 65 for 20 year policy 10');
                    this.$parent.forestForm.term = 10;
                    this.$parent.maxTermRange = 10;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.$parent.forestForm.age > 71) {
                    console.log('client is older than 71'); 
                    this.$parent.maxTermRange = 10;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                }
                if(this.$parent.forestForm.term > 15 && this.$parent.forestForm.age > 65 && this.$parent.forestForm.age < 71) {
                    console.log('client is older than 65 for 20 year policy 15');
                    this.$parent.forestForm.term = parseInt(15);
                    this.$parent.maxTermRange = 15;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.$parent.forestForm.age > 65 && this.$parent.forestForm.age < 71) {
                    console.log('client is older than 65 within index ' + this.$parent.forestForm.term); 
                    this.$parent.maxTermRange = 15;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                }

                if(this.$parent.forestForm.term > 20 && this.$parent.forestForm.age > 55 && this.$parent.forestForm.age < 65) {
                    console.log('client is older than 55 for 25 year policy');
                    this.$parent.forestForm.term = 20;
                    this.$parent.maxTermRange = 20;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.$parent.forestForm.age > 55 && this.$parent.forestForm.age < 65) {
                    console.log('client is older than 55'); 
                    this.$parent.maxTermRange = 20;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                }

                if(this.$parent.forestForm.term > 25 && this.$parent.forestForm.age > 50 && this.$parent.forestForm.age < 55) {
                    console.log('client is older than 50 for 20 year policy');
                    this.$parent.forestForm.term = 25;
                    this.$parent.maxTermRange = 25;
                    this.reSubmit();
                }
                if(this.$parent.forestForm.age > 50 && this.$parent.forestForm.age < 55) {
                    console.log('client is older than 50'); 
                    this.$parent.maxTermRange = 25;
                }
            } else if(this.$parent.forestForm.gender == "Male" && this.$parent.forestForm.ss == "ss") {
                console.log("inside male ss");
                if(this.$parent.forestForm.term == 20 && this.$parent.forestForm.age >= 61) {
                    console.log('client is older than 61 for 20 year policy 10');
                    this.$parent.forestForm.term = 15;
                    this.$parent.maxTermRange = 15;
                    this.reSubmit();
                }
                if(this.$parent.forestForm.age > 71) {
                    console.log('client is older than 71 ss'); 
                    this.$parent.maxTermRange = 10;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                }
                if(this.$parent.forestForm.term > 15 && this.$parent.forestForm.age > 60 && this.$parent.forestForm.age < 71) {
                    console.log('client is older than 60 ss');
                    this.$parent.forestForm.term = parseInt(15);
                    this.$parent.maxTermRange = 15;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.$parent.forestForm.age > 60 && this.$parent.forestForm.age < 71) {
                    console.log('client is older than 60 ss'); 
                    this.$parent.maxTermRange = 15;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                }

                if(this.$parent.forestForm.term > 20 && this.$parent.forestForm.age > 50 && this.$parent.forestForm.age < 60) {
                    console.log('client is older than 55 ss');
                    this.$parent.forestForm.term = 20;
                    this.$parent.maxTermRange = 20;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.$parent.forestForm.age > 50 && this.$parent.forestForm.age < 60) {
                    console.log('client is older than 50'); 
                    this.$parent.maxTermRange = 20;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                }

                if(this.$parent.forestForm.term > 25 && this.$parent.forestForm.age > 45 && this.$parent.forestForm.age < 50) {
                    console.log('client is older than 45');
                    this.$parent.forestForm.term = 25;
                    this.$parent.maxTermRange = 25;
                    this.reSubmit();
                }
                if(this.$parent.forestForm.age > 45 && this.$parent.forestForm.age < 50) {
                    console.log('client is older than 45'); 
                    this.$parent.maxTermRange = 25;
                }
            } else if(this.$parent.forestForm.gender == "Female" && this.$parent.forestForm.ss == "ns") {
                console.log("inside female ns");
                if(this.$parent.forestForm.term == 10 && (this.$parent.forestForm.age == 18 || this.$parent.forestForm.age == 19 || this.$parent.forestForm.age == 20 || this.$parent.forestForm.age == 21 || this.$parent.forestForm.age == 22 || this.$parent.forestForm.age == 23)) {
                    console.log('ages under 23');
                    this.$parent.minTermRange = 15;
                    this.$parent.forestForm.term = 15;
                    
                    this.reSubmit();
                } 
                if((this.$parent.forestForm.age == 18 || this.$parent.forestForm.age == 19 || this.$parent.forestForm.age == 20 || this.$parent.forestForm.age == 21 || this.$parent.forestForm.age == 22 || this.$parent.forestForm.age == 23)) {
                    console.log('ages under 23');
                    this.$parent.minTermRange = 15;
                }
                if(this.$parent.forestForm.term >= 15 && this.$parent.forestForm.age >= 71) {
                    console.log('client is older than 72');
                    this.$parent.forestForm.term = 10;
                    this.$parent.maxTermRange = 10;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.$parent.forestForm.age > 71) {
                    console.log('client is older than 71'); 
                    this.$parent.maxTermRange = 10;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                }
                if(this.$parent.forestForm.term > 15 && this.$parent.forestForm.age > 65 && this.$parent.forestForm.age < 71) {
                    console.log('client is older than 65 for 20 year policy 15');
                    this.$parent.forestForm.term = parseInt(15);
                    this.$parent.maxTermRange = 15;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.$parent.forestForm.age > 65 && this.$parent.forestForm.age < 71) {
                    console.log('client is older than 65 within index ' + this.$parent.forestForm.term); 
                    this.$parent.maxTermRange = 15;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                }

                if(this.$parent.forestForm.term > 20 && this.$parent.forestForm.age > 55 && this.$parent.forestForm.age < 65) {
                    console.log('client is older than 55 for 25 year policy');
                    this.$parent.forestForm.term = 20;
                    this.$parent.maxTermRange = 20;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.$parent.forestForm.age > 55 && this.$parent.forestForm.age < 65) {
                    console.log('client is older than 55'); 
                    this.$parent.maxTermRange = 20;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                }

                if(this.$parent.forestForm.term > 25 && this.$parent.forestForm.age > 50 && this.$parent.forestForm.age < 55) {
                    console.log('client is older than 50 for 20 year policy');
                    this.$parent.forestForm.term = 25;
                    this.$parent.maxTermRange = 25;
                    this.reSubmit();
                }
                if(this.$parent.forestForm.age > 50 && this.$parent.forestForm.age < 55) {
                    console.log('client is older than 50'); 
                    this.$parent.maxTermRange = 25;
                }
            } else if(this.$parent.forestForm.gender == "Female" && this.$parent.forestForm.ss == "ss") {
                console.log("inside female ss");
                if(this.$parent.forestForm.term == 10 && (this.$parent.forestForm.age == 18 || this.$parent.forestForm.age == 19 || this.$parent.forestForm.age == 20 || this.$parent.forestForm.age == 21 || this.$parent.forestForm.age == 22)) {
                    console.log('ages under 23');
                    this.$parent.minTermRange = 15;
                    this.$parent.forestForm.term = 15;
                    
                    this.reSubmit();
                } 
                if((this.$parent.forestForm.age == 18 || this.$parent.forestForm.age == 19 || this.$parent.forestForm.age == 20 || this.$parent.forestForm.age == 21 || this.$parent.forestForm.age == 22)) {
                    console.log('ages under 23');
                    this.$parent.minTermRange = 15;
                }
                
                if(this.$parent.forestForm.age > 71) {
                    console.log('client is older than 71 ss'); 
                    this.$parent.maxTermRange = 10;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                }
                if(this.$parent.forestForm.term > 15 && this.$parent.forestForm.age > 60 && this.$parent.forestForm.age < 71) {
                    console.log('client is older than 60 ss');
                    this.$parent.forestForm.term = parseInt(15);
                    this.$parent.maxTermRange = 15;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.$parent.forestForm.age > 60 && this.$parent.forestForm.age < 71) {
                    console.log('client is older than 60 ss'); 
                    this.$parent.maxTermRange = 15;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                }

                if(this.$parent.forestForm.term > 20 && this.$parent.forestForm.age > 55 && this.$parent.forestForm.age < 60) {
                    console.log('client is older than 55 ss');
                    this.$parent.forestForm.term = 20;
                    this.$parent.maxTermRange = 20;
                    this.$parent.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.$parent.forestForm.age > 55 && this.$parent.forestForm.age < 60) {
                    console.log('client is older than 55'); 
                    this.$parent.maxTermRange = 20;
                    this.$paren.forestForm.amount = 150;
                    this.$parent.maxCoverageRange = 150;
                }

                if(this.$parent.forestForm.term > 25 && this.$parent.forestForm.age > 50 && this.$parent.forestForm.age < 55) {
                    console.log('client is older than 55');
                    this.$parent.forestForm.term = 25;
                    this.$parent.maxTermRange = 25;
                    this.reSubmit();
                }
                if(this.$parent.forestForm.age > 50 && this.$parent.forestForm.age < 55) {
                    console.log('client is older than 45'); 
                    this.$parent.maxTermRange = 25;
                }
            }

            console.log(this.$parent.forestForm);

            axios.post('/api/fetchforestquote', this.$parent.forestForm).then(resp => {
                console.log(resp.data);
                this.$parent.forestForm.quote = resp.data.quote;
                this.$parent.forestForm.startdate = resp.data.policystart;
            });


        },
        reSubmit() {
            console.log("resubmitting ");
            console.log(this.$parent.forestForm);
            /* this.submitQuoteAlt(); */
        },
        submitQuoteAlt() {
            this.$parent.coverageTerm = this.$parent.forestForm.term;
            this.$parent.coverageValue = this.$parent.forestForm.amount;
            this.$parent.forestForm.dob = this.$store.state.policy.dob;

            axios.post('/api/fetchaltforestquote', this.$parent.forestForm).then(resp => {
                this.$parent.forestForm.quote = resp.data.quote;
                this.$parent.forestForm.startdate = resp.data.policystart;
            })
        },
        showValueChange(e) {
            this.$parent.forestForm.amount = this.$parent.coverageValue;
            this.$parent.forestForm.term = this.$parent.coverageTerm;

            axios.post('/api/fetchforestquote', this.$parent.forestForm).then(resp => {
                this.$parent.forestForm.quote = resp.data.quote;
                this.$parent.forestForm.startdate = resp.data.policystart;
            });
        },
        addToShelf() {
            this.$parent.foresterstermForm.id = 'Fidelity Life - Term Rapid Decision Senior Life';
            this.$store.state.shelf = [];
            this.$parent.foresterstermForm.amount = this.$parent.foresterstermForm.coverageValue;
            this.$parent.foresterstermForm.term = this.$parent.foresterstermForm.coverageTerm;
            this.$store.commit('addToShelf', this.$parent.foresterstermForm);
            
            this.$router.push({name: 'q.contactinfo'})
        },
        ShowForestersUnderwritting() {
            $(this.$refs.forestersUnderwritting.$el).modal('show');
        }
    }
}
</script>