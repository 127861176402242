<template>
<div>

    <div class="slice slice-sm bg-section-secondary">
        <div class="container">
            <div v-if="!loading" class="row justify-content-center">
                <div class="col-lg-11">

                    
                    <div class="row mt-4">
                        <div v-if="!loadingS" class="col-lg-12">
                            <div class="mb-10">
                                
                                <div class="table-responsive">
                                    <table class="table table-cards2 align-items-center">
                                        <thead>
                                            <tr>
                                                <th scope="col" style="min-width:240px;border:0px solid white;">Name</th>
                                                <th v-if="$store.getters.isAdmin" scope="col" style="border:0px solid white;">Recruitor</th>
                                                <th scope="col" style="border:0px solid white;">City / State</th>
                                                
                                                <th scope="col" style="border: 0px solid white"></th>
                                            </tr>
                                        </thead>
                                        <tbody> 
                                            <tr v-for="(i, index) in agentList" :key="i.id">
                                                <th scope="row" :style="styleBorder1(i.status)">
                                                    <div class="media align-items-center">
                                                        <div>
                                                            <div class="avatar-parent-child py-2">
                                                                <avatar :username="i.user.name" :size="60" class="rounded-circle"> </avatar> 
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="media-body ml-4">
                                                            <a href="#" class="name h6 mb-0 text-sm">{{i.user.name}}</a> 
                                                            <small class="d-block font-weight-bold">{{i.user.email}}</small>
                                                        </div>
                                                    </div>
                                                </th>
                                                <td v-if="$store.getters.isAdmin">
                                                    <div class="media align-items-start">
                                                        
                                                        <div v-if="i.recruitor" class="media-body">
                                                            <span class="name h5 mb-0 text-sm">{{i.recruitor.user.name}}</span> 
                                                            
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="media align-items-start">
                                                        
                                                        <div v-if="i.applicant" class="media-body">
                                                            <span class="name h5 mb-0 text-sm">{{i.applicant.city}}</span> 
                                                            <span class="d-block font-weight-light text-muted">{{$store.state.state[i.applicant.state]}}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                
                                                <td>
                                                    
                                                </td>
                                                <td class="text-right">
                                                    <div class="actions ml-6" style="display:flex;align-items:center;justify-content:end;">
                                                       
                                                            
                                                                <button v-if="i.applicant" @click="agentContactShow(i.applicant, index)" class="action-item mr-4" style="margin-top:-2px;height:1em;width:1em;">
                                                                    <i class="uil uil-user-square " style="font-size:1.18em;"></i>
                                                                </button>
                                                                
                                                                <button @click="setAgentName(i)" class="action-item mr-2" data-toggle="tooltip" title="" >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg> 
                                                                </button>
                                                            
                                                        
                                                        
                                                        
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-observe-visibility="scrollBottom"></tr>
                                    </tbody>
                                </table>
                                <div v-if="agentList.length == 0" class="" style="display:flex;align-items:center;justify-content:center;width:100%;margin-top:100px;">
                                    <div class="mt-4" style="">
                                        <div class="h6">
                                            Your Signed Agents Will Show Here
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div style="position:absolute;top:0px;margin-top:-10px;right:10px;">
                        <div class="col-auto">
                            <div class="row">
                                <div class="col-auto">                                    
                                </div>
                                <div v-if="this.$store.getters.returnRole.toLowerCase().includes('admin') || (this.$store.getters.returnRole.toLowerCase().includes('enroller') && !this.$store.getters.returnRole.toLowerCase().includes('recruitor'))" class="col-auto">
                                    <select @change="changeOrder" class="form-control custom-select-sm">
                                        <option value="default" selected>Default</option>
                                        <option value="name">Sort By Name A-Z</option>
                                        <option value="namea">Sort By Name Z-A</option> 
                                        <option value="default">Sort by New</option>
                                        <option value="old">Sort by Old</option>
                                                                                 
                                    </select>
                                </div>
                            </div>   
                        </div>
                    </div>
                    
                </div>
            </div>
            <div v-else style="height:145px;width:100%;display:flex;align-items:center;justify-content:center;">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        </div>
        <agent-contact2 
            ref="agentContact"
            
        />
</div>
</template>
<script>
import axios from 'axios';
import Avatar from 'vue-avatar'
import agentContact2 from '../comps/AgentContact2.vue';
export default {
    components: {
        Avatar, agentContact2
    },
    data() {
        return {
            loading: false,
            loadingS: false,
            order: 'default',
            agentList: [],
            contactInfo: '',
            contactInfoIndex: '',
            page: 1,
            last_page: 1,
            routeInitial: false,
            showNoteUpdate: false,
            showPassword: false,
            noteLoading: false,
        }
    },
    created() {
        
        this.fetchAgents();
        
    },
    methods: {
        fetchAgents() {
             axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            if(this.$store.getters.isAdmin || (this.$store.getters.isEnroller)) {
                if(this.routeInitial == false) {
                    this.loading = true;
                    this.routeInitial = true;
                }
               
                axios.get(`/api/fetchagentlist?order=${this.order}&page=${this.page}`).then(resp => {
                    console.log(resp.data.t);
                    this.last_page = resp.data.t.last_page;
                    this.agentList.push(...resp.data.t.data);
                    
                }).then(resp2 => {
                    this.loading = false;
                    this.loadingS = false;
                }).catch(err => { 
                    if(err.message == "Request failed with status code 401") {
                      
                        this.$router.push({name: 'Login'});
                        this.$store.dispatch('destroyToken').then(resp => {
                            console.log(resp.data);
                            
                            this.$router.push({name: 'Login'});
                        }).then(resp => {  }).catch(err => { this.outLoading = false });
                    }
                    this.loading = false; this.loadingS = false; });
            } else if(this.$store.getters.isRecruiter) {
                if(this.routeInitial == false) {
                    this.loading = true;
                    this.routeInitial = true;
                }
               
                axios.get(`/api/fetchagentby?page=${this.page}&page=${this.page}`).then(resp => {
                    console.log('2');
                    console.log(resp.data.rec);
                    this.last_page = resp.data.t.last_page;
                    this.agentList.push(...resp.data.rec.data);
                    this.loading = false;
                    
                }).catch(err => { 
                    if(err.message == "Request failed with status code 401") {
                      
                        this.$router.push({name: 'Login'});
                        this.$store.dispatch('destroyToken').then(resp => {
                            console.log(resp.data);
                            
                            this.$router.push({name: 'Login'});
                        }).then(resp => {  }).catch(err => { this.outLoading = false });
                    }
                    this.loading = false });
            }
            
        },
        scrollBottom(isVisible) {
            console.log('Reached End');
            if(!isVisible) { return }
            if(this.page >= this.last_page) { return }
            console.log('Bottom');
            this.page++;
            this.fetchAgents();
        },
        fetchAgents2() {
            
        },
        styleBorder1(i) {
            if(i != 'inactive') {
                return {borderLeft: '3px solid rgb(92, 201, 167)', borderTop: '0px solid #5cc9a7', borderBottom: '0px solid #5cc9a7'}
            }  
            
        },
        changeOrder(event) {
            console.log(event.target.value);
            if(event.target.value == 'name') {
                this.order = 'name';
                this.agentList = [];
                this.page = 1;
                this.loadingS = true;
                this.fetchAgents();
            } if(event.target.value == 'namea') {
                this.order = 'namea';
                this.agentList = [];
                this.page = 1;
                this.loadingS = true;
                this.fetchAgents();
            } else if(event.target.value == 'default') {
                this.order = 'default';
                this.agentList = [];
                this.page = 1;
                this.loadingS = true;
                this.fetchAgents();
            } else if(event.target.value == 'old') {
                this.order = 'old';
                this.agentList = [];
                this.page = 1;
                this.loadingS = true;
                this.fetchAgents();
            }
        },
        agentContactShow(i, index) {
            this.contactInfo = i;
            this.contactInfoIndex = index;
            this.showPassword = false;
            $(this.$refs.agentContact.$el).modal('show');
        },
        updateValues(app) {
            this.agentList[this.contactInfo].applicant = app;
        },
        setAgentName(i) {
            console.log('chaning route for agent name');
            console.log(i);
            this.$store.state.agentRouteName = i.user.name;
            this.$router.push({name: 'admin.tickets.list', params: {slug: i.slug}});
        },
    }
    
}
</script>