<template>
<div>
    <section v-if="this.$store.state.shelf.length > 0" class="pt-2 bg-section-secondary">
        <div class=container>
            <div class="row justify-content-center">
                <div class=col-lg-9>
                    <div class="row align-items-center">
                        <div class=col>
                            <span class=surtitle></span>
                            <h1 class="h2 mb-0">Ticket Items</h1>
                        </div>
                    </div>
                    <div class="row align-items-center mt-4">
                        <div class=col>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div v-if="this.$store.state.shelf.length > 0" class="slice slice-sm bg-section-secondary">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="row">
                        <div class="col-lg-12">
                            <div v-for="(i, index) in $store.state.shelf" :key="i.id" class="card" style="margin-bottom:4px;">
                                <div class="card-body">
                                    <div class="row row-grid align-items-center">
                                        <div class="col-lg-8">
                                            <div class="media align-items-center">
                                                <span class="avatar bg-success text-white rounded-circle mr-3">
                                                    <i class="uil uil-check" style="font-size: 1.75rem;"></i>
                                                </span>
                                                <div class="media-body">
                                                    <h5 class="mb-0">Guaranteed Issue Whole Life {{i.name}}</h5>
                                                    <p class="text-muted lh-150 text-sm mb-0">Age {{i.age}} | Date of policy start: {{i.startdate}} </p>
                                                    <p class="text-muted lh-150 text-sm mb-0">Coverage Amount: {{formatCurrency2(i.amount * 1000)}} </p>
                                                    <p class="text-muted lh-150 text-sm mb-0">Term: {{ i.term }} </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-auto flex-fill mt-4 mt-sm-0 text-sm-right">
                                            <i @click="clearShelf(index)" class="uil uil-multiply" style="cursor: pointer;"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="$store.state.rider1" class="card" style="padding-top:0px;padding-bottom:0px;margin-bottom:4px">
                                <div class="card-body">
                                    <div class="row row-grid align-items-center">
                                        <div class="col-lg-8">
                                            <div class="media align-items-center">
                                                <span class="avatar bg-success text-white rounded-circle mr-3">
                                                    <i class="uil uil-check" style="font-size: 1.75rem;"></i>
                                                </span>
                                                <div class="media-body">
                                                    <h6 class="mb-0">Guaranteed Issue Whole Life Child Rider</h6>
                                                    <p class="text-muted lh-150 text-sm mb-0">Coverage Amount: {{formatCurrency2($store.state.rider1.amount * 1000)}} </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-auto flex-fill mt-4 mt-sm-0 text-sm-right">
                                            <i @click="clearRider1()" class="uil uil-multiply" style="cursor: pointer;"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card" style="padding-top:0px;padding-bottom:0px;margin-bottom:4px">
                                <div class="card-body">
                                    <div class="row row-grid align-items-center">
                                        <div class="col-lg-12">
                                            <div class="media align-items-end">
                                                
                                                <div class="media-body">
                                                    
                                                    <p class="lh-150 h6 mb-0">Total: ${{formatCurrency2(cartTotal())}} </p>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>                            

                            <div class="row justify-content-end mt-5">
                                    <div class="col-md-4">
                                        <button v-if="this.$store.getters.loggedIn" type="button" @click="$router.push({name: 'Ticket'})" class="btn btn-primary">Create Ticket <i class="uil uil-arrow-right"></i></button>
                                        <button v-else type="button" @click="$router.push({name: 'Login2'})" class="btn btn-primary">Please Login <i class="uil uil-arrow-right"></i></button>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section class=" bg-section-secondary" :class="{ 
            'pt-4' : this.$store.state.shelf.length == 0,
            'pt-0' : this.$store.state.shelf.length > 0, }">
        <div class="container">
            <div class="row justify-content-center">
                <div class=col-lg-9>
                    <div class="row align-items-center">
                        <div class=col>
                            <span class=surtitle></span>
                            <h1 class="h2 mb-0">Your Quote  44353Options</h1>
                        </div>
                    </div>
                    <div class="row align-items-center mt-4">
                        <div class="col">
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <div class="slice slice-sm bg-section-secondary">
        <div class=container>
            <div class="row justify-content-center">
                <div class=col-lg-9>
                    <div class=row>
                        <div class=col-lg-12>
                            <div class=mb-5>
                                <!-- First Item-->
                                <div class=card>
                                    <div class=card-header>
                                        <h3 class="h3 mb-0">Guaranteed Issue Whole Life Insurance</h3>
                                        <p class="text-sm mb-0">Lock in your rate by applying</p>
                                    </div>
                                    <ul class="list-group list-group-flush">
                                        <li class=list-group-item>
                                            <div class="row align-items-center">
                                                <div class=col-md-4>
                                                    <div class="card hover-translate-y-n4 hover-shadow-lg" style="height:185px;">
                                                        <div class="card-body p-5 text-center">
                                                            <div class="pb-1">
                                                                <h2>${{this.$store.state.policy.quote}}</h2>
                                                            </div>
                                                            <div class="pt-2">
                                                                <a href="#" class="h6 stretched-link mb-0">Monthly Price</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class=col-md-5>
                                                    <div class="card hover-translate-y-n4 hover-shadow-lg" style="height:185px;">
                                                        <div class="card-body p-5 text-center">
                                                            <!-- <div v-if="editQuote" class="pb-3 pt-2">
                                                                <h4>{{formatCurrency2(this.editQuoteValue * 1000)}}</h4>
                                                              
                                                            </div>
                                                            <div v-else class="pb-3 pt-2">
                                                                <h4>{{formatCurrency2(this.$store.state.policy.amount * 1000)}}</h4>
                                                            </div> -->
                                                            <div class="pb-3 pt-2">
                                                                <h4>{{formatCurrency2(this.$store.state.policy.amount * 1000)}}</h4>
                                                            </div>
                                                            <div class="pt-2">
                                                                <span class="h6 stretched-link mb-0">Coverage Amount</span>
                                                            </div>
                                                            <!-- <div  style="position:absolute;top:0px;left:10%;z-index:44;">
                                                            
                                                                    <i @click="changeQuoteCoverage" class="uil uil-ellipsis-h" style="font-size:1.45rem;"></i>
                                                                
                                                                
                                                            </div>
                                                            <div v-if="editQuote == true" style="position:absolute;z-index:44;top:5px;right:21%;cursor:pointer;">
                                                                
                                                                <i @click="showTicketQuoter" class="uil uil-pen" style="margin: 0px;padding:0px;font-size:1rem;"></i>
                                                                
                                                            </div>
                                                            <div v-if="editQuote == true" style="position:absolute;z-index:44;top:6px;right:8%">
                                                                
                                                                <i @click="changeQuoteCoverageExit" class="uil uil-multiply" style="margin: 0px;padding:0px"></i>
                                                            </div>
                                                            <div v-if="editQuote" style="position:absolute;height:100%;top:0px;left:0px;z-index:40;display:flex;align-items:center;">
                                                                <i @click="changeQuoteCoverageLeft" class="uil uil-angle-left" style="font-size:4rem;"></i>
                                                            </div>
                                                            <div v-if="editQuote" style="position:absolute;height:100%;top:0px;right:0px;z-index:40;display:flex;align-items:center;">
                                                                <i @click="changeQuoteCoverageRight" class="uil uil-angle-right" style="font-size:4rem;"></i>
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class=col-md-3>
                                                    <div class="">
                                                        <div class=" p-5 text-center">
                                                            <div class="pb-4">
                                                                <button @click="addGuaranteed" type="button" class="btn btn-primary arrow-up-right">
                                                                    Add
                                                                </button>
                                                            </div>
                                                            <div class="pt-2">
                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                
                                               
                                            </div>
                                            
                                        </li>
                                        
                                            
                                        </ul>
                                </div>
                                <!-- Second Item -->
                                
                            </div>
                               
                            </div>
                            <div class="col-lg-9 srollSpouse">
                            <div class="row align-items-center">
                                    <div class=col>
                                        <span class=surtitle></span>
                                        <h4 class="h4 mb-0">View Additional Coverage</h4>
                                    </div>
                                </div>
                                <div class="row align-items-center mt-4">
                                    <div class=col>
                                        
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-lg-12">
                            <div class=mb-5>
                                <!-- First Item-->
                                <div class=card>
                                    <div class=card-header>
                                        <h4 class="h4 mb-0">Guaranteed Issue Whole Life Insurance Spouse Rider</h4>
                                        <p class="text-sm mb-0">Lock in your rate by applying</p>
                                    </div>
                                    <ul class="list-group list-group-flush">
                                        <li v-if="spouseStart || $store.state.policySpouse.amount" class=list-group-item>
                                            <div class="row align-items-center">
                                                <div class=col-md-4>
                                                    <div class="card hover-translate-y-n4 hover-shadow-lg" style="height:185px;">
                                                        <div class="card-body p-5 text-center">
                                                            <div class="pb-1">
                                                                <h2>{{formatCurrency($store.state.policySpouse.quote)}}</h2>
                                                                
                                                            </div>
                                                            <div class="pt-2">
                                                                <a href="#" class="h6 stretched-link mb-0">Monthly Price</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class=col-md-5>
                                                    <div class="card hover-translate-y-n4 hover-shadow-lg" style="height:185px;">
                                                        <div class="card-body p-5 text-center">
                                                            <div v-if="editQuote2" class="pb-3 pt-2">
                                                                <h4>{{formatCurrency2(this.editQuoteValue2 * 1000)}}</h4>
                                                              
                                                            </div>
                                                            <div v-else class="pb-3 pt-2">
                                                                <h4>{{formatCurrency2(this.$store.state.policySpouse.amount * 1000)}}</h4>
                                                            </div>
                                                            <div class="pt-2">
                                                                <span class="h6 stretched-link mb-0">Coverage Amount</span>
                                                            </div>
                                                            <div  style="position:absolute;top:0px;left:10%;z-index:44;">
                                                            
                                                                    <i @click="changeQuoteCoverage2" class="uil uil-ellipsis-h" style="font-size:1.45rem;"></i>
                                                                
                                                                
                                                            </div>
                                                            <div v-if="editQuote2 == true" style="position:absolute;z-index:44;top:5px;right:21%;cursor:pointer;">
                                                                
                                                                <i @click="showSpouseEdit" class="uil uil-pen" style="margin: 0px;padding:0px;font-size:1rem;"></i>
                                                                
                                                            </div>
                                                            <div v-if="editQuote2 == true" style="position:absolute;z-index:44;top:6px;right:8%">
                                                                
                                                                <i @click="changeQuoteCoverageExit2" class="uil uil-multiply" style="margin: 0px;padding:0px"></i>
                                                            </div>
                                                            <div v-if="editQuote2" style="position:absolute;height:100%;top:0px;left:0px;z-index:40;display:flex;align-items:center;">
                                                                <i @click="changeQuoteCoverageLeft2" class="uil uil-angle-left" style="font-size:4rem;"></i>
                                                            </div>
                                                            <div v-if="editQuote2" style="position:absolute;height:100%;top:0px;right:0px;z-index:40;display:flex;align-items:center;">
                                                                <i @click="changeQuoteCoverageRight2" class="uil uil-angle-right" style="font-size:4rem;"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class=col-md-3>
                                                    <div class="">
                                                        <div class=" p-5 text-center">
                                                            <div class="pb-4">
                                                                <button @click="addGuaranteed2" type="button" class="btn btn-primary arrow-up-right">
                                                                    Add
                                                                </button>
                                                            </div>
                                                            <div class="pt-2">
                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                
                                               
                                            </div>
                                            
                                        </li>
                                        <li v-else class=list-group-item>
                                            <div class="row align-items-center" style="padding-bottom:0px;margin-top:0px;padding-top:0px;">
                                                <div class=col-md-12>
                                                    <div class="" style="margin-bottom:10px;">
                                                        <div class="text-center">
                                                            
                                                            <div class="py-6    ">
                                                                <button @click="showSpouseQuoter()" class="btn btn-primary">
                                                                    Quote Spouse
                                                                </button>
                                                            </div>
                                                            
                                                            
                                                            
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                
                                               
                                            
                                                
                                                
                                               
                                            </div>
                                            
                                        </li>
                                        
                                            
                                        </ul>
                                </div>
                                <!-- Second Item -->
                                
                            </div>
                               
                            </div>
                            <div class=col-lg-12>
                            <div class=mb-5>
                                <!-- First Item-->
                                <div class=card>
                                    <div class="card-header">
                                        <h4 class="h4 mb-0">Guaranteed Issue Whole Life Insurance Child Rider</h4>
                                        <p class="text-sm mb-0">Lock in your rate by applying</p>
                                    </div>
                                    <ul class="list-group list-group-flush">
                                        <li class=list-group-item>
                                            <div v-for="(i, index) in rider1" :key="index" class="row align-items-center" style="padding-bottom:0px;margin-bottom:0px;padding-top:0px;">
                                                <div class=col-md-5>
                                                    <div class="card hover-translate-y-n4 hover-shadow-lg" style="margin-bottom:10px;">
                                                        <div class="card-body text-center">
                                                            
                                                            <div class="">
                                                                <h6>{{formatCurrency2(i.amount * 1000)}}</h6>
                                                            </div>
                                                            <div class="">
                                                                <span class="h6 stretched-link mb-0">Coverage Amount</span>
                                                            </div>
                                                            
                                                            
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class=col-md-4>
                                                    <div class="card hover-translate-y-n4 hover-shadow-lg" style="margin-bottom:10px;">
                                                        <div class="card-body text-center">
                                                            <div class="">
                                                                <h6>{{formatCurrency(i.cost)}}</h6>
                                                            </div>
                                                            <div class="">
                                                                <a href="#" class="h6 stretched-link mb-0">Monthly Price</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class=col-md-3>
                                                    <div style="display:flex;align-items:center;justify-content:center;">
                                                        
                                                                <button @click="addRider1(index)" type="button" class="btn btn-sm btn-primary">
                                                                    Add
                                                                </button>
                                                    </div>
                                                            
                                                    
                                                </div>
                                                
                                                
                                               
                                            </div>
                                            
                                        </li>
                                        
                                            
                                    </ul>
                                </div>
                                <!-- Second Item -->
                                
                            </div>
                               
                            </div>

                        <!-- Accident Inurance -->
                        <!-- <div class=col-md-6>
                            <div class=mb-5>
                                <div class=card>
                                    <div class=card-header>
                                        <h4 class="h4 mb-0">Accident Insurance</h4>
                                        <p class="text-sm mb-0" style="height:40px;">Accident protection that can help you get back on your feet.</p>
                                    </div>
                                    <ul class="list-group list-group-flush">
                                       
                                          
                                                
                                               
                                                    <div class="card hover-translate-y-4 m-4 hover-shadow-lg">
                                                        <div class="card-body p-5 text-center" style="h">
                                                            <div class="pb-2">
                                                                <h2>$20</h2>
                                                            </div>
                                                            <div class="pt-2">
                                                                <a href="#" class="h6 stretched-link mb-0">Monthly Price</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="">
                                                        <div class=" text-center">
                                                            <div class="pb-2">
                                                                <button type="button" class="btn btn-primary arrow-up-right">
                                                                    Apply
                                                                </button>
                                                            </div>
                                                            <div class="pt-2">
                                                               <h3 v-for="(i, index) in rider1" :key="index">{{i.amount}}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                            
                                                
                                                
                                                
                                               
                                           
                                        
                                            
                                        </ul>
                                
                                </div>
                               
                                
                            </div>
                               
                        </div> -->
                        <!-- Dental + Vision -->
                        <!-- <div class=col-md-6>
                            <div class=mb-5>
                                <div class=card>
                                    <div class=card-header>
                                        <h4 class="h4 mb-0">Dental + Vision</h4>
                                        <p class="text-sm mb-0" style="height:40px;">Our dentanl and vision coverage just for you.</p>
                                    </div>
                                    <ul class="list-group list-group-flush">
                                       
                                          
                                                
                                               
                                                    <div class="card hover-translate-y-4 m-4 hover-shadow-lg">
                                                        <div class="card-body p-5 text-center" style="h">
                                                            <div class="pb-2">
                                                                <h2>$15</h2>
                                                            </div>
                                                            <div class="pt-2">
                                                                <a href="#" class="h6 stretched-link mb-0">Monthly Price</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="">
                                                        <div class=" text-center">
                                                            <div class="pb-2">
                                                                <button type="button" class="btn btn-primary arrow-up-right">
                                                                    Apply
                                                                </button>
                                                            </div>
                                                            <div class="pt-2">
                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                            
                                                
                                                
                                                
                                               
                                           
                                        
                                            
                                        </ul>
                                
                                </div>
                               
                                
                            </div>
                               
                        </div> -->
                        <!-- Medical Item -->
                        <!-- <div class=col-md-6>
                            <div class=mb-5>
                               
                                <div class=card>
                                    <div class=card-header>
                                        <h4 class="h4 mb-0">Select Medical</h4>
                                        <p class="text-sm mb-0" style="height:40px;">Select medical protection that can help you get back on your feet.</p>
                                    </div>
                                    <ul class="list-group list-group-flush">
                                       
                                          
                                                
                                               
                                                    <div class="card hover-translate-y-4 m-4 hover-shadow-lg">
                                                        <div class="card-body p-5 text-center" style="h">
                                                            <div class="pb-2">
                                                                <h2>$87</h2>
                                                            </div>
                                                            <div class="pt-2">
                                                                <a href="#" class="h6 stretched-link mb-0">Monthly Price</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="">
                                                        <div class=" text-center">
                                                            <div class="pb-2">
                                                                <button type="button" class="btn btn-primary arrow-up-right">
                                                                    Apply
                                                                </button>
                                                            </div>
                                                            <div class="pt-2">
                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                            
                                                
                                                
                                                
                                               
                                           
                                        
                                            
                                        </ul>
                                
                                </div>
                                
                                
                            </div>
                               
                        </div> -->
                       


                            
                           
                        </div>
                    </div>
                </div>
            </div>
    </div>
    <section v-if="this.$store.state.shelf.length > 0" class="pt-2 bg-section-secondary">
        <div class="container">
            <div class="row justify-content-center">
                <div class=col-lg-9>
                    <div class="row align-items-center">
                        <div class="col">
                            <span class="surtitle"></span>
                            <h1 class="h2 mb-0">Ticket Items</h1>
                        </div>
                    </div>
                    <div class="row align-items-center mt-4">
                        <div class="col">
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div v-if="this.$store.state.shelf.length > 0" class="slice slice-sm pb-6 bg-section-secondary">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="row">
                        <div class="col-lg-12">
                            <div v-for="(i, index) in $store.state.shelf" :key="i.id" class="card" style="margin-bottom:4px;">
                                <div class="card-body">
                                    <div class="row row-grid align-items-center">
                                        <div class="col-lg-8">
                                            <div class="media align-items-center">
                                                <span class="avatar bg-success text-white rounded-circle mr-3">
                                                    <i class="uil uil-check" style="font-size: 1.75rem;"></i>
                                                </span>
                                                <div class="media-body">
                                                    <h5 class="mb-0">Guaranteed Issue Whole Life {{i.name}}</h5>
                                                    <p class="text-muted lh-150 text-dsm mb-0">Age {{i.age}} | Date of policy start: {{i.startdate}} </p>
                                                    <p class="text-muted lh-150 text-sm mb-0">Coverage Amount: {{formatCurrency2(i.amount * 1000)}} </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-auto flex-fill mt-4 mt-sm-0 text-sm-right">
                                            <i @click="clearShelf(index)" class="uil uil-multiply" style="cursor: pointer;"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="$store.state.rider1" class="card" style="padding-top:0px;padding-bottom:0px;margin-bottom:4px">
                                <div class="card-body">
                                    <div class="row row-grid align-items-center">
                                        <div class="col-lg-8">
                                            <div class="media align-items-center">
                                                <span class="avatar bg-success text-white rounded-circle mr-3">
                                                    <i class="uil uil-check" style="font-size: 1.75rem;"></i>
                                                </span>
                                                <div class="media-body">
                                                    <h6 class="mb-0">Guaranteed Issue Whole Life Child Rider</h6>
                                                    <p class="text-muted lh-150 text-sm mb-0">Coverage Amount: {{formatCurrency2($store.state.rider1.amount * 1000)}} </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-auto flex-fill mt-4 mt-sm-0 text-sm-right">
                                            <i @click="clearRider1()" class="uil uil-multiply" style="cursor: pointer;"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card" style="padding-top:0px;padding-bottom:0px;margin-bottom:4px">
                                <div class="card-body">
                                    <div class="row row-grid align-items-center">
                                        <div class="col-lg-12">
                                            <div class="media align-items-end">
                                                
                                                <div class="media-body">
                                                    
                                                    <p class="lh-150 h5 mb-0">Total: {{formatCurrency2(cartTotal())}} </p>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            

                            <div class="row justify-content-end mt-5">
                                    <div class="col-md-4">
                                        <button v-if="this.$store.getters.loggedIn" type="button" @click="$router.push({name: 'Ticket'})" class="btn btn-primary">Create Ticket <i class="uil uil-arrow-right"></i></button>
                                        <button v-else type="button" @click="$router.push({name: 'Login2'})" class="btn btn-primary">Please Login <i class="uil uil-arrow-right"></i></button>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ticket-quoter 
        ref="ticketQuoter"
    />
    <spouse-quoter 
        ref="spouseQuoter"
    />
    <spouse-edit 
        ref="spouseEdit"
    />
</div>  
</template>
<script>
import axios from 'axios';
import _ from 'lodash';
import ticketQuoter from '../comps/TicketQuoter2.vue';
import spouseQuoter from '../comps/SpouseQuoter.vue';
import spouseEdit from '../comps/SpouseEdit.vue';
export default {  
    components: {
        ticketQuoter, spouseQuoter, spouseEdit
    },
    data() {
        return {
            editQuote: false,
            editQuote2: false,
            quote2: '',
            editQuoteValue: '',
            editQuoteValue2: '',
            additionalV: '',
            spouseStart: false,
            policySpouse: '',
            rider1: {
                o: {
                    amount: 10,
                    cost: 481,
                },
                1: {
                    amount: 25,
                    cost: 1203,
                }
            },
            rider2: {
                0: {
                    amount: 15,
                    cost: 3746,
                },
                1: {
                    amount: 10,
                    cost: 2734,
                },
                2: {
                    amount: 5,
                    cost: 1722,
                },
            }
        }
    },
    created() {
        window.scrollTo(0,0);
        if(this.$store.state.policySpouse) {
            this.policySpouse = this.$store.state.policySpouse;
            this.policySpouse.month = this.$store.state.policySpouse.dob.split('/')[0];
            this.policySpouse.day = this.$store.state.policySpouse.dob.split('/')[1];
            this.policySpouse.year = ((this.$store.state.policySpouse.dob.split('/')[2]));
            this.policySpouse.ss = this.$store.policySpouse.ss;
            
            this.policySpouse.amount = this.$store.state.policySpouse.amount;
        }
    },
    methods: {
        formatCurrency(amount) {
            amount = (amount / 100);
            return amount.toLocaleString('en', { style: 'currency', currency: 'usd', minimumFractionDigits: 2 });
        },
        formatCurrency2(amount) {
            amount = (amount);
            return amount.toLocaleString('en', { style: 'currency', currency: 'usd', minimumFractionDigits: 0 });
        },
        cartTotal() {
                let amount = this.$store.state.shelf.reduce((acc, item) => acc + (item.quote), 0);
                if(this.$store.state.rider1.cost) {
                    amount += this.$store.state.rider1.cost;
                }
                return amount;
            },
        addGuaranteed() {
            let guaranteed = [];
            guaranteed.id = 1;
            guaranteed.name = "whole Life";
            console.log(guaranteed);
            this.$store.state.policy.id = 1;
            this.$store.state.policy.name = "";
            this.$store.commit('addToShelf', this.$store.state.policy);
            this.$toasted.success('Added!');
        },
        addGuaranteed2() {
            let guaranteed = [];
            guaranteed.id = 2;
            guaranteed.name = "whole Life";
            console.log(guaranteed);
            this.$store.state.policySpouse.id = 2;
            this.$store.state.policySpouse.name = "Spouse Rider";
            this.$store.commit('addToShelf', this.$store.state.policySpouse);
            this.$toasted.success('Added!');
        },
        changeQuoteCoverage() {
            console.log('Hit quote change');
            
            if(this.editQuote == false) {
                this.editQuote = true;
                this.editQuoteValue = this.$store.state.policy.amount;

            } else if(this.editQuote == true) {
                this.editQuote = false;
            }
            
        },
        changeQuoteCoverage2() {
            console.log('Hit quote change');
            
            if(this.editQuote2 == false) {
                this.editQuote2 = true;
                this.editQuoteValue2 = this.$store.state.policySpouse.amount;

            } else if(this.editQuote2 == true) {
                this.editQuote2 = false;
            }
            
        },
        changeQuoteCoverageLeft() {
            if(this.editQuoteValue >= 10) {
                console.log((this.editQuoteValue = this.editQuoteValue - 5));
                let form = this.$store.state.policy;
           
                form.amount = this.editQuoteValue;
                
                axios.post("/api/fetchquote", form).then(resp => {
                    console.log(resp);
                    this.quote2 = resp.data.quote;
                    form.quote = parseInt(resp.data.quote) + 400;
                    this.$store.commit('addPolicy', form);
                });
            }
            
        },
        
        changeQuoteCoverageRight() {
            if(this.editQuoteValue <= 95) {
                console.log((this.editQuoteValue = parseInt(this.editQuoteValue) + 5));
                let form = this.$store.state.policy;
           
                form.amount = this.editQuoteValue;
                
                axios.post("/api/fetchquote", form).then(resp => {
                    console.log(resp);

                    form.quote = parseInt(resp.data.quote) + 400;
                    this.$store.commit('addPolicy', form);
                });
            }
        },
        changeQuoteCoverageLeft2() {
            if(this.editQuoteValue2 >= 10) {
                this.editQuoteValue2 = parseInt(this.editQuoteValue2) - 5;
                console.log(this.editQuoteValue2);
                let form = this.$store.state.policySpouse;
           
                form.amount = this.editQuoteValue2;
                
                axios.post("/api/fetchquote", form).then(resp => {
                    console.log(resp);

                    form.quote = parseInt(resp.data.quote);
                    this.$store.commit('addPolicySpouse', form);
                });
            }
            
        },
        changeQuoteCoverageRight2() {
            if(this.editQuoteValue2 <= 10) {
                console.log((this.editQuoteValue2 = parseInt(this.editQuoteValue2) + 5));
                let form = this.$store.state.policySpouse;
           
                form.amount = this.editQuoteValue2;
                
                axios.post("/api/fetchquote", form).then(resp => {
                    console.log(resp);

                    form.quote = parseInt(resp.data.quote);
                    this.$store.commit('addPolicySpouse', form);
                });
            }
        },
        changeQuoteCoverageExit() {
            this.editQuote = false;
        },
        changeQuoteCoverageExit2() {
            this.editQuote2 = false;
        },
        changeQuoteCoverageAccept() {
            if(this.$store.state.shelf) {
                this.$store.dispatch('clearShelf');
                window.scrollTo(0,0);
            }
            
            let form = this.$store.state.policy;
           
            form.amount = this.editQuoteValue;
            
            axios.post("/api/fetchquote", form).then(resp => {
                console.log(resp);

                form.quote = parseInt(resp.data.quote) + 400;
                this.$store.commit('addPolicy', form);
            });
        },
        clearShelf(i) {
            /* this.$store.dispatch('clearShelf2'); */
            this.$store.state.shelf.splice(i,1);
        },
        showTicketQuoter() {
            $(this.$refs.ticketQuoter.$el).modal('show');
        },
        showTicketQuoter4() {

        },
        showSpouseQuoter() {
            $(this.$refs.spouseQuoter.$el).modal('show');
        },
        showSpouseEdit() {
            $(this.$refs.spouseEdit.$el).modal('show');
        },
        addRider1(i) {
            this.$store.state.rider1 = this.rider1[i];
            this.$toasted.success('Added');
        },
        addRider2(i) {
            this.$store.state.rider2 = this.rider2[i];
            this.$toasted.success('Added');
        },
        clearRider1() {
            this.$store.state.rider1 = '';
        },
        clearRider2() {
            this.$store.state.rider2 = '';
        },
        closeSpouseQuoter() {
            $(this.$refs.spouseQuoter.$el).modal('hide');
            this.focusForm();
        },
        closeSpouseQuoter2() {
            $(this.$refs.spouseEdit.$el).modal('hide');
            this.focusForm();
        },
        focusForm:_.debounce(function() {
            const el = this.$el.getElementsByClassName('srollSpouse')[0];
            console.log(el);
            if (el) {
                // Use el.scrollIntoView() to instantly scroll to the element
                el.scrollIntoView({behavior: 'smooth'});
            }
        }, 500),
           
    }

}
</script>
<style scoped>
.uil-ellipsis-h {
    cursor: pointer;
}
.uil-check {
    font-size: 1.4rem;
    cursor: pointer;
}
.uil-check:hover {
    font-size: 1.47rem;
    
}
.uil-multiply {
    font-size:1rem;
    cursor: pointer;
    
}
.uil-multiply:hover {
    color:black;
    font-size: 1.05rem;
    cursor: pointer;
}
.uil-pen:hover {
    color:black;
    font-size: 1.05rem;
}
.uil-angle-left:hover {
    color:#525a66;
    cursor: pointer;
}
.uil-angle-right:hover {
    color:#525a66;
    cursor: pointer;
}
</style>