<template>
<header class=header-transparent id=header-main>
            <nav class="navbar navbar-main navbar-expand-lg navbar-light shadow" id=navbar-main
                
            >
                <div class=container>
                
                <router-link class=navbar-brand :to="{name: 'Home'}">
                    <h2 class="display-6 my-4" style="font-weight:100">Visionary Inc</h2>
                </router-link>
                <button class=navbar-toggler type=button data-toggle=collapse data-target=#navbar-main-collapse aria-controls=navbar-main-collapse aria-expanded=false aria-label="Toggle navigation">
                    <i class="uil uil-bars" style="color: rgba(0,0,0,0.8);font-size:1.45rem;"></i>
                </button>
                <div class="collapse navbar-collapse navbar-collapse-overlay" id=navbar-main-collapse>
                    <div class=position-relative>
                        <button class=navbar-toggler type=button data-toggle=collapse data-target=#navbar-main-collapse aria-controls=navbar-main-collapse aria-expanded=false aria-label="Toggle navigation">
                            <i class="uil uil-multiply" data-feather=x></i>
                        </button>
                    </div>
                    <ul class="navbar-nav ml-lg-auto">
                        <li class="nav-item nav-item-spaced d-lg-block">
                            <router-link class=nav-link :to="{name: 'Home'}">Home</router-link>
                        </li>
                        <li class="nav-item nav-item-spaced d-lg-block">
                            <router-link class=nav-link :to="{name: 'Overview'}">Overview</router-link>
                        </li>
                        <li class="nav-item nav-item-spaced d-lg-block">
                            <router-link class=nav-link :to="{name:'Benefits'}">Benefits</router-link>
                        </li>
                        <li class="nav-item nav-item-spaced d-lg-block">
                            <router-link class=nav-link :to="{name: 'HowToApply'}">How to Apply</router-link>
                        </li>
                        <li class="nav-item nav-item-spaced d-lg-block">
                            <router-link class=nav-link :to="{name:'About'}">About</router-link>
                        </li>
                        
                        <li class="nav-item nav-item-spaced d-lg-block">
                            <router-link class=nav-link :to="{name: 'Contact'}">Contact Us</router-link>
                        </li>
                        
                        
                       
                </ul>
                <ul class="navbar-nav align-items-lg-center d-lg-flex d-sm-none d-sm-none2 ml-lg-auto">
                    <li v-if="!this.$store.getters.loggedIn" class=nav-item>
                        <router-link class=nav-link :to="{name: 'Login'}">
                            Sign in
                        </router-link>
                    </li>
                
                    <li v-if="this.$store.getters.loggedIn" class="nav-item">
                        <button @click="logStateOut" class="btn btn-sm btn-primary btn-icon ml-3" target="_blank">
                            <span v-if="!outLoading" class="btn-inner--icon">
                                <svg style="margin-top:-3px;" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                            </span> 
                            <span v-if="!outLoading" class="btn-inner--text">Logout</span>
                            <div v-else class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                        </button>
                    </li>
                    <li v-if="!this.$store.getters.loggedIn" class=nav-item>
                        <router-link class=nav-link :to="{name: 'Register'}">
                            Register
                        </router-link>
                    </li>
                    
                </ul>
                <div v-if="this.$store.getters.loggedIn" class="d-lg-none px-4 text-center">
                        
                    <button @click="logStateOut" class="btn btn-block btn-sm btn-primary">
                            
                            <span v-if="!outLoading" class="btn-inner--text">Logout</span>
                            <div v-else class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                        </button>
                </div>
                <div v-if="!this.$store.getters.loggedIn" class="d-lg-none px-4 text-center">
                    
                    <router-link v-if="!this.$store.getters.loggedIn" :class="{'navlink-black' : this.$route.name == 'HowToApply'}" class="btn btn-block btn-sm btn-primary" :to="{name: 'Login'}">
                        Login
                    </router-link>
                </div>
                
            </div>
        </div>
    </nav>
   </header>
</template>
<script>
export default {
    name: 'header-light',
    data() {
        return {
            outLoading: false,
        }
    },
    methods: {
        logStateOut() {
            this.outLoading = true;
            this.$store.dispatch('destroyToken').then(resp => {
                console.log(resp.data);
                this.outLoading = false;
                this.$router.push({name: 'Home'})
            }).then(resp => { this.$router.push({name: 'Home'}) }).catch(err => { this.outLoading = false });
        },
    }
}
</script>