<template>
    <div class="modal fade docs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

        <div class="modal-header">
            <h5 class="modal-title h6" id="myLargeModalLabel">Visionary Inc</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row mt-4 pb-4" style="display: flex; justify-content: center; margin-top: -24px;">
                <div class="col-md-8" style="display: flex; justify-content: center;">
                    <div class="row" style="justify-content: center;">
                        
                        
                        <div class="col-md-12">
                            <div class="card mt-2">
                                <div class="card-body">
                                    <div class="row row-grid align-items-center">
                                        <div class="col-lg-12 text-center">
                                            <div class="media align-items-center">
                                               
                                                <div class="media-body">
                                                    <p>
                                                    <h5 class="">Limited Partnership Explanation</h5></p>
                                                    <p class="mb-0">
                                                        There is NO COST and NO LIABILITY to participate.</p>


                                                        <p>Anyone can become a partner in the Limited Partnership. The Limited Partnership offers a quick and easy way to earn secondary income from online marketing programs. In addition, partners who participate in one or more marketing programs enjoy access to a broad suite of voluntary Health and Life insurance benefits administered by the Administrator.</p>

                                                        <p>The Limited Partnership, whose mission is to provide the American public the ability to earn part-time income through exclusive online marketing programs.

                                                        </p>

                                                        
                                                   
                                                   
                                                </div>
                                            </div>
                                        </div>
                                        
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 pb-2" style="display: flex; justify-content: center; align-items: center; margin-top: 40px;">
                            
                            <button @click="close()" type="submit" class="btn btn-primary btn-loading">
                                
                                <span>Close</span>
                                
                            </button>
                        </div>
                      
                        
                       
                        
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    </div>
</template>
<script>

export default {
    name: "llp",
   
    
    data() {
        return {
            slot: '',
            loading: false,
            form: {
                name: '',
            },
        }
    },
    methods: {
        close() {
            //$(this.$parent.$refs.llp.$el).modal('hide');
            this.$parent.closellp();
        }
    }
   
   
    
}
</script>