<template>
    <div class="modal fade docs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

        <div class="modal-header">
            <h5 class="modal-title h6" id="myLargeModalLabel">Visionary Inc</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" style="padding: 0px">
            <div class="row mt-4 pb-4" style="display: flex; justify-content: center; margin-top: -24px;">
                <div class="col-lg-9 col-12" style="margin: 0 auto;">
                            <div class=" rounded-bottom-right">
                                <div class="card-body">
                                    <div class="d-flex align-items-center mb-4">
                                        <h3 class="display-5 text-dark my-2 " style="margin: 0 auto;">Life Quoter</h3>
                                    </div>
                                    <div class="row justify-content-center list-group-flush mb-4">
                                        <div class="col-12">
                                            <div id="form-contact">
                                                <div class="row">
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                    <label class="form-control-label">Coverage Amount</label> 
                                                    <select v-model="form.amount" class="form-control custom-select-lg">
                                                        <option selected>Amount</option>
                                                        <option value="100">$100,000</option>
                                                        <option value="95">$95,000</option>
                                                        <option value="90">$90,000</option>
                                                        <option value="85">$85,000</option>
                                                        <option value="80">$80,000</option>
                                                        <option value="75">$75,000</option>
                                                        <option value="70">$70,000</option>
                                                        <option value="65">$65,000</option>
                                                        <option value="60">$60,000</option>
                                                        <option value="55">$55,000</option>
                                                        <option value="50">$50,000</option>
                                                        <option value="45">$45,000</option>
                                                        <option value="40">$40,000</option>
                                                        <option value="35">$35,000</option>
                                                        <option value="30">$30,000</option>
                                                        <option value="25">$25,000</option>
                                                        <option value="20">$20,000</option>
                                                        <option value="15">$15,000</option>
                                                        <option value="10">$10,000</option>
                                                        <option value="5">$5,000</option>
                                                    </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                    <label class="form-control-label">State</label> 
                                                    <select v-model="form.state" class="form-control custom-select-lg">
                                                        <option selected>State</option>
                                                        <option value="AL">Alabama</option>
                                                        <option value="AZ">Arizona</option>
                                                        <option value="AR">Arkansas</option>
                                                        <option value="CA">California</option>
                                                        <option value="CO">Colorado</option>
                                                        <option value="CT">Connecticut</option>
                                                        <option value="DE">Delaware</option>
                                                        <option value="FL">Florida</option>
                                                        <option value="GA">Georgia</option>     
                                                        <option value="ID">Idaho</option>
                                                        <option value="IL">Illinois</option>
                                                        <option value="IN">Indiana</option>
                                                        <option value="IA">Iowa</option>
                                                        <option value="KS">Kansas</option>
                                                        <option value="KY">Kentucky</option>
                                                        <option value="LA">Louisiana</option>
                                                        <option value="ME">Maine</option>
                                                        <option value="MD">Maryland</option>
                                                        <option value="MA">Massachusetts</option>
                                                        <option value="MI">Michigan</option>
                                                        <option value="MN">Minnesota</option>
                                                        <option value="MS">Mississippi</option>
                                                        <option value="MO">Missouri</option>
                                                        <option value="MT">Montana</option>
                                                        <option value="NE">Nebraska</option>
                                                        <option value="NV">Nevada</option>
                                                        <option value="NH">New Hampshire</option>
                                                        <option value="NJ">New Jersey</option>
                                                        <option value="NM">New Mexico</option>
                                                        <option value="NC">North Carolina</option>
                                                        <option value="ND">North Dakota</option>
                                                        <option value="OH">Ohio</option>
                                                        <option value="OK">Oklahoma</option>
                                                        <option value="OR">Oregon</option>
                                                        <option value="PA">Pennsylvania</option>
                                                        <option value="RI">Rhode Island</option>
                                                        <option value="SC">South Carolina</option>
                                                        <option value="SD">South Dakota</option>
                                                        <option value="TN">Tennessee</option>
                                                        <option value="TX">Texas</option>
                                                        <option value="UT">Utah</option>
                                                        <option value="VT">Vermont</option>
                                                        <option value="VA">Virginia</option>
                                                        <option value="WA">Washington</option>
                                                        <option value="WV">West Virginia</option>
                                                        <option value="WI">Wisconsin</option>
                                                        <option value="WY">Wyoming</option>

                                                    </select>
                                                </div>
                                                    </div>
                                                </div>
                                                
                                                
                                                <div class="form-group">
                                                    <label class="form-control-label">Smoking Status</label> 
                                                    <select v-model="form.ss" class="form-control custom-select-lg">
                                                        <option selected>Smoking Status</option>
                                                        <option value="1">No</option>
                                                        <option value="2">Yes</option>                                          
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <label class="form-control-label">Gender</label> 
                                                    <select v-model="form.gender" class="form-control custom-select-lg">
                                                        <option selected>Gender</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                    </select>
                                                </div>
                                                
                                                <div class="form-group">
                                                    
                                                    <div class="row">
                                                        <div class="col-4" style="padding-right:2px;">
                                                            <select v-model="form.month" class="form-control custom-select-lg">
                                                                <option selected>Month</option>
                                                                <option value="01">01 - January</option>
                                                                <option value="02">02 - February</option>
                                                                <option value="03">03 - March</option>
                                                                <option value="04">04 - April</option>
                                                                <option value="05">05 - May</option>
                                                                <option value="06">06 - June</option>
                                                                <option value="07">07 - July</option>
                                                                <option value="08">08 - August</option>
                                                                <option value="09">09 - September</option>
                                                                <option value="10">10 - October</option>
                                                                <option value="11">11 - November</option>
                                                                <option value="12">12 - December</option>
                                                            </select> 
                                                        </div>
                                                        <div class="col-4" style="padding-right:2px;padding-left:2px;">
                                                        
                                                            <select v-model="form.day" class="form-control custom-select-lg">
                                                                <option selected>Day</option>
                                                                <option v-for="i in 31" :key="i" :value="i">{{i}}</option>
                                                            
                                                            </select> 
                                                        </div>
                                                        <div class="col-4" style="padding-left:2px;">
                                                            <select v-model="form.year" class="form-control custom-select-lg" >
                                                                <option selected>Year</option>
                                                                <option v-for="i in 100" :key="i" :value="todayYear-i">{{ todayYear-i }}</option>
                                                            </select> 
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="text-center">
                                                    <button type="reset" class="btn-reset d-none">

                                                    </button> 
                                                    <button type="submit" @click="submitQuote" class="btn btn-block btn-lg btn-primary mt-4 mb-2">
                                                        <span v-if="loading == false">Update Quote</span>
                                                        <span v-else>
                                                            <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                            </div>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
            </div>
        </div>
        </div>
    </div>
    </div>
</template>
<script>
import Avatar from 'vue-avatar'
import axios from 'axios';
export default {
    name: "ticket-quoter",
   
    components: {
        Avatar,
    },
    
    data() {
        return {
            slot: '',
            loading: false,
            todayYear: new Date().getFullYear() - 18,
            form: {
                amount: '',
                state: '',
                ss: '',
                gender: '',
                dob: '',
                month: '',
            },
            selectedDay: '',
            selectedMonth: '',
            selectedYear: '',
        }
    },
    created() {
        this.form = this.$store.state.policy;
        this.form.month = this.$store.state.policy.dob.split('/')[0];
        this.form.day = this.$store.state.policy.dob.split('/')[1];
        this.form.year = ((this.$store.state.policy.dob.split('/')[2]));
    },
    methods: {
        acceptSlot() {
            this.loading = true;
            console.log(this.$parent.sInfo.id);
            this.form.hour_id = this.$parent.sHour.id;
            this.form.user_id = JSON.parse(localStorage.getItem('access_token2')).id;
            this.form.ticket_id = this.$parent.sInfo.id;
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.post('/api/scheduleslot', this.form).then(resp => {
                console.log(resp.data);
                this.$parent.ticketList[this.$parent.sIndex].enroller_id = resp.data.e;
                this.$parent.ticketList[this.$parent.sIndex].status = 'queued';
            }).then(resp2 => {
                this.loading = false;
                
                $(this.$parent.$refs.enrAccept.$el).modal('hide');
                this.$parent.$toasted.success("Scheduled");
            }).catch(err => {
                this.loading = false;
                $(this.$parent.$refs.enrAccept.$el).modal('hide');
            });

        },
        submitQuote() {
            console.log('submitting quote');
            console.log(this.form);
            
            let policy = {};
            if(this.form.amount != '' && this.form.state != '' && this.form.ss != '' && this.form.gender != '' && this.form.month != '' && this.form.day != '' && this.form.year != '') {
                this.loading = true;
                this.$store.dispatch('clearShelf');
                this.$store.state.policySpouse = ''; 
                this.$parent.spouseStart = false;
                this.form.dob = this.form.month + '/' + this.form.day + '/' + this.form.year;
                axios.post("/api/fetchquote", this.form).then(resp => {
                    console.log(resp.data.quote);
                    console.log(resp);
                    policy.quote = parseInt(resp.data.quote) + 400;
                    policy.ss = this.form.ss;
                    policy.amount = this.form.amount;
                    policy.startdate = resp.data.policystart;
                    policy.age = resp.data.diff;
                    policy.dob = this.form.dob;
                    policy.gender = this.form.gender;
                    policy.state = this.form.state;
                   
                    this.$store.commit('addPolicy', policy);
                    this.$parent.editQuote = false;
                }). then(resp2 => {
                    this.loading = false;
                    $(this.$parent.$refs.ticketQuoter.$el).modal('hide');
                }).catch(error => {
                    this.loading = false;
                    $(this.$parent.$refs.ticketQuoter.$el).modal('hide');
                });
            } else if(this.form.amount == '') {
                this.$toasted.error('Select Amount');
            } else if(this.form.state == '') {
                this.$toasted.error('Select State');
            } else if(this.form.ss == '') {
                this.$toasted.error('Select Smoking Status');
            } else if(this.form.gender == '') {
                this.$toasted.error('Select Gender');
            } else if(this.form.day == '') {
                this.$toasted.error('Select Day');
            } else if(this.form.month == '') {
                this.$toasted.error('Select Month');
            } else if(this.form.year == '') {
                this.$toasted.error('Select Year');
            }
          
        },
        amountChange(event) {
          if(event.target.value !== "Amount") {
              this.form.amount = event.target.value;
              console.log('succesfully changed');
          } else {
              console.log("please select from the dropdown");
          }
        },
        stateChange(event) {
            if(event.target.value !== "State") {
                this.form.state = event.target.value;
                console.log('succesfully changed states');
            } else {
                console.log("please select from the dropdown");
            }
        },
        ssChange(event) {
          if(event.target.value !== "Smoking Status") {
              this.form.ss = event.target.value;
              console.log('succesfully selected status');
          } else {
              console.log("please select from the dropdown");
          }
        },
        genderChange(event) {
            if(event.target.value !== "Gender") {
                this.form.gender = event.target.value;
                console.log('succesfully selected gender');
            } else {
                console.log("please select from the dropdown");
            }
        },
        changeDay(event) {
          if(event.target.value !== 'Day') {
              this.selectedDay = event.target.value;
              console.log(event.target.value);
          }
        },
        changeMonth(event) {
          if(event.target.value !== 'Month') {
              this.selectedMonth = event.target.value;
              console.log(event.target.value);
          }
        },
        changeYear(event) {
            if(event.target.value !== 'Year') {
                this.selectedYear = event.target.value;
                console.log(event.target.value);
            }
        },
    }
    
}
</script>