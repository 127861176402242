<template>
<div>
    <section class="slice pt-9 pb-7 bg-light">
        <img src="https://media.istockphoto.com/photos/healthy-vision-picture-id1255642556?b=1&k=20&m=1255642556&s=170667a&w=0&h=njv1RVq4LK-FbJGZ7kbSgV-iam3Gl_hWstUYLErlSO0=" alt="Image" class="img-as-bg blend-mode--multiply">
        <span class="mask bg-gradient-dark opacity-2"></span>
        <div data-offset-top="#navbar-main">
            <div class="container d-flex align-items-center text-center text-lg-left">
                <div class="col px-0">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 text-center">
                            <h2 class="h1 text-white mt-6 mb-2">Vision & Dental</h2>
                            <!-- <h3 class="font-weight-light text-white opacity-8">Guaranteed Whole Life Insurance</h3>
                            <h3 class="font-weight-light text-white opacity-8">Individual Policy</h3> -->
                            <div class="my-3">
                                
                            </div>
                            <div class="row mt-5">
                                
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
</div>
</template>
<script>
import flatpickr from "flatpickr";
import axios from 'axios';

export default {
  name: 'Home',
  components: {
    flatpickr,
  },
  data() {
      return {
          loading: false,
          todayYear: new Date().getFullYear() - 18,
          form: {
              amount: '',
              state: '',
              ss: '',
              gender: '',
              dob: '',
          },
          selectedDay: '',
          selectedMonth: '',
          selectedYear: '',
      }
  },
  mounted() {
    flatpickr('#datepicker', {
        dateFormat: "m/d/Y",
        maxDate: "07/28/2003",
    });
  },
  methods: {
      submitQuote() {
          console.log('submitting quote');
          console.log(this.form);
          
          let policy = {};
          if(this.form.amount != '' && this.form.state != '' && this.form.ss != '' && this.form.gender != '' && this.selectedDay != '' && this.selectedMonth != '' && this.selectedYear != '') {
              this.loading = true;
              this.$store.dispatch('clearShelf');
              this.$store.state.policySpouse = '';
                this.$store.state.rider1 = '';
              this.form.dob = this.selectedMonth + '/' + this.selectedDay + '/' + this.selectedYear;
              axios.post("/api/fetchquote", this.form).then(resp => {
                console.log(resp.data.quote);
                console.log(resp);
                policy.quote = parseInt(resp.data.quote) + 400;
                policy.ss = this.form.ss;
                policy.amount = this.form.amount;
                policy.startdate = resp.data.policystart;
                policy.age = resp.data.diff;
                policy.dob = this.form.dob;
                policy.gender = this.form.gender;
                policy.state = this.form.state;
                this.$store.commit('addPolicy', policy);
            }). then(resp2 => {
                this.loading = false;
                this.$router.push({name: 'Quote'})
            });
          } else if(this.form.amount == '') {
              this.$toasted.error('Select Amount');
          } else if(this.form.state == '') {
              this.$toasted.error('Select State');
          } else if(this.form.ss == '') {
              this.$toasted.error('Select Smoking Status');
          } else if(this.form.gender == '') {
              this.$toasted.error('Select Gender');
          } else if(this.selectedDay == '') {
              this.$toasted.error('Select Day');
          } else if(this.selectedMonth == '') {
              this.$toasted.error('Select Month');
          } else if(this.selectedYear == '') {
              this.$toasted.error('Select Year');
          }
          
      },
      amountChange(event) {
          if(event.target.value !== "Amount") {
              this.form.amount = event.target.value;
              console.log('succesfully changed');
          } else {
              console.log("please select from the dropdown");
          }
      },
      stateChange(event) {
          if(event.target.value !== "State") {
              this.form.state = event.target.value;
              console.log('succesfully changed states');
          } else {
              console.log("please select from the dropdown");
          }
      },
      ssChange(event) {
          if(event.target.value !== "Smoking Status") {
              this.form.ss = event.target.value;
              console.log('succesfully selected status');
          } else {
              console.log("please select from the dropdown");
          }
      },
      genderChange(event) {
          if(event.target.value !== "Gender") {
              this.form.gender = event.target.value;
              console.log('succesfully selected gender');
          } else {
              console.log("please select from the dropdown");
          }
      },
      changeDay(event) {
          if(event.target.value !== 'Day') {
              this.selectedDay = event.target.value;
              console.log(event.target.value);
          }
      },
      changeMonth(event) {
          if(event.target.value !== 'Month') {
              this.selectedMonth = event.target.value;
              console.log(event.target.value);
          }
      },
      changeYear(event) {
          if(event.target.value !== 'Year') {
              this.selectedYear = event.target.value;
              console.log(event.target.value);
          }
      }
  },
}
</script>