<template>
<div>

    <div class="slice slice-sm bg-section-secondary">
        <div v-if="!loading" class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">

                    
                    <div class="row mt-3">
                        <div class="col-lg-12">
                            <div class="mb-10">
                                
                                <div class="table-responsive">
                                    <table class="table table-cards align-items-center">
                                        <thead>
                                            <tr>
                                                <th scope="col" style="min-width:240px">Name</th>
                                                <th scope="col">City / State</th>
                                                
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody> 
                                            <tr v-for="(i, index) in agentList" :key="i.id">
                                                <th scope="row">
                                                    <div class="media align-items-center">
                                                        <div>
                                                            <div class="avatar-parent-child">
                                                                <avatar v-if="i.user" :username="i.user.name" :size="60" class="rounded-circle"> </avatar> 
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="media-body ml-4">
                                                            <a v-if="i.user" href="#" class="name h6 mb-0 text-sm">{{i.user.name}}</a> 
                                                            <small v-if="i.user" class="d-block font-weight-bold">{{i.user.email}}</small>
                                                        </div>
                                                    </div>
                                                </th>
                                                <td>
                                                    <div class="media align-items-start">
                                                        
                                                        <div v-if="i.applicant" class="media-body">
                                                            <span class="name h5 mb-0 text-sm">{{i.applicant.city}}</span> 
                                                            <span class="d-block font-weight-light text-muted">{{$store.state.state[i.applicant.state]}}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    
                                                </td>
                                                <td class="text-right">
                                                    
                                                        
                                                    <div class="actions ml-6" style="display:flex;align-items:center;justify-content:end;">
                                                        <button v-if="i.applicant" @click="agentContactShow(i.applicant, index)" class="action-item mr-4" data-toggle="tooltip" title="" style="margin-top:-2px;height:1em;width:1em;">
                                                            <i class="uil uil-user-square" style="font-size:1.18em;"></i>
                                                        </button>
                                                        <button @click="setAgentName(i)" class="action-item mr-2" data-toggle="tooltip" title="" >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg> 
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-observe-visibility="scrollBottom"></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    </div>

                    <div style="position:absolute;top:0px;margin-top:-14px;left:10px;">
                        <div class="col-auto">
                            <button @click="$router.go(-1)" type="button" class="btn btn-xs btn-primary btn-icon rounded-pill">
                                <span class="btn-inner--icon">
                                    <i class="uil uil-arrow-left"></i>
                                </span> 
                                <span class="btn-inner--text">Back</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else style="height:145px;width:100%;display:flex;align-items:center;justify-content:center;">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <agent-contact2 
            ref="agentContact"
            
        />
</div>
</template>
<script>
import axios from 'axios';
import Avatar from 'vue-avatar';
import agentContact2 from '../comps/AgentContact2.vue';
export default {
  
    components: {
        Avatar, agentContact2
    },
    data() {
        return {
            agentList: [],
            alist: [],
            loading: false,
            routeInitial: false,
            page: 1,
            last_page: 1,
            contactInfo: '',
            contactInfoIndex: '',
            showNoteUpdate: false,
            noteLoading: false,
            
        }
    },
    created() {
        this.fetchAgents();
        //this.alist = this.$route.query.alist;
    },
    methods: {
        fetchAgents() {
            if(this.routeInitial == false) {
                    this.loading = true;
                    this.routeInitial = true;
                }
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.get(`/api/fetchagentbyrecruiter?slug=${this.$route.params.slug}&page=${this.page}`).then(resp => {
                console.log(resp);
              

                this.last_page = resp.data.t.last_page;
                this.agentList.push(...resp.data.t.data);
                this.loading = false;
            });
        },
        scrollBottom(isVisible) {
            console.log('Reached End');
            if(!isVisible) { return }
            if(this.page >= this.last_page) { return }
            console.log('Bottom');
            this.page++;
            this.fetchAgents();
        },
        agentContactShow(i, index) {
            this.contactInfo = i;
            this.contactInfoIndex = index;
            this.showNoteUpdate = false; this.noteLoading=false;
            $(this.$refs.agentContact.$el).modal('show');
        },
        updateValues(app) {
            this.agentList[this.contactInfo].applicant = app;
        },
        setAgentName(i) {
           
            this.$store.state.agentRouteName = i.user.name;
            this.$router.push({name: 'admin.tickets.list', params: {slug: i.slug}});
        },
    }
    
}
</script>