<template>
<div>
    
    

    <div v-if="$store.state.shelf.length >= 1" class="slice slice-sm bg-section-secondary" style="margin-top:140px;">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="row">
                        <div class="col-lg-12">
                            <div v-for="(i, index) in this.$store.state.shelf" :key="i.id" class="card" style="margin-bottom:4px;">
                                <div class="card-body">
                                    <div class="row row-grid align-items-center">
                                        <div class="col-lg-8">
                                            <div class="media align-items-center">
                                                <span class="avatar bg-success text-white rounded-circle mr-3">
                                                    <i class="uil uil-check" style="font-size: 1.75rem;"></i>
                                                </span>
                                                <div class="media-body">
                                                    <h5 class="mb-0">{{i.id}}</h5>
                                                    <!-- <p class="text-muted lh-150 text-sm mb-0">Age {{i.age}} | Date of policy start: {{i.startdate}} </p> -->
                                                    <p v-if="i.amount" class="text-muted lh-150 text-sm mb-0">Coverage Amount: {{formatCurrency2(i.amount * 1000)}} </p>
                                                    <p v-if="i.term" class="text-muted lh-150 text-sm mb-0">Term: {{ i.term }} </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-auto flex-fill mt-4 mt-sm-0 text-sm-right">
                                            <p v-if="i.quote" class="text-muted lh-150 text-sm mb-0">{{ formatCurrency2(i.quote) }} </p>
                                        </div>
                                        <div class="col-auto flex-fill mt-4 mt-sm-0 text-sm-right">
                                            <i @click="clearShelf(index)" class="uil uil-multiply" style="cursor: pointer;"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card" style="padding-top:0px;padding-bottom:0px;margin-bottom:4px">
                                <div class="card-body">
                                    <div class="row row-grid align-items-center">
                                        <div class="col-lg-12">
                                            <div class="media align-items-end">
                                                
                                                <div class="media-body">
                                                    
                                                    <!-- <p class="lh-150 h6 mb-0">Total: $ </p> -->
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-end mt-5">
                                    <div class="col-md-4">
                                        <button v-if="$store.getters.loggedIn" type="button" @click="$router.push({name: 'q.contactinfo'})" class="btn btn-primary">Create Ticket <i class="uil uil-arrow-right"></i></button>
                                        <button v-else type="button" @click="$router.push({name: 'Login2'})" class="btn btn-primary">Please Login <i class="uil uil-arrow-right"></i></button>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section class="pt-4 bg-section-secondary" :class="{ 
            'pt-10' : this.$store.state.shelf.length == 0,
            'pt-4' : this.$store.state.shelf.length > 0, }">
        <div class="container">
            <div class="row justify-content-center">
                <div class=col-lg-10>
                    <button type="button" @click="$router.go(-1)" class="btn btn-secondary rounded-circle btn-icon-only" style="margin-top: -38px;position: absolute;left: -76px;">
                            <span class="btn-inner--icon">
                                <i class="uil uil-arrow-left" style="font-size:1.64rem;"></i>
                                
                            </span>
                            </button>
                    <div class="row align-items-center">
                        <div class=col>
                            <span class=surtitle></span>
                            <h1 class="h2 mb-0">Your Quote Options</h1>
                            
                        </div>
                        <div>
                            <i class="uil uil-align-justify" @click="showAdjustments = !showAdjustments" style="font-size:29px;cursor:pointer;"></i>
                        </div>
                        
                    </div>
                    <div v-if="showAdjustments" class="row align-items-center mt-4">
                        
                        <div class="col-md-4 col-sm-12">
                            <!-- <h4 class="block" style="margin-top:-40px;">Adjustments   </h4> -->
                                <div class="card">
                                       
                                <div class="card-body">
                                    <div class="row row-grid">
                                        <div class="col-lg-12">
                                            <div class="media align-items-center">
                                                <span class="avatar bg-success text-white rounded-circle mr-3">
                                                    <i class="uil uil-check" style="font-size: 1.75rem;"></i>
                                                </span>
                                                <div class="media-body" style="margin-left:10px;">
                                                
                                                    <p class="text-muted lh-150 text-sm mb-0">Age: {{$store.state.policy.age}}</p>
                                                    <p class="text-muted lh-150 text-sm mb-0">{{$store.state.policy.dob}}</p>
                                                    <p class="text-muted lh-150 text-sm mb-0">Amount: ${{ parseInt($store.state.policy.amount).toLocaleString('en') }} </p>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                </div>

                                <div class="row mx-n2 justify-content-center align-items-center justify-content-center">
                               
                                    <div class="col-md-12 col-sm-12 px-1">
                                       
                                        <div class="card mb-1" style="cursor:pointer;" @click="selectFixed()" :style="styleBorder1()">
                                            
                                            <div class="card-body py-3" style="padding: 0.26rem">
                                                <div class="d-flex align-items-center">
                                                   <span class="avatar rounded-circle mr-3" style="height:2.4rem;width:2.4rem;">
                                                        <span class="" style="font-size: 1.25rem;background-color:white;color:black;"></span>
                                                    </span>
                                                     <h6 class="mb-0">Peace of Mind 5k-50k</h6>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-sm-12 px-1">
                                       
                                        <div class="card mb-1" style="cursor:pointer;" @click="selectTerm()" :style="styleBorder2()">
                                            
                                            <div class="card-body py-3" style="padding: 0.26rem">
                                                <div class="d-flex align-items-center">
                                                   <span class="avatar rounded-circle mr-3" style="height:2.4rem;width:2.4rem;">
                                                        <span class="" style="font-size: 1.25rem;color:white;"></span>
                                                    </span>
                                                     <h6 class="mb-0">More Coverage 50k+</h6>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    
                                   
                                </div>
                        </div>
                        <div class="col-md-8 col-sm-12">
                            <modal-quoter v-if="modalKey"></modal-quoter>
                            
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </div>
    </section>
    <div class="slice slice-sm bg-section-secondary">
        <div class=container>
            <div class="row justify-content-center">
                <div class=col-lg-9>
                    <div class=row>
                        <div class=col-lg-12>
                            
                            <div v-if="quoteType == 'fixed'">
                            <div v-for="i in $store.state.allQuotes" :key="i.key">
                                <!-- First Item-->
                                <div v-if="showAllQuotes(i.products.name)" class="card mb-4">
                                    <div class=card-header>
                                        <h3 class="h3 mb-0">{{ i.products.name }}</h3>
                                        <p v-if="i.products.description" class="text-sm mb-0">{{ i.products.description }}</p>
                                        <p v-if="i.products.name == 'Mutual of Omaha - Living Promise'" class="mt-1 mb-0">
                                            <button class="btn btn-xs btn-outline-info" @click="ShowMutualUnderwritting()">Underwritting Guide</button>
                                        </p>
                                        <p v-if="i.products.name == 'Fidelity Life - Whole Life Rapid Decision Final Expense'" class="mt-1 mb-0">
                                            <button class="btn btn-xs btn-outline-info" @click="ShowFidelityWholeFinalUnderwritting()">Underwritting Guide</button>
                                        </p>
                                        <p v-if="i.products.name == 'Federal Life - Express Choice'" class="mt-1 mb-0">
                                            <button class="btn btn-xs btn-outline-info" @click="ShowFederalFinalUnderwritting()">Underwritting Guide</button>
                                        </p>
                                    </div>
                                    <ul class="list-group list-group-flush">
                                        <li class=list-group-item>
                                            <div class="row align-items-center">
                                                <div class=col-md-4>
                                                    <div class="card hover-translate-y-n4 hover-shadow-lg" style="">
                                                        <div class="card-body text-center">
                                                            <div class="">
                                                               
                                                                <h2>{{ formatCurrency2(i.quote) }}</h2>
                                                            </div>
                                                            <div class="">
                                                                <a href="#" class="h6 stretched-link mb-0">Monthly Price</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class=col-md-5>
                                                    <div class="card hover-translate-y-n4 hover-shadow-lg" style="">
                                                        <div class="card-body text-center">
                                                            <!-- <div v-if="editQuote" class="pb-3 pt-2">
                                                                <h4>{{formatCurrency2(this.editQuoteValue * 1000)}}</h4>
                                                              
                                                            </div>
                                                            <div v-else class="pb-3 pt-2">
                                                                <h4>{{formatCurrency2(this.$store.state.policy.amount * 1000)}}</h4>
                                                            </div> -->
                                                            <div class="">
                                                                <h4>${{parseInt(i.amount * 1000).toLocaleString('en') }}</h4>
                                                            </div>
                                                            <div class="">
                                                                <span class="h6 stretched-link mb-0">Coverage Amount</span>
                                                            </div>
                                                            <!-- <div  style="position:absolute;top:0px;left:10%;z-index:44;">
                                                            
                                                                    <i @click="changeQuoteCoverage" class="uil uil-ellipsis-h" style="font-size:1.45rem;"></i>
                                                                
                                                                
                                                            </div>
                                                            <div v-if="editQuote == true" style="position:absolute;z-index:44;top:5px;right:21%;cursor:pointer;">
                                                                
                                                                <i @click="showTicketQuoter" class="uil uil-pen" style="margin: 0px;padding:0px;font-size:1rem;"></i>
                                                                
                                                            </div>
                                                            <div v-if="editQuote == true" style="position:absolute;z-index:44;top:6px;right:8%">
                                                                
                                                                <i @click="changeQuoteCoverageExit" class="uil uil-multiply" style="margin: 0px;padding:0px"></i>
                                                            </div>
                                                            <div v-if="editQuote" style="position:absolute;height:100%;top:0px;left:0px;z-index:40;display:flex;align-items:center;">
                                                                <i @click="changeQuoteCoverageLeft" class="uil uil-angle-left" style="font-size:4rem;"></i>
                                                            </div>
                                                            <div v-if="editQuote" style="position:absolute;height:100%;top:0px;right:0px;z-index:40;display:flex;align-items:center;">
                                                                <i @click="changeQuoteCoverageRight" class="uil uil-angle-right" style="font-size:4rem;"></i>
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class=col-md-3>
                                                    <div class="">
                                                        <div class=" p-5 text-center">
                                                            <div class="pb-4">
                                                                <button @click="addToShelf1(i)" type="button" class="btn btn-primary arrow-up-right">
                                                                    Apply
                                                                </button>
                                                            </div>
                                                            <div class="pt-2">
                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                
                                               
                                            </div>
                                            
                                        </li>
                                        
                                            
                                    </ul>
                                </div>
                            </div>
                            </div>
                            <transamerica-component v-if="transamericaKey"></transamerica-component>
                            <aig-component v-if="aigKey && quoteType == 'fixed' && showAig"></aig-component>
                            <fidelitywl-component v-if="fidelitywlKey && showFidelitywl && $store.state.policy.state != 'MT'"></fidelitywl-component>
                            <!-- Test term component -->
                            <div class="row">
                               <div class="row">
                                    <div v-if="quoteType == 'term' && forestersKey && showForestersTerm" class="col-md-6 col-sm-12">
                                        <foresters-component></foresters-component>
                                    </div>
                                    <div class="col-md-6 col-sm-12" v-if="fidelitytermKey && showFidelityterm && $store.state.policy.state != 'MA' && $store.state.policy.state != 'MT' && $store.state.policy.state != 'NH' && $store.state.policy.state != 'NJ' && $store.state.policy.state != 'NC' && $store.state.policy.state != 'PA' && $store.state.policy.state != 'WA'">
                                        <fidelityterm-component></fidelityterm-component>
                                    </div>
                                    <div v-if="quoteType == 'term' && instatermKey && showInstatermTerm && $store.getters.isAdmin" class="col-md-6 col-sm-12">
                                        <instaterm-component></instaterm-component>
                                    </div>
                                    
                                </div>
                                <div v-if="quoteType == 'term' && turbotermKey && this.$store.state.policy.amount >= 100" class="">
                                    <turboterm-component></turboterm-component>
                                </div>  
                            <div class=mb-5>
                                <!-- First Item-->
                               
                            </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <mutual-omaha
        ref="mutualUnderwritting"
    />
    <fidelity-final
        ref="fidelityFinalUnderwritting"
    />
    <federal-final
        ref="federalFinalUnderwritting"
    />
</div>
</template>
<script>
import axios from 'axios';
import modalQuoter from './comps/ModalQuoter.vue';
import forestersComponent from './comps/term/ForestersComponent2.vue';
import InstatermComponent from './comps/term/InstatermComponent.vue';
import aigComponent from './comps/term/AigComponent.vue';
import fidelitywlComponent from './comps/term/FidelitywlComponent.vue';
import fidelitytermComponent from './comps/term/FidelitytermComponent.vue';
import transamericaComponent from './comps/term/TransamericaComponent.vue';
import mutualOmaha from './comps/UModals/MutualOmaha.vue';
import fidelityFinal from './comps/UModals/FindelityFinal.vue';
import federalFinal from './comps/UModals/FederalFinal.vue';
import turbotermComponent from './comps/term/TurbotermComponent.vue';
export default {
    components: {
        modalQuoter, forestersComponent, aigComponent,
        fidelitywlComponent, fidelitytermComponent, transamericaComponent,
        InstatermComponent, mutualOmaha, fidelityFinal, federalFinal, turbotermComponent,
    },
    data() {
        return {
            showAdjustments: true,
            todayYear: new Date().getFullYear() - 18,

            coverageValue: 75,
            coverageTerm: 15,
            minCoverageRange: 50,
            maxCoverageRange: 400,
            minTermRange: 10,
            maxTermRange: 30,
            emailValid: '',

            forestForm: {
                amount: '',
                state: '',
                ss: '',
                gender: '',
                dob: '',
                age: '',
                term: 20,
                quote: '',
                startdate: '',
            },
            showForesters: true,
            forestersKey: true,
            aigForm: {
                id: '',
                amount: '',
                quote: '',
                dob: '',
            },
            showAig: true,
            aigKey: true,
            fidelitywlForm: {
                id: '',
                amount: '',
                quote: '',
                dob: '',
            },
            showFidelitywl: true,
            fidelitywlKey: true,
            showTransamerica: true,
            transamericaKey: true,
            transamericaForm: {
                pQuote: '',
                pAmount: '',
                pShow: false,
                sQuote: '',
                sAmount: '',
                sShow: false,
                gQuote: '',
                gAmount: '',
                gShow: false,
                values: {},
            },
            fidelitytermForm: {
                id: '',
                amount: '',
                term: 20,
                quote: '',
                dob: '',
                minCoverageRange: 10,
                maxCoverageRange: 150,
                coverageValue: 75,
                minTermRange: 10,
                maxTermRange: 30,
                coverageTerm: 20,

            },
            foresterstermForm: {
                id: '',
                amount: '',
                term: 20,
                quote: '',
                dob: '',
                minCoverageRange: 10,
                maxCoverageRange: 150,
                coverageValue: 75,
                minTermRange: 10,
                maxTermRange: 30,
                coverageTerm: 20,
                gender: '',
                ss: '',

            },
            showForestersTerm: true,
            instatermForm: {
                id: '',
                amount: '',
                term: 20,
                quote: '',
                dob: '',
                minCoverageRange: 50,
                maxCoverageRange: 250,
                coverageValue: 75,
                minTermRange: 10,
                maxTermRange: 25,
                coverageTerm: 20,
                gender: '',
                ss: '',

            },
            turbotermForm: {
                id: '',
                amount: '',
                term: 20,
                quote: '',
                dob: '',
                minCoverageRange: 100,
                maxCoverageRange: 1000,
                coverageValue: 250,
                minTermRange: 10,
                maxTermRange: 30,
                coverageTerm: 20,
                gender: '',
                ss: '',

            },
            turbotermKey: true,
            showInstatermTerm: true,
            instatermKey: true,
            showFidelityterm: true,
            fidelitytermKey: true,
            modalKey: true,
            quoteType: '',
        }
    },
    created() {
        console.log(this.$store.state.policy)
        this.$store.state.shelf = [];
        this.quoteType = this.$store.state.quoteType;
    },
    mounted() {

    },
    methods: {
        showAllQuotes(i) {
            console.log('Within all quotes method: ' + i);
            
            if(i == 'Fidelity Life - Whole Life Rapid Decision Final Expense') {
                if(this.$store.state.policy.state != 'MT') {
                    return true;
                } else {
                    return false;
                }
            } else if(i == 'Federal Life - Express Choice') {
                if(this.$store.state.policy.state != 'AK' && this.$store.state.policy.state != 'AR' && this.$store.state.policy.state != 'CA' && this.$store.state.policy.state != 'CT' && this.$store.state.policy.state != 'DE' && this.$store.state.policy.state != 'DC' && this.$store.state.policy.state != 'FL' && this.$store.state.policy.state != 'HI' && this.$store.state.policy.state != 'ID' && this.$store.state.policy.state != 'MT' && this.$store.state.policy.state != 'NC' && this.$store.state.policy.state != 'ND' && this.$store.state.policy.state != 'OK' && this.$store.state.policy.state != 'RI' && this.$store.state.policy.state != 'SD' && this.$store.state.policy.state != 'WY') {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        },
        showAd() {
            console.log('ello')
            
        },
        returnMonthly(i) {
            let rate = '';
            let quote = '';
            if(this.$store.state.policy.gender == 'Male' && this.$store.state.policy.ss == 'ns') {
                
                rate = i.m_ns_rate;
            }
            if(this.$store.state.policy.gender == 'Male' && this.$store.state.policy.ss == 'ss') {
                rate = i.m_ss_rate;
            }
            if(this.$store.state.policy.gender == 'Female' && this.$store.state.policy.ss == 'ns') {
                rate = i.f_ns_rate;
            }
            if(this.$store.state.policy.gender == 'Female' && this.$store.state.policy.ss == 'ss') {
                rate = i.f_ss_rate;
            }

            /* Set logic to set min and max values depending on product  */
            let amount = Number(this.$store.state.policy.amount).toFixed(2);

            if(amount < i.min_coverage) {
                amount = Number(i.min_coverage).toFixed(2);
            }
            if(amount > i.max_coverage) {
                amount = Number(i.max_coverage).toFixed(2);
            }
            amount = Number(amount) / 1000;
            

            
            
            console.log('Amount: ' + amount);
            quote = (((rate * amount) + Number(i.premium)) * i.mode_eft);
            if(Number(amount * 1000) == Number(this.$store.state.policy.amount)){
                return quote;
            }
            
        },
        formatCurrency(amount) {
            amount = (amount / 100);
            return amount.toLocaleString('en', { style: 'currency', currency: 'usd', minimumFractionDigits: 2 });
        },
        formatCurrency2(amount) {
            amount = (amount);
            return amount.toLocaleString('en', { style: 'currency', currency: 'usd', minimumFractionDigits: 2 });
        },
        cartTotal() {
            let amount = this.$store.state.shelf.reduce((acc, item) => acc + (item.quote), 0);
            if(this.$store.state.rider1.cost) {
                amount += this.$store.state.rider1.cost;
            }
            return amount;
        },
        renderForesters() {
            console.log("Re rendering foresters")
            this.forestersKey = false;
            this.$nextTick().then(() => {
                // Add the component back in
                this.forestersKey = true;
            });
        },
        renderAig() {
            console.log("Re rendering foresters")
            this.aigKey = false;
            this.$nextTick().then(() => {
                // Add the component back in
                this.aigKey = true;
            });
        },
        renderFidelitywl() {
            console.log("Re rendering foresters")
            this.fidelitywlKey = false;
            this.$nextTick().then(() => {
                // Add the component back in
                this.fidelitywlKey = true;
            });
        },
        renderTransamerica() {
            console.log("Re rendering transamerica")
            this.transamericaKey = false;
            this.$nextTick().then(() => {
                // Add the component back in
                this.transamericaKey = true;
            });
        },
        renderFidelityterm() {
            console.log("Re rendering fidelity term")
            this.fidelitytermKey = false;
            this.showFidelityterm = true;
            this.$nextTick().then(() => {
                // Add the component back in
                this.fidelitytermKey = true;
            });
        },
        returnTurboterm() {
            this.turbotermKey = false;
            this.$nextTick().then(() => {
                this.turbotermKey = true;
            });
        },
        renderInstaterm() {
            console.log("Re rendering instaterm")
            this.instatermKey = false;
            this.$nextTick().then(() => {
                // Add the component back in
                this.instatermKey = true;
            });
        },
        renderModalKey() {
            
            this.modalKey = false;
            this.$nextTick().then(() => {
                // Add the component back in
                this.modalKey = true;
            });
        },
        addToShelf1(i) {
            
            i.id = i.products.name;
            
            console.log(i);

            this.$store.state.shelf = [];
            this.$store.commit('addToShelf', i);

            if(i.id == 'Mutual of Omaha - Living Promise') {
                this.$router.push({name: 'q.contactinfo'})
            }
            if(i.id == 'Fidelity Life - Whole Life Rapid Decision Final Expense') {
                this.$router.push({name: 'q.contactinfo'})
            }
            if(i.id == 'Federal Life - Express Choice') {
                this.$router.push({name: 'q.contactinfo'})
            }
        },
        clearShelf(i) {
            /* this.$store.dispatch('clearShelf2'); */
            this.$store.state.shelf.splice(i,1);
        },
        selectFixed() {
            console.log('clicked fixed')
            this.quoteType = 'fixed';
            this.$store.state.policy.amount = 25000;
            this.$store.state.quoteType = 'fixed';
            this.renderModalKey();
            this.renderFidelityterm();
            this.renderFidelityw();
            this.renderAig();
            this.renderTransamerica();
            axios.post('/api/fetchquoteall', this.$store.state.policy).then(resp => {
                console.log(resp.data);
                /* this.$store.state.allQuotes = resp.data.qval; */
                
                this.$store.state.allQuotes = [];
                this.$store.state.quoteType = this.$parent.showQuoter;
            
               

                resp.data.qval.forEach(this.iterateProducts);
            }).then(resp2 => {
                
            });
            
        },
        selectTerm() {
            console.log('clicked term')
            this.quoteType = 'term';
            this.$store.state.policy.amount = 75000;
            this.$store.state.quoteType = 'term';
            this.renderModalKey();
            this.renderFidelityterm();
            this.renderTransamerica();
            this.renderForesters();
            this.renderFidelitywl();
        },
        iterateProducts(i) {
            console.log('Inside iterate products');
            console.log(i)

            let rate = '';
            let quote = '';
            if(this.$store.state.policy.gender == 'Male' && this.$store.state.policy.ss == 'ns') {
                
                rate = i.m_ns_rate;
            }
            if(this.$store.state.policy.gender == 'Male' && this.$store.state.policy.ss == 'ss') {
                rate = i.m_ss_rate;
            }
            if(this.$store.state.policy.gender == 'Female' && this.$store.state.policy.ss == 'ns') {
                rate = i.f_ns_rate;
            }
            if(this.$store.state.policy.gender == 'Female' && this.$store.state.policy.ss == 'ss') {
                rate = i.f_ss_rate;
            }

            /* Set logic to set min and max values depending on product  */
            let amount = Number(this.$store.state.policy.amount).toFixed(2);

            if(amount < Number(i.min_coverage)) {
                amount = Number(i.min_coverage).toFixed(2);
            }
            if(amount > Number(i.max_coverage)) {
                amount = Number(i.max_coverage).toFixed(2);
            }
            amount = Number(amount) / 1000;
            

            
            
            console.log('Amount: ' + amount);
            quote = (((rate * amount) + Number(i.premium)) * i.mode_eft);
            i.amount = amount;
            i.quote = quote;
            
            this.$store.state.allQuotes.push(i);
        },
        styleBorder1() {
            if(this.quoteType == 'fixed') {
                return { boxShadow: '0 0rem 0.42rem #7180965e' }
            } 
            
        },
        styleBorder2() {
            if(this.quoteType == 'term') {
                return { boxShadow: '0 0rem 0.42rem #7180965e' }
            } 
            
        },
        ShowMutualUnderwritting() {
            $(this.$refs.mutualUnderwritting.$el).modal('show');
        },
        ShowFidelityWholeFinalUnderwritting() {
            $(this.$refs.fidelityFinalUnderwritting.$el).modal('show');
        },
        ShowFederalFinalUnderwritting() {
            $(this.$refs.federalFinalUnderwritting.$el).modal('show');
        },

    }
}
</script>
<style scoped>
 .uil-align-justify:hover {
     color:rgb(80, 80, 80);
 }
 .selectedQuoter {
     box-shadow: 0 0rem 0.42rem #7180965e;
 }
</style>